import { DialogTitle, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { FC } from "react";
import { carbon } from "~/styles/chartedsailsColors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: carbon[50],
    "& .MuiTypography-h4": {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(6),
    },
    "& .MuiTypography-body1": {
      marginTop: theme.spacing(2),
    },
    paddingBottom: theme.spacing(3),
  },
}));

interface IProps {
  title: string;
  subtitle?: string;
  className?: string;
}

export const CSDialogTitle: FC<IProps> = ({ title, subtitle, className }) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={clsx(classes.root, className)}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>
      {subtitle !== undefined && (
        <Typography variant="body1" align="center">
          {subtitle}
        </Typography>
      )}
    </DialogTitle>
  );
};

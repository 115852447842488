/**
 * Interpolate the value at a given distance between a and b.
 *
 * @param a
 * @param b
 * @param distance
 */
export function interpolate(a: number, b: number, distance: number) {
  return a + (b - a) * distance;
}

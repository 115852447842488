import { RaceGateType } from "~/backend/graphql/globalTypes";
import { RaceGate } from "~/backend/graphql/RaceGate";

interface RaceGate_Line {
  type:
    | RaceGateType.START_LINE
    | RaceGateType.FINISH_LINE
    | RaceGateType.UPWIND_LINE
    | RaceGateType.DOWNWIND_LINE;
  markId: string;
  secondMarkId: string;
}

interface RaceGate_DeprecatedBuoy {
  type:
    | RaceGateType.UPWIND_BUOY
    | RaceGateType.DOWNWIND_BUOY
    | RaceGateType.WING_BUOY;
  markId: string;
  secondMarkId: null;
}

export const isGateLine = (gate: RaceGate): gate is RaceGate_Line => {
  switch (gate.type) {
    case RaceGateType.GATE:
    case RaceGateType.START_LINE:
    case RaceGateType.FINISH_LINE:
    case RaceGateType.DOWNWIND_LINE:
    case RaceGateType.UPWIND_LINE:
      return true;
    default:
      return false;
  }
};

export const isWindOrientedBuoy = (
  gate: RaceGate
): gate is RaceGate_DeprecatedBuoy => {
  switch (gate.type) {
    case RaceGateType.UPWIND_BUOY:
    case RaceGateType.WING_BUOY:
    case RaceGateType.DOWNWIND_BUOY:
      return true;
    default:
      return false;
  }
};

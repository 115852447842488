import { InteractiveTrip } from "@chartedsails/tracks";
import { useEffect, useReducer } from "react";
import { SessionInfo } from "~/backend/graphql/SessionInfo";
import { isNotNullish } from "~/components/util/isNotNullish";
import { useSailingInsights } from "~/components/util/useSailingInsights";
import { isRaceSegment } from "~/model/RaceSegment";
import { SailingSessionEdit } from "~/model/SailingSessionEdit";
import { useActiveVideoMedia } from "../../video/useActiveVideoMedia";
import { createReplayBoats } from "./createReplayBoats";
import {
  createReplayContextFromReplayTrip,
  createReplayContextFromSession,
} from "./createReplayContext";
import { replayWithActiveRace } from "./reducer-helpers";
import { Replay, replayCurrentTwd } from "./Replay";
import { replayReducer } from "./replay-reducer";

interface IOptions {
  initialReplay?: Partial<Replay>;
  liveStreaming?: boolean;
  editable?: boolean;
  editSession?: (edit: SailingSessionEdit) => void;
  initialWorldToBoatsAnimation?: boolean;
}

export const useReplayReducerFromSession = (
  session: SessionInfo | null,
  trips: Map<string, InteractiveTrip>,
  {
    initialReplay,
    liveStreaming,
    editable,
    editSession,
    initialWorldToBoatsAnimation,
  }: IOptions = {}
) => {
  const [replay, dispatchReplayEvent] = useReducer(replayReducer, {}, () => {
    const newReplayFromSession = createReplayContextFromSession(session, trips);

    const replay = {
      ...newReplayFromSession,
      ...initialReplay,
      editable: editable ?? session?.permissions.edit,
    } as Replay;
    // If you include a race activeSegment in initialReplay, we will do the race analysis for you.
    if (replay.activeSegment && isRaceSegment(replay.activeSegment)) {
      return replayWithActiveRace(replay, replay.activeSegment);
    } else {
      return replay;
    }
  });

  // Update boats (with their data) in the replay context whenever data changes
  const sessionBoats = session?.boats;
  useEffect(() => {
    dispatchReplayEvent({
      event: "session-boats-changed",
      boats: sessionBoats ? createReplayBoats(sessionBoats, trips) : [],
      isLiveTracking: !!liveStreaming,
      initialWorldToBoatsAnimation: initialWorldToBoatsAnimation ?? false,
    });
  }, [initialWorldToBoatsAnimation, liveStreaming, sessionBoats, trips]);

  // Update media when they change
  useEffect(() => {
    dispatchReplayEvent({
      event: "session-media-change",
      media: session?.media ?? [],
    });
  }, [session?.media]);

  // Update races when they change
  useEffect(() => {
    dispatchReplayEvent({
      event: "session-segments-or-marks-change",
      segments: session?.segments ?? [],
      sessionMarks: session?.marks ?? [],
      trips,
    });
  }, [session?.marks, session?.segments, trips]);

  // Update the session start/end/availableDataStart/availableDataEnd when they change
  // They change whenever we add another track and the server returns an updated session
  const session_earliestData = session?.earliestData;
  const session_latestData = session?.latestData;
  const session_startTime = session?.startTime;
  const session_endTime = session?.endTime;

  const parseTime = (date: string | undefined) =>
    isNotNullish(date) ? Date.parse(date) : 0;
  useEffect(() => {
    dispatchReplayEvent({
      event: "session-bounds-changed",
      earliestData: parseTime(session_earliestData ?? session_startTime),
      latestData: parseTime(session_latestData ?? session_endTime),
      startTime: parseTime(session_startTime),
      endTime: parseTime(session_endTime),
    });
  }, [
    session_earliestData,
    session_startTime,
    session_latestData,
    session_endTime,
  ]);

  // Get sailing insights and update the replay whenever they change
  const sailingInsights = useSailingInsights(trips, {
    trueWindDirection: replayCurrentTwd(replay),
    liveStreaming,
  });
  useEffect(() => {
    dispatchReplayEvent({
      event: "background-sailinginsights-updated",
      sailingInsights,
    });
  }, [sailingInsights]);

  // Update the currently playing video whenever we switch from one to another (except when syncing)
  const eligibleVideos = useActiveVideoMedia({
    time: replay.playbackTime,
    media: replay.media ?? [], // session?.media ?? [],
  });
  let activeVideo = replay.activeVideo;
  // If we are not syncing a video - AND the active video is not available anymore, switch.
  if (!replay.mediaSyncing) {
    if (
      activeVideo === undefined ||
      !eligibleVideos.find((v) => v.id === activeVideo?.id)
    ) {
      activeVideo = eligibleVideos.length > 0 ? eligibleVideos[0] : undefined;
    }
  }
  useEffect(() => {
    dispatchReplayEvent({
      event: "video-activevideo-switch",
      activeVideo: activeVideo,
    });
  }, [activeVideo]);

  // Change the editable field on the replay if the session editable status changes
  useEffect(() => {
    if (session?.permissions.edit !== replay.editable) {
      dispatchReplayEvent({
        event: "session-editable-changed",
        editable: session?.permissions.edit,
      });
    }
  }, [replay.editable, session?.permissions.edit]);

  // Apply any local changes to the cloud
  useEffect(() => {
    if (replay.pendingEdit) {
      editSession?.(replay.pendingEdit);
      dispatchReplayEvent({ event: "session-edit-saved" });
    }
  }, [editSession, replay.pendingEdit]);

  return [replay, dispatchReplayEvent] as const;
};

export type ReplayDispatch = ReturnType<typeof useReplayReducerFromSession>[1];

export const useReplayReducerFromTrip = (
  trip: InteractiveTrip | undefined,
  {
    initialReplay,
    boatColor,
    boatName,
  }: { initialReplay: Partial<Replay>; boatColor?: string; boatName?: string }
) => {
  const [replay, dispatch] = useReducer(replayReducer, {}, () => {
    return createReplayContextFromReplayTrip(trip, {
      initialReplay,
      boatColor,
      boatName,
    });
  });

  // This will trigger the autozoom effect once the width/height of the map are known
  useEffect(() => {
    if (trip) {
      const boats = [
        {
          ...replay.boats[0],
          data: trip,
        },
      ];
      const bounds = [trip.startTime, trip.endTime] as [number, number];
      dispatch({ event: "import-changed", boats, bounds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);
  return [replay, dispatch] as const;
};

import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { CSDialog, CSDialogProps } from "../csdialog/CSDialog";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
  },
});

interface ErrorDialogProps {
  title?: string;
  message?: string;
}

export const ErrorDialog = ({
  title,
  message,
  ...props
}: ErrorDialogProps & Omit<CSDialogProps, "dialogName">) => {
  const classes = useStyles();

  const { onClose } = props;
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose({}, "closeButton");
    }
  }, [onClose]);

  title = title || "Sorry - Looks like an error occurred.";

  return (
    <CSDialog {...props} dialogName="error">
      <div className={classes.main}>
        {title && (
          <Typography paragraph align="center" variant="h4">
            {title}
          </Typography>
        )}
        <Typography paragraph align="center">
          {message ? message : "Not sure what happened."}
        </Typography>
        {props.onClose ? (
          <Button
            onClick={handleClose}
            fullWidth
            variant="contained"
            color="primary"
          >
            Continue
          </Button>
        ) : (
          <Typography align="center">Please try reloading the page.</Typography>
        )}
      </div>
    </CSDialog>
  );
};

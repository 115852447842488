interface FlaggedFeature {
  development?: boolean;
  preview?: boolean;
  beta?: boolean;
  prod?: boolean;
  urlParameter?: string;
}

export type FlaggedFeatureName = keyof typeof flaggedFeatures;

export const flaggedFeatures = {
  mediacard: {
    development: true,
    preview: false,
    beta: false,
    prod: false,
    urlParameter: "mediacard",
  } as FlaggedFeature,
  garmin: {
    development: true,
    preview: true,
    beta: true,
    prod: true,
    urlParameter: undefined,
  } as FlaggedFeature,
  pro: {
    development: true,
    preview: true,
    beta: false,
    prod: false,
    urlParameter: undefined,
  } as FlaggedFeature,
  comments: {
    development: true,
    preview: true,
    beta: false,
    prod: false,
    urlParameter: undefined,
  } as FlaggedFeature,
};

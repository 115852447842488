import React, { createContext, useContext } from "react";
import { Replay } from "./Replay";
import { ReplayDispatch } from "./useReplayReducer";

const ReplayContextDispatch = createContext<{
  replay: Replay;
  dispatch: ReplayDispatch;
} | null>(null);

export const useReplayContext = () => {
  const ctx = useContext(ReplayContextDispatch);
  if (ctx === null) {
    throw new Error(`useReplayContext without a ReplayContext`);
  }
  return ctx.replay;
};

export const useReplayContextAndDispatch = () => {
  const ctx = useContext(ReplayContextDispatch);
  if (ctx === null) {
    throw new Error(`useReplayContextAndDispatch without a ReplayContext`);
  }
  return ctx;
};

// For backwards compatibility - before we added the dispatch in the context

export const ReplayContext = {
  Provider: ({
    value,
    dispatch,
    children,
  }: {
    value: Replay;
    dispatch?: ReplayDispatch;
    children: React.ReactNode;
  }) => (
    <ReplayContextDispatch.Provider
      value={{
        replay: value,
        dispatch: dispatch !== undefined ? dispatch : () => {},
      }}
    >
      {children}
    </ReplayContextDispatch.Provider>
  ),
};

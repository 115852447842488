import { Position } from "./Position";
import { Time } from "./Time";

export interface TrackPoint {
  // Always need a time
  time: Time;

  // GPS Position
  position?: Position;

  // GPS Derived values
  sog?: number;
  cog?: number;

  // Heading
  heading?: number;
  headingMagnetic?: number;

  // Boat speed
  bsp?: number;

  // Attitude of the boat - In Radians
  roll?: number;
  pitch?: number;

  // Wind information
  twa?: number;
  twd?: number;
  tws?: number;
  awa?: number;
  aws?: number;

  // Other basic boat data - in meters
  depth?: number;

  // Nav
  log?: number;
  tripLog?: number;

  // Other interesting data
  rudderAngle?: number;
  forestayTension?: number;
  backstayTension?: number;
  mainsheetTension?: number;
  // When we do not know where the cell is mounted
  loadCell?: number;

  atmosphericPressure?: number;
  airTemperature?: number;
  waterTemperature?: number;

  // Sailor/Human info
  heartRate?: number; // in BPM

  // Device information (for our trackers)
  gpsFixQuality?: number;
  gpsHorizontalAccuracy?: number;
  cellularQuality?: number;
  cellularStrength?: number;
  cellularAccessTechnology?: number;
  systemFreeMemory?: number;
  systemStateOfCharge?: number;
  systemUptime?: number;

  // The meaning of this will be highly dependent on how it was calculated (VMG
  // to what?) but it's helpful to have it declared on the TrackPoint
  vmg?: number;
}

export type SailingColumnDescription = {
  unit?: string;
  type?:
    | "angle" // [0 to 2PI[
    | "relative-angle" // ]-PI to PI]
    | "depth"
    | "distance"
    | "force"
    | "heartrate"
    | "position"
    | "speed"
    | "pressure"
    | "temperature";
  aggregation?: "time" | "speed" | "simple";
};

export const TrackPointFieldsDescription: {
  [v in keyof TrackPoint]: SailingColumnDescription;
} = {
  time: {
    aggregation: "time",
  },
  airTemperature: {
    type: "temperature",
  },
  atmosphericPressure: {
    type: "pressure",
  },
  awa: {
    type: "relative-angle",
  },
  aws: {
    type: "speed",
  },
  bsp: {
    type: "speed",
  },
  cog: {
    type: "angle",
  },
  depth: {
    type: "depth",
  },
  forestayTension: {
    type: "force",
  },
  backstayTension: {
    type: "force",
  },
  mainsheetTension: {
    type: "force",
  },
  heading: {
    type: "angle",
  },
  headingMagnetic: {
    type: "angle",
  },
  heartRate: {
    type: "heartrate",
  },
  log: {
    type: "distance",
  },
  pitch: {
    type: "relative-angle",
  },
  position: { type: "position" },
  roll: {
    type: "relative-angle",
  },
  rudderAngle: {
    type: "relative-angle",
  },
  sog: {
    type: "speed",
  },
  tripLog: {
    type: "distance",
  },
  twa: {
    type: "relative-angle",
  },
  twd: {
    type: "angle",
  },
  tws: {
    type: "speed",
  },
  waterTemperature: {
    type: "temperature",
  },
};

import { arraysFromTrackData } from "../sailing-arrays/arraysFromTrackData";
import { interactiveTripFromArrays } from "../sailing-arrays/interactiveFromArrays";
import { interpolateArraysValues } from "../sailing-arrays/interpolateArraysValue";
import { sogcogFromPositions } from "../sailing-arrays/sogcogFromPositions";
import { TrackData } from "../types/TrackData";

/**
 * Provides backwards compatibility with data stored in TrackData format but
 * uses the same sailingTables/sailingArrow logic.  This only supports
 * sog,cog,heading,roll,pitch.
 * @param data
 * @returns InteractiveTrip
 */
export const interactiveTripFromTrackData = (data: TrackData) => {
  let sa = arraysFromTrackData(data);

  // Fill all the NaN in the data so we have 100% coverage of data
  sa = interpolateArraysValues(sa);

  if (!("sog" in sa) || !("cog" in sa)) {
    sa = sogcogFromPositions(sa);
  }
  return interactiveTripFromArrays(sa);
}
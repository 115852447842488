import { useEffect } from "react";
import { useRouteMatch } from "react-router";

const AffiliateLandingPage = () => {
  const match = useRouteMatch<{ affiliate: string }>();

  useEffect(() => {
    if (match) {
      const existingParams = new URLSearchParams(window.location.search);

      window.location.href = `/?utm_source=${
        existingParams.get("utm_source") ?? match.params.affiliate
      }&utm_campaign=${
        existingParams.get("utm_campaign") ?? match.params.affiliate
      }&utm_medium=${existingParams.get("utm_medium") ?? "affiliate"}&aff=${
        match.params.affiliate
      }`;
    } else {
      window.location.href = "/";
    }
  }, [match]);

  return null;
};

export default AffiliateLandingPage;

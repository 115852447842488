import { toDegrees } from "@chartedsails/sailing-math";
import WebMercatorViewport from "viewport-mercator-project";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";
import {
  isBoatWithData,
  ReplayBoat,
} from "~/components/replay/replaycontext/Replay";
import {
  coordinatesToLonLat,
  lonLatToCoordinates,
} from "~/util/coordinates-to-lonlat";

/**
 * Return a new list of boats sorted by which boat is further ahead on the given bearing.
 *
 * @param boats
 * @param time
 * @param bearing
 */
export const orderBoatsOnBearing = (
  boats: ReplayBoat[],
  time: number,
  bearing: number
) => {
  const boatsData = boats.filter(isBoatWithData).map((b) => b.data);
  if (boatsData.length === 0) {
    return boats;
  }

  const projectionCenter = boatsData[0].lonlat(0);
  const mercator = new WebMercatorViewport({
    ...lonLatToCoordinates(projectionCenter),
    bearing: toDegrees(bearing),
    width: 1000,
    height: 1000,
  }) as ChartedSailsViewport;

  const rotatedBoatPositions = boats.map((b) => {
    const p = b.data?.getValuesAtTime(time);
    if (p) {
      return {
        rotatedPosition: mercator.project(coordinatesToLonLat(p)),
        boat: b,
      };
    } else {
      return { rotatedPosition: null, boat: b };
    }
  });

  rotatedBoatPositions.sort((a, b) => {
    if (a.rotatedPosition === null) return 1;
    if (b.rotatedPosition === null) return -1;
    return a.rotatedPosition[1] - b.rotatedPosition[1];
  });
  return rotatedBoatPositions.map((b) => b.boat);
};

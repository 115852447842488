import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { ReactComponent as CouponBarcodeSvg } from "~/assets/img/coupon-barcode.svg";
import CouponShapeImg from "~/assets/img/coupon-shape.svg";
import { DayPassOffer } from "~/backend/graphql/DayPassOffer";
import { SubscriptionOffer } from "~/backend/graphql/SubscriptionOffer";
import LoadingButton from "~/components/buttons/LoadingButton";
import { Link } from "~/components/link/Link";
import { CSCardElement } from "~/components/payment/CSCardElement";
import { isNotNullish } from "~/components/util/isNotNullish";
import { algae, carbon } from "~/styles/chartedsailsColors";
import { fontLato } from "~/styles/chartedSailsTheme";
import { formatPrice } from "~/util/formatPrice";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { isDayPassOffer, isSubscriptionOffer } from "./isSubscriptionOffer";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(6),
  },
  title: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    marginBottom: theme.spacing(4),
  },
  badge: {
    marginLeft: theme.spacing(2),
  },
  cardElements: {
    backgroundColor: "white",
  },
  cardLegend: {
    ...fontLato,
    fontSize: 16,
    color: carbon[500],
    marginTop: theme.spacing(1),
  },
  checkboxControl: {
    "& .MuiFormControlLabel-label": {
      ...fontLato,
      fontSize: 16,
    },
    marginBottom: 20,
  },
  coupon: {
    display: "flex",
    alignItems: "center",
    backgroundImage: `url(${CouponShapeImg})`,
    width: "100%",
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    ...fontLato,
    fontSize: 14,
    color: carbon[700],
    "& svg:first-of-type": {
      marginRight: theme.spacing(2),
    },
    "& svg:last-of-type": {
      marginLeft: "auto",
    },
  },
  couponDescription: {
    marginTop: theme.spacing(1),
    ...fontLato,
    fontSize: 14,
    color: carbon[800],
  },
  priceLine: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    width: "100%",
    "& span:first-of-type": {
      ...fontLato,
      color: carbon[500],
      fontWeight: 700,
    },
    "& span:last-of-type": {
      marginLeft: "auto",
    },
    "& $price": {
      fontSize: 24,
    },
  },
  price: {
    ...fontLato,
    fontSize: 38,
    fontWeight: 700,
    "& small": {
      fontSize: 16,
      fontWeight: "normal",
    },
  },
  strikedThroughPrice: {
    fontSize: 24,
    textDecoration: "line-through",
    color: carbon[500],
  },
}));

interface IProps {
  className?: string;
  offer: DayPassOffer | SubscriptionOffer;
  onPaymentConfirmed?: () => Promise<void>;
  onBack?: () => void;
  error?: string;
}

export const PaymentContent = ({
  className,
  offer,
  error,
  onPaymentConfirmed,
  onBack,
}: IProps) => {
  const classes = useStyles();

  const [cardInputComplete, updateCardInputComplete] = useState(false);
  const [cardInputError, updateCardInputError] = useState<string>();
  const handleCardChange = useCallback((e) => {
    if (e.complete) {
      updateCardInputComplete(true);
      updateCardInputError(undefined);
    } else if (e.error) {
      updateCardInputComplete(false);
      updateCardInputError(e.error.message);
    } else {
      updateCardInputComplete(false);
      updateCardInputError(undefined);
    }
  }, []);

  const [conditionsAccepted, updateConditionsAccepted] = useState(false);
  const toggleConditionsAccepted = useCallback((e) => {
    updateConditionsAccepted(e.target.checked);
  }, []);

  const daypass = isDayPassOffer(offer) ? offer : null;
  const subscription = isSubscriptionOffer(offer) ? offer : null;

  return (
    <>
      <CSDialogContent className={clsx(classes.root, className)}>
        <div className={classes.title}>
          <Typography variant="h4">
            {subscription
              ? "Activate your subscription"
              : "Activate a day pass"}{" "}
          </Typography>
          <Chip color="primary" className={classes.badge} label={offer.label} />
        </div>
        {error && (
          <Typography color="error" paragraph>
            {error}
          </Typography>
        )}
        <CSCardElement
          className={classes.cardElements}
          onChange={handleCardChange}
        />
        {cardInputError ? (
          <Typography variant="body2" color="error" paragraph>
            {cardInputError}
          </Typography>
        ) : (
          <Typography variant="body2" paragraph className={classes.cardLegend}>
            {subscription ? (
              <>
                {subscription.initialChargeDescription}
                <br />
                {subscription.recurrenceDescription}
              </>
            ) : (
              daypass?.initialChargeDescription
            )}
          </Typography>
        )}

        <FormControlLabel
          className={classes.checkboxControl}
          control={
            <Checkbox
              checked={conditionsAccepted}
              onChange={toggleConditionsAccepted}
              color="primary"
              size="small"
            />
          }
          label={
            <>
              I agree to the{" "}
              <Link target="_blank" to="/terms">
                terms of service
              </Link>{" "}
              and the{" "}
              <Link target="_blank" to="/privacy">
                privacy policy
              </Link>
              .
            </>
          }
        />
        <Divider />
        {isNotNullish(offer.priceBeforeDiscountUSD) ? (
          <>
            <div className={classes.coupon}>
              <CheckIcon
                style={{ color: algae["900"], height: 24, width: 24 }}
              />
              <span>
                <strong>Coupon applied</strong> ({offer.discountApplied})
              </span>
              <CouponBarcodeSvg />
            </div>
            <p className={classes.couponDescription}>
              {offer.discountDescription}
            </p>
            <div className={classes.priceLine}>
              <span>TOTAL</span>
              <span
                className={clsx(classes.price, classes.strikedThroughPrice)}
              >
                {formatPrice(offer.priceBeforeDiscountUSD)}
              </span>
            </div>
            <div className={classes.priceLine}>
              <span>NEW PRICE</span>
              <span className={classes.price}>
                {formatPrice(offer.priceUSD)}
                <small>
                  {" "}
                  USD{" "}
                  {subscription?.pricePeriod
                    ? `/${subscription.pricePeriod}`
                    : ""}
                </small>
              </span>
            </div>
          </>
        ) : (
          <div className={clsx(classes.priceLine)}>
            <span>TOTAL</span>
            <span className={classes.price}>
              {formatPrice(offer.priceUSD)}
              <small>
                {" "}
                USD{" "}
                {subscription?.pricePeriod
                  ? `/${subscription.pricePeriod}`
                  : ""}
              </small>
            </span>
          </div>
        )}
      </CSDialogContent>
      <CSDialogActions>
        {onBack && (
          <Button variant="contained" onClick={onBack}>
            Back
          </Button>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!conditionsAccepted || !cardInputComplete}
          onTriggerAction={onPaymentConfirmed}
        >
          {isSubscriptionOffer(offer) ? "SUBSCRIBE" : "ACTIVATE"}
        </LoadingButton>
      </CSDialogActions>
    </>
  );
};

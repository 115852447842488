import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { carbon } from "~/styles/chartedsailsColors";
import { fontLato } from "~/styles/chartedSailsTheme";
import { formatDuration } from "~/util/formatDuration";
import { formatDateShort } from "~/util/timeFormat";

const useStyles = makeStyles({
  root: {
    ...fontLato,
    fontSize: 16,
    color: carbon[500],
  },
});

export const TrackTextSummary = ({
  className,
  track,
}: {
  className?: string;
  track: ImportInfo;
}) => {
  const classes = useStyles();
  return (
    <span className={clsx(classes.root, className)}>
      {track.displayName} - {formatDateShort(new Date(track.earliestPoint))} -{" "}
      {formatDuration(
        Date.parse(track.latestPoint) - Date.parse(track.earliestPoint)
      )}
    </span>
  );
};

import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import { CSDialogContent } from "../csdialog/CSDialogContent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(8),
  },
}));

export const UploadTrackLoadingContent = () => {
  const classes = useStyles();

  return (
    <CSDialogContent className={classes.root}>
      <CircularProgress />
    </CSDialogContent>
  );
};

import { createContext, FC, useContext, useMemo, useState } from "react";
import { Media } from "~/backend/graphql/Media";

export interface IFileManagerContext {
  files: File[];
  addFile: (f: File) => void;
}

export const FileManagerContext = createContext<IFileManagerContext>({
  files: [],
  addFile: () => {},
});

export const FileManager: FC<{ preexistingFiles?: File[] }> = ({
  children,
  preexistingFiles,
}) => {
  const [files, updateFiles] = useState<File[]>(preexistingFiles ?? []);

  const context = useMemo(() => {
    return {
      files,
      addFile: (f: File) => updateFiles((files) => [...files, f]),
    };
  }, [files]);

  return (
    <FileManagerContext.Provider value={context}>
      {children}
    </FileManagerContext.Provider>
  );
};

export const useFileManager = () => {
  return useContext(FileManagerContext);
};

export const useFileFromMedia = (media?: Media) => {
  const files = useContext(FileManagerContext).files;

  if (!media) {
    return undefined;
  }

  const matchingFiles = files.filter(
    (f) =>
      media.sourceMeta &&
      f.name === media.sourceMeta.filename &&
      f.size === media.sourceMeta.size
  );
  if (matchingFiles.length > 0) {
    return matchingFiles[0];
  } else {
    return undefined;
  }
};

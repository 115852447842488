import {
  breakTripInSegment, convertAllTurnsInManeuver, sailingManeuverTolerance, segmentForTime
} from "@chartedsails/analysis";
import { InteractiveTrip } from "@chartedsails/tracks";
import { AlgoWorker } from "~/workers/algo-worker/algo-worker";
import { SailingSegment } from "./SailingSegment";

export const asyncBreakTripInSegment = async (
  trip: InteractiveTrip
): Promise<SailingSegment[]> => {
  let keyPoints = await AlgoWorker.getInstance().douglasPeucker(
    trip,
    sailingManeuverTolerance
  );
  return breakTripInSegment(trip, keyPoints);
};

export class SailingInsights {
  private trips: Map<string, InteractiveTrip>;
  private boatsSailingSegments: Map<string, SailingSegment[]>;

  private constructor(
    boats: Map<string, InteractiveTrip>,
    sailingSegments: Map<string, SailingSegment[]>
  ) {
    this.trips = boats;
    this.boatsSailingSegments = sailingSegments;
  }

  public static analyzeSync(boats: Map<string, InteractiveTrip>) {
    const sailingSegments = new Map();

    boats.forEach((trip, key) => {
      sailingSegments.set(key, breakTripInSegment(trip));
    });

    return new SailingInsights(boats, sailingSegments);
  }

  public static async analyze(boats: Map<string, InteractiveTrip>) {
    const sailingSegments = new Map();

    const jobs = Array.from(boats.entries()).map(async ([id, trip]) => {
      sailingSegments.set(id, await asyncBreakTripInSegment(trip));
    }
    )
    await Promise.all(jobs)

    return new SailingInsights(boats, sailingSegments);
  }

  public applyTrueWindDirection(trueWindDirection: number): SailingInsights {
    const sailingSegmentsWithWind = new Map();

    this.boatsSailingSegments.forEach((segments, boatId) => {
      const trip = this.trips.get(boatId);
      if (trip && segments) {
        const segmentsWithTwd = convertAllTurnsInManeuver(
          trip,
          segments,
          trueWindDirection
        );
        sailingSegmentsWithWind.set(boatId, segmentsWithTwd);
      }
    });

    return new SailingInsights(this.trips, sailingSegmentsWithWind);
  }

  public getSailingSegments(boatId: string) {
    if (this.boatsSailingSegments.has(boatId)) {
      return this.boatsSailingSegments.get(boatId)!;
    }
    return [];
  }

  public getSailingSegmentAt(boatId: string, time: number) {
    const segments = this.boatsSailingSegments.get(boatId);

    if (segments) {
      return segmentForTime(segments, time);
    }
    return null;
  }
}

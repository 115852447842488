import {
  ButtonTypeMap,
  CircularProgress,
  ExtendButtonBase,
  makeStyles,
} from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";
import React, { useCallback, useState } from "react";
import { useIsMounted } from "../util/useIsMounted";

const useStyles = makeStyles({
  buttonLoader: {
    width: "20px !important",
    height: "20px !important",
    marginRight: 10,
  },
});

interface IProps {
  loading?: boolean;
  onTriggerAction?: () => Promise<void>;
  buttonComponent?: ExtendButtonBase<ButtonTypeMap<{}, "button">>;
}

/**
 * A button that can display a loader while some action is happening.
 *
 * You can manually control the loading props to show the loader, or instead of
 * defining 'onClick', set the 'onTriggerAction' property to something returning a promise.
 * The button will automatically show a loader until the promise resolved.
 */
function LoadingButton<T extends ButtonProps>({
  loading,
  children,
  onTriggerAction,
  buttonComponent,
  ...buttonProps
}: T & IProps) {
  const classes = useStyles();
  const [actionExecuting, updateActionExecuting] = useState(false);

  const isMounted = useIsMounted();

  const handleClick = useCallback(() => {
    if (onTriggerAction) {
      updateActionExecuting(true);
      onTriggerAction()
        .then(() => {
          if (isMounted.current) {
            updateActionExecuting(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            updateActionExecuting(false);
          }
        });
    }
  }, [isMounted, onTriggerAction]);

  const ButtonComponent = buttonComponent ?? Button;

  return (
    <ButtonComponent
      onClick={handleClick}
      {...buttonProps}
      disabled={
        loading === true || actionExecuting === true || buttonProps.disabled
      }
    >
      <>
        {(loading || actionExecuting) && (
          <CircularProgress color="inherit" className={classes.buttonLoader} />
        )}
        {children}
      </>
    </ButtonComponent>
  );
}

export default LoadingButton;

import { ApolloError } from "@apollo/client";
import { useCallback } from "react";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { getBackendErrorCode } from "./BackendError";
import { processApolloError } from "./processApolloError";

export const processError = (error: Error) => {
  if (error instanceof ApolloError) {
    const pe = processApolloError(error);
    return {
      error: pe.simplifiedError,
      errorCode: getBackendErrorCode(error) ?? undefined,
    };
  } else {
    return { error: error.message };
  }
};

/**
 *  Shows an error message using the function provided and logs an event.
 * @param onError
 * @param eventName
 * @returns
 */
export const useErrorHandler = (
  onError?: (message: string) => void,
  eventName?: string
) => {
  const errorEvent = useAnalyticEvent(eventName ?? "error");

  return useCallback(
    (e: Error) => {
      const { error, errorCode } = processError(e);
      onError?.(error);
      errorEvent({ error, errorCode });
    },
    [errorEvent, onError]
  );
};

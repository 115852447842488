import { InteractiveTrip } from "@chartedsails/tracks";
import { SessionInfo } from "~/backend/graphql/SessionInfo";
import { trackColorsScheme } from "~/styles/chartedSailsTheme";
import { ReplayBoat } from "./Replay";

export const createReplayBoats = (
  boats: SessionInfo["boats"],
  trips?: Map<string, InteractiveTrip>
): ReplayBoat[] => {
  return boats.map((b, index) => ({
    id: b.id,
    name: b.name,
    color: trackColorsScheme[index % trackColorsScheme.length],
    sailNumber: b.sailNumber ?? undefined,
    boatClass: b.boatClass ?? undefined,
    data: trips?.get(b.id),
  }));
};

import { playbackSpeedOptions } from "../custombuttons/ReplaySpeedSelector";
import { updatePlaybackAnimation } from "./reducer-helpers";
import { Replay } from "./Replay";

export type ReplayKeyboardAction =
  | { event: "keyboard-toggleplaying" }
  | { event: "keyboard-bigjumpback" }
  | { event: "keyboard-bigjumpforward" }
  | { event: "keyboard-decreasespeed" }
  | { event: "keyboard-increasespeed" }
  | { event: "keyboard-jumpback" }
  | { event: "keyboard-jumpforward" };

export const keyboardEventReducer = (
  replay: Replay,
  action: ReplayKeyboardAction
) => {
  switch (action.event) {
    // Keyboard
    case "keyboard-bigjumpback":
      return updatePlaybackAnimation(replay, replay.playbackTime - 10000);
    case "keyboard-bigjumpforward":
      return updatePlaybackAnimation(replay, replay.playbackTime + 10000);
    case "keyboard-decreasespeed": {
      const index = playbackSpeedOptions.findIndex(
        (x) => x === replay.playbackSpeed
      );
      if (index > -1) {
        const newIndex =
          index === 0 ? playbackSpeedOptions.length - 1 : index - 1;
        return {
          ...updatePlaybackAnimation(replay, replay.playbackTime),
          playbackSpeed: playbackSpeedOptions[newIndex],
        };
      }
      return replay;
    }
    case "keyboard-increasespeed": {
      const index = playbackSpeedOptions.findIndex(
        (x) => x === replay.playbackSpeed
      );
      if (index > -1) {
        const newIndex = (index + 1) % playbackSpeedOptions.length;
        return {
          ...updatePlaybackAnimation(replay, replay.playbackTime),
          playbackSpeed: playbackSpeedOptions[newIndex],
        };
      }
      return replay;
    }
    case "keyboard-jumpback":
      return updatePlaybackAnimation(replay, replay.playbackTime - 1000);
    case "keyboard-jumpforward":
      return updatePlaybackAnimation(replay, replay.playbackTime + 1000);
    case "keyboard-toggleplaying":
      return {
        ...updatePlaybackAnimation(replay, replay.playbackTime),
        playing: !replay.playing,
      };
  }
  return replay;
};

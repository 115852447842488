import { gql } from "@apollo/client";
import { useCallback } from "react";
import { forceDisconnectSubscriptionsClient } from "~/backend/graphql";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { Signup, SignupVariables } from "../../graphql/Signup";
import { FRAGMENT_USER_INFO } from "./fragmentUserInfo";

const SIGNUP = gql`
  mutation Signup($user: SignupInput!) {
    signup(user: $user) {
      ...UserInfo
      boats {
        name
      }
    }
  }
  ${FRAGMENT_USER_INFO}
`;

export const useSignup = () => {
  const mutation = useMutationWithErrorHandling<Signup, SignupVariables>(
    SIGNUP,
    {
      refetchQueries: ["GetMe"],
      awaitRefetchQueries: true,
    }
  );
  return useCallback<typeof mutation>(
    (args) => {
      return mutation(args).then((data) => {
        forceDisconnectSubscriptionsClient();
        return data;
      });
    },
    [mutation]
  );
};

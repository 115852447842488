import TagManager from "react-gtm-module";
import { UserInfo } from "../graphql/UserInfo";
import { AnalyticsProperties } from "./AnalyticsProperties";

import posthog from "posthog-js";
import { log } from "../../util/devconsole";

declare global {
  interface Window {
    analytics: any;
  }
}

export interface LinkAnalytics {
  eventName?: string;
  eventProps?: AnalyticsProperties;
}

class AnalyticsWrapper {
  private currentPageName?: string;

  constructor() {
    const gtmId = process.env.REACT_APP_GTM_ID;
    const gtmAuth = process.env.REACT_APP_GTM_AUTH;
    const gtmPreview = process.env.REACT_APP_GTM_PREVIEW;

    if (gtmId) {
      const tagManagerArgs = {
        gtmId: gtmId,
        auth: gtmAuth,
        preview: gtmPreview,
      };
      TagManager.initialize(tagManagerArgs);
    }

    const posthogKey = process.env.REACT_APP_POSTHOG_KEY;
    const posthogHost = process.env.REACT_APP_POSTHOG_HOST;
    if (posthogKey) {
      posthog.init(posthogKey, { api_host: posthogHost });
    }
  }

  public page(location: string, properties?: object) {
    console.log(
      `[Analytics] Page: ${location} ${JSON.stringify(properties)}`, { properties }
    );
    this.currentPageName = this.getPageName(location);

    TagManager.dataLayer({
      dataLayer: { event: "page_view", page_location: location, ...properties },
    });

    posthog.capture("page", { location, ...properties });
  }

  public event(eventName: string, properties?: AnalyticsProperties) {
    properties = properties || {};
    properties.pageName = properties.pageName || this.currentPageName;
    if (properties.actionName) {
      properties.origin = `${properties.pageName || "unknownpage"}-${properties.actionName
        }`;
    }
    log(
      `[Analytics] Event: ${eventName} ${JSON.stringify(properties)}`, { properties }
    );
    TagManager.dataLayer({ dataLayer: { event: eventName, ...properties } });
    posthog.capture(eventName, properties);
  }

  public identifyUser(user: UserInfo) {
    log(`[Analytics] IdentifyUser: ${JSON.stringify(user)}`);
    TagManager.dataLayer({
      dataLayer: {
        name: user.name,
        user_id: user.id,
        email: user.email,
        subscriber: !!user.subscription,
      },
    });
    posthog.identify(user.id, {
      name: user.name,
      email: user.email,
      subscriber: !!user.subscription,
    });
  }

  public resetUser() {
    log(`[Analytics] ResetUser`);
    TagManager.dataLayer({
      dataLayer: {
        name: undefined,
        user_id: undefined,
        email: undefined,
        subscriber: undefined,
      },
    });
    posthog.reset();
  }

  private getPageName(location: string) {
    if (location === "/") {
      return "home";
    } else if (location.startsWith("/session/")) {
      return "session";
    } else if (location.startsWith("/live/")) {
      return "live";
    } else if (location.startsWith("/demo/")) {
      return "demo";
    } else {
      return location
        .split("/")
        .filter((p) => p !== "")
        .join("-");
    }
  }
}

export const analytics = new AnalyticsWrapper();

import { InteractiveTrip } from "@chartedsails/tracks";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";
import { convexHull } from "./convex-hull";
import { TripBounds } from "./TripBounds";

/**
 * Returns the 4 extreme positions of this boat on this trip when projected in the given viewport.
 * @param trip
 * @param viewport
 * @returns
 */
export const boundsFromTripSimpleAndSlow = (
  trip: InteractiveTrip,
  viewport: ChartedSailsViewport
): TripBounds => {
  return boundsFromPositions(trip.lonlat, trip.length, viewport);
};

/**
 * Returns the 4 extreme positions of this boat on this trip when projected in the given viewport.
 *
 * This is a new faster implementation that uses the convex hull of the track to avoid having to project all the points.
 *
 * @param trip
 * @param viewport
 * @returns
 */
export const boundsFromTrip = (
  trip: InteractiveTrip,
  viewport: ChartedSailsViewport
): TripBounds => {
  if (trip.length === 0) {
    throw new Error(`Empty trip has no bounds.`);
  }

  const pt = new PerfTimer("boundsFromTripFast");
  const positions = trip.getPositions();
  pt.mark("positions");
  const hull = convexHull(positions);
  pt.mark("convexHull");

  const bounds = boundsFromPositions(
    (i: number) => hull[i],
    hull.length,
    viewport
  );
  pt.mark("bounds");
  pt.log();
  return bounds;
};

const boundsFromPositions = (
  position: (i: number) => [number, number],
  length: number,
  { project, unproject }: ChartedSailsViewport
) => {
  const projectedLonLat = (i: number) => project(position(i));

  if (length === 0) {
    throw new Error(`Empty trip has no bounds.`);
  }

  const p0 = projectedLonLat(0);
  let topMost = p0;
  let leftMost = p0;
  let rightMost = p0;
  let downMost = p0;

  for (let i = 1; i < length; i += 1) {
    const p = projectedLonLat(i);
    if (p[1] < topMost[1]) topMost = p;
    if (p[1] > downMost[1]) downMost = p;
    if (p[0] < leftMost[0]) leftMost = p;
    if (p[0] > rightMost[0]) rightMost = p;
  }
  return {
    topMost: unproject(topMost),
    leftMost: unproject(leftMost),
    rightMost: unproject(rightMost),
    downMost: unproject(downMost),
  };
};

import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  horizontalSplitter: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: 12,
    lineHeight: "2em",
    "& div": {
      flexGrow: 1,
      height: 1,
      backgroundColor: theme.palette.grey[500],
    },
    "& span": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

export const HorizontalSplitter = ({ text }: { text: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.horizontalSplitter}>
      <div />
      <span>{text}</span>
      <div />
    </div>
  );
};

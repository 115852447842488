import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useCallback } from "react";
import ReactSlider from "react-slider";
import { carbon, seaway } from "~/styles/chartedsailsColors";
import { useAnalyticEvent } from "../analytics/useAnalyticEvent";
import { BoatFilter } from "../boatfilter/BoatFilter";
import { PlayButton } from "../replay/custombuttons/PlayButton";
import { ReplaySpeedSelector } from "../replay/custombuttons/ReplaySpeedSelector";
import { useReplayContextAndDispatch } from "../replay/replaycontext/ReplayContext";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    height: 120,
    padding: 24,
    backgroundColor: carbon[50],
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  sliderContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 4,
  },
  slider: {},
  track: {
    height: 4,
    background: theme.palette.common.white,
    "&:first-of-type": {
      background: seaway[400],
    },
  },
  thumb: {
    cursor: "pointer",
    /*color for the thumb */
    background: theme.palette.common.white,
    border: `4px solid ${theme.palette.primary.main}`,
    /* shape of the thumb: circle */
    width: 18,
    height: 18,
    borderRadius: "100%",
    /* remove default outline when selected */
    outline: "none",
    transform: "translateY(-7px)",

    // Add a pseudo element to make the clickable area larger
    "&::after": {
      content: `""`,
      position: "absolute",
      left: -20,
      right: -20,
      top: -20,
      bottom: -20,
      cursor: "move",
    },
  },
  playButton: {
    height: 64,
    width: 64,
  },
  speedSelector: {
    fontWeight: 700,
    fontSize: 24,
  },
  titleSeparator: {
    display: "inline-block",
    height: 16,
    width: 1,
    backgroundColor: carbon[200],
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  boatFilter: {
    flexGrow: 1,
    backgroundColor: "white",
    // Inset border
    boxShadow: `inset 0px 0px 0px 1px ${carbon[200]}`,
    borderRadius: 8,
    transition: theme.transitions.create("border-radius"),
    "&.cs-bf-open": {
      borderRadius: "8px 8px 0px 0px",
    },
  },
}));

interface IProps {
  className?: string;
}

export const MobilePane = ({ className }: IProps) => {
  const classes = useStyles();
  const { replay, dispatch } = useReplayContextAndDispatch();

  const triggerEvent = useAnalyticEvent("mobilepane");
  const handlePlayClick = useCallback(
    () => dispatch({ event: "timepanel-toggle-playing" }),

    [dispatch]
  );
  const handleSetSpeed = useCallback(
    (speed) => dispatch({ event: "timepanel-set-playbackspeed", speed }),
    [dispatch]
  );
  const handleBoatFilterChange = useCallback(
    (selectedBoatIds: string[]) => {
      triggerEvent({
        eventName: "boatfilter-change",
        visibleBoats: selectedBoatIds.length,
        totalBoats: replay.boats.length,
      });
      dispatch({
        event: "timepanel-set-visibleboats",
        visibleBoatsIds: selectedBoatIds,
      });
    },
    [dispatch, replay.boats.length, triggerEvent]
  );

  const handleTimeChange = useCallback(
    (value: number, _index: number) => {
      dispatch({ event: "timechart-click", time: value });
    },
    [dispatch]
  );

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.sliderContainer}>
        <ReactSlider
          className={classes.slider}
          trackClassName={classes.track}
          thumbClassName={classes.thumb}
          min={replay.startTime}
          max={replay.endTime}
          value={replay.playbackTime}
          onChange={handleTimeChange}
        />
      </div>
      <PlayButton
        className={classes.playButton}
        playing={replay.playing}
        onChange={handlePlayClick}
      />
      <ReplaySpeedSelector
        playbackSpeed={replay.playbackSpeed}
        onPlaybackSpeedChange={handleSetSpeed}
        className={classes.speedSelector}
      />
      <span className={classes.titleSeparator} />
      <BoatFilter
        boats={replay.boats}
        selectedBoatIds={replay.visibleBoats}
        onSelectionChange={handleBoatFilterChange}
        className={classes.boatFilter}
      />
    </div>
  );
};

import React from "react";
import { CycleOptionsButton } from "~/components/buttons/CycleOptionsButton/CycleOptionsButton";
interface IProps {
  playbackSpeed: number;
  onPlaybackSpeedChange: (speed: number) => void;
  className?: string;
}

export const playbackSpeedOptions = [0.5, 1, 2, 10, 20, 100];
const playbackSpeedOptionsWithLabel = playbackSpeedOptions.map((s) => ({
  value: s,
  label: `${s}x`,
}));

export const ReplaySpeedSelector = ({
  playbackSpeed,
  onPlaybackSpeedChange,
  className,
}: IProps) => {
  return (
    <CycleOptionsButton
      value={playbackSpeed}
      options={playbackSpeedOptionsWithLabel}
      onChange={onPlaybackSpeedChange}
      className={className}
    />
  );
};

import { toDegrees } from "@chartedsails/sailing-math";
import { makeStyles } from "@material-ui/core";
import { CSSProperties, ReactNode } from "react";
import { Marker } from "react-map-gl";
import { ReactComponent as GuntimeSVG } from "~/assets/icons/map-guntime.svg";
import { RaceTooltip } from "~/components/race/racemark/RaceTooltip";
import { useScalableMarkerStyle } from "../map/useScalableMarkerSize";
import { useReplayContext } from "../replaycontext/ReplayContext";

interface IProps {
  time: number;
  boatId: string;
  tooltip?: ReactNode;
}

const useStyles = makeStyles(() => ({
  icon: {},
}));

const IconAdjustments = {
  rotateToNorthDegrees: 0,
  offsetLeft: -12.5,
  offsetTop: -12.5,
};

export const GuntimeMapMarker = ({ boatId, time, tooltip }: IProps) => {
  const classes = useStyles();

  const replay = useReplayContext();
  const boat = replay.boats.find((b) => b.id === boatId);
  const navData = boat?.data?.getValuesAtTime(time);

  const svgStyle = useScalableMarkerStyle(2, 25, 16);

  if (!navData) {
    return null;
  }

  const rotationDegrees =
    IconAdjustments.rotateToNorthDegrees -
    (replay.viewState.bearing ?? 0) +
    toDegrees(navData.cog);

  const customStyle: CSSProperties = {
    color: boat?.color,
    transform: `rotate(${rotationDegrees}deg) ${svgStyle.transform}`,
  };

  return (
    <Marker
      className="guntime-map-marker"
      latitude={navData.latitude}
      longitude={navData.longitude}
      offsetLeft={IconAdjustments.offsetLeft}
      offsetTop={IconAdjustments.offsetTop}
    >
      {tooltip ? (
        <RaceTooltip title={tooltip}>
          <GuntimeSVG className={classes.icon} style={customStyle} />
        </RaceTooltip>
      ) : (
        <GuntimeSVG className={classes.icon} style={customStyle} />
      )}
    </Marker>
  );
};

import { gql } from "@apollo/client";
import { FRAGMENT_BOAT } from "../boat/fragmentBoat";
import {
  FRAGMENT_DAYPASS_OFFER,
  FRAGMENT_SUBSCRIPTION_OFFER,
} from "../subscription/fragmentOffer";

export const FRAGMENT_IMPORT = gql`
  fragment ImportInfo on Import {
    id
    date
    timezone
    displayName
    earliestPoint
    latestPoint
    fileSize
    userAgent
    boat {
      ...Boat
    }
    usedBySessions {
      id
      title
    }
  }
  ${FRAGMENT_BOAT}
`;

export const FRAGMENT_IMPORT_LICENSE = gql`
  fragment ImportLicense on Import {
    licensingOptions {
      prompt
      dayByDayOption {
        ...DayPassOffer
      }
      monthlyOffer {
        ...SubscriptionOffer
      }
      annualOffer {
        ...SubscriptionOffer
      }
    }
    licensed
  }
  ${FRAGMENT_DAYPASS_OFFER}
  ${FRAGMENT_SUBSCRIPTION_OFFER}
`;

export const FRAGMENT_IMPORT_INFO_WITH_LICENSE = gql`
  fragment ImportInfoWithLicense on Import {
    ...ImportInfo
    ...ImportLicense
  }
  ${FRAGMENT_IMPORT}
  ${FRAGMENT_IMPORT_LICENSE}
`;

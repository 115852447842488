export function pickMostCommon<T>(options: Array<T>) {
  if (options.length === 0) {
    return undefined;
  }

  const counters = options.reduce((counters, o) => {
    if (counters.has(o)) {
      counters.set(o, counters.get(o)! + 1);
    } else {
      counters.set(o, 1);
    }
    return counters;
  }, new Map<T, number>());

  // Sort in decreasing order
  const sortedOptions = Array.from(counters.entries()).sort(
    (a, b) => b[1] - a[1]
  );

  return sortedOptions[0][0];
}

import { Button, Link } from "@material-ui/core";
import React, { useCallback } from "react";
import { LinkButton } from "../link/LinkButton";
import { NavLink } from "../link/NavLink";
import { INavAction } from "./INavAction";

export const NavBarAction = ({ title, style, action }: INavAction) => {
  const handler = useCallback(
    (e) => {
      e.preventDefault();
      if (typeof action === "function") {
        action();
      }
    },
    [action]
  );

  if (typeof action === "string") {
    if (style === undefined || style === "navlink") {
      return <NavLink to={action}>{title}</NavLink>;
    } else {
      const color =
        style === "primary" || style === "outlined" ? "primary" : "default";
      const variant =
        style === "primary" || style === "secondary" ? "contained" : style;
      return (
        <LinkButton variant={variant} color={color} to={action}>
          {title}
        </LinkButton>
      );
    }
  } else {
    if (style === undefined || style === "navlink") {
      return (
        <Link href="#" onClick={handler}>
          {title}
        </Link>
      );
    } else {
      const color =
        style === "primary" || style === "outlined" ? "primary" : "default";
      const variant =
        style === "primary" || style === "secondary" ? "contained" : style;
      return (
        <Button variant={variant} color={color} onClick={handler}>
          {title}
        </Button>
      );
    }
  }
};

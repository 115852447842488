import { InteractiveTrip } from "@chartedsails/tracks";
import { GeoSegment } from "../../geometry/GeoSegment";
import { doSegmentIntersect } from "./segmentsIntersect";

/*
 * Workaround a bug where we can sometime return a time that is earlier than the given startTime.
 * That is because we approximate the intersection point and do not do the linear interpolation to find the
 * exact time. Otherwise this would not happen.
 *
 */
export const findTripIntersectionWithSegment: typeof unsafe_findTripIntersectionWithSegment =
  (trip, segment, startTime) => {
    const t = unsafe_findTripIntersectionWithSegment(trip, segment, startTime);

    if (t !== null && t < startTime) {
      // console.warn(
      //   `findTripIntersectionWithSegment returning ${t} but startTime was ${startTime}`,
      //   { trip, segment, startTime }
      // )
      return startTime;
    }

    return t;
  };

const unsafe_findTripIntersectionWithSegment = (
  trip: InteractiveTrip,
  segment: GeoSegment,
  startTime: number
) => {
  let tripIndex = trip.indexFromTime(startTime) ?? 0;
  while (tripIndex <= trip.length - 2) {
    const tripSegment = {
      a: trip.lonlat(tripIndex),
      b: trip.lonlat(tripIndex + 1),
    };

    if (doSegmentIntersect(tripSegment, segment)) {
      // TO DO: make this more precise by finding the exact time
      return trip.time(tripIndex);
    }
    tripIndex++;
  }
  return null;
};

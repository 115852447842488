import { isNotNullish } from "~/components/util/isNotNullish";
import { ReplayRaceSetup } from "../../replaycontext/Replay";
import { TrackConfig } from "./TrackConfig";

const pastLegsColor = "grey";
const nextLegPreviewDuration = 1000 * 60 * 0.5;

export const replayTracksInRaceSetup = (replay: ReplayRaceSetup) => {
  const race = replay.activeSegment;

  // Show a light grey track for everything that has already been defined
  const previousTracks = replay.boats.map<TrackConfig | null>((b) => {
    const boatStats = replay.raceAnalysis?.boats.get(b.id);

    // We show everything available except the segment reaching to the gate currently being edited
    const stopAtLegIndex = replay.currentGateIndex - 1;
    const visibleStartTime = boatStats?.legs[0]?.startTime;
    const visibleEndTime = boatStats?.legs[stopAtLegIndex]?.finishTime;
    if (stopAtLegIndex >= 0 && visibleStartTime && visibleEndTime) {
      return {
        mode: "fading-solid",
        trackId: `${b.id}-beforeleg`,
        boatId: b.id,
        solidColor: pastLegsColor,
        visibleStartTime,
        visibleEndTime,
      };
    }
    return null;
  });

  // Show the segment for the current leg as solid if it's defined - or as a red line if it does not reach the gate
  const currentLegTracksArrays = replay.boats.map<TrackConfig[] | null>((b) => {
    const boatStats = replay.raceAnalysis?.boats.get(b.id);

    const currentLegIndex = replay.currentGateIndex - 1;

    // For the start we show the track from beginning of start sequence all the way to boat crossing the line
    if (currentLegIndex === -1) {
      if (boatStats?.start) {
        return [
          {
            mode: "solid",
            trackId: `${b.id}-leg`,
            boatId: b.id,
            solidColor: b.color,
            visibleStartTime: boatStats?.start.startTime,
            visibleEndTime: boatStats?.start?.timeAtLine,
          },
          // And show a preview of the next leg
          {
            mode: "fading-forward",
            trackId: `${b.id}-previewnext`,
            boatId: b.id,
            solidColor: b.color,
            visibleStartTime: boatStats.start.timeAtLine,
            visibleEndTime: boatStats.start.timeAtLine + nextLegPreviewDuration,
          },
        ];
      }
      // Boat is not crossing the start line.
      else {
        return [
          {
            mode: "fading-forward",
            trackId: `${b.id}-previewnext`,
            boatId: b.id,
            solidColor: "red",
            visibleStartTime: race.startTime,
            visibleEndTime: race.raceConfig.gunTime + 1000 * 60 * 3,
          },
        ];
      }
    } else {
      const legStats =
        currentLegIndex === -1
          ? boatStats?.start
          : boatStats?.legs[currentLegIndex];
      const lastLegStats =
        currentLegIndex === 0
          ? boatStats?.start
          : boatStats?.legs[currentLegIndex - 1];
      // If this boat reaches the gate, show the segment in solid
      if (legStats?.startTime && legStats?.finishTime) {
        return [
          {
            mode: "solid",
            trackId: `${b.id}-leg`,
            boatId: b.id,
            solidColor: b.color,
            visibleStartTime: legStats.startTime,
            visibleEndTime: legStats.finishTime,
          },
          // And show a preview of the next leg
          {
            mode: "fading-forward",
            trackId: `${b.id}-previewnext`,
            boatId: b.id,
            solidColor: b.color,
            visibleStartTime: legStats.finishTime,
            visibleEndTime: legStats.finishTime + nextLegPreviewDuration,
          },
        ];
      }
      // Else and only if the boat reached the previous mark, show the track from last gate to the end in red
      // When it's the start line we show everything from gunTime (since that is where we start looking for the boat intersecting with first mark)
      else {
        if (lastLegStats?.finishTime) {
          return [
            {
              mode: "solid",
              trackId: `${b.id}-leg`,
              boatId: b.id,
              solidColor: "red",
              visibleStartTime: lastLegStats.finishTime,
              visibleEndTime: race.endTime,
            },
          ];
        }
      }
    }

    return null;
  });

  // Flatten the array of array
  const editingTracks = currentLegTracksArrays.reduce<TrackConfig[]>(
    (concat, current) => [...concat, ...(current ?? [])],
    []
  );

  return [...previousTracks, ...editingTracks].filter(isNotNullish);
};

import { Avatar as MuiAvatar, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import chartedSailsLogo from "~/assets/img/chartedsails-logo.svg";
import { UserInfo } from "~/backend/graphql/UserInfo";

const useStyles = makeStyles(() => ({
  avatarLetter: {
    // Fine tweaking to center the text
    paddingTop: 2,
  },
  avatarProductLogo: {
    backgroundColor: "#F2F2F2",
    "& img": {
      width: "65%",
      height: "55%",
    },
  },
}));

interface IProps {
  user?: Pick<UserInfo, "name" | "email">;
  className?: string;
  onClick?: () => void;
}

export const Avatar = ({ user, className, onClick }: IProps) => {
  const classes = useStyles();

  // Default to a centered Charted Sails logo
  if (!user) {
    return (
      <MuiAvatar
        onClick={onClick}
        className={clsx(className, classes.avatarProductLogo)}
        src={chartedSailsLogo}
      />
    );
  } else {
    const letter = (user.name ?? user.email).substring(0, 1).toUpperCase();
    return (
      <MuiAvatar
        onClick={onClick}
        className={clsx(className, classes.avatarLetter)}
      >
        {letter}
      </MuiAvatar>
    );
  }
};

import { DialogContent, Theme, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { carbon } from "~/styles/chartedsailsColors";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
      backgroundColor: carbon[50],
    },
  });

export const CSDialogContent = withStyles(styles)(DialogContent);

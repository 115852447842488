import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { BoatSelector } from "~/components/boatselector/BoatSelector";
import { TrackTextSummary } from "./TrackTextSummary";

const useStyles = makeStyles((theme) => ({
  root: {},
  boatSelector: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    backgroundColor: "white",
  },
}));

interface IProps {
  className?: string;
  track: ImportInfo;
}

export const TrackSummary = ({ className, track }: IProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <BoatSelector
        className={classes.boatSelector}
        TextFieldProps={{ disabled: true, variant: "outlined" }}
        value={track.boat}
      />
      <TrackTextSummary track={track} />
    </div>
  );
};

import { Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { useAssignBoatToImport } from "~/backend/data-hooks/imports/useAssignBoatToImport";
import { Boat } from "~/backend/graphql/Boat";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { useErrorHandler } from "~/backend/utils/useErrorHandler";
import { BoatSelector } from "~/components/boatselector/BoatSelector";
import LoadingButton from "~/components/buttons/LoadingButton";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";
import { TrackTextSummary } from "./TrackTextSummary";

const useStyles = makeStyles(() => ({
  root: {},
  boatSelector: {
    backgroundColor: "white",
  },
  uploadInfo: {},
}));

interface IProps {
  className?: string;
  track: ImportInfo;
  onBoatAssigned: (track: ImportInfoWithLicense) => void;
  onCreateNewBoat: (name: string) => void;
  onBack: () => void;
}

export const AssignBoatBox = ({
  className,
  track,
  onBoatAssigned,
  onCreateNewBoat,
  onBack,
}: IProps) => {
  const classes = useStyles();
  const doAssignBoat = useAssignBoatToImport();
  const [selectedBoat, updateSelectedBoat] = useState<Boat | null>(track.boat);
  const [error, updateError] = useState<string>();

  const assignBoatErrorHandler = useErrorHandler(updateError, "upload-error");

  const handleBoatSelected = useCallback((b: Boat | null) => {
    updateSelectedBoat(b);
    updateError(undefined);
  }, []);
  const handleContinue = useCallback(() => {
    updateError(undefined);
    if (!selectedBoat) {
      return Promise.resolve();
    }
    return doAssignBoat({
      variables: { importId: track.id, boatId: selectedBoat.id },
    })
      .then((data) => {
        return onBoatAssigned(data.assignBoatToImport);
      })
      .catch((e) => {
        assignBoatErrorHandler(e);
      });
  }, [
    doAssignBoat,
    assignBoatErrorHandler,
    onBoatAssigned,
    selectedBoat,
    track.id,
  ]);

  return (
    <>
      <CSDialogTitle
        title="Add a new track"
        subtitle="You can import data from your GPS, sport watches and mobile apps."
      />
      <CSDialogContent className={clsx(classes.root, className)}>
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <BoatSelector
          className={classes.boatSelector}
          TextFieldProps={{
            variant: "outlined",
            fullWidth: true,
            placeholder: "Select a boat",
          }}
          value={selectedBoat}
          onChange={handleBoatSelected}
          onAddBoat={onCreateNewBoat}
        />
        <TrackTextSummary track={track} />
      </CSDialogContent>
      <CSDialogActions>
        <Button variant="contained" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={selectedBoat === null}
          onTriggerAction={handleContinue}
        >
          Continue
        </LoadingButton>
      </CSDialogActions>
    </>
  );
};

import { TrackPoint } from "@chartedsails/sailing-data";
import { findRowWithTime } from "./findRowWithTime";
import { interpolateValues } from "./interpolateValues";
import { SailingDataArray } from "./types";

export const sliceSailingArray = (
  sa: SailingDataArray,
  start: number,
  end: number
): SailingDataArray => {
  let indexA = findRowWithTime(sa["time"], start);
  let indexB = findRowWithTime(sa["time"], end);

  if (indexA === null || indexB === null) {
    throw new Error("Invalid slice bounds (slicing-beyond-bounds).");
  }

  // If the start and end do not fall on existing points then we will add new points in the new array.
  let dataA: TrackPoint | null = null;
  let dataB: TrackPoint | null = null;
  if (sa["time"][indexA] !== start) {
    dataA = interpolateValues(sa, start);
    indexA++;
  }
  if (sa["time"][indexB] !== end) {
    dataB = interpolateValues(sa, end);
  }
  const sliceLength = 1 + indexB - indexA + (dataA ? 1 : 0) + (dataB ? 1 : 0);

  const slice: SailingDataArray = (
    Object.keys(sa) as Array<keyof SailingDataArray>
  ).reduce((slice, k) => {
    const columnType = k === 'time'
      ? "array" :
      k === 'latitude' || k === 'longitude' ?
        "typedarray64"
        : "typedarray32";

    // Use subarray when it's available (on TypedArray) and slice otherwise (number[] for time)
    const existingData = (
      columnType === "typedarray64" || columnType === "typedarray32"
        ? (sa[k] as Float64Array).subarray(indexA!, indexB! + 1)
        : sa[k]!.slice(indexA!, indexB! + 1)
    ) as Float32Array;

    // If we do not need to add new points, then we can just return subarrays. This is much faster.
    if (!dataA && !dataB) {
      slice[k as "sog"] = existingData;
    } else {
      // Create new array
      if (columnType === "typedarray64" || columnType === "typedarray32") {
        slice[k as "latitude"] = columnType === 'typedarray64' ? new Float64Array(sliceLength) : new Float32Array(sliceLength) as unknown as Float64Array;
        // Add first point if needed
        if (dataA) {
          slice[k as "sog"][0] = dataA[k as "sog"] as number;
        }
        // Copy existing data
        slice[k as "sog"].set(existingData, dataA ? 1 : 0);
        // Add new end point if needed
        if (dataB) {
          slice[k as "sog"][sliceLength - 1] = dataB[k as "sog"] as number;
        }
      } else {
        // When the column is not a FloatArray (time)
        slice[k as "time"] = [
          ...(dataA ? [dataA[k as "time"]] : []),
          ...(existingData as unknown as number[]),
          ...(dataB ? [dataB[k as "time"]] : []),
        ];
      }
    }
    return slice;
  }, {} as SailingDataArray);

  return slice;
};

import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { GetBillingHistory } from "~/backend/graphql/GetBillingHistory";

export const FRAGMENT_BILLING_HISTORY = gql`
  fragment InvoiceDetails on Invoice {
    date
    itemsDescription
    subtotal
    discountName
    total
    paid
    hostedInvoiceUrl
  }
  fragment BillingHistory on User {
    billingInfo {
      recentInvoices {
        ...InvoiceDetails
      }
      upcomingInvoicePreview {
        ...InvoiceDetails
      }
    }
  }
`;

const GET_BILLING_HISTORY = gql`
  query GetBillingHistory {
    me {
      id

      ...BillingHistory
    }
  }
  ${FRAGMENT_BILLING_HISTORY}
`;

export const useBillingHistory = ({
  fetchPolicy,
}: { fetchPolicy?: FetchPolicy } = {}) => {
  return useQuery<GetBillingHistory>(GET_BILLING_HISTORY, {
    errorPolicy: "all",
    fetchPolicy,
  });
};

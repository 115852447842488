import humanizeDuration from "humanize-duration";

const chartedSailsHumanizer = humanizeDuration.humanizer({
  round: true,
  units: ["y", "w", "d", "h", "m", "s"],
  delimiter: " ",
  spacer: "",
  largest: 2,
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

export function formatDuration(d: number) {
  return chartedSailsHumanizer(d);
}

export function formatChrono(d: number) {
  let negative = d < 0;
  if (negative) d = d * -1;

  // Hide minus sign for small negative number that will show up as 0 anyway.
  if (negative && d < 500) {
    negative = false;
  }
  let seconds = Math.round(d / 1000);

  const h = Math.floor(seconds / 3600);
  seconds %= 3600;
  const m = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);

  return `${negative ? "-" : ""}${String(h).padStart(2, "0")}:${String(
    m
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}

// Without hours
export function formatChronoShort(d: number) {
  let negative = d < 0;
  if (negative) d = d * -1;
  // Hide minus sign for small negative number that will show up as 0 anyway.
  if (negative && d < 500) {
    negative = false;
  }

  let seconds = Math.round(d / 1000);
  const m = Math.floor(seconds / 60);
  seconds = Math.round(seconds % 60);

  return `${negative ? "-" : ""}${String(m).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
}

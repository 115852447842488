import chroma from "chroma-js";
import { rgba2rgb } from "~/components/util/rgba2rgb";

export const carbon = {
  "50": "#f9f9f9",
  "100": "#e9e9e9",
  "200": "#d3d3d3",
  "300": "#BEBEBE",
  "400": "#A8A8A8",
  "500": "#7C7C7C",
  "600": "#666666",
  "700": "#515151",
  "800": "#3B3B3B",
  "900": "#252525",
} as const;

export const seaway = {
  "50": "#F5F9FE",
  "100": "#EAF2FD",
  "200": "#D5E6FB",
  "300": "#C1D9FA",
  "400": "#ACCCF8",
  "500": "#82B3F4",
  "600": "#6DA6F2",
  "700": "#5999F1",
  "800": "#448DEF",
  "900": "#2F80ED",
};

export const buoy = {
  "50": "#fef8f2",
  "100": "#fef1e6",
  "200": "#fde4cc",
  "300": "#fbd6b3",
  "400": "#fac89a",
  "500": "#f8ad67",
  "600": "#f79f4e",
  "700": "#f59135",
  "800": "#f4841b",
  "900": "#f37602",
};

export const flame = {
  "100": "#FEEFEE",
  "300": "#FCADA8",
  "600": "#FA847C",
  "900": "#f95c50",
};

export const algae = {
  "100": rgba2rgb(chroma("#5cd1b0").alpha(0.1), chroma("#ffffff")).hex(),
  "300": rgba2rgb(chroma("#5cd1b0").alpha(0.5), chroma("#ffffff")).hex(),
  "600": rgba2rgb(chroma("#5cd1b0").alpha(0.75), chroma("#ffffff")).hex(),
  "900": "#5cd1b0",
};

import {
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useEffect, useRef } from "react";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { Avatar } from "~/components/navbar/Avatar";
import { useDisableFullPageDropZone } from "../../dropzone/FullPageDropzone";
import { DialogTransitionUp } from "../DialogTransitionUp";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(1),
    },
  },
  content: {
    margin: `${theme.spacing(5)}px ${theme.spacing(9)}px ${theme.spacing(
      5
    )}px ${theme.spacing(9)}px`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
  logo: {
    height: 104,
    width: 104,
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

interface IProps {
  // Replace the close button by a back arrow
  showBackArrow?: boolean;
  dialogName?: string;
  onClose?: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeButton"
  ) => void;
  onBack?: () => void;
}

export type CSDialogProps = IProps & DialogProps;

export const CSDialog = ({
  children,
  showBackArrow,
  dialogName,
  onBack,
  open,
  ...props
}: CSDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const { onClose } = props;
  const handleClose = useCallback(
    () => onClose?.({}, "closeButton"),
    [onClose]
  );

  const lastOpenState = useRef<boolean | undefined>();
  const openEvent = useAnalyticEvent(`${dialogName || "unnameddialog"}-open`);
  const closeEvent = useAnalyticEvent(`${dialogName || "unnameddialog"}-close`);
  useEffect(() => {
    if (dialogName && open !== lastOpenState.current) {
      if (open) {
        openEvent();
      } else if (lastOpenState.current === true) {
        // we want to ignore when the previous state was undefined
        closeEvent();
      }
    }
    lastOpenState.current = open;
  });

  useDisableFullPageDropZone(open);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      {...props}
      TransitionComponent={DialogTransitionUp}
      // See https://github.com/ariakit/ariakit/issues/863#issuecomment-1063483439
      TransitionProps={{ tabIndex: "none" } as any}
    >
      {(props.onClose || (showBackArrow && onBack)) && (
        <IconButton
          className={classes.closeButton}
          onClick={showBackArrow ? onBack : handleClose}
        >
          {showBackArrow ? <BackIcon /> : <CloseIcon />}
        </IconButton>
      )}
      <div className={classes.content}>
        <Avatar className={classes.logo} />
        {children}
      </div>
    </Dialog>
  );
};

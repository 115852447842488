import { gql, useQuery } from "@apollo/client";
import { GetMyTracks } from "~/backend/graphql/GetMyTracks";
import { FRAGMENT_IMPORT } from "../imports/fragmentImport";

export const GET_MY_TRACKS = gql`
  query GetMyTracks {
    me {
      id
      countTracks
      imports {
        ...ImportInfo
      }
    }
  }
  ${FRAGMENT_IMPORT}
`;

export const useMyTracks = () => {
  return useQuery<GetMyTracks>(GET_MY_TRACKS);
};

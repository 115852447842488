import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useMutateSession from "~/backend/data-hooks/session/useMutateSession";
import useSessionData from "~/backend/data-hooks/session/useSessionData";
import { processApolloError } from "~/backend/utils/processApolloError";
import {
  SailingSessionEdit,
  applyEditToSession,
  coalesceSailingSessionEdits,
  editedProperties,
} from "~/model/SailingSessionEdit";
import { SessionInfo } from "../../backend/graphql/SessionInfo";
import { useAnalyticEvent } from "../analytics/useAnalyticEvent";
import { isNullish } from "../util/isNotNullish";
import { ISessionContext, SessionContext } from "./SessionContext";
import { SessionReplayProps } from "./SessionReplay";

export const GQLSessionReplay = ({
  session,
  ReplayComponent,
  waitUntilDataLoaded,
  DataLoadingComponent,
}: {
  session: SessionInfo;
  ReplayComponent: FC<SessionReplayProps>;
  waitUntilDataLoaded?: boolean;
  DataLoadingComponent?: FC;
}) => {
  const mutateSession = useMutateSession(session);
  const [sessionEdits, updateSessionEdits] =
    useState<SailingSessionEdit | null>(null);

  // Memoize the result so the session object does not change on every render.
  const sessionWithEdits = useMemo(
    () => (sessionEdits ? applyEditToSession(session, sessionEdits) : session),
    [session, sessionEdits]
  );

  const {
    trips,
    loading: sessionDataLoading,
    error,
  } = useSessionData({
    id: session.id,
    startTime: session.startTime,
    endTime: session.endTime,
    boatIds: session.boats.map((b) => b.id),
    editable: session.permissions.edit,
    filterGpsPointsAboveSOGKts:
      session.configuration?.filterGpsPointsAboveSOGKts ?? undefined,
  });

  const [countMutationInProgress, updateCountMutationInProgress] = useState(0);
  const [mutationError, updateMutationError] = useState<string | null>(null);

  if (error) {
    throw new Error(error);
  }
  if (mutationError) {
    throw new Error(mutationError);
  }

  const triggerEvent = useAnalyticEvent("session");

  const handleOneEdit = useCallback(
    (edit: SailingSessionEdit) => {
      updateSessionEdits((edits) =>
        edits
          ? coalesceSailingSessionEdits(edits, edit)
          : coalesceSailingSessionEdits({ id: session.id }, edit)
      );
    },
    [session.id]
  );

  useEffect(() => {
    if (sessionEdits) {
      const timeout = setTimeout(() => {
        updateSessionEdits(null);
        updateCountMutationInProgress((c) => c + 1);
        mutateSession(sessionEdits)
          .then(() => {
            triggerEvent({
              eventName: "session-edit-complete",
              editedProperties: editedProperties(sessionEdits),
            });
            updateCountMutationInProgress((c) => c - 1);
          })
          .catch((e) => {
            const pe = processApolloError(e);
            triggerEvent({
              eventName: "session-edit-error",
              editedProperties: editedProperties(sessionEdits),
              error: pe.simplifiedError,
            });
            updateMutationError(pe.simplifiedError);
            updateCountMutationInProgress((c) => c - 1);
          });
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [mutateSession, sessionEdits, triggerEvent]);

  const sessionContext = useMemo<ISessionContext>(
    () => ({ session, editSession: handleOneEdit }),
    [session, handleOneEdit]
  );

  if (isNullish(trips) && waitUntilDataLoaded) {
    return DataLoadingComponent ? <DataLoadingComponent /> : null;
  }

  return (
    <SessionContext.Provider value={sessionContext}>
      <ReplayComponent
        session={sessionWithEdits}
        trips={trips || new Map()}
        onSessionEdited={session.permissions.edit ? handleOneEdit : undefined}
        showLoadingIndicator={sessionDataLoading || countMutationInProgress > 0}
      />
    </SessionContext.Provider>
  );
};

export default GQLSessionReplay;

import { normalizeDirection, pointOfSail } from "@chartedsails/sailing-math";
import { v4 as uuidv4 } from "uuid";
import { normalSegmentByPointAndBearing } from "~/algo/geometry/normal-segment/normalSegmentByPointAndBearing";
import { maxDistance } from "~/algo/max-distance/max-distance";
import { orderBoatsOnBearing } from "~/algo/projections/order-boats-on-bearing";
import { AddGateOption } from "~/algo/race/make-gates/AddGateOption";
import { RaceGateType } from "~/backend/graphql/globalTypes";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { ReplayBoat } from "~/components/replay/replaycontext/Replay";
import { isNotNullish } from "~/components/util/isNotNullish";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { pickMostCommon } from "~/util/pick-most-common";

type StartDirection =
  | "upwind"
  | "reaching-starboard"
  | "reaching-port"
  | "downwind";

const boatDirection = ({
  trueWindDirection,
  boat,
  startTime,
}: {
  trueWindDirection: number;
  boat: ReplayBoat;
  startTime: number;
}): null | StartDirection => {
  const startPosition = boat.data?.getValuesAtTime(startTime)?.position;
  if (!startPosition || !boat.data) {
    return null;
  }

  // Find where the boat are going
  let t = maxDistance(boat.data, startPosition, startTime, 3 * 60 * 1000);
  // But limit to 2min after start to make sure we dont go too far...
  t = Math.min(t, startTime + 2 * 60 * 1000);

  // t can be less than startTime if the track ends before startTime
  const bracket =
    t > startTime
      ? boat.data?.getBracketData(startTime, t, { trueWindDirection })
      : null;

  if (!bracket || !bracket.averageTrueWindAngle) {
    return null;
  }

  const pos = pointOfSail(bracket.averageTrueWindAngle);

  switch (pos) {
    case "reaching":
      if (bracket.averageTrueWindAngle < 0) {
        return "reaching-port";
      } else {
        return "reaching-starboard";
      }
    default:
      return pos;
  }
};

const boatsGeneralDirection = ({
  trueWindDirection,
  boats,
  startTime,
}: {
  trueWindDirection: number;
  boats: ReplayBoat[];
  startTime: number;
}) => {
  const directions = boats
    .map((boat) => boatDirection({ trueWindDirection, boat, startTime }))
    .filter(isNotNullish);

  return pickMostCommon(directions) ?? null;
};

/*
 * Returns the direction in which the boats are sailing for a given wind and type of start.
 * Because the convention is to say "northerly wind" when the wind comes from 0 degrees, an upwind start with a wind of 0 will return 0.
 */
const boatsDirectionForStart = (
  trueWindDirection: number,
  startDirection: StartDirection
) => {
  switch (startDirection) {
    case "upwind":
      return trueWindDirection;
    case "downwind":
      return normalizeDirection(trueWindDirection + Math.PI);
    case "reaching-starboard":
      return normalizeDirection(trueWindDirection - Math.PI / 2);
    case "reaching-port":
      return normalizeDirection(trueWindDirection + Math.PI / 2);
  }
};

export const guessStartLineGate = ({
  trueWindDirection,
  boats,
  startTime,
}: {
  trueWindDirection: number;
  boats: ReplayBoat[];
  startTime: number;
}): AddGateOption | null => {
  // First we detect in what directions the boats are sailing at the start
  const startDirection = boatsGeneralDirection({
    trueWindDirection,
    boats,
    startTime,
  }) as StartDirection | null;
  if (startDirection === null) {
    return null;
  }
  const boatsDirection = boatsDirectionForStart(
    trueWindDirection,
    startDirection
  );

  // Then we find who is first in that direction. They will be at the center of the line
  const orderedBoats = orderBoatsOnBearing(boats, startTime, boatsDirection);
  if (orderedBoats.length === 0) {
    return null;
  }

  const firstBoat = orderedBoats[0];
  const centerCoordinates = firstBoat.data?.getValuesAtTime(startTime);

  if (!centerCoordinates) {
    return null;
  }
  let centerPosition = coordinatesToLonLat(centerCoordinates);

  const startSegment = normalSegmentByPointAndBearing(
    centerPosition,
    boatsDirection,
    200
  );

  const newMarks: SailingMark[] = [
    {
      id: uuidv4(),
      longitude: startSegment.a[0],
      latitude: startSegment.a[1],
      type: null,
    },
    {
      id: uuidv4(),
      longitude: startSegment.b[0],
      latitude: startSegment.b[1],
      type: null,
    },
  ];
  return {
    label: "Start Line",
    gate: {
      type: RaceGateType.START_LINE,
      markId: newMarks[0].id!,
      secondMarkId: newMarks[1].id,
    },
    addMarks: newMarks,
  };
};

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

interface IProps {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: { [mimeType: string]: string[] };
  multiple?: boolean;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  renderDragAccept?: (isAccept: boolean) => React.ReactNode;
  renderDragReject?: (isReject: boolean) => React.ReactNode;
}

const FPDropzoneContext = createContext({
  disable: false,
  setDisable: (v: boolean) => {},
});

export const FullPageDropZone = ({
  children,
  onDrop,
  accept,
  multiple,
  style,
  className,
  renderDragAccept,
  renderDragReject,
}: IProps) => {
  const [disable, setDisable] = useState(false);

  const {
    getRootProps: getDropzoneRootProps,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple,
    accept,
    noClick: true,
    noKeyboard: true,
    disabled: disable,
  });

  const dragAcceptNode = useMemo(
    () => renderDragAccept?.(isDragAccept),
    [isDragAccept, renderDragAccept]
  );
  const dragRejectNode = useMemo(
    () => renderDragReject?.(isDragReject),
    [isDragReject, renderDragReject]
  );

  return (
    <div
      {...getDropzoneRootProps({
        className,
        style: style,
      })}
    >
      <FPDropzoneContext.Provider value={{ disable, setDisable }}>
        {children}
      </FPDropzoneContext.Provider>
      {dragAcceptNode}
      {dragRejectNode}
    </div>
  );
};

const useFullPageDropZoneContext = () => {
  return useContext(FPDropzoneContext);
};

export const useDisableFullPageDropZone = (disable: boolean) => {
  // Disable the fullpage dropzone when we appear and re-enable when we disappear.
  const { setDisable: setDisableFullPageDropzone } =
    useFullPageDropZoneContext();

  // This bugs when we have more than two dialogs. Happens when creating a new boat class.
  useEffect(() => {
    setDisableFullPageDropzone(disable);
    return () => {
      setDisableFullPageDropzone(false);
    };
  }, [setDisableFullPageDropzone, disable]);
};

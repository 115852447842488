import { ApolloClient, gql } from "@apollo/client";
import {
  SessionData,
  SessionDataVariables
} from "~/backend/graphql/SessionData";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { isNotNullish } from "../../../components/util/isNotNullish";
import { fetchAndPrepareTracks } from "./fetchAndPrepareDataArrow";

export const SESSION_DATA = gql`
  query SessionData($sessionId: ID!) {
    session(id: $sessionId) {
      id
      configuration {
        filterGpsPointsAboveSOGKts
      }
      sessionData {
        boatId
        markId
        tracks {
          importId
          url
        }
      }
    }
  }
`;


/**
 * Use the sessionId to fetch fresh track S3 links from the server and load the tracks.
 *
 * @param graphqlClient
 * @param sessionId
 * @param filterGpsPointsAboveSOGKts
 * @returns
 */
export const fetchAndPrepareSessionData = async (
  graphqlClient: ApolloClient<any>,
  sessionId: string,
  filterGpsPointsAboveSOGKts: number | undefined
) => {
  const startLoadingTimer = performance.now();

  const pt = new PerfTimer("fetchAndPrepareDataArrow");
  const { data: sessionData } = await graphqlClient.query<
    SessionData,
    SessionDataVariables
  >({
    query: SESSION_DATA,
    variables: { sessionId },
    // We need to refetch from server because the S3 links expire after 10 minutes.
    fetchPolicy: "network-only",
  });
  pt.mark("graphql query SessionData");

  if (!sessionData?.session?.sessionData) {
    throw new Error("No session data");
  }

  const trackList = sessionData.session.sessionData.map(sd => {
    const trackId = sd.boatId ?? sd.markId ?? null;
    if (!trackId) {
      return null;
    }
    else return { trackId, ...sd };
  }).filter(isNotNullish);

  // Now load the tracks and convert them to InteractiveTrips
  const data = await fetchAndPrepareTracks(trackList, filterGpsPointsAboveSOGKts)

  return {
    ...data,
    // Make sure the elapsed time includes time to make the first GQL query.
    elapsedTime: performance.now() - startLoadingTimer,
  }
};

import { GeoPosition } from "@chartedsails/tracks";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";

/**
 * Project the points in the given viewport and returns the top most, left most, etc points.
 *
 * @param points
 * @param viewport
 * @returns
 */
export const boundsFromPoints = (
  points: GeoPosition[],
  { project, unproject }: ChartedSailsViewport
) => {
  if (points.length === 0) {
    throw new Error(`Cant find gate marks.`);
  }

  const p0 = project([points[0].longitude, points[0].latitude]);
  let topMost = p0;
  let leftMost = p0;
  let rightMost = p0;
  let downMost = p0;

  for (let i = 1; i < points.length; i++) {
    const p = project([points[i].longitude, points[i].latitude]);
    if (p[1] < topMost[1]) topMost = p;
    if (p[1] > downMost[1]) downMost = p;
    if (p[0] < leftMost[0]) leftMost = p;
    if (p[0] > rightMost[0]) rightMost = p;
  }
  return {
    topMost: unproject(topMost),
    leftMost: unproject(leftMost),
    rightMost: unproject(rightMost),
    downMost: unproject(downMost),
  };
};

import { BoatManeuver } from "~/components/maneuver/ManeuverList";
import { ManeuverSortOrder } from "~/components/maneuver/ManeuverTable/ManeuverTable";
import {
  centerReplayOnBoat,
  standardMapAnimationDuration,
  updatePlaybackAnimation,
} from "./reducer-helpers";
import { Replay } from "./Replay";

export type ReplayManeuverCardAction =
  | { event: "maneuvercard-hovermaneuver"; maneuver?: BoatManeuver }
  | { event: "maneuvercard-clickmaneuver"; maneuver?: BoatManeuver }
  | {
      event: "maneuvercard-sortorder-change";
      sortOrder: ManeuverSortOrder | undefined;
    }
  | { event: "maneuvercard-filterchange-hover"; maneuver?: BoatManeuver }
  | {
      event: "maneuvercard-filterchange-selected";
      maneuver?: BoatManeuver;
    }
  | { event: "maneuvercard-effect-dismissselectedmaneuver" }
  | { event: "maneuvergraph-hover"; time?: number }
  | { event: "maneuvergraph-click"; time: number }
  | { event: "maneuvercard-update-visibleboats"; visibleBoats: string[] };

export const maneuverCardEventReducer = (
  replay: Replay,
  action: ReplayManeuverCardAction
): Replay => {
  switch (action.event) {
    // Events triggered by the maneuver card
    case "maneuvercard-hovermaneuver": {
      return {
        ...replay,
        hover: action.maneuver
          ? {
              type: "maneuver",
              maneuver: action.maneuver,
              boatId: action.maneuver.boatId,
            }
          : undefined,
      };
    }
    case "maneuvercard-clickmaneuver": {
      if (action.maneuver === undefined) {
        return { ...replay, selectedManeuver: undefined };
      } else {
        replay = centerReplayOnBoat(
          {
            ...replay,
            lockMapOnBoatId: action.maneuver.boatId,
            playbackTime: action.maneuver.maneuver.interval[0],
          },
          action.maneuver.boatId,
          standardMapAnimationDuration
        );
        return {
          ...replay,
          selectedManeuver: action.maneuver,
          playing: false,
        };
      }
    }
    case "maneuvercard-sortorder-change": {
      return {
        ...replay,
        maneuverSortOrder: action.sortOrder,
      };
    }
    case "maneuvercard-filterchange-hover": {
      return {
        ...replay,
        hover: action.maneuver
          ? {
              type: "maneuver",
              maneuver: action.maneuver,
              boatId: action.maneuver.boatId,
            }
          : undefined,
      };
    }
    case "maneuvercard-effect-dismissselectedmaneuver": {
      return { ...replay, selectedManeuver: undefined };
    }
    case "maneuvercard-filterchange-selected": {
      return { ...replay, selectedManeuver: action.maneuver };
    }
    case "maneuvergraph-click": {
      const m = replay.selectedManeuver;
      if (!m) {
        return replay;
      }
      const boat = replay.boats.find((b) => b.id === m.boatId);
      const data = boat?.data?.getValuesAtTime(action.time);
      if (data) {
        replay = {
          ...replay,
          viewState: {
            ...replay.viewState,
            latitude: data.latitude,
            longitude: data.longitude,
            transitionInterpolator: undefined,
            transitionDuration: 0,
            transitionEasing: undefined,
          },
        };
      }
      return updatePlaybackAnimation(replay, action.time);
    }
    case "maneuvergraph-hover": {
      if (replay.selectedManeuver && action.time) {
        return {
          ...replay,
          hover: {
            type: "maneuver",
            boatId: replay.selectedManeuver.boatId,
            maneuver: replay.selectedManeuver,
            time: action.time,
          },
        };
      } else {
        return { ...replay, hover: undefined };
      }
    }
    case "maneuvercard-update-visibleboats": {
      const isLockedBoatInVisibleBoats =
        replay.lockMapOnBoatId &&
        action.visibleBoats.indexOf(replay.lockMapOnBoatId) !== -1;
      return {
        ...replay,
        lockMapOnBoatId: isLockedBoatInVisibleBoats
          ? replay.lockMapOnBoatId
          : undefined,
        visibleBoats: action.visibleBoats,
      };
    }
  }
  return replay;
};

export function percentile(a: number[], p: number) {
  if (p === 1) {
    return Math.max(...a);
  } else {
    const sortedList = [...a].sort((a, b) => a - b);
    const index = Math.min(
      Math.max(0, Math.round(sortedList.length * p)),
      sortedList.length - 1
    );
    return sortedList[index];
  }
}

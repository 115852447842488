import { NavigationData } from "@chartedsails/tracks";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useCallback, useMemo } from "react";
import { SVGOverlay, ViewState } from "react-map-gl";
import { RaceAnalysis } from "~/algo/race/model/results/RaceAnalysis";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { isNotNullish } from "~/components/util/isNotNullish";
import { isRaceSegment } from "~/model/RaceSegment";
import { isBoatWithData, ReplayBoat } from "../replaycontext/Replay";
import { useReplayContextAndDispatch } from "../replaycontext/ReplayContext";
import { MovingBoat } from "./MovingBoat";

interface IProps {
  viewport: ViewState;
}

const nonClickableStyle: CSSProperties = { pointerEvents: "none" };

const isBoatInCurrentLeg = (
  b: ReplayBoat,
  raceStats: RaceAnalysis | undefined,
  currentLeg: number | undefined,
  time: number
) => {
  if (currentLeg !== undefined && raceStats) {
    const boatStats = raceStats.boats.get(b.id);
    const legStats =
      currentLeg === -1 ? boatStats?.start : boatStats?.legs[currentLeg];
    if (!legStats) {
      return false;
    }
    if (legStats?.startTime === null || legStats?.finishTime === null) {
      return false;
    }
    if (time < legStats.startTime || time > legStats?.finishTime) return false;
  }
  return true;
};

export const BoatOverlay = ({ viewport }: IProps) => {
  const { replay, dispatch } = useReplayContextAndDispatch();

  const alwaysDefinedGateIndex =
    replay.activePane === "racesetup" ? replay.currentGateIndex : -1;
  const boatMarkers = useMemo(() => {
    let boatMarkers: Array<{
      boat: ReplayBoat;
      clickable?: boolean;
      opacity: number;
      id: string;
      position: NavigationData | null;
      boatLength: number | undefined;
    }> = [];

    if (replay.activePane === "replay-chart") {
      // If user is adjusting a time selection, show the boats at start/end of selection
      if (replay.isTimeSelectionChanging && replay.timeSelection) {
        boatMarkers.push(
          ...replay.boats
            .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
            .map((b) => ({
              id: `timesel-start-${b.id}`,
              clickable: false,
              boat: b,
              boatLength: b.boatClass?.boatLength,
              opacity: 1,
              position:
                b.data?.getValuesAtTime(replay.timeSelection![0]) ?? null,
            }))
        );
        boatMarkers.push(
          ...replay.boats
            .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
            .map((b) => ({
              id: `timesel-end-${b.id}`,
              clickable: false,
              boat: b,
              boatLength: b.boatClass?.boatLength,
              opacity: 0.5,
              position:
                b.data?.getValuesAtTime(replay.timeSelection![1]) ?? null,
            }))
        );
      }
      // If user is hovering on a segment, show boats at start/end of segment
      else if (replay.hover?.type === "segment") {
        boatMarkers.push(
          ...replay.boats
            .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
            .map((b) => ({
              id: `start-${b.id}`,
              clickable: false,
              boat: b,
              boatLength: b.boatClass?.boatLength,
              opacity: 1,
              position:
                b.data?.getValuesAtTime(replay.hover!.segment!.startTime) ??
                null,
            }))
        );
        boatMarkers.push(
          ...replay.boats
            .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
            .map((b) => ({
              id: `end-${b.id}`,
              clickable: false,
              boat: b,
              boatLength: b.boatClass?.boatLength,
              opacity: 0.5,
              position:
                b.data?.getValuesAtTime(replay.hover!.segment!.endTime) ?? null,
            }))
        );
      } else {
        // Normally we always want to show the boat in solid at the current playback time.
        // EXCEPT when hovering on the timeline and with tailmode=fading-tail
        // because the solid boat would sit without a tail and that is just
        // weird.
        if (
          !(
            replay.selectedTailMode === "fading-tail" &&
            replay.hover?.type === "time"
          )
        ) {
          boatMarkers.push(
            ...replay.boats
              .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
              .filter(
                (b) =>
                  replay.timeSelection !== undefined ||
                  !isRaceSegment(replay.activeSegment) ||
                  isBoatInCurrentLeg(
                    b,
                    replay.raceAnalysis,
                    replay.selectedLegIndex,
                    replay.playbackTime
                  )
              )
              .map((b) => ({
                id: `solid-${b.id}`,
                clickable: true,
                boat: b,
                boatLength: b.boatClass?.boatLength,
                opacity: 1,
                position: b.data?.getValuesAtTime(replay.playbackTime) ?? null,
              }))
          );
        }
        if (replay.hover?.type === "time") {
          // If we are looking at a race do not show hover beyond the limits of the current leg.
          boatMarkers.push(
            ...replay.boats
              .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
              // Filter out boats that are not in the selected leg at the current time
              .filter(
                (b) =>
                  replay.timeSelection !== undefined ||
                  !isRaceSegment(replay.activeSegment) ||
                  isBoatInCurrentLeg(
                    b,
                    replay.raceAnalysis,
                    replay.selectedLegIndex,
                    replay.hover!.time!
                  )
              )

              .map((b) => ({
                id: `hover-${b.id}`,
                clickable: false,
                boat: b,
                boatLength: b.boatClass?.boatLength,
                opacity: 0.5,
                position: b.data?.getValuesAtTime(replay.hover!.time!) ?? null,
              }))
          );
        }
        // Add hover for hovering on the map or hovering on a maneuver
        if (replay.hover?.type === "map") {
          const boat = replay.boats.find((b) => b.id === replay.hover!.boatId);

          if (boat) {
            boatMarkers.push({
              id: `maphover-${boat.id}`,
              clickable: false,
              boat,
              boatLength: boat.boatClass?.boatLength,
              opacity: 0.5,
              position: boat.data?.getValuesAtTime(replay.hover.time) ?? null,
            });
          }
        }
      }
    } else if (replay.activePane === "trimming") {
      // Show boat at beginning and end of track
      boatMarkers.push(
        ...replay.boats
          .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
          .map((b) => ({
            id: `start-${b.id}`,
            clickable: false,
            boat: b,
            boatLength: b.boatClass?.boatLength,
            opacity: 1,
            position:
              b.data?.getValuesAtTime(
                Math.max(replay.startTime, b.data.startTime)
              ) ?? null,
          }))
      );
      boatMarkers.push(
        ...replay.boats
          .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
          .map((b) => ({
            id: `end-${b.id}`,
            clickable: false,
            boat: b,
            boatLength: b.boatClass?.boatLength,
            opacity: 1,
            position:
              b.data?.getValuesAtTime(
                Math.min(replay.endTime, b.data.endTime)
              ) ?? null,
          }))
      );
    }
    // In race setup mode we show the boat at the end of the leg if it finishes - at the beginning otherwise - or nowhere if it does not even start.
    else if (replay.activePane === "racesetup") {
      const stats = replay.raceAnalysis;
      const race = replay.activeSegment;

      replay.boats.filter(isBoatWithData).forEach((b) => {
        const boatStat = stats?.boats.get(b.id);

        let boatTime: number | undefined = undefined;

        if (alwaysDefinedGateIndex === 0) {
          boatTime = Math.max(race.startTime, b.data.startTime);
          if (boatStat?.start) {
            boatTime = boatStat.start.finishTime;
          }
        } else {
          const leg = boatStat?.legs[alwaysDefinedGateIndex - 1];
          if (leg) {
            if (leg.finishTime) {
              boatTime = leg.finishTime;
            }
          }
        }
        boatMarkers.push({
          id: `race-${b.id}`,
          clickable: false,
          boat: b,
          boatLength: b.boatClass?.boatLength,
          opacity: 1,
          position: boatTime ? b.data.getValuesAtTime(boatTime) : null,
        });
      });

      if (replay.hover?.time) {
        boatMarkers.push(
          ...replay.boats
            .filter((b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1)
            .map((b) => ({
              id: `hover-${b.id}`,
              clickable: false,
              boat: b,
              boatLength: b.boatClass?.boatLength,
              opacity: 0.5,
              position: b.data?.getValuesAtTime(replay.hover!.time!) ?? null,
            }))
        );
      }
    }
    return boatMarkers;
  }, [
    replay.activePane,
    replay.isTimeSelectionChanging,
    replay.timeSelection,
    replay.hover,
    replay.boats,
    replay.visibleBoats,
    replay.selectedTailMode,
    replay.playbackTime,
    replay.activeSegment,
    replay.selectedLegIndex,
    replay.raceAnalysis,
    replay.startTime,
    replay.endTime,
    alwaysDefinedGateIndex,
  ]);

  const boatSelectedEvent = useAnalyticEvent("boat-selected", {
    actionName: "map",
  });

  const drawCallback = useCallback(
    (redrawContext: any) => {
      return boatMarkers
        .filter((bm) => isNotNullish(bm.position))
        .map((bm) => (
          <MovingBoat
            key={bm.id}
            data={bm.position!}
            opacity={bm.opacity}
            mapAngleDegrees={viewport.bearing || 0}
            redrawContext={redrawContext}
            boatColor={bm.boat.color}
            boatLength={bm.boatLength}
            onClick={
              bm.clickable
                ? () => {
                    dispatch({ event: "map-click-boat", boatId: bm.boat.id });
                    boatSelectedEvent({ boatName: bm.boat.name });
                  }
                : undefined
            }
            style={bm.clickable ? undefined : nonClickableStyle}
          />
        ));
    },
    [boatMarkers, boatSelectedEvent, dispatch, viewport.bearing]
  );
  return <SVGOverlay redraw={drawCallback} />;
};

import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import clsx from "clsx";
import React, { useCallback } from "react";
import { useKnownBoats } from "~/backend/data-hooks/useKnownBoats";
import { Boat } from "~/backend/graphql/Boat";
import { processApolloError } from "~/backend/utils/processApolloError";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 20px)",
    },
  },
}));

export interface BoatSelectorProps {
  className?: string;
  value: Boat | null;
  onChange?: (boat: Boat | null) => void;
  onBlur?: (e: any) => void;
  onAddBoat?: (name: string) => void;
  TextFieldProps?: TextFieldProps;
}

const ADD_BOAT_ID = "add-boat";
const filter = createFilterOptions<Boat>();

const getOptionLabel = (b: Boat) => {
  if (b.id === ADD_BOAT_ID) {
    return b.name === "" ? "Add new boat" : `Add ${b.name}`;
  } else {
    return b.name;
  }
};
const getOptionSelected = (option: Boat, value: Boat) => option.id === value.id;
const renderOption = (b: Boat) => {
  if (b.id === ADD_BOAT_ID) {
    return (
      <>
        <AddCircleIcon
          color="primary"
          style={{ marginLeft: -4, marginRight: 8 }}
        />
        {b.name ? `Add ${b.name}` : "Add new boat"}
      </>
    );
  } else {
    return b.name;
  }
};

export const BoatSelector = ({
  className,
  value,
  onChange,
  onBlur,
  onAddBoat,
  TextFieldProps,
}: BoatSelectorProps) => {
  const classes = useStyles();
  TextFieldProps = TextFieldProps ? { ...TextFieldProps } : {};

  const boatsQuery = useKnownBoats();
  const boats = boatsQuery.data?.me?.boats ?? [];

  const handleChange = useCallback(
    (_e, value, _reason) => {
      if (value?.id === ADD_BOAT_ID) {
        onChange?.(null);
        onAddBoat?.(value.name);
      } else {
        onChange?.(value);
      }
    },
    [onAddBoat, onChange]
  );
  const handleFilterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      if (onAddBoat !== undefined) {
        if (params.inputValue !== "") {
          filtered.push({
            id: ADD_BOAT_ID,
            name: params.inputValue,
            sailNumber: null,
            boatClass: null,
          });
        } else {
          filtered.push({
            id: ADD_BOAT_ID,
            name: "",
            sailNumber: null,
            boatClass: null,
          });
        }
      }
      return filtered;
    },
    [onAddBoat]
  );

  if (boatsQuery.loading) {
    TextFieldProps.helperText = "Loading...";
    TextFieldProps.disabled = true;
  } else if (boatsQuery.error) {
    const errorText = processApolloError(boatsQuery.error).simplifiedError;

    TextFieldProps.helperText = errorText;
    TextFieldProps.disabled = true;
    TextFieldProps.error = true;
  }

  return (
    <>
      <Autocomplete
        disabled={TextFieldProps.disabled}
        fullWidth={TextFieldProps.fullWidth}
        size={TextFieldProps.size}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        options={boats}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        filterOptions={handleFilterOptions}
        renderInput={(params) => (
          <TextField
            {...TextFieldProps}
            {...params}
            className={clsx(classes.root, className)}
          />
        )}
        renderOption={renderOption}
      />
    </>
  );
};

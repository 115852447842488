import { SailingMark } from "~/backend/graphql/SailingMark";
import { Segment } from "~/backend/graphql/Segment";
import { EditSessionInput } from "~/backend/graphql/globalTypes";
import { SessionInfo } from "../backend/graphql/SessionInfo";

export type SailingSessionEdit = Partial<EditSessionInput>;

export const coalesceSailingSessionEdits = (
  ...edits: SailingSessionEdit[]
): SailingSessionEdit => {
  return edits.reduce((sumEdit, edit) => ({ ...sumEdit, ...edit }), {});
};

export const applyEditToSession = (
  session: SessionInfo,
  edit: SailingSessionEdit
): SessionInfo => {
  const updatedSession = {
    ...session,
  };
  if (edit.title !== undefined) {
    updatedSession.title = edit.title;
  }
  if (edit.startTime) {
    updatedSession.startTime = edit.startTime;
  }
  if (edit.endTime) {
    updatedSession.endTime = edit.endTime;
  }
  if (edit.mapOrientation !== undefined) {
    updatedSession.mapOrientation = edit.mapOrientation;
  }
  // We need to make sure the __typename is added or the Apollo Cache will dismiss these objects
  if (edit.marks) {
    updatedSession.marks = edit.marks.map<SailingMark>((m) => ({
      ...m,
      __typename: "SailingMark",
      id: m.id ?? null,
      type: m.type ?? null,
    }));
  }
  if (edit.segments) {
    updatedSession.segments = edit.segments.map((s) => ({
      ...s,
      raceConfig: s.raceConfig
        ? { ...s.raceConfig, __typename: "RaceConfig" }
        : null,
      __typename: "Segment",
    })) as Segment[];
  }

  return updatedSession;
};

export const editSessionSegments = (
  segments: Segment[],
  editedSegment: Segment
): Segment[] => {
  const index = segments.findIndex((s) => s.id === editedSegment.id);
  const newSegments = [...segments];
  if (index > -1) {
    newSegments[index] = editedSegment;
  } else {
    newSegments.push(editedSegment);
  }
  return newSegments;
};

export const editedProperties = (edit: SailingSessionEdit) => {
  return Object.keys(edit)
    .filter((k) => k !== "id")
    .sort()
    .join(", ");
};

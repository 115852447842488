import { Replay } from "../../replaycontext/Replay";
import { replayTracksInRaceSetup } from "./replayTracksInRaceSetup";
import { replayTracksInReplay } from "./replayTracksInReplay";
import { replayTracksWhileTrimming } from "./replayTracksWhileTrimming";
import { TrackConfig } from "./TrackConfig";

export const tracksConfigForReplay = (replay: Replay): TrackConfig[] => {
  switch (replay.activePane) {
    case "replay-chart":
      return replayTracksInReplay(replay);
    case "trimming":
      return replayTracksWhileTrimming(replay);
    case "racesetup":
      return replayTracksInRaceSetup(replay);
  }
};

import { shortAngleDist, toRadians } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { getRhumbLineBearing } from "geolib";
import { SailingSegment, UnmarkedSailingSegment } from "../types";

export const makeSegmentsFromIndices = (
  trip: InteractiveTrip,
  keyPoints: number[]
) => {
  const segments = new Array<UnmarkedSailingSegment>();
  for (let i = 0; i < keyPoints.length - 1; i++) {
    const start = trip.time(keyPoints[i]);
    const end = trip.time(keyPoints[i + 1]);
    segments.push({ interval: [start, end] });
  }
  return segments;
};

export const convertSegmentToPoints = (
  trip: InteractiveTrip,
  segments: SailingSegment[]
) => {
  const points = new Array<[number, number]>();
  for (let i = 0; i < segments.length; i++) {
    const s = segments[i];
    if (i === 0 || s.interval[0] !== segments[i - 1].interval[1]) {
      const p1 = trip.getValuesAtTime(s.interval[i])!;
      if (!p1) {
        throw new Error("Invalid segment on trip.");
      }
      points.push([p1.longitude, p1.latitude]);
    }

    const s2 = segments[i];
    const p2 = trip.getValuesAtTime(s2.interval[1])!;
    if (!p2) {
      throw new Error("Invalid segment on trip.");
    }
    points.push([p2.longitude, p2.latitude]);
  }
  return points;
};

/**
 * The true course between the start and end point of the given sailing segment.
 */
export const sailingSegmentCourse = (
  trip: InteractiveTrip,
  segment: SailingSegment
) => {
  const p1 = trip.getValuesAtTime(segment.interval[0]);
  const p2 = trip.getValuesAtTime(segment.interval[1]);
  if (!p1 || !p2) {
    throw new Error(`Invalid segment on trip.`);
  }
  return toRadians(
    getRhumbLineBearing(
      [p1.longitude, p1.latitude],
      [p2.longitude, p2.latitude]
    )
  );
};

// Note that midpoint here is time based. Might be better to do distance based actually.
export const sailingSegmentMidpointAngle = (
  trip: InteractiveTrip,
  segment: UnmarkedSailingSegment
) => {
  const midTime =
    segment.interval[0] + (segment.interval[1] - segment.interval[0]) / 2;

  const p1 = trip.getValuesAtTime(segment.interval[0]);
  const p2 = trip.getValuesAtTime(midTime);
  const p3 = trip.getValuesAtTime(segment.interval[1]);
  if (!p1 || !p2 || !p3) {
    throw new Error(`Invalid segment on trip.`);
  }
  const a1 = toRadians(
    getRhumbLineBearing(
      [p1.longitude, p1.latitude],
      [p2.longitude, p2.latitude]
    )
  );
  const a2 = toRadians(
    getRhumbLineBearing(
      [p2.longitude, p2.latitude],
      [p3.longitude, p3.latitude]
    )
  );
  return shortAngleDist(a1, a2);
};

// Return the invert (selected seconds become unselected and vice-versa)
export const invertSegments = (
  trip: InteractiveTrip,
  segments: UnmarkedSailingSegment[]
) => {
  const s2: UnmarkedSailingSegment[] = [];
  for (let i = 0; i <= segments.length; i++) {
    const start = i === 0 ? trip.startTime : segments[i - 1].interval[1];
    const end = i === segments.length ? trip.endTime : segments[i].interval[0];
    if (end - start > 0) {
      s2.push({ interval: [start, end] });
    }
  }

  return s2;
};

export const segmentForTime = (segments: SailingSegment[], time: number) => {
  const matches = segments
    .filter((s) => time >= s.interval[0] && time < s.interval[1])
    .sort(
      (a, b) => a.interval[1] - a.interval[0] - b.interval[1] - b.interval[0]
    );

  return matches.length > 0 ? matches[0] : null;
};

import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQuery } from "../util/useQuery";
import { WebSession } from "./WebSession";

export const WebSessionContext = createContext<WebSession>({});

export const useWebSession = () => useContext(WebSessionContext);

export const WebSessionWrapper: FC = ({ children }) => {
  const [affiliateCode, setAffiliateCode] = useState<string>();

  const query = useQuery();
  const queryAff = query.get("aff");

  useEffect(() => {
    if (queryAff && queryAff !== affiliateCode) {
      setAffiliateCode(queryAff);
    }
  }, [affiliateCode, queryAff]);

  const webSessionContext = useMemo<WebSession>(() => {
    return {
      affiliateCode,
    };
  }, [affiliateCode]);

  return (
    <WebSessionContext.Provider value={webSessionContext}>
      {children}
    </WebSessionContext.Provider>
  );
};

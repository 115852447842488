import { default as MuiLink, LinkProps } from "@material-ui/core/Link";
import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { analytics, LinkAnalytics } from "~/backend/analytics/analytics";

const LinkAdapter = React.forwardRef<
  RouterLink,
  { to: string; children: string }
>((props, ref) => <RouterLink innerRef={ref as null} {...props} />);

type ILinkProps =
  | ({ to: string; children: string } & Omit<LinkProps, "ref"> & LinkAnalytics)
  | ({
      to: string;
      children: JSX.Element | null;
      className?: string;
      onClick?: () => void;
    } & LinkAnalytics);

export const Link = ({ eventName, eventProps, ...props }: ILinkProps) => {
  const { onClick } = props;
  const clickHandler = useCallback(
    (e) => {
      if (eventName) {
        analytics.event(eventName, eventProps);
      }
      onClick?.(e);
    },
    [eventName, eventProps, onClick]
  );

  if (typeof props.children === "string") {
    return (
      <MuiLink
        component={LinkAdapter}
        {...props}
        children={props.children as string}
        onClick={clickHandler}
      />
    );
  } else {
    return <RouterLink {...props} onClick={clickHandler} />;
  }
};

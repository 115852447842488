import { toDegrees } from "@chartedsails/sailing-math";
import { easeCubic } from "d3-ease";
import { FlyToInterpolator } from "react-map-gl";
import { prepareRaceAnalysis } from "~/algo/race/model/results/prepareRaceAnalysis";
import { RaceSegment } from "~/model/RaceSegment";
import {
  coalesceSailingSessionEdits,
  SailingSessionEdit
} from "~/model/SailingSessionEdit";
import { Replay, replayCurrentTwd } from "./Replay";

export const standardMapAnimationDuration = 500;

export const centerReplayOnCoordinates = (
  replay: Replay,
  lonlat: [number, number],
  transitionDuration?: number
) => {
  return {
    ...replay,
    viewState: {
      ...replay.viewState,
      latitude: lonlat[1],
      longitude: lonlat[0],
      transitionInterpolator:
        transitionDuration !== undefined ? new FlyToInterpolator() : undefined,
      transitionDuration,
      transitionEasing:
        transitionDuration !== undefined ? easeCubic : undefined,
    },
  };
};

export const centerReplayOnBoat = (
  replay: Replay,
  boatId: string,
  transitionDuration?: number
) => {
  const boat = replay.boats.find((b) => b.id === boatId);
  const data = boat?.data?.getValuesAtTime(replay.playbackTime);
  if (data) {
    return centerReplayOnCoordinates(
      replay,
      [data.longitude, data.latitude],
      transitionDuration
    );
  } else {
    return replay;
  }
};

export const updatePlaybackAnimation = <T extends Replay>(
  replay: T,
  time: number
): T => {
  if (time > replay.endTime) {
    time = replay.endTime;
  }
  if (time < replay.startTime) {
    time = replay.startTime;
  }
  return {
    ...replay,
    playbackTime: time,
    playbackAnimationStartPosition: time,
    playbackAnimationStartTime: Date.now(),
  };
};

export const animateRotation = <R extends Replay>(replay: R, angle: number) => {
  return {
    ...replay,

    viewState: {
      ...replay.viewState,
      bearing: toDegrees(angle),
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    },
  };
};

export const replayWithSessionEdit = <T extends Replay>(
  replay: T,
  edit: SailingSessionEdit
): T => {
  return {
    ...replay,
    pendingEdit: replay.pendingEdit
      ? coalesceSailingSessionEdits(replay.pendingEdit, edit)
      : edit,
  };
};

export const replayWithActiveRace = <T extends Replay>(
  replay: T,
  race: RaceSegment
) => {
  const newReplay = { ...replay, activeSegment: race };
  const twd = replayCurrentTwd(newReplay);
  return {
    ...newReplay,
    raceAnalysis: replay.sailingInsights
      ? prepareRaceAnalysis(race, newReplay.boats, twd, replay.sailingInsights)
      : undefined,
  };
};

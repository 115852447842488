import { Position } from "@chartedsails/sailing-data";
import { lineString } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
import lineIntersect from "@turf/line-intersect";
import { csturf } from "~/util/turf-in-webapp";

/**
 * Starting from two lines defined by a point and a bearing, return the geographical point where those two lines intersect.
 */
export const findIntersectionFromPointAndBearing = (
  startPoint: Position,
  startBearing: number,
  endPoint: Position,
  endBearing: number,
  maxDistance: number
) => {
  const entryLine = [
    startPoint,
    csturf.destination(startPoint, maxDistance, startBearing),
  ];
  const exitLine = [
    csturf.destination(endPoint, maxDistance, endBearing),
    endPoint,
  ];
  const intercept = lineIntersect(lineString(entryLine), lineString(exitLine));
  if (intercept.features.length >= 1) {
    return getCoord(intercept.features[0]) as Position;
  } else {
    return null;
  }
};

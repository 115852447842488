import { shortAngleDist, toRadians } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { getDistance, getRhumbLineBearing } from "geolib";

interface IProps {
  trip: InteractiveTrip;
  twd: number;
  interval: [number, number];
}

const AVERAGE_DURATION_PCT = 0.1;
const MINIMUM_AVERAGE_PERIOD = 5000;

export const bucketLoss = ({ trip, twd, interval }: IProps) => {
  const duration = interval[1] - interval[0];
  const averagePeriod = Math.max(
    duration * AVERAGE_DURATION_PCT,
    MINIMUM_AVERAGE_PERIOD
  );
  const startAveraging = Math.max(trip.startTime, interval[0] - averagePeriod);
  const entryData = trip.getBracketData(startAveraging, interval[0], {
    trueWindDirection: twd,
  });

  if (!entryData || !entryData.averageVelocityMadeGood) {
    return undefined;
  }
  // This is the distance the boat would have gained towards the bissecting course without the tack
  const distanceProjectedOnCourse =
    (entryData.averageVelocityMadeGood * duration) / 1000;

  const startPosition = trip.getValuesAtTime(interval[0]);
  const endPosition = trip.getValuesAtTime(interval[1]);
  if (!startPosition || !endPosition) {
    return undefined;
  }
  const distance = getDistance(startPosition, endPosition);
  const angle = toRadians(getRhumbLineBearing(startPosition, endPosition));
  const distanceMadeOnCourse = distance * Math.cos(shortAngleDist(angle, twd));

  return distanceProjectedOnCourse - distanceMadeOnCourse;
};

import { makeStyles } from "@material-ui/core";
import { useMemo } from "react";
import { SessionInfo } from "~/backend/graphql/SessionInfo";
import { MAPBOX_STYLE_MOBILE } from "~/backend/maps";
import { ReplayMap } from "~/components/replay/map/ReplayMap";
import { ReplayContext } from "~/components/replay/replaycontext/ReplayContext";
import { isRaceSegment } from "~/model/RaceSegment";
import { LaylineOverlay } from "../race/laylines/LaylineOverlay";
import { RaceOverlay } from "../race/racemark/RaceOverlay";
import { useAnimatedPlayback } from "../replay/hooks/useAnimatedPlayback";
import { useIOSfullHeightStyle } from "../replay/hooks/useIOSfullHeightStyle";
import { tailFadingTime } from "../replay/map/tracks-config/replayTracksInReplay";
import { useReplayReducerFromSession } from "../replay/replaycontext/useReplayReducer";
import { SessionEventOverlay } from "../replay/session-events/SessionEventsOverlay";
import { SessionReplayProps } from "../sessionreplay/SessionReplay";
import { MobilePane } from "./MobilePane";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    touchAction: "none",
  },
  mapContainer: {
    position: "relative",
    flexGrow: 2,
    flexShrink: 2,
    minHeight: 0,
  },
}));

export const MobileSessionReplay = ({
  session,
  showLoadingIndicator,
  onSessionEdited,
  trips,
}: SessionReplayProps) => {
  const classes = useStyles();

  const [replay, dispatch] = useReplayReducerFromSession(
    session as SessionInfo,
    trips,
    { editSession: onSessionEdited }
  );

  useAnimatedPlayback({ replay, dispatch });

  // Expand to full screen on iOS
  const extraRootStyle = useIOSfullHeightStyle();

  const markOverlay = useMemo(() => {
    if (replay.activePane === "racesetup") {
    } else if (replay.activePane === "replay-chart") {
      if (replay.activeSegment && isRaceSegment(replay.activeSegment)) {
        return (
          <>
            {replay.raceAnalysis && replay.selectedLegIndex !== undefined && (
              <LaylineOverlay
                raceConfig={replay.activeSegment.raceConfig}
                raceAnalysis={replay.raceAnalysis}
                activeLeg={replay.selectedLegIndex}
              />
            )}
            <RaceOverlay
              race={replay.activeSegment}
              boats={replay.boats.filter(
                (b) => replay.visibleBoats.indexOf(b.id) !== -1
              )}
            />
          </>
        );
      } else if (
        replay.hover &&
        replay.hover.type === "segment" &&
        isRaceSegment(replay.hover.segment)
      ) {
        return (
          <RaceOverlay
            race={replay.hover.segment}
            boats={replay.boats.filter(
              (b) => replay.visibleBoats.indexOf(b.id) !== -1
            )}
          />
        );
      }
    }
  }, [
    replay.activePane,
    replay.activeSegment,
    replay.boats,
    replay.hover,
    replay.raceAnalysis,
    replay.selectedLegIndex,
    replay.visibleBoats,
  ]);

  const sessionEventMarkers = useMemo(() => {
    if (
      replay.activePane !== "replay-chart" ||
      isRaceSegment(replay.activeSegment) ||
      isRaceSegment(replay.hover?.segment)
    ) {
      return null;
    }
    const visibleTime: [number, number] = replay.activeSegment
      ? [replay.activeSegment.startTime, replay.activeSegment.endTime]
      : replay.timeSelection
      ? replay.timeSelection
      : replay.selectedTailMode === "fading-tail"
      ? [
          (replay.hover?.time ?? replay.playbackTime) - tailFadingTime,
          replay.hover?.time ?? replay.playbackTime,
        ]
      : [replay.startTime, replay.endTime];
    return (
      <SessionEventOverlay
        sessionEvents={replay.events}
        visibleBoats={replay.visibleBoats}
        visibleTimeBounds={visibleTime}
      />
    );
  }, [
    replay.activePane,
    replay.activeSegment,
    replay.endTime,
    replay.events,
    replay.hover?.segment,
    replay.hover?.time,
    replay.playbackTime,
    replay.selectedTailMode,
    replay.startTime,
    replay.timeSelection,
    replay.visibleBoats,
  ]);

  return (
    <div className={classes.root} style={extraRootStyle}>
      <ReplayContext.Provider value={replay} dispatch={dispatch}>
        <div className={classes.mapContainer}>
          <ReplayMap
            mapDecorations={markOverlay}
            mapStyle={MAPBOX_STYLE_MOBILE}
          >
            {sessionEventMarkers}
          </ReplayMap>
        </div>
        <MobilePane />
      </ReplayContext.Provider>
    </div>
  );
};

// For backwards compatibility with old session saved before new fields were added

import { TrackPoint, TrackPointFieldsDescription } from "@chartedsails/sailing-data";
import { SailingDataArray } from "./types";

export const arraysFromTrackpoints = (points: TrackPoint[]): SailingDataArray => {
  const length = points.length;

  const sailingArrays: SailingDataArray = {
    time: points.map(p => p.time),
    latitude: new Float64Array(Array.from({ length }, (_, i) => points[i].position![1])),
    longitude: new Float64Array(Array.from({ length }, (_, i) => points[i].position![0])),
    sog: new Float32Array(Array.from({ length }, (_, i) => points[i].sog!)),
    cog: new Float32Array(Array.from({ length }, (_, i) => points[i].cog!)),

  }

  for (const key of Object.keys(TrackPointFieldsDescription) as Array<
    keyof TrackPoint
  >) {
    if (
      !["time", "longitude", "latitude", "sog", "cog"].includes(key) &&
      points.some((p) => p[key] !== undefined && p[key] !== null)
    ) {
      sailingArrays[key as 'sog'] = new Float32Array(Array.from({ length }, (_, i) => points[i][key] as number))
    }
  }

  return sailingArrays
}

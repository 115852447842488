import chroma from "chroma-js";

// Thanks http://marcodiiga.github.io/rgba-to-rgb-conversion
export const rgba2rgb = (color: chroma.Color, background: chroma.Color) => {
  const alpha = color.alpha();
  const bg_rgb = background.rgb();
  const color_rgb = color.rgb();

  return chroma([
    (1 - alpha) * bg_rgb[0] + alpha * color_rgb[0],
    (1 - alpha) * bg_rgb[1] + alpha * color_rgb[1],
    (1 - alpha) * bg_rgb[2] + alpha * color_rgb[2],
  ]);
};

import {
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useCallback, useEffect, useRef } from "react";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { useDisableFullPageDropZone } from "../../dropzone/FullPageDropzone";
import { DialogTransitionUp } from "../DialogTransitionUp";

const useStyles = makeStyles((theme) => ({
  dialog: {},
  notFullscreen: {
    "& .MuiPaper-rounded": {
      borderRadius: 16,
    },
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    "& svg": {
      height: 24,
      width: 24,
    },
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
      // I am not sure why but this is required on iOS - Works in Safari and Chrome but not iOS Safari.
      zIndex: 1,
    },
  },
  content: {
    margin: `${theme.spacing(5)}px ${theme.spacing(9)}px ${theme.spacing(
      5
    )}px ${theme.spacing(9)}px`,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(6),
    },
  },
}));

interface IProps {
  dialogName?: string;
  onClose?: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeButton"
  ) => void;
  onBack?: () => void;
}

export type CSDialogProps = IProps & DialogProps;

export const CSDialog2 = ({
  children,
  dialogName,
  onBack,
  open,
  ...props
}: CSDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const { onClose } = props;
  const handleClose = useCallback(
    () => onClose?.({}, "closeButton"),
    [onClose]
  );

  const lastOpenState = useRef<boolean | undefined>();
  const openEvent = useAnalyticEvent(`${dialogName || "unnameddialog"}-open`);
  const closeEvent = useAnalyticEvent(`${dialogName || "unnameddialog"}-close`);
  useEffect(() => {
    if (dialogName && open !== lastOpenState.current) {
      if (open) {
        openEvent();
      } else if (lastOpenState.current === true) {
        // we want to ignore when the previous state was undefined
        closeEvent();
      }
    }
    lastOpenState.current = open;
  });

  useDisableFullPageDropZone(open);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      className={clsx(classes.dialog, !fullScreen && classes.notFullscreen)}
      maxWidth="sm"
      fullWidth={true}
      {...props}
      TransitionComponent={DialogTransitionUp}
    >
      {props.onClose && (
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};

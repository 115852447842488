import { InteractiveTrip } from "@chartedsails/tracks";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { isNotNullish } from "~/components/util/isNotNullish";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { GeoSegment } from "../../geometry/GeoSegment";
import { findTripIntersectionWithSegment } from "./findTripIntersectionWithSegment";

/**
 * Given a line defined by two marks and a list of trips, returns the time when the first boat crosses that line.
 */
export const findFirstLineCrossingTime = (
  markA: SailingMark,
  markB: SailingMark,
  trips: InteractiveTrip[],
  startTime: number
) => {
  const line: GeoSegment = {
    a: coordinatesToLonLat(markA),
    b: coordinatesToLonLat(markB),
  };

  const times = trips
    .map((trip) => findTripIntersectionWithSegment(trip, line, startTime))
    .filter(isNotNullish);

  if (times.length > 0) {
    return Math.min(...times);
  } else {
    return null;
  }
};

import { gql } from "@apollo/client";
import { CreateBoat, CreateBoatVariables } from "~/backend/graphql/CreateBoat";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { GET_KNOWN_BOATS } from "../useKnownBoats";
import { FRAGMENT_BOAT_CLASS } from "./fragmentBoatClass";

const CREATE_BOAT = gql`
  mutation CreateBoat($name: String!, $sailNumber: String, $boatClassId: ID) {
    createBoat(
      name: $name
      sailNumber: $sailNumber
      boatClassId: $boatClassId
    ) {
      id
      name
      sailNumber
      user {
        id
      }
      boatClass {
        ...BoatClass
      }
    }
  }
  ${FRAGMENT_BOAT_CLASS}
`;

export const useCreateBoat = () => {
  const mutation = useMutationWithErrorHandling<
    CreateBoat,
    CreateBoatVariables
  >(CREATE_BOAT, {
    refetchQueries: [{ query: GET_KNOWN_BOATS }],
  });

  return mutation;
};

import { TrackPoint } from "@chartedsails/sailing-data";
import { arraysFromTrackpoints } from "../sailing-arrays/arraysFromTrackpoints";
import { interactiveTripFromArrays } from "../sailing-arrays/interactiveFromArrays";
import { interpolateArraysValues } from "../sailing-arrays/interpolateArraysValue";
import { sogcogFromPositions } from "../sailing-arrays/sogcogFromPositions";

export const interactiveTripFromTrackpoints = (data: TrackPoint[]) => {
  let sa = arraysFromTrackpoints(data);

  // Fill all the NaN in the data so we have 100% coverage of data
  sa = interpolateArraysValues(sa);

  if (!("sog" in sa) || !("cog" in sa)) {
    sa = sogcogFromPositions(sa);
  }

  return interactiveTripFromArrays(sa);
};

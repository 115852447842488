import { ApolloError } from "@apollo/client";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useContactMessage } from "~/backend/data-hooks/contact/useContactMessage";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";
import { CSDialog } from "../csdialog/CSDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    display: "flex",
    flexDirection: "column",
  },
  errorMessage: {},
  messageSent: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  actionButton: {
    alignSelf: "flex-end",
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  actionName: string;
  prompt?: string;
  title?: string;
  subtitle?: string;
}

const formSchema = yup.object({
  email: yup
    .string()
    .required("Please provide an email address so we can reply.")
    .email("Please provide a valid email address."),
  message: yup
    .string()
    .required("How can we help?")
    .min(10, "Please tell us a little more!"),
});

export const ContactDialog = ({
  open,
  onClose,
  prompt,
  actionName,
  title,
  subtitle,
}: IProps) => {
  const classes = useStyles();
  const me = useMe();
  const email = me.data?.me?.email;
  const initialFormValues = {
    email: email || "",
    message: prompt || "",
  };

  title = title ?? "Contact Us";
  subtitle = subtitle ?? "We are here to help! We typically reply in 24 hours.";

  const [messageSent, updateMessageSent] = useState(false);
  useEffect(() => {
    if (open) {
      updateMessageSent(false);
    }
  }, [open]);

  const sendContactMessage = useContactMessage();
  const sentEvent = useAnalyticEvent("contact-sent");

  const handleSend = useCallback<
    FormikConfig<typeof initialFormValues>["onSubmit"]
  >(
    async (values: typeof initialFormValues, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        await sendContactMessage({
          variables: { ...values, context: actionName },
        });
        updateMessageSent(true);
        sentEvent();
      } catch (e) {
        setStatus(processApolloError(e as ApolloError).simplifiedError);
        setSubmitting(false);
      }
    },
    [actionName, sendContactMessage, sentEvent]
  );

  return (
    <CSDialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
      dialogName="contact"
    >
      {messageSent ? (
        <div className={classes.content}>
          <Typography variant="h4" align="center" paragraph>
            Message Sent!
          </Typography>
          <Typography
            variant="body1"
            className={classes.messageSent}
            align="center"
          >
            Your message has been sent. We typically reply in 24 hours.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={onClose}
            className={classes.actionButton}
          >
            Close
          </Button>
        </div>
      ) : (
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSend}
          validationSchema={formSchema}
        >
          {({ isSubmitting, handleSubmit, status, setStatus }) => (
            <form className={classes.content} onChange={() => setStatus()}>
              <Typography variant="h4" align="center" paragraph>
                {title}
              </Typography>
              <Typography variant="body1" align="center" paragraph>
                {subtitle}
              </Typography>
              <Typography
                color="error"
                align="center"
                variant="body2"
                paragraph
                className={classes.errorMessage}
              >
                {status || " "}
              </Typography>
              <TextFormField
                label="Your Email address"
                name="email"
                variant="outlined"
              />
              <TextFormField
                label="Your Message"
                name="message"
                variant="outlined"
                multiline
                rows={10}
              />
              <LoadingButton
                onClick={handleSubmit as any}
                loading={isSubmitting}
                variant="contained"
                color="primary"
                className={classes.actionButton}
                type="submit"
              >
                Send
              </LoadingButton>
            </form>
          )}
        </Formik>
      )}
    </CSDialog>
  );
};

import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { useEffect, useState } from "react";

// simple version of
//  https://github.com/mvasin/react-div-100vh/blob/master/src/lib/getWindowHeight.js

export const useIOSfullHeightStyle = () => {
  // https://stackoverflow.com/a/57924983/1405629
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  const [height, setHeight] = useState<number | null>(() =>
    isIOS ? measureHeight() : null
  );
  useEffect(() => {
    function setMeasuredHeight() {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    if (isIOS) {
      window.addEventListener("resize", setMeasuredHeight);
      return () => window.removeEventListener("resize", setMeasuredHeight);
    }
  }, [isIOS]);

  const style: CSSProperties = {};

  if (isIOS) {
    // On iOS the '100vh' does not work because it's the size without address bar but the bar never goes away.
    style.height = height ? `${height}px` : "100vh";
  }

  return style;
};

function measureHeight(): number | null {
  return document.documentElement?.clientHeight || window.innerHeight;
}

export function average(numbers: number[] | Float32Array | Float64Array) {
  if (numbers.length === 0) {
    return null;
  }
  if (numbers instanceof Float32Array || numbers instanceof Float64Array) {
    let sum = 0;
    for (let i = 0; i < numbers.length; i++) {
      sum += numbers[i];
    }
    return sum / numbers.length;
  } else {
    return numbers.reduce((total, n) => total + n, 0) / numbers.length;
  }
}

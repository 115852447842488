import { toDegrees } from "@chartedsails/sailing-math";
import { v4 as uuidv4 } from "uuid";
import { AddGateOption } from "~/algo/race/make-gates/AddGateOption";
import { addGateToRace } from "~/algo/race/make-gates/addGateToRace";
import { findFirstLineCrossingTime } from "~/algo/race/marks-rounding/findFirstLineCrossingTime";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { SailingMarkType } from "~/backend/graphql/globalTypes";
import { cleanRaceConfig } from "~/components/race/make-gates/clean-race-config";
import { isNotNullish } from "~/components/util/isNotNullish";
import { RaceSegment } from "~/model/RaceSegment";
import { editSessionSegments } from "~/model/SailingSessionEdit";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { isIntervalOverlapping } from "~/util/isIntervalOverlapping";
import { turf } from "~/util/turf-in-webapp";
import { ReplayMapStyle } from "../map/ReplayMap";
import { Replay, ReplayRaceSetup } from "./Replay";
import { replayMakeAllTracksFitView } from "./helpers/makeAllTracksFitView";
import { replayWithActiveRace, replayWithSessionEdit } from "./reducer-helpers";

const AUTOMATIC_MARK_MERGING = false;
const MERGE_MARK_DISTANCE = 3;

export type RaceSetupAction =
  // Race Editor
  | {
      event: "racesetuppane-edit-guntime";
      gunTime: number;
    }
  | {
      event: "racesetuppane-adjust-bounds";
      bounds: [number, number];
      adjusting: boolean;
    }
  | { event: "racesetuppane-save-race"; segment: RaceSegment }
  | { event: "racesetuppane-add-gate"; selectedGate: AddGateOption }
  | { event: "racesetuppane-delete-lastgate" }
  | { event: "racesetuppane-select-gate"; currentGateIndex: number }

  // Editing marks on map
  | { event: "raceoverlay-mark-edit"; mark: SailingMark }

  // time chart
  | { event: "timechart-click"; time: number }
  | { event: "timechart-hover"; time?: number; boatId?: string }
  | { event: "timechart-hover-leg"; hoverLegIndex?: number }
  | { event: "timechart-click-leg"; legIndex: number }

  // Map controls
  | { event: "mapcontrols-rotate-wind"; twd: number }
  | { event: "mapcontrols-select-mapstyle"; mapStyle: ReplayMapStyle };

export const raceSetupEventReducer = (
  replay: ReplayRaceSetup,
  action: RaceSetupAction
): Replay => {
  switch (action.event) {
    case "racesetuppane-edit-guntime": {
      return {
        ...replayWithActiveRace(replay, {
          ...replay.activeSegment,
          raceConfig: {
            ...replay.activeSegment.raceConfig,
            gunTime: action.gunTime,
          },
        }),
        activePane: "racesetup",
        currentGateIndex: 0,
      };
    }
    case "racesetuppane-adjust-bounds": {
      // Always update the isTimeSelectionChanging
      replay = { ...replay, isTimeSelectionChanging: action.adjusting };
      // Check that we are not reducing too much (beyond start time or before finish time)
      const finishStats = replay.raceAnalysis?.finish;
      if (action.bounds[0] > replay.activeSegment.raceConfig.gunTime) {
        return replay;
      }
      if (
        finishStats &&
        finishStats.lastBoatToFinish &&
        action.bounds[1] <= finishStats.lastBoatToFinish
      ) {
        return replay;
      }
      const updatedRace = {
        ...replay.activeSegment,
        startTime: action.bounds[0],
        endTime: action.bounds[1],
      };

      // Make sure users cannot extend this race on top of another segment
      const otherSegments = replay.segments.filter(
        (s) => s.id !== updatedRace.id
      );
      const noOverlap = otherSegments.every(
        (s) => !isIntervalOverlapping(action.bounds, s)
      );
      if (noOverlap) {
        return {
          ...replayWithActiveRace(replay, updatedRace),
        };
      } else {
        return replay;
      }
    }
    case "racesetuppane-add-gate": {
      const updatedRace = addGateToRace(
        replay.activeSegment,
        action.selectedGate
      );
      let newReplay = {
        ...replayWithActiveRace(replay, updatedRace),
        currentGateIndex: updatedRace.raceConfig.gates.length - 1,
      };
      return replayMakeAllTracksFitView(newReplay);
    }
    case "racesetuppane-delete-lastgate": {
      const race = replay.activeSegment;
      if (replay.activeSegment.raceConfig.gates.length < 2) {
        return replay;
      }
      const updatedRace: RaceSegment = {
        ...race,
        raceConfig: cleanRaceConfig({
          ...race.raceConfig,
          gates: race.raceConfig.gates.slice(
            0,
            race.raceConfig.gates.length - 1
          ),
        }),
      };
      const newReplay = {
        ...replayWithActiveRace(replay, updatedRace),
        currentGateIndex: updatedRace.raceConfig.gates.length - 1,
      };
      return replayMakeAllTracksFitView(newReplay);
    }
    case "racesetuppane-save-race":
      const segments = editSessionSegments(replay.segments, action.segment);
      return {
        ...replayWithSessionEdit(replay, { segments }),
        activePane: "replay-chart",
        analysisTab: "performance",
        activeSegment: action.segment,
        timeSelection: undefined,
        segments,
      };
    case "racesetuppane-select-gate":
      return replayMakeAllTracksFitView({
        ...replay,
        currentGateIndex: action.currentGateIndex,
      });
    case "raceoverlay-mark-edit": {
      let gates = [...replay.activeSegment.raceConfig.gates];
      let marks = [...replay.activeSegment.raceConfig.marks];
      const index = marks.findIndex((m) => m.id === action.mark.id);
      if (index === -1) {
        throw new Error(`Cannot find mark being edited ${action.mark.id}`);
      }

      if (action.mark.type === SailingMarkType.PINGED) {
        // Pinged marks cannot be edited - Create a new one instead.
        // Push a copy of the pinged mark with a different UUID (to save it)
        marks.push({ ...action.mark, id: uuidv4() });
        // Update the mark that the user has grabbed and is editing
        action.mark.type = null;
        marks[index] = action.mark;
      } else {
        // See if user is trying to merge two marks together. Less than 3m will merge the marks.
        if (AUTOMATIC_MARK_MERGING) {
          const thisMark = marks[index];
          const otherMarks = marks
            .filter((m) => m.id !== action.mark.id)
            .map((m) => ({
              mark: m,
              distance: turf.distance(
                turf.point(coordinatesToLonLat(thisMark)),
                turf.point(coordinatesToLonLat(m)),
                { units: "meters" }
              ),
            }));
          const superCloseMark = otherMarks.find(
            (m) => m.distance < MERGE_MARK_DISTANCE
          );

          if (superCloseMark) {
            // Switcheroo: We cannot easily change the mark being edited while it's dragged
            // so instead we remove the other one and make the one we are editing take its place
            console.log(
              `Merging mark ${superCloseMark.mark.id} into ${action.mark.id}`
            );
            // Get rid of our mark.
            marks = marks.filter((m) => m.id !== superCloseMark.mark.id);
            // Any gate referencing this mark needs to be updated
            gates = gates.map((g) => ({
              ...g,
              markId:
                g.markId === superCloseMark.mark.id
                  ? action.mark.id!
                  : g.markId,
              secondMarkId:
                g.secondMarkId === superCloseMark.mark.id
                  ? action.mark.id!
                  : g.secondMarkId,
            }));
            console.log(`edited list of gates`, gates);
          } else {
            // Otherwise just replace the mark being edited by the updated version provided by the action
            marks[index] = action.mark;
          }
        } else {
          marks[index] = action.mark;
        }
      }

      const raceSegment: RaceSegment = {
        ...replay.activeSegment,
        raceConfig: {
          ...replay.activeSegment.raceConfig,
          gates,
          marks,
        },
      };

      // If editing the start line we will adjust the start time
      if (replay.currentGateIndex === 0) {
        const startGate = replay.activeSegment.raceConfig.gates[0];
        const markA = marks.find((m) => m.id === startGate.markId);
        const markB = marks.find((m) => m.id === startGate.secondMarkId);
        if (markA && markB) {
          const newStartTime = findFirstLineCrossingTime(
            markA,
            markB,
            replay.boats.map((b) => b.data).filter(isNotNullish),
            replay.activeSegment.startTime
          );
          if (newStartTime) {
            raceSegment.raceConfig.gunTime = newStartTime;
          }
        }
      }

      const newReplay = replayWithActiveRace(replay, raceSegment);

      // Only allow edits if we are not messing up the previous marks
      if (
        replay.raceAnalysis &&
        replay.currentGateIndex > 0 &&
        replay.raceAnalysis.legs[replay.currentGateIndex - 1]
      ) {
        const legAnalysis =
          replay.raceAnalysis.legs[replay.currentGateIndex - 1];
        const newLegAnalysis =
          newReplay.raceAnalysis?.legs[replay.currentGateIndex - 1];
        if (
          newLegAnalysis &&
          newLegAnalysis.startRanks.length < legAnalysis.startRanks.length
        ) {
          // Refuse to edit if we are breaking a previous leg
          return replay;
        }
      }
      return newReplay;
    }
    case "timechart-click":
      return { ...replay, playbackTime: action.time };
    case "timechart-hover":
      return {
        ...replay,
        hover:
          action.time !== undefined
            ? { type: "time", time: action.time }
            : undefined,
      };
    case "timechart-hover-leg":
      return { ...replay };
    case "timechart-click-leg":
      return { ...replay, currentGateIndex: action.legIndex + 1 };
    case "mapcontrols-select-mapstyle":
      return {
        ...replay,
        mapStyle: action.mapStyle,
      };
    case "mapcontrols-rotate-wind":
      const raceSegment = {
        ...replay.activeSegment,
        trueWindDirection: action.twd,
      };

      return {
        ...replayWithActiveRace(replay, raceSegment),
        viewState: {
          ...replay.viewState,
          bearing: toDegrees(action.twd),
          transitionInterpolator: undefined,
          transitionDuration: undefined,
          transitionEasing: undefined,
        },
      };
  }
  return replay;
};

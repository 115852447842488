import { TrackEvent } from "@chartedsails/sailing-data";
import { InteractiveTrip, TripNumericVariable } from "@chartedsails/tracks";
import { ViewportProps, ViewState } from "react-map-gl";
import { RaceAnalysis } from "~/algo/race/model/results/RaceAnalysis";
import { BoatClass } from "~/backend/graphql/BoatClass";
import { Segment } from "~/backend/graphql/Segment";
import { BoatManeuver } from "~/components/maneuver/ManeuverList";
import { ManeuverSortOrder } from "~/components/maneuver/ManeuverTable/ManeuverTable";
import { RaceSegment } from "~/model/RaceSegment";
import { SailingInsights } from "~/model/SailingInsights";
import { SailingSessionEdit } from "~/model/SailingSessionEdit";
import { Media } from "../../../backend/graphql/Media";
import { SailingMark } from "../../../backend/graphql/SailingMark";
import { VideoMedia } from "../../../model/VideoMedia";
import { ReplayMapStyle, TailMode } from "../map/ReplayMap";
import { ReplayMapPick } from "../map/useMapPickCallback";

export interface ReplayBoat {
  name: string;
  id: string;
  color: string;
  sailNumber?: string;
  boatClass?: BoatClass;

  // Undefined until data is loaded
  data?: InteractiveTrip;
}

export interface ReplaySailingMark {
  mark: SailingMark;
  // Only for dynamic marks. Undefined until data is loaded.
  trip?: InteractiveTrip;
}

export interface ReplayBoatWithData extends ReplayBoat {
  data: InteractiveTrip;
}

export const isBoatWithData = (b: ReplayBoat): b is ReplayBoatWithData =>
  !!b.data;

/*
 * Encapsulate the information that is used by many of the components
 * to display a replay.
 *
 * This is meant to simplify and factor out of UI code a large part of the state that was
 * previously maintained in ReplayPage.tsx
 *
 * It includes: the data we display, where we are in the replay, user selection that impacts the entire UI, etc.
 *
 * FAQ:
 *  - Isn't the entire tree going to be re-rendered whenever one of these change?
 *    Yes - It was already the case when we had them in the state of ReplayPage.
 *    But now we can be smarter and work towards memoizing this object so re-rendering is avoided when possible.
 *
 *  - Isn't this going to tighly couple the components and make reuse harder?
 *    Yes it will.
 *    To mitigate this, only components in the replay/ folder should use the ReplayState. Complex components
 *    should be built from existing components that live elsewhere. The replay/* components become adapters to take
 *    the context and pass it to these other components.
 *
 *  - Can I modify the replay state directly?
 *    No! It should be considered immutable. Never change it directly but use a state/updater to change it.
 */
export type Replay = ReplayChartMode | ReplayRaceSetup | ReplayTrimming;

type ReplayHover =
  | {
    type: "time";
    time: number;
    boatId?: string;
    segment?: undefined;
    variable?: TripNumericVariable
  }
  | {
    type: "maneuver";
    maneuver: BoatManeuver;
    boatId: string;
    // Time is defined when user is hovering on the maneuver graph
    time?: number;
    segment?: undefined;
  }
  | { type: "segment"; segment: Segment; time?: undefined; boatId?: undefined }
  | ({ type: "map"; segment?: undefined } & ReplayMapPick)
  | {
    type: "leg";
    legIndex: number;
    time?: undefined;
    boatId?: undefined;
    segment?: undefined;
  };

export type SessionEventWithTypedTrackEvent = {
  boatId: string;
  trackEvent: TrackEvent;
};

interface BaseReplay {
  // What is the earliest date displayed
  startTime: number;
  // What is the latest date displayed
  endTime: number;

  // Limits of trimming
  earliestDataAvailable: number;
  latestDataAvailable: number;

  // View state
  viewState: ViewState & Partial<ViewportProps>;
  mapStyle: ReplayMapStyle;
  initialZoomToBoatsRequested: boolean;
  initialZoomToBoatsScheduled: boolean;

  // Playhead time
  playbackTime: number;
  playbackAnimationStartTime: number;
  playbackAnimationStartPosition: number;
  // Is the replay playing?
  playing: boolean;
  // Speed multiplier of the replay
  playbackSpeed: number;

  hover?: ReplayHover;

  // Selected range
  timeSelection?: [number, number];
  isTimeSelectionChanging?: boolean;
  // Time-Zoom
  zoomSelection?: [number, number];

  selectedManeuver?: BoatManeuver;
  selectedLegIndex?: undefined | number;

  selectedTailMode: TailMode;

  sessionTrueWindDirection?: number;

  boats: Array<ReplayBoat>;
  lockMapOnBoatId?: string;
  visibleBoats: string[];

  activeSegment?: Segment;
  segments: Segment[];

  raceAnalysis?: RaceAnalysis;

  sailingInsights?: SailingInsights;
  speedScaleMax: number;
  maneuverSortOrder?: ManeuverSortOrder;

  sailingMarks: ReplaySailingMark[];

  media: Media[];
  activeVideo?: VideoMedia;
  mediaSyncing?: boolean;

  analysisTab: null | string;

  editable?: boolean;
  pendingEdit?: SailingSessionEdit;

  events: SessionEventWithTypedTrackEvent[];
}

export interface ReplayChartMode extends BaseReplay {
  activePane: "replay-chart";
  selectedLegIndex?: number;
  analysisTab: "instruments" | "performance" | "maneuvers";
}

export interface ReplayTrimming extends BaseReplay {
  activePane: "trimming";
}

export interface ReplayRaceSetup extends BaseReplay {
  activePane: "racesetup";
  activeSegment: RaceSegment;
  // Gate being edited
  currentGateIndex: number;
}

export const replayCurrentTwdOrNull = (replay: Replay) => {
  return (
    replay.activeSegment?.trueWindDirection ??
    replay.sessionTrueWindDirection ??
    null
  );
};

export const replayCurrentTwd = (replay: Replay) => {
  return replayCurrentTwdOrNull(replay) ?? 0;
};

import {
  ApolloError,
  DocumentNode,
  MutationFunctionOptions,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";

/**
 * Like useMutation() but will return a function that always throws or returns a result and it's clear in the signature.
 * This simplifies error management in mutation heavy code.
 *
 * @param graphql
 * @returns
 */
export const useMutationWithErrorHandling = <T, V = OperationVariables>(
  graphql: DocumentNode,
  options?: MutationHookOptions<T, V>
) => {
  const [mutation] = useMutation<T, V>(graphql, options);

  const newMutation = useCallback(
    (options?: MutationFunctionOptions<T, V>) => {
      return mutation(options).then(({ data, errors }) => {
        if (errors) {
          throw new ApolloError({ graphQLErrors: errors });
        }
        if (!data) {
          throw new Error(
            `Server did not return data. Please contact us for help.`
          );
        }
        return data;
      });
    },
    [mutation]
  );

  return newMutation;
};

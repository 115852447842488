import { TrackPointFieldsDescription } from "@chartedsails/sailing-data";
import { msToKts, toCelsius, toDegrees } from "@chartedsails/sailing-math";
import { TripNumericVariable } from "@chartedsails/tracks";

export const useDataFormatter = () => {
  // Future improvement: use some Context here so we can change the formatter based on user preferences.
  return (
    variableName: TripNumericVariable,
    value: number
  ): { label: string; unit: string; formattedValue: string } => {
    switch (variableName) {
      case "sog":
        return {
          label: "SOG",
          unit: "kn",
          formattedValue: `${msToKts(value).toFixed(2)}`,
        };
      case "cog":
        return {
          label: "COG",
          unit: "°",
          formattedValue: `${toDegrees(value).toFixed(0)}`,
        };
      case "heading":
        return {
          label: "HDG",
          unit: "°",
          formattedValue: `${toDegrees(value).toFixed(0)}`,
        };
      case "pitch":
        return {
          label: "Pitch",
          unit: "°",
          formattedValue: `${toDegrees(value).toFixed(1)}`,
        };
      case "roll":
        return {
          label: "Heel",
          unit: "°",
          formattedValue: `${toDegrees(value).toFixed(0)}`,
        };
      case "depth":
        return {
          label: "Depth",
          unit: "m",
          formattedValue: `${value.toFixed(0)}`,
        };
      case "heartRate":
        return {
          label: "Heart Rate",
          unit: "bpm",
          formattedValue: `${value.toFixed(0)}`,
        };
      case "airTemperature":
        return {
          label: "Air",
          unit: "ºC",
          formattedValue: `${toCelsius(value).toFixed(1)}`,
        };
      case "waterTemperature":
        return {
          label: "Water",
          unit: "ºC",
          formattedValue: `${toCelsius(value).toFixed(1)}`,
        };
    }

    let description = TrackPointFieldsDescription[variableName];

    switch (description?.type) {
      case "angle":
      case "relative-angle":
        return {
          label: variableName.toUpperCase(),
          unit: "°",
          formattedValue: `${value.toFixed(0)}`,
        };
      case "distance":
        return {
          label: variableName.toUpperCase(),
          unit: "m",
          formattedValue: `${value.toFixed(0)}`,
        };
      case "force":
        return {
          label: variableName.toUpperCase(),
          unit: "N",
          formattedValue: `${value.toFixed(1)}`,
        };
      case "speed":
        return {
          label: variableName.toUpperCase(),
          unit: "kts",
          formattedValue: `${msToKts(value).toFixed(1)}`,
        };
      case "temperature":
        return {
          label: variableName.toUpperCase(),
          unit: "ºC",
          formattedValue: `${toCelsius(value).toFixed(1)}`,
        };
      case "pressure":
        return {
          label: variableName.toUpperCase(),
          unit: "ºC",
          formattedValue: `${toCelsius(value).toFixed(1)}`,
        };
    }

    return {
      label: variableName.toUpperCase(),
      unit: "",
      formattedValue: value.toFixed(1),
    };
  };
};

import { Button, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { RefObject, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useSignup } from "~/backend/data-hooks/user/useSignup";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";
import { HorizontalSplitter } from "~/components/util/HorizontalSplitter";

const initialFormValues = { name: "", password: "", email: "" };

const signupFormSchema = yup.object({
  email: yup
    .string()
    .required("An email address to identify you.")
    .email("Please provide a valid email address."),
  name: yup
    .string()
    .required("Select a name or pseudonym to identify you.")
    .min(3, "At least 3 characters please."),
  password: yup
    .string()
    .required("Please choose a password.")
    .min(6, "Your password must have at least 6 characters."),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  errorMessage: {
    marginBottom: theme.spacing(4),
  },
}));

interface IProps {
  onComplete?: () => void;
  // Used to give some visibility to the parent on our current status for analytics purposes.
  statusRef?: RefObject<{ step: string; error?: string }>;
  onLogin?: (email: string) => void;
  prefillEmail?: string;
}

export const SignupForm = ({
  onComplete,
  onLogin,
  statusRef,
  prefillEmail,
}: IProps) => {
  const classes = useStyles();

  const [step, updateStep] = useState<"email" | "name" | "password">("email");
  const doSignup = useSignup();

  // Clear error on load
  useEffect(() => {
    if (statusRef && statusRef.current) {
      statusRef.current.error = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (statusRef && statusRef.current) {
    statusRef.current.step = step;
  }

  const signupErrorEvent = useAnalyticEvent("signup-error");
  const signupSuccessEvent = useAnalyticEvent("signup-complete");

  const handleSignup: FormikConfig<typeof initialFormValues>["onSubmit"] =
    useCallback(
      (values, actions) => {
        doSignup({ variables: { user: values } })
          .then(({ signup: user }) => {
            signupSuccessEvent({
              id: user.id,
              email: user.email,
              name: user.name ?? undefined,
              isSubscriber: !!user.subscription,
            });
            onComplete?.();
            actions.setSubmitting(false);
          })
          .catch((e) => {
            const pe = processApolloError(e);
            if (Object.keys(pe.fieldErrors).length > 0) {
              actions.setErrors(pe.fieldErrors);
            } else {
              actions.setStatus(pe.simplifiedError);
            }
            actions.setSubmitting(false);
            signupErrorEvent({ error: pe.simplifiedError });
            if (statusRef && statusRef.current) {
              statusRef.current.error = pe.simplifiedError;
            }
          });
      },
      [doSignup, onComplete, signupErrorEvent, signupSuccessEvent, statusRef]
    );

  const handleNext = useCallback(
    (e) => {
      e.preventDefault();
      updateStep(step === "email" ? "name" : "password");
    },
    [step, updateStep]
  );

  let title: string | undefined;
  let visibleFields: Array<typeof step> = [];

  switch (step) {
    case "email":
      title = "Sign Up Using Your Email.";
      visibleFields = ["email"];
      break;
    case "name":
      title = "What's Your Name?";
      visibleFields = ["name"];
      break;
    case "password":
      title = "Set Your Password.";
      visibleFields = ["email", "password"];
      break;
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...initialFormValues, email: prefillEmail || "" }}
        onSubmit={handleSignup}
        validationSchema={signupFormSchema}
      >
        {({
          isSubmitting,
          handleSubmit,
          status,
          errors,
          setStatus,
          values,
        }) => (
          <form className={classes.form} onChange={() => setStatus()}>
            <Typography variant="h4" align="center">
              {title}
            </Typography>
            <Typography
              color="error"
              align="center"
              variant="body2"
              paragraph
              className={classes.errorMessage}
            >
              {status || " "}
            </Typography>
            {visibleFields.indexOf("email") === -1 || (
              <TextFormField
                fullWidth={true}
                autoFocus={step === "email"}
                label="E-Mail Address"
                name="email"
                type="email"
                variant="outlined"
              />
            )}
            {visibleFields.indexOf("name") === -1 || (
              <TextFormField
                fullWidth={true}
                autoFocus={step === "name"}
                label="Name"
                name="name"
                variant="outlined"
              />
            )}
            {visibleFields.indexOf("password") === -1 || (
              <TextFormField
                fullWidth={true}
                autoFocus={step === "password"}
                label="Password"
                name="password"
                type="password"
                helperText="Use at least 6 characters and one number."
                variant="outlined"
              />
            )}
            {step !== "password" ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                type="submit"
                size="large"
                disabled={!!errors[step]}
              >
                Next
              </Button>
            ) : (
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                color="primary"
                onClick={handleSubmit as any}
                type="submit"
                size="large"
              >
                Sign Up
              </LoadingButton>
            )}
            <HorizontalSplitter text="OR" />
            <Button
              variant="outlined"
              onClick={() => {
                onLogin?.(values.email);
              }}
              color="primary"
              size="large"
              fullWidth
            >
              Log in
            </Button>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

import { SailingMarkType } from "../../../backend/graphql/globalTypes";
import { RaceConfig_marks } from "../../../backend/graphql/RaceConfig";
import { RaceSegment } from "../../../model/RaceSegment";
import { uniq } from "../../../util/uniq";
import { ReplaySailingMark } from "../replaycontext/Replay";

/**
 * Take a race and return the same race with references to session dynamic marks
 * replaced by a static mark in `raceConfig.marks`.
 *
 * @param race
 * @param replayMarks
 * @returns
 */
export const raceWithDynamicMarksFrozen = (race: RaceSegment, replayMarks: ReplaySailingMark[]): RaceSegment => {
  const marks = Array<RaceConfig_marks>();

  const fz = (markId: string) => freezeMark(markId, race.raceConfig.marks, replayMarks, race.raceConfig.gunTime);

  for (const gate of race.raceConfig.gates) {
    const mark = fz(gate.markId);
    marks.push(mark);
    if (gate.secondMarkId) {
      const secondMark = fz(gate.secondMarkId);
      marks.push(secondMark);
    }
  }

  return {
    ...race,
    raceConfig: {
      ...race.raceConfig,
      marks: uniq(marks, (a, b) => a.id === b.id)
    }
  }
};

const freezeMark = (markId: string, raceMarks: RaceConfig_marks[], replayMarks: ReplaySailingMark[], freezeTime: number): RaceConfig_marks => {
  let raceMark = raceMarks.find(m => m.id === markId);
  if (raceMark) {
    return raceMark;
  }
  const replayMark = replayMarks.find(m => m.mark.id === markId);
  if (replayMark) {
    // Use the position at freeze time (until we are actually able to use the track data for the mark)
    if (replayMark.mark.type === SailingMarkType.TRACKED) {
      if (!replayMark.trip) {
        throw new Error(`Tracked mark (${markId}) not found in data`);
      }
      const position = replayMark.trip.getValuesAtTime(freezeTime);
      if (!position) {
        throw new Error(`Tracked mark (${markId}) has no position at time ${new Date(freezeTime).toISOString()}`);
      }
      raceMark = { ...replayMark.mark, latitude: position.latitude, longitude: position.longitude };
      return raceMark
    }
    else {
      return replayMark.mark
    }
  }
  else {
    throw new Error(`Mark not found: ${markId}`)
  }
}
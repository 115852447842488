import { interpolate } from "@chartedsails/sailing-math";
import { SailingDataArray } from "./types";

export const interpolateArraysValues = (
  sa: SailingDataArray
): SailingDataArray => {
  const interpolatedArrays: SailingDataArray = {
    time: sa.time,
  } as SailingDataArray;

  const fields = Object.keys(sa).filter((k) => k !== "time") as Array<
    keyof SailingDataArray
  >;
  for (const f of fields) {
    const array = sa[f]!.slice();

    let lastValidIndex = undefined as number | undefined;

    for (let i = 0; i < array.length; i++) {
      if (!Number.isNaN(array[i])) {
        // Walk back and fill the gaps
        for (let j = i; j > (lastValidIndex ?? -1); j--) {
          // Interpolate if we have a previous valid value
          if (
            lastValidIndex !== undefined &&
            sa["time"][i] - sa["time"][lastValidIndex] > 0
          ) {
            const d =
              (sa["time"][j] - sa["time"][lastValidIndex]) /
              (sa["time"][i] - sa["time"][lastValidIndex]);
            if (
              Number.isNaN(array[lastValidIndex ?? 0]) ||
              Number.isNaN(d) ||
              Number.isNaN(array[i])
            ) {
              throw new Error("NaN");
            }
            array[j] = interpolate(array[lastValidIndex], array[i], d);
          }
          // Or just carry this value back
          else {
            array[j] = array[i];
          }
        }

        lastValidIndex = i;
      }
    }
    // Fill end of array if needed
    if (lastValidIndex !== undefined && lastValidIndex < array.length - 1) {
      for (let i = lastValidIndex + 1; i < array.length; i++) {
        array[i] = array[lastValidIndex];
      }
    }
    // Only include the column if it had at least one valid value
    if (lastValidIndex !== undefined) {
      interpolatedArrays[f] = array as any;
    }
  }

  return interpolatedArrays;
};

import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback } from "react";
import { csBlue } from "~/styles/chartedSailsTheme";

interface IProps<V = any> {
  value: V;
  options: Array<{ label: string; value: V }>;
  onChange?: (v: V) => void;
  className?: string;
}

const useStyles = makeStyles(() => ({
  cycleButtonRoot: {
    color: csBlue,
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1,
    textTransform: "none",
  },
}));

export const CycleOptionsButton = ({
  value,
  options,
  onChange,
  className,
}: IProps) => {
  const classes = useStyles();
  let selectedOptionIndex = options.findIndex(({ value: v }) => value === v);
  if (selectedOptionIndex < 0) {
    selectedOptionIndex = 0;
  }
  const selectedOption = options[selectedOptionIndex];

  const handleClick = useCallback(() => {
    const selected = (selectedOptionIndex + 1) % options.length;
    if (onChange) {
      onChange(options[selected].value);
    }
  }, [selectedOptionIndex, options, onChange]);

  if (options.length === 0) {
    return <div />;
  }

  return (
    <Button
      onClick={handleClick}
      className={clsx(classes.cycleButtonRoot, className)}
    >
      {selectedOption.label}
    </Button>
  );
};

import { detectEnvironment } from "./detect-environment";

let t0 = performance.now();
const showRelativeTime = true;

export const resetLogTimer = () => {
  t0 = performance.now();
};

export const log = (m: any, ...args: any[]) => {
  if (detectEnvironment() !== "prod") {
    const time = showRelativeTime
      ? `+${Number(performance.now() - t0).toFixed(1)}ms`
      : new Date().toISOString();
    console.log(`${time}: ${m}`, ...args);
  }
};

import { gql, useQuery } from "@apollo/client";
import { GetMySessions } from "~/backend/graphql/GetMySessions";
import { FRAGMENT_SESSION_DESCRIPTION } from "../session/fragmentSessionDescription";

export const GET_MY_SESSIONS = gql`
  query GetMySessions {
    me {
      id
      countSessions
      sessions {
        ...SessionDescription
      }
    }
  }
  ${FRAGMENT_SESSION_DESCRIPTION}
`;

export const useMySessions = () => {
  return useQuery<GetMySessions>(GET_MY_SESSIONS);
};

import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { guessStartLineGate } from "~/algo/race/make-gates/guessStartLineGate";
import { RaceGateType, SailingMarkType } from "~/backend/graphql/globalTypes";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { Segment } from "~/backend/graphql/Segment";
import {
  Replay,
  replayCurrentTwd,
  SessionEventWithTypedTrackEvent,
} from "~/components/replay/replaycontext/Replay";
import { isRaceSegment, RaceSegment } from "~/model/RaceSegment";

export const DEFAULT_START_SEQUENCE_DURATION = 2 * 60 * 1000;

const startEventsFromSessionEvents = (
  events: SessionEventWithTypedTrackEvent[]
) => {
  const startEvents: Array<{
    time: number;
    pin: SailingMark;
    boat: SailingMark;
  }> = [];
  let lastPinPing: SailingMark | undefined;
  let lastBoatPing: SailingMark | undefined;
  events?.forEach((e) => {
    if (e.trackEvent.event === "ping-boat") {
      lastBoatPing = {
        id: uuidv4(),
        type: SailingMarkType.PINGED,
        latitude: e.trackEvent.position[1],
        longitude: e.trackEvent.position[0],
      };
    } else if (e.trackEvent.event === "ping-pin") {
      lastPinPing = {
        id: uuidv4(),
        type: SailingMarkType.PINGED,
        latitude: e.trackEvent.position[1],
        longitude: e.trackEvent.position[0],
      };
    } else if (
      e.trackEvent.event === "race-start" &&
      lastPinPing &&
      lastBoatPing
    ) {
      startEvents.push({
        time: e.trackEvent.time,
        pin: lastPinPing,
        boat: lastBoatPing,
      });
    }
  });
  return startEvents;
};

const gpsStartFromEvents = (
  events: SessionEventWithTypedTrackEvent[],
  timeSelection: [number, number],
  trueWindDirection: number
) => {
  const eligibleEvents = startEventsFromSessionEvents(events).filter(
    (e) => e.time > timeSelection[0] && e.time < timeSelection[1]
  );

  if (eligibleEvents.length === 0) {
    return null;
  }

  const raceEvent = eligibleEvents[0];

  const marks: SailingMark[] = [
    { ...raceEvent.pin, id: uuidv4() },
    { ...raceEvent.boat, id: uuidv4() },
  ];

  return {
    startTime: timeSelection[0],
    endTime: timeSelection[1],
    id: uuidv4(),
    title: "Recorded race",
    trueWindDirection,
    raceConfig: {
      gunTime: raceEvent.time,
      marks,
      gates: [
        {
          type: RaceGateType.START_LINE,
          markId: marks[0].id,
          secondMarkId: marks[1].id,
        },
      ],
    },
  } as RaceSegment;
};

export const useNewRaceFromGPS = (replay: Replay) => {
  const trueWindDirection = replayCurrentTwd(replay);

  return useMemo<RaceSegment | undefined>(() => {
    const startTime =
      replay.activeSegment?.startTime ??
      replay.timeSelection?.[0] ??
      replay.startTime;
    const endTime =
      replay.activeSegment?.endTime ??
      replay.timeSelection?.[1] ??
      replay.endTime;

    // Return the start recorded by the GPS if there was one.
    const gpsStart = gpsStartFromEvents(
      replay.events,
      [startTime, endTime],
      trueWindDirection
    );
    if (gpsStart) {
      return gpsStart;
    }
  }, [
    replay.activeSegment?.endTime,
    replay.activeSegment?.startTime,
    replay.endTime,
    replay.events,
    replay.startTime,
    replay.timeSelection,
    trueWindDirection,
  ]);
};

export const useNewRace = (replay: Replay) => {
  const trueWindDirection = replayCurrentTwd(replay);

  return useMemo<RaceSegment | undefined>(() => {
    // This is a little big ambiguous: The start of the segment is not the same as the guntime... This is the start of the selection.
    const gunTime =
      replay.activeSegment?.startTime ??
      replay.timeSelection?.[0] ??
      replay.playbackTime;

    // Selection should start at guntime - 30s.
    const selectionStart = Math.max(
      gunTime - DEFAULT_START_SEQUENCE_DURATION,
      replay.startTime
    );
    const endTime =
      replay.activeSegment?.endTime ??
      replay.timeSelection?.[1] ??
      replay.endTime;

    // Guess the start
    const startLine = guessStartLineGate({
      trueWindDirection,
      boats: replay.boats,
      startTime: gunTime,
    });
    if (isRaceSegment(replay.activeSegment)) {
      return undefined;
    } else if (startLine) {
      const segment: Segment = replay.activeSegment
        ? { ...replay.activeSegment, startTime: selectionStart }
        : {
            id: uuidv4(),
            title: "New Race",
            trueWindDirection,
            startTime: selectionStart,
            endTime,
            raceConfig: null,
          };

      segment.raceConfig = {
        gates: [startLine.gate],
        gunTime,
        marks: startLine.addMarks,
      };
      return segment as RaceSegment;
    }
    return undefined;
  }, [
    replay.activeSegment,
    replay.boats,
    replay.endTime,
    replay.playbackTime,
    replay.startTime,
    replay.timeSelection,
    trueWindDirection,
  ]);
};

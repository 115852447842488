import { IconButton, LinearProgress, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import clsx from "clsx";
import { useCallback, useLayoutEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "~/assets/icons/upload2.svg";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { fontLato } from "~/styles/chartedSailsTheme";
import { algae, carbon, flame, seaway } from "~/styles/chartedsailsColors";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";
import {
  FileAcceptedEvent,
  useFileAcceptedCallback,
} from "../upload/useFileAcceptedCallback";

const useStyles = makeStyles((theme) => ({
  root: {},
  uploadPlaceholder: {
    backgroundColor: "white",
    height: 164,
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    border: `1px dashed ${seaway[900]}`,
    borderRadius: 8,
    padding: theme.spacing(4),
    color: seaway[500],
    ...fontLato,
    fontSize: 18,
  },
  cloudIcon: {
    marginBottom: theme.spacing(3),
  },
  helpMessage: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 24,
      color: carbon[800],
    },
    color: carbon[400],
    ...fontLato,
    fontSize: 16,
  },
  error: {
    color: theme.palette.error.main,
  },
  fileUpload: {
    display: "flex",
    backgroundColor: "white",
    border: `1px solid ${carbon[200]}`,
    borderRadius: 8,
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  filePicto: {
    margin: theme.spacing(1),
    height: 64,
    width: 81,
    borderRadius: 4,
    backgroundColor: carbon[400],
  },
  uploadDetails: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  fileName: {
    ...fontLato,
    fontWeight: "bold",
    fontSize: 18,
  },
  progress: {
    ...fontLato,
    fontSize: 14,
    color: carbon[500],
  },
  progressError: {
    color: flame[900],
  },
  progressBar: {
    height: 2,
    marginTop: theme.spacing(1),
    backgroundColor: algae[100],
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: algae[900],
    },
    "& .MuiLinearProgress-bar2Indeterminate": {
      backgroundColor: algae[300],
    },
  },
}));

interface IProps {
  file?: File;
  onNewTrack: (track: ImportInfoWithLicense) => void;
}

/**
 * Handles going from initial state (no file) to an Import object returned by the server.
 * @param param0
 * @returns
 */
export const UploadBox = ({ onNewTrack, file }: IProps) => {
  const classes = useStyles();
  const [uploadState, updateUploadState] = useState<FileAcceptedEvent | null>(
    null
  );

  const handleResetError = useCallback(() => {
    updateUploadState(null);
  }, []);
  const handleUploadEvent = useCallback(
    (e: FileAcceptedEvent) => {
      updateUploadState(e);
      if (e.event === "upload-complete") {
        onNewTrack(e.info);
      }
    },
    [onNewTrack]
  );
  const handleFileAccepted = useFileAcceptedCallback({
    handleEvent: handleUploadEvent,
  });
  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    isDragActive: isDropzoneDragActive,
  } = useDropzone({
    onDropAccepted: handleFileAccepted,
    multiple: false,
  });

  // If we were passed a file, start uploading immediately.
  useLayoutEffect(() => {
    if (file) {
      handleFileAccepted([file]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CSDialogTitle
        title="Add a new track"
        subtitle="You can import data from your GPS, sport watches and mobile apps."
      />
      <CSDialogContent className={classes.root}>
        {uploadState === null ? (
          <>
            <div
              className={classes.uploadPlaceholder}
              {...getDropzoneRootProps()}
            >
              <input {...getDropzoneInputProps()} />
              <img src={CloudUploadIcon} className={classes.cloudIcon} alt="" />
              {isDropzoneDragActive
                ? "Drop your file here."
                : "Drag and drop, or click here to choose a file."}
            </div>
            <div className={classes.helpMessage}>
              <IconButton size="small">
                <HelpOutlineIcon />
              </IconButton>
              Compatible with Velocitek, Vakaros, Sailmon, RaceGeek, Garmin and
              more.
            </div>
          </>
        ) : uploadState.event === "upload-start" ? (
          <div className={classes.fileUpload}>
            <div className={classes.filePicto} />
            <div className={classes.uploadDetails}>
              <div className={classes.fileName}>{uploadState.file.name}</div>
              <div className={classes.progress}>Uploading...</div>
              <LinearProgress className={classes.progressBar} variant="query" />
            </div>
          </div>
        ) : uploadState.event === "upload-uploading" ? (
          <div className={classes.fileUpload}>
            <div className={classes.filePicto} />
            <div className={classes.uploadDetails}>
              <div className={classes.fileName}>{uploadState.file.name}</div>
              <div className={classes.progress}>Uploading...</div>
              <LinearProgress
                className={classes.progressBar}
                variant={
                  uploadState.progress !== undefined
                    ? "determinate"
                    : "indeterminate"
                }
                value={uploadState.progress * 100}
              />
            </div>
          </div>
        ) : uploadState.event === "upload-processing" ? (
          <div className={classes.fileUpload}>
            <div className={classes.filePicto} />
            <div className={classes.uploadDetails}>
              <div className={classes.fileName}>{uploadState.file.name}</div>
              <div className={classes.progress}>Processing...</div>
              <LinearProgress
                className={classes.progressBar}
                variant={
                  uploadState.progress !== undefined
                    ? "determinate"
                    : "indeterminate"
                }
                value={
                  uploadState.progress !== undefined
                    ? uploadState.progress * 100
                    : undefined
                }
              />
            </div>
          </div>
        ) : uploadState.event === "error" ? (
          <div className={classes.fileUpload}>
            <div className={classes.filePicto} />
            <div className={classes.uploadDetails}>
              <div className={classes.fileName}>{uploadState.file.name}</div>
              <div className={clsx(classes.progress, classes.progressError)}>
                {uploadState.message}
              </div>
            </div>
            <IconButton onClick={handleResetError}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : uploadState.event === "upload-complete" ? (
          <div className={classes.fileUpload}>
            <div className={classes.filePicto} />
            <div className={classes.uploadDetails}>
              <div className={classes.fileName}>
                {uploadState.info.displayName}
              </div>
              <div className={classes.progress}>Uploading...</div>
              <LinearProgress
                className={classes.progressBar}
                value={100}
                variant="determinate"
              />
            </div>
          </div>
        ) : null}
      </CSDialogContent>
    </>
  );
};

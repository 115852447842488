export const coordinatesToLonLat = ({
  latitude,
  longitude,
}: {
  latitude: number;
  longitude: number;
}): [number, number] => [longitude, latitude];

export const lonLatToCoordinates = ([lon, lat]:
  | [number, number]
  | number[]) => ({
  latitude: lat,
  longitude: lon,
});

import { Position } from "@chartedsails/sailing-data";
import { distance } from "@chartedsails/sailing-math";
import { SailingDataArray } from "./types";

const isValidPosition = (sa: SailingDataArray, i: number) => {
  return !Number.isNaN(sa.latitude[i]) && !Number.isNaN(sa.longitude[i]);
};

const speedAtIndex = (sa: SailingDataArray, i: number, i2: number) => {
  const p1 = [sa.longitude[i], sa.latitude[i]] as Position;
  const p2 = [sa.longitude[i2], sa.latitude[i2]] as Position;

  const t1 = sa.time[i];
  const t2 = sa.time[i2];

  const d = distance(p1, p2);
  return d / ((t2 - t1) / 1000);
};

export const filterBogusGPSPoints = (
  sa: SailingDataArray,
  maxAcceptableSpeed: number
): SailingDataArray => {
  const filtered = {
    ...sa,
    latitude: sa.latitude.slice(),
    longitude: sa.longitude.slice(),
    sog: sa.sog?.slice() ?? new Float32Array(sa.latitude.length),
    cog: sa.cog?.slice() ?? new Float32Array(sa.latitude.length),
  };
  // Search for the first point with a non-null position
  let firstValidPoint = 0;
  while (
    !isValidPosition(sa, firstValidPoint) &&
    firstValidPoint < sa.latitude.length
  ) {
    firstValidPoint++;
  }

  // Throw an error if there are no such points
  if (firstValidPoint === sa.latitude.length) {
    throw new Error(`No valid position in this dataset.`);
  }

  // Always keep track of the last point we trust to be valid.
  // Here we assume that the first valid point is one we can trust.
  let lastValidPosition = firstValidPoint;

  let dismissed = 0;

  for (let i = firstValidPoint; i < sa.latitude.length; i++) {
    if (!isValidPosition(sa, i)) {
      continue;
    }

    const v = speedAtIndex(sa, lastValidPosition, i);
    if (v > maxAcceptableSpeed) {
      filtered.latitude[i] =
        filtered.longitude[i] =
        filtered.sog[i] =
        filtered.cog[i] =
        NaN;
      dismissed++;
    } else {
      // Otherwise, trust this point.
      lastValidPosition = i;
    }
  }

  if (dismissed > sa.latitude.length / 2) {
    console.warn(
      `Filtering for bogus GPS points would have discarded more than 50% of the points.`
    );
    return sa;
  }

  return filtered;
};

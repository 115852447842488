import { VideoMedia } from "~/model/VideoMedia";
import { Replay } from "./Replay";
import { updatePlaybackAnimation } from "./reducer-helpers";

export type ReplayVideoAction =
  | {
      event: "video-activevideo-switch";
      activeVideo?: VideoMedia;
    }
  | { event: "video-playback-updatetime"; time: number }
  | { event: "video-playback-toggle" }
  | { event: "video-dropped"; media: VideoMedia }
  | { event: "video-clicked"; media: VideoMedia }
  | {
      event: "video-sync-start";
    }
  | {
      event: "video-sync-complete";
    };

export const videoEventReducer = (
  replay: Replay,
  action: ReplayVideoAction
): Replay => {
  switch (action.event) {
    case "video-activevideo-switch":
      return { ...replay, activeVideo: action.activeVideo };
    case "video-playback-updatetime":
      return updatePlaybackAnimation(replay, action.time);
    case "video-playback-toggle":
      return { ...replay, playing: !replay.playing };
    case "video-dropped":
      return {
        ...replay,
        playbackSpeed: 1,
        playbackTime: action.media.time,
        playing: true,
        activeVideo: action.media,
      };
    case "video-clicked":
      return {
        ...replay,
        playbackSpeed: 1,
        playbackTime: action.media.time,
        playing: true,
        activeVideo: action.media,
      };
    case "video-sync-start":
      return {
        ...replay,
        mediaSyncing: true,
        playing: false,
      };
    case "video-sync-complete":
      return {
        ...replay,
        mediaSyncing: false,
        playing: false,
      };
  }
  return replay;
};

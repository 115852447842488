import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { Formik, FormikConfig } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";
import { useResetPassword } from "~/backend/data-hooks/user/useResetPassword";
import { processApolloError } from "~/backend/utils/processApolloError";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface IProps {
  className?: string;
  token: string;
  onPasswordChangeComplete?: () => void;
}

interface PasswordFormValues {
  password1: string;
  password2: string;
}

const passwordFormSchema = yup.object({
  password1: yup
    .string()
    .required("")
    .min(6, "Your password has at least 6 characters."),
  password2: yup
    .string()
    .oneOf([yup.ref("password1")], "Passwords do not match"),
});

export const PasswordForm = ({
  className,
  token,
  onPasswordChangeComplete,
}: IProps) => {
  const classes = useStyles();
  const doResetPassword = useResetPassword();

  const handleSubmit = useCallback<
    FormikConfig<PasswordFormValues>["onSubmit"]
  >(
    (values, { setSubmitting, setStatus }) => {
      doResetPassword({
        variables: {
          reset: { token, password: values.password1 },
        },
      })
        .then(() => {
          setStatus("Your password has been changed.");
          setSubmitting(false);
          onPasswordChangeComplete?.();
        })
        .catch((e) => {
          setStatus(processApolloError(e).simplifiedError);
          setSubmitting(false);
        });
    },
    [doResetPassword, token, onPasswordChangeComplete]
  );

  return (
    <Formik<PasswordFormValues>
      initialValues={{ password1: "", password2: "" }}
      onSubmit={handleSubmit}
      validationSchema={passwordFormSchema}
    >
      {({ isSubmitting, handleSubmit, status }) => (
        <form className={clsx(classes.root, className)}>
          <Typography color="error" align="center" variant="body2" paragraph>
            {status || " "}
          </Typography>
          <TextFormField
            label="Password"
            name="password1"
            type="password"
            variant="outlined"
          />
          <TextFormField
            label="Confirm Password"
            name="password2"
            type="password"
            variant="outlined"
          />
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isSubmitting}
            type="submit"
            size="large"
            onClick={handleSubmit as any}
          >
            Change Password
          </LoadingButton>
        </form>
      )}
    </Formik>
  );
};

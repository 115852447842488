export const formatPrice = (v: number, currency: string = "usd") => {
  const formattedPrice = v.toFixed(2);

  if (currency === "usd") {
    return "$\u00A0" + formattedPrice;
  } else if (currency === "eur") {
    return `${formattedPrice}\u00A0€`;
  } else {
    return `${formattedPrice} ${currency}`;
  }
};

import * as Sentry from "@sentry/react";
import { detectEnvironment } from "./detect-environment";

export const initSentry = () => {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          // This caused CORS error on the websocket link pre-request to /s - not sure why ...
          // tracePropagationTargets: ["localhost", "100.126.114.111", /^https:\/\/api\.chartedsails\.com/, /^https:\/\/api\.beta\.chartedsails\.com/],
          tracePropagationTargets: []
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: detectEnvironment()
    });
  }
}

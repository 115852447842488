import { median } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { isNotNullish } from "~/components/util/isNotNullish";
import { SailingSegment } from "~/model/SailingSegment";

export const calculateBoatLaylines = (
  segments: SailingSegment[],
  trip: InteractiveTrip,
  trueWindDirection: number
) => {
  const straightLineSegments = segments
    .map((s) => {
      if (s.type === "line") {
        const stats = trip.getBracketData(s.interval[0], s.interval[1], {
          trueWindDirection,
        });
        return stats;
      }
      return null;
    })
    .filter(isNotNullish);

  const starboardSegmentsTWAs = straightLineSegments
    .map((s) => s.averageTrueWindAngle)
    .filter(isNotNullish)
    .filter((twa) => twa > 0);
  const portSegmentsTWAs = straightLineSegments
    .map((s) => s.averageTrueWindAngle)
    .filter(isNotNullish)
    .filter((twa) => twa < 0);

  return {
    starboardLayline: median(starboardSegmentsTWAs),
    portLayline: median(portSegmentsTWAs),
  };
};

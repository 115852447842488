import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";
import { useRegisterBoatClass } from "~/backend/data-hooks/boat/useRegisterBoatClass";
import { BoatClass } from "~/backend/graphql/BoatClass";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";
import { CSDialog } from "../csdialog/CSDialog";

const defaultFormValues = {
  name: "",
  boatLength: "",
  boatLengthUnit: "meters",
  url: "",
};

const useStyles = makeStyles((theme) => ({
  root: {},
  boatLengthFields: {
    display: "flex",
    width: "100%",
    alignItems: "baseline",
    "& .MuiTextField-root": {
      flexGrow: 3,
      marginRight: theme.spacing(2),
    },
    "& .MuiInputBase-root": {
      flexGrow: 0,
    },
  },
}));

interface IProps {
  open: boolean;
  onClose: (boatClass?: BoatClass) => void;
  initialValue?: string;
}

const registerBoatClassSchema = yup.object({
  name: yup.string().required("How should we call this class of boat?"),
  boatLength: yup
    .number()
    .typeError("Please provide a number.")
    .positive("This should be a positive number.")
    .required("The LOA of the boat is required."),
  boatLengthUnit: yup.string(),
  url: yup
    .string()
    .matches(
      /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "This does not look like a valid website."
    )
    .notRequired(),
});

export const AddBoatClassDialog = ({ open, onClose, initialValue }: IProps) => {
  const classes = useStyles();

  const registerBoatClass = useRegisterBoatClass();
  const addboatclassEvent = useAnalyticEvent("addboatclass-complete");

  const handleSubmit = useCallback<
    FormikConfig<typeof defaultFormValues>["onSubmit"]
  >(
    async (values, actions) => {
      actions.setSubmitting(true);
      try {
        const result = await registerBoatClass({
          variables: {
            name: values.name,
            boatLength:
              Number.parseFloat(values.boatLength) *
              (values.boatLengthUnit === "feet" ? 0.3048 : 1),
            url: values.url,
          },
        });
        addboatclassEvent({
          name: values.name,
          boatLength: Number.parseFloat(values.boatLength),
          unit: values.boatLengthUnit,
          url: values.url,
        });
        onClose(result.registerBoatClass);
      } catch (e) {
        addboatclassEvent({
          eventName: "addboatclass-error",
          error: (e as Error).message,
          name: values.name,
          boatLength: values.boatLength,
          unit: values.boatLengthUnit,
          url: values.url,
        });
        actions.setStatus((e as Error).message);
      }
      actions.setSubmitting(false);
    },
    [addboatclassEvent, onClose, registerBoatClass]
  );

  return (
    <CSDialog open={open} onClose={() => onClose()} dialogName="addboatclass">
      <Formik
        initialValues={{ ...defaultFormValues, name: initialValue ?? "" }}
        onSubmit={handleSubmit}
        validationSchema={registerBoatClassSchema}
      >
        {({ isSubmitting, status, setStatus, handleSubmit }) => (
          <form
            className={classes.root}
            onChange={() => setStatus()}
            onSubmit={handleSubmit}
          >
            <DialogTitle>
              <Typography variant="h3" align="center" paragraph>
                Add a new boat class
              </Typography>
            </DialogTitle>
            <DialogContent>
              {status && (
                <Typography color="error" paragraph>
                  {status}
                </Typography>
              )}
              <TextFormField
                required
                fullWidth
                margin="normal"
                label="Name of the class"
                helperText="Optimist, Laser, J/70, etc."
                variant="outlined"
                autoFocus
                name="name"
              />
              <div className={classes.boatLengthFields}>
                <TextFormField
                  required
                  margin="normal"
                  label="Boat Length"
                  variant="outlined"
                  name="boatLength"
                  helperText="Boat length in meters"
                />
                <TextFormField select name="boatLengthUnit" variant="outlined">
                  <MenuItem value={"feet"}>Feet</MenuItem>
                  <MenuItem value={"meters"}>Meters</MenuItem>
                </TextFormField>
              </div>
              <TextFormField
                margin="normal"
                fullWidth
                label="Boat Class Website"
                variant="outlined"
                name="url"
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="default"
                variant="contained"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Add Boat Class
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Formik>
    </CSDialog>
  );
};

import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { analytics } from "~/backend/analytics/analytics";
import { ErrorDialog } from "~/components/dialogs/error/ErrorDialog";
import { NavBar } from "~/components/navbar/NavBar";
import { SEO } from "~/components/util/SEO";

interface IProps {
  title: string;
  message: string;
}

const ErrorPage = ({ title, message }: IProps) => {
  const { pathname } = useLocation();

  analytics.page("error", {
    errorTitle: title,
    errorMessage: message,
    errorLocation: pathname,
  });

  const handleClose = useCallback(() => {
    document.location.pathname = "/";
  }, []);
  return (
    <>
      <SEO title="Oops! - ChartedSails" />
      <NavBar />
      <ErrorDialog open title={title} message={message} onClose={handleClose} />
    </>
  );
};

export default ErrorPage;

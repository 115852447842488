import { Button, Typography } from "@material-ui/core";
import React from "react";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";

interface IProps {
  error: string;
  onClose?: () => void;
}

export const UploadTrackErrorContent = ({ error, onClose }: IProps) => {
  return (
    <>
      <CSDialogTitle title="Add Track" />
      <CSDialogContent>
        <Typography color="error" paragraph>
          {error}
        </Typography>
      </CSDialogContent>
      <CSDialogActions>
        {onClose && (
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        )}
      </CSDialogActions>
    </>
  );
};

import { Link, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback } from "react";
import { NavLink } from "../link/NavLink";
import { INavAction } from "./INavAction";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: "26px",
    color: theme.palette.text.primary,
    paddingRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    width: "100%",
    textAlign: "right",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#F8F8F8",
    },
    "&.active": {
      backgroundColor: "#F8F8F8",
      borderRight: `3px solid ${theme.palette.primary.main}`,
      paddingRight: theme.spacing(3) - 3,
    },
  },
}));

interface IProps {
  className?: string;
}

export const ProfileAction = ({
  title,
  action,
  className: propsClassName,
}: IProps & INavAction) => {
  const classes = useStyles();
  const className = clsx(propsClassName, classes.root);

  const handler = useCallback(
    (e) => {
      e.preventDefault();
      if (typeof action === "function") {
        action();
      }
    },
    [action]
  );

  if (typeof action === "string") {
    return (
      <NavLink to={action} className={className}>
        {title}
      </NavLink>
    );
  } else {
    return (
      <Link href="#" onClick={handler} className={className}>
        {title}
      </Link>
    );
  }
};

import { safeTimeSlice } from "@chartedsails/tracks";
import { useMemo } from "react";
import { trackHighlightColor } from "~/styles/chartedSailsTheme";
import { isIntervalOverlapping } from "~/util/isIntervalOverlapping";
import { useReplayContext } from "../replaycontext/ReplayContext";
import { TrackConfig } from "./tracks-config/TrackConfig";
import { TripLayer, TripLayerData } from "./TripLayer";
import { useMapHoverTarget } from "./useMapHoverTarget";

/**
 *
 * Returns a deckgl layer to highlight whatever the user is currently hovering on.
 *
 * @param param
 */
export const useMapHoverHighlightLayer = (tracksConfig: TrackConfig[]) => {
  const replay = useReplayContext();

  const mapHoverTarget = useMapHoverTarget();

  const maneuverHover = useMemo(() => {
    if (replay.hover?.type === "maneuver") {
      // Only show the maneuver highlight if the maneuver is visible with the current track config.
      const boatTrack = tracksConfig.find(
        (t) => t.boatId === replay.hover?.boatId
      );
      if (
        boatTrack &&
        isIntervalOverlapping(replay.hover.maneuver.maneuver.interval, [
          boatTrack.visibleStartTime,
          boatTrack.visibleEndTime,
        ])
      ) {
        return {
          interval: replay.hover.maneuver.maneuver.interval,
          boatId: replay.hover.boatId,
        };
      }
    }
    return null;
  }, [replay.hover, tracksConfig]);

  const hoverTarget = mapHoverTarget ?? maneuverHover;

  const layerData = useMemo<TripLayerData | null>(() => {
    const boat = replay.boats.find((b) => b.id === hoverTarget?.boatId);
    if (!boat || !boat.data || !hoverTarget?.interval) return null;
    const visibleTrip = safeTimeSlice(
      boat.data,
      hoverTarget?.interval[0],
      hoverTarget?.interval[1]
    );
    if (!visibleTrip) return null;

    return {
      length:
        visibleTrip !== null && visibleTrip.length > 1
          ? visibleTrip.length - 1
          : 0,
      trip: visibleTrip,
      speedColors: [],
      colorSegments: [],
    };
  }, [hoverTarget?.boatId, hoverTarget?.interval, replay.boats]);

  if (!layerData) {
    return null;
  }

  return new TripLayer({
    id: "highlight",
    data: layerData,
    layerColor: trackHighlightColor,
    width: 5,
    pickable: false,
    fading: false,
  });
};

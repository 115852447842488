import { TrackPositions } from "@chartedsails/tracks";

// See https://en.wikibooks.org/wiki/Algorithm_Implementation/Geometry/Convex_hull/Monotone_chain#JavaScript

export const convexHull = (
  positions: TrackPositions
): Array<[number, number]> => {
  const indices = new Uint32Array(positions.latitude.length);
  for (let i = 0; i < indices.length; i++) {
    indices[i] = i;
  }

  indices.sort((a, b) =>
    positions.longitude[a] === positions.latitude[a]
      ? positions.latitude[a] - positions.latitude[b]
      : positions.longitude[a] - positions.longitude[b]
  );

  const cross = (a: number, b: number, o: number) => {
    const ax = positions.longitude[a];
    const ay = positions.latitude[a];
    const bx = positions.longitude[b];
    const by = positions.latitude[b];
    const ox = positions.longitude[o];
    const oy = positions.latitude[o];
    return (ax - ox) * (by - oy) - (ay - oy) * (bx - ox);
  };

  const lowerHull = new Array<number>();
  for (let i = 0; i < indices.length; i++) {
    while (
      lowerHull.length >= 2 &&
      cross(
        lowerHull[lowerHull.length - 2],
        lowerHull[lowerHull.length - 1],
        indices[i]
      ) <= 0
    ) {
      lowerHull.pop();
    }
    lowerHull.push(indices[i]);
  }
  const upperHull = new Array<number>();
  for (let i = indices.length - 1; i >= 0; i--) {
    while (
      upperHull.length >= 2 &&
      cross(
        upperHull[upperHull.length - 2],
        upperHull[upperHull.length - 1],
        indices[1]
      ) <= 0
    ) {
      upperHull.pop();
    }
    upperHull.push(indices[i]);
  }

  upperHull.pop();
  lowerHull.pop();
  const completeHull = lowerHull.concat(upperHull);

  return completeHull.map((i) => [
    positions.longitude[i],
    positions.latitude[i],
  ]);
};

import { toDegrees } from "@chartedsails/sailing-math";
import { NavigationData } from "@chartedsails/tracks";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { SVGLayerRedrawOptions } from "~/components/util/SVGRedrawOptions";
import { useScaledDistance } from "../map/useScaledDistance";

interface MovingBoatProps {
  data: NavigationData;
  boatLength?: number;
  redrawContext: SVGLayerRedrawOptions;
  mapAngleDegrees: number;
  boatColor?: string;
  opacity?: number;
  onClick?: () => void;
  style?: CSSProperties;
}
const boatSvgLengthInPixels = 100;
export const defaultBoatLength = 6.5;

/**
 * Returns SVG elements for a 200x200 surface with the boat centered at 100x100
 * and wind arrows (if data available).
 * @param param
 */
export function MovingBoat({
  data,
  redrawContext,
  boatColor,
  boatLength,
  mapAngleDegrees,
  opacity,
  onClick,
  style,
}: MovingBoatProps) {
  const pixelCoordinate = redrawContext.project([
    data.longitude,
    data.latitude,
  ]);

  const boatHeading = data.heading ?? data.cog;

  const boatLengthInPixels = Math.max(
    25,
    useScaledDistance(boatLength ?? defaultBoatLength) ?? 0
  );

  const scaleLevelX = boatLengthInPixels / boatSvgLengthInPixels;
  const scaleLevelY = scaleLevelX;

  return (
    // 200x200 surface with boat centered
    // Last transform before drawing:
    //   read right to left: center boat, rotate boat (to normalize), scale, translate back to center of canvas
    <g
      style={style}
      transform={`translate(${pixelCoordinate[0]} ${
        pixelCoordinate[1]
      }) rotate(${-mapAngleDegrees})`}
    >
      <g transform="translate(-100, -100)">
        <g
          id="boat"
          transform={`rotate(${toDegrees(boatHeading)} 100 100)`}
          opacity={opacity !== undefined ? opacity : 1}
        >
          <g
            transform={`translate(100 100) scale(${scaleLevelX} ${scaleLevelY}) rotate(-45) translate(-40 -40)`}
          >
            <path
              onClick={onClick}
              fill={boatColor}
              d="M0,56.55
          L14.079,70.635
          L23.438,80
          C56.384,68.35,80,36.943,80,0
          C43.066,0,11.657,23.604,0,56.55z"
            />
          </g>
        </g>
      </g>
    </g>
  );
}

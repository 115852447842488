import { RaceSegment } from "~/model/RaceSegment";
import { AddGateOption } from "./AddGateOption";

export const addGateToRace = (race: RaceSegment, gate: AddGateOption) => {
  const updatedRace: RaceSegment = {
    ...race,
    raceConfig: {
      ...race.raceConfig,
      gates: [...race.raceConfig.gates, gate.gate],
      marks: [...race.raceConfig.marks, ...gate.addMarks],
    },
  };
  return updatedRace;
};

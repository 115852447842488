import { RefObject, useCallback } from "react";
import { Replay } from "../replaycontext/Replay";
import { ReplayDispatch } from "../replaycontext/useReplayReducer";
import { TrackConfig } from "./tracks-config/TrackConfig";

interface DeckGLPickInfo {
  layer: { id: string } | null;
  index: number;
  picked: boolean;
  x: number;
  y: number;
  lngLat: [number, number];
  handled: boolean;
}

export interface ReplayMapPick {
  boatId: string;
  time: number;
  x: number;
  y: number;
  lngLat: [number, number];
}

export const useMapPickCallback = (
  deckglRef: RefObject<any>,
  replay: Replay,
  dispatch: ReplayDispatch,
  eventName: "map-hover-track" | "map-click-track",
  tracksConfig: TrackConfig[]
) => {
  return useCallback(
    (e: any) => {
      if (!deckglRef.current) {
        return;
      }
      let possiblePicks = deckglRef.current.pickMultipleObjects({
        ...e.offsetCenter,
        radius: 10,
      }) as DeckGLPickInfo[];

      // Filter out picks that are not visible
      let event: ReplayMapPick | undefined;
      for (const pick of possiblePicks) {
        if (pick && pick.layer !== null) {
          const track = tracksConfig.find((t) => t.boatId === pick.layer!.id);
          const boat = replay.boats.find((b) => b.id === track?.boatId);
          if (boat && boat.data && track) {
            const time = boat.data.time(pick.index);

            if (time > track.visibleStartTime && time < track.visibleEndTime) {
              event = {
                x: e.offsetCenter.x,
                y: e.offsetCenter.y,
                lngLat: e.lngLat,
                boatId: boat.id,
                time,
              };
              break;
            }
          }
          return false;
        }
      }

      dispatch({ event: eventName, pick: event });
    },
    [deckglRef, dispatch, eventName, replay.boats, tracksConfig]
  );
};

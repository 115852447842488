import { Badge, Fab, makeStyles } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create("opacity"),
    userSelect: "none",
  },
  hidden: {
    opacity: 0,
    pointerEvents: "none",
  },
  badge: {},
}));

interface IProps {
  className?: string;
}

/*
 * Important: For this button to work, you must:
 *
 *  - Configure the custom_launcher_selector in intercom settings (or in Segment UI) to be ".IntercomButton"
 *  - Disable the default launcher (hide to everyone by default)
 *
 * Great article on customizing the launcher: https://en.defacto.nl/blog/customizing-the-intercom-launcher/
 */

type IntercomWindow = typeof window & {
  Intercom: any;
  intercomEventsAdded?: boolean;
  intercomUnreadCount?: number;
  intercomOpen?: boolean;
};

const intercomWindow = window as IntercomWindow;

export const useIntercomChat = (message?: string) => {
  const openChat = useAnalyticEvent("intercom-newconversation");
  const openChatFail = useAnalyticEvent("intercom-openfail");

  return (overrideMessage?: string) => {
    if (intercomWindow && intercomWindow.Intercom) {
      openChat({ message: overrideMessage || message });
      intercomWindow.Intercom("showNewMessage", overrideMessage || message);
      return true;
    } else {
      openChatFail({ message: overrideMessage || message });
      return false;
    }
  };
};

export const IntercomButton = ({ className }: IProps) => {
  const [intercomReady, updateIntercomReady] = useState(
    intercomWindow.intercomEventsAdded
  );
  const [intercomOpen, updateIntercomOpen] = useState(
    intercomWindow.intercomOpen ?? false
  );
  const [intercomUnreadCount, updateIntercomUnreadCount] = useState(
    intercomWindow?.intercomUnreadCount ?? 0
  );
  const openChatEvent = useAnalyticEvent("intercom-open");
  const closeChatEvent = useAnalyticEvent("intercom-close");
  const messageCountUpdateEvent = useAnalyticEvent(
    "intercom-messageCountUpdate"
  );

  const handleClick = useCallback(
    (e) => {
      // Stop propagation or intercom window will close immediately.
      e.stopPropagation();
      if (intercomOpen) {
        intercomWindow.Intercom("hide");
      } else {
        intercomWindow.Intercom("show");
      }
    },
    [intercomOpen]
  );
  const handleIntercomOpen = useCallback(() => {
    updateIntercomOpen(true);
    openChatEvent({ countMessages: intercomWindow.intercomUnreadCount });
  }, [openChatEvent]);
  const handleIntercomClose = useCallback(() => {
    updateIntercomOpen(false);
    closeChatEvent({ countMessages: intercomWindow.intercomUnreadCount });
  }, [closeChatEvent]);
  const handleIntercomUnreadCountChange = useCallback(() => {
    updateIntercomUnreadCount(intercomWindow.intercomUnreadCount ?? 0);
    if (intercomWindow.intercomUnreadCount) {
      messageCountUpdateEvent({
        countMessages: intercomWindow.intercomUnreadCount,
      });
    }
  }, [messageCountUpdateEvent]);

  useEffect(() => {
    window.addEventListener("intercomShow", handleIntercomOpen);
    window.addEventListener("intercomHide", handleIntercomClose);
    window.addEventListener(
      "intercomUnreadCountChange",
      handleIntercomUnreadCountChange
    );

    if (!intercomReady) {
      // Poll for the intercom SDK to be booted
      const interval = window.setInterval(() => {
        if (intercomWindow.Intercom && intercomWindow.Intercom.booted) {
          updateIntercomReady(true);
          clearInterval(interval);
          clearTimeout(timeout);

          // We make intercom events go through window events because this way we can register/unregister listeners and keep track of state which is not possible just with the Intercom API
          if (!intercomWindow.intercomEventsAdded) {
            intercomWindow.Intercom("onShow", () => {
              intercomWindow.intercomOpen = true;
              window.dispatchEvent(new Event("intercomShow"));
            });
            intercomWindow.Intercom("onHide", () => {
              intercomWindow.intercomOpen = false;
              window.dispatchEvent(new Event("intercomHide"));
            });
            intercomWindow.Intercom("onUnreadCountChange", (count: number) => {
              intercomWindow.intercomUnreadCount = count;
              window.dispatchEvent(new Event("intercomUnreadCountChange"));
            });
            intercomWindow.intercomEventsAdded = true;
          }
        }
      });
      // After 30s - give up waiting on intercom
      const timeout = setTimeout(() => {
        clearInterval(interval);
      }, 30000);
      return () => {
        clearInterval(interval);
        clearTimeout(timeout);

        window.removeEventListener("intercomShow", handleIntercomOpen);
        window.removeEventListener("intercomHide", handleIntercomClose);
        window.removeEventListener(
          "intercomUnreadCountChange",
          handleIntercomUnreadCountChange
        );
      };
    }
    return () => {
      window.removeEventListener("intercomShow", handleIntercomOpen);
      window.removeEventListener("intercomHide", handleIntercomClose);
      window.removeEventListener(
        "intercomUnreadCountChange",
        handleIntercomUnreadCountChange
      );
    };
  }, [
    intercomReady,
    handleIntercomOpen,
    handleIntercomClose,
    handleIntercomUnreadCountChange,
  ]);

  const classes = useStyles();
  if (!intercomReady) {
    return <div className={className} />;
  }

  return (
    <div
      className={clsx(
        classes.root,
        className,
        !intercomReady && classes.hidden,
        "IntercomButton"
      )}
    >
      <Badge
        className={classes.badge}
        badgeContent={intercomUnreadCount}
        showZero={false}
      >
        <Fab color="primary" onClick={handleClick}>
          {!intercomOpen ? <ChatIcon /> : <CloseIcon />}
        </Fab>
      </Badge>
    </div>
  );
};

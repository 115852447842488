import React, { FC } from "react";
import { Route, RouteProps } from "react-router-dom";

export const isPrerendering = () =>
  navigator.userAgent.indexOf("prerender") > -1;

export const NoPrerenderRoute: FC<RouteProps> = (props) => {
  if (isPrerendering()) {
    return <div>Open this page in your browser.</div>;
  } else {
    return <Route {...props} />;
  }
};

import { useLocation } from "react-router-dom";

export const useQuery = () => {
  // Need this to avoid crashing in Storybook - will be fixed when we have a proper router simulation in Storybook.
  /* eslint-disable react-hooks/rules-of-hooks */
  try {
    return new URLSearchParams(useLocation().search);
  } catch (e) {
    return new URLSearchParams();
  }
};

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2FyZmF0YSIsImEiOiJjamxtYm10MnAwM2FqM2twZmxvcWczYnJ3In0.ENqYoSsnFJNy2gKsJQtXaA";
export const MAPTILER_STYLE_MAP =
  "https://api.maptiler.com/maps/9dc47227-f791-4147-b5e4-ce32699d8f6e/style.json?key=frABpSWILD6MrLBg16bM";

// Maptiler satellite view sucks big time - so we use mapbox for satellite at the moment.
export const MAPBOX_STYLE_SATELLITE = "mapbox://styles/mapbox/satellite-v9";

export const MAPTILER_STYLE_SATELLITE =
  "https://api.maptiler.com/maps/0f2526de-04ee-4cb1-8e39-594674d3a855/style.json?key=frABpSWILD6MrLBg16bM";

export const MAPBOX_STYLE_MOBILE = "mapbox://styles/mapbox/light-v10";

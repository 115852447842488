import { DialogActions, Theme, withStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      "&.MuiDialogActions-spacing  > :not(:first-child)": {
        marginLeft: theme.spacing(4),
      },
    },
  });

export const CSDialogActions = withStyles(styles)(DialogActions);

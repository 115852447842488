import { toDegrees } from "@chartedsails/sailing-math";
import { TripNumericVariable } from "@chartedsails/tracks";
import { v4 as uuidv4 } from "uuid";
import { Segment } from "~/backend/graphql/Segment";
import { isRaceSegment, RaceSegment } from "~/model/RaceSegment";
import {
  editSessionSegments,
  SailingSessionEdit,
} from "~/model/SailingSessionEdit";
import { isIntervalOverlapping } from "~/util/isIntervalOverlapping";
import { ReplayMapStyle, TailMode } from "../map/ReplayMap";
import { replayMakeAllTracksFitView } from "./helpers/makeAllTracksFitView";
import {
  animateRotation,
  replayWithActiveRace,
  replayWithSessionEdit,
  updatePlaybackAnimation,
} from "./reducer-helpers";
import { Replay, ReplayChartMode, replayCurrentTwd } from "./Replay";

export type ReplayChartAction =
  // TimeChart
  | { event: "timechart-click"; time: number }
  | {
      event: "timechart-hover";
      time?: number;
      boatId?: string;
      variable?: TripNumericVariable;
    }
  | { event: "timechart-segment-click"; segment: Segment }
  | { event: "timechart-segment-close" }
  | { event: "timechart-segment-hover"; segment?: Segment }
  | { event: "timechart-leg-hover"; legIndex?: number }
  | { event: "timechart-leg-click"; legIndex: number }
  | {
      event: "timechart-timeselection-changing";
      timeSelection: [number, number];
      changing: boolean;
    }
  | {
      event: "timechart-timeselection-closed";
    }

  // TimePanel
  | { event: "timepanel-toggle-playing" }
  | { event: "timepanel-next-tailmode" }
  | { event: "timepanel-set-playbackspeed"; speed: number }
  | { event: "timepanel-set-visibleboats"; visibleBoatsIds: string[] }
  | { event: "timepanel-set-title"; title: string }

  // TimePanel Segment Controls
  | { event: "timepanelsegment-created"; title?: string }
  | { event: "timepanelsegment-edited"; segment: Segment }
  | {
      event: "timepanelsegment-edit-bounds";
      bounds: [number, number];
      adjusting: boolean;
    }
  | { event: "timepanelsegment-deleted"; segment: Segment }
  | { event: "timepanelsegment-dismissed" }

  // Replay Pane
  | { event: "replaypane-show-results" }
  | { event: "replaypane-close-segment" }
  | { event: "replaypane-delete-segment" }
  | { event: "replaypane-new-race"; segment: RaceSegment }
  | { event: "replaypane-edit-race"; segment: RaceSegment }

  // Analysis Panel
  | { event: "analysistabs-select"; selected: "performance" | "maneuvers" }

  // Map Controls
  | { event: "mapcontrols-rotate-wind"; twd: number }
  | { event: "mapcontrols-select-tailmode"; tailMode: TailMode }
  | { event: "mapcontrols-select-mapstyle"; mapStyle: ReplayMapStyle };

export const replayChartEventReducer = (
  replay: ReplayChartMode,
  action: ReplayChartAction
): Replay => {
  switch (action.event) {
    // Events triggered by the time chart
    case "timechart-click":
      let r = updatePlaybackAnimation(
        { ...replay, hover: undefined },
        action.time
      );

      // Dismiss time selection if needed
      if (
        r.timeSelection &&
        (action.time < r.timeSelection[0] || action.time > r.timeSelection[1])
      ) {
        r.timeSelection = undefined;
        r.isTimeSelectionChanging = false;
      }

      // Dismiss active segment if needed
      if (
        r.activePane === "replay-chart" &&
        r.activeSegment &&
        (action.time < r.activeSegment.startTime ||
          action.time > r.activeSegment.endTime)
      ) {
        r.activeSegment = undefined;
        r = animateRotation(
          {
            ...r,
            activeSegment: undefined,
            selectedLegIndex: undefined,
            timeSelection: undefined,
          },
          replay.sessionTrueWindDirection ?? 0
        );
      }
      return r;
    case "timechart-hover":
      let newHoverTime = action.time;
      // Dismiss the new hover time if its without the bounds of an active selection
      if (action.time) {
        if (
          replay.timeSelection &&
          (action.time < replay.timeSelection[0] ||
            action.time > replay.timeSelection[1])
        ) {
          newHoverTime = undefined;
        } else if (
          replay.activeSegment &&
          (action.time < replay.activeSegment.startTime ||
            action.time > replay.activeSegment.endTime)
        ) {
          newHoverTime = undefined;
        }
      }
      return {
        ...replay,
        hover:
          newHoverTime !== undefined
            ? {
                type: "time",
                time: newHoverTime,
                boatId: action.boatId,
                variable: action.variable,
              }
            : undefined,
      };
    case "timechart-segment-click": {
      if (replay.activePane === "replay-chart") {
        let r = updatePlaybackAnimation(
          {
            ...replay,
            activeSegment: action.segment,
            timeSelection: undefined,
          },
          action.segment.startTime
        );

        if (isRaceSegment(action.segment)) {
          r = replayWithActiveRace(r, action.segment);
        }
        return replayMakeAllTracksFitView(r);
      }

      return replay;
    }
    case "timechart-segment-hover":
      return {
        ...replay,
        hover: action.segment
          ? { type: "segment", segment: action.segment }
          : undefined,
      };
    case "timechart-leg-hover":
      return {
        ...replay,
        hover:
          action.legIndex !== undefined
            ? { type: "leg", legIndex: action.legIndex }
            : undefined,
      };
    case "timechart-leg-click": {
      if (!replay.raceAnalysis) {
        return replay;
      }

      // Deselect when clicking on same leg again
      if (action.legIndex === replay.selectedLegIndex) {
        return replayMakeAllTracksFitView({
          ...replay,
          selectedLegIndex: undefined,
        });
      } else {
        const t =
          action.legIndex === -1
            ? replay.raceAnalysis.start?.startTime
            : replay.raceAnalysis.legs[action.legIndex].lastBoatInTime;

        return replayMakeAllTracksFitView({
          ...updatePlaybackAnimation(replay, t ?? replay.playbackTime),
          selectedLegIndex: action.legIndex,
        });
      }
    }
    case "timechart-timeselection-changing":
      return {
        ...updatePlaybackAnimation(replay, action.timeSelection[0]),
        hover: undefined,
        timeSelection: action.timeSelection,
        isTimeSelectionChanging: action.changing,
      };
    case "timechart-timeselection-closed":
      return {
        ...replay,
        timeSelection: undefined,
        isTimeSelectionChanging: false,
      };

    // Events triggered by the timepanel
    case "timepanel-toggle-playing":
      return {
        ...updatePlaybackAnimation(replay, replay.playbackTime),
        playing: !replay.playing,
      };
    case "timepanel-next-tailmode":
      const userSelectableModes: TailMode[] = [
        "solid",
        "fading-tail",
        "speed-gradient",
      ];
      const tailMode =
        userSelectableModes[
          (userSelectableModes.indexOf(replay.selectedTailMode) + 1) %
            userSelectableModes.length
        ];
      return { ...replay, selectedTailMode: tailMode };
    case "timepanel-set-playbackspeed":
      return {
        ...updatePlaybackAnimation(replay, replay.playbackTime),
        playbackSpeed: action.speed,
      };
    case "timepanel-set-visibleboats": {
      const isLockedBoatInVisibleBoats =
        replay.lockMapOnBoatId &&
        action.visibleBoatsIds.indexOf(replay.lockMapOnBoatId) !== -1;
      return {
        ...replay,
        lockMapOnBoatId: isLockedBoatInVisibleBoats
          ? replay.lockMapOnBoatId
          : undefined,
        visibleBoats: action.visibleBoatsIds,
      };
    }
    case "timepanel-set-title": {
      if (!replay.activeSegment && replay.timeSelection) {
        const segment: Segment = {
          title: action.title ?? null,
          id: uuidv4(),
          startTime: replay.timeSelection[0],
          endTime: replay.timeSelection[1],
          trueWindDirection: null,
          raceConfig: null,
        };
        const segments = editSessionSegments(replay.segments, segment);
        return {
          ...replayWithSessionEdit(replay, { segments }),
          activeSegment: segment,
          timeSelection: undefined,
          segments,
        };
      } else if (replay.activeSegment) {
        const editedActiveSegment = {
          ...replay.activeSegment,
          title: action.title,
        };
        const segments = editSessionSegments(
          replay.segments,
          editedActiveSegment
        );
        return {
          ...replayWithSessionEdit(replay, { segments }),
          activeSegment: editedActiveSegment,
          segments: segments,
        };
      } else {
        return replay;
      }
    }
    // TimePanel Segment
    case "timepanelsegment-created": {
      if (!replay.timeSelection) return replay;

      const segment: Segment = {
        title: action.title ?? null,
        id: uuidv4(),
        startTime: replay.timeSelection[0],
        endTime: replay.timeSelection[1],
        trueWindDirection: null,
        raceConfig: null,
      };
      const segments = editSessionSegments(replay.segments, segment);

      return {
        ...replayWithSessionEdit(replay, { segments }),
        activeSegment: segment,
        timeSelection: undefined,
        segments,
      };
    }
    case "timechart-segment-close":
    case "replaypane-close-segment":
    case "timepanelsegment-dismissed":
      replay = {
        ...replay,
        activeSegment: undefined,
        timeSelection: undefined,
        raceAnalysis: undefined,
      };
      return replayMakeAllTracksFitView(replay);
    case "timepanelsegment-deleted":
    case "replaypane-delete-segment": {
      const segmentId =
        action.event === "timepanelsegment-deleted"
          ? action.segment.id
          : replay.activeSegment?.id;
      if (!segmentId) {
        return replay;
      }

      const index = replay.segments.findIndex((s) => s.id === segmentId);
      const newSegments = [...replay.segments];
      if (index > -1) {
        newSegments.splice(index, 1);
        const edit: SailingSessionEdit = {
          segments: newSegments,
        };
        replay = replayWithSessionEdit(replay, edit);
      }
      replay = {
        ...replay,
        segments: newSegments,
        activePane: "replay-chart",
        activeSegment: undefined,
        timeSelection: undefined,
        raceAnalysis: undefined,
      };
      return animateRotation(replay, replayCurrentTwd(replay));
    }
    case "timepanelsegment-edited":
      const segments = editSessionSegments(replay.segments, action.segment);
      replay = replayWithSessionEdit(replay, { segments });

      // When the user is editing the active segment we need to propagate the change to activeSegment
      if (replay.activeSegment?.id === action.segment.id) {
        return {
          ...replay,
          activeSegment: action.segment,
          segments: segments,
        };
      } else {
        return { ...replay, segments };
      }
    case "timepanelsegment-edit-bounds":
      // Check that there is no overlap
      replay = { ...replay, isTimeSelectionChanging: action.adjusting };
      if (!replay.activeSegment) {
        return replay;
      }
      const otherSegments = replay.segments.filter(
        (s) => s.id !== replay.activeSegment!.id
      );
      const noOverlap = otherSegments.every(
        (s) => !isIntervalOverlapping(action.bounds, s)
      );
      if (noOverlap) {
        const updatedSegment = {
          ...replay.activeSegment,
          startTime: action.bounds[0],
          endTime: action.bounds[1],
        };
        const segments = editSessionSegments(replay.segments, updatedSegment);
        replay = replayWithSessionEdit(replay, { segments });
        return {
          ...updatePlaybackAnimation(replay, action.bounds[0]),
          activeSegment: updatedSegment,
          segments,
        };
      } else {
        return replay;
      }

    case "replaypane-new-race":
    case "replaypane-edit-race":
      return replayMakeAllTracksFitView({
        ...replayWithActiveRace(replay, action.segment),
        activePane: "racesetup",
        currentGateIndex:
          replay.selectedLegIndex !== undefined
            ? replay.selectedLegIndex + 1
            : 0,
        timeSelection: undefined,
        hover: undefined,
        selectedLegIndex: undefined,
      });
    case "analysistabs-select":
      return {
        ...replay,
        analysisTab: action.selected,
      };
    case "mapcontrols-select-tailmode":
      return {
        ...replay,
        selectedTailMode: action.tailMode,
      };
    case "mapcontrols-select-mapstyle":
      return {
        ...replay,
        mapStyle: action.mapStyle,
      };
    case "mapcontrols-rotate-wind":
      if (replay.activeSegment) {
        const activeSegment = {
          ...replay.activeSegment,
          trueWindDirection: action.twd,
        };
        const segments = editSessionSegments(replay.segments, activeSegment);

        if (isRaceSegment(activeSegment)) {
          replay = replayWithActiveRace(replay, activeSegment);
        } else {
          replay = { ...replay, activeSegment };
        }
        return {
          ...replayWithSessionEdit(replay, { segments }),
          segments,
          viewState: {
            ...replay.viewState,
            bearing: toDegrees(action.twd),
            transitionInterpolator: undefined,
            transitionDuration: undefined,
            transitionEasing: undefined,
          },
        };
      } else {
        return {
          ...replayWithSessionEdit(replay, { mapOrientation: action.twd }),
          sessionTrueWindDirection: action.twd,
          viewState: {
            ...replay.viewState,
            bearing: toDegrees(action.twd),
            transitionInterpolator: undefined,
            transitionDuration: undefined,
            transitionEasing: undefined,
          },
        };
      }
  }
  return replay;
};

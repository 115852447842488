import {
  median,
  pointOfSail,
  shortAngleDist,
  trackpointWithWindDirection,
} from "@chartedsails/sailing-math";
import { lineString, point } from "@turf/helpers";
import pointToLineDistance from "@turf/point-to-line-distance";
import { calculateBoatLaylines } from "~/algo/laylines/calculate-laylines";
import { RaceGateType } from "~/backend/graphql/globalTypes";
import { DEFAULT_START_SEQUENCE_DURATION } from "~/components/race/make-gates/useNewRace";
import { ReplayBoat } from "~/components/replay/replaycontext/Replay";
import { isNotNullish } from "~/components/util/isNotNullish";
import { RaceSegment } from "~/model/RaceSegment";
import { SailingInsights } from "~/model/SailingInsights";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { pickMostCommon } from "~/util/pick-most-common";
import { prepareLegInfo } from "../../leg-info/leg-info";
import { findMarkRoundingTimes } from "../../marks-rounding/findMarkRoundingTimes";
import { MarkRoundingTimes } from "./MarkRoundingTimes";
import { BoatRaceAnalysis, RaceAnalysis } from "./RaceAnalysis";

export const prepareRaceAnalysis = (
  raceSegment: RaceSegment,
  boats: ReplayBoat[],
  trueWindDirection: number,
  sailingInsights: SailingInsights,
  // Make it possible for tests to override the mark rounding times
  opts: {
    markRoundingTimes?: MarkRoundingTimes;
  } = {}
) => {
  const gates = raceSegment.raceConfig.gates;

  const markRoundingTimes =
    opts.markRoundingTimes ??
    findMarkRoundingTimes(raceSegment, boats, trueWindDirection);

  // Calculate the rank of each boat at each gate
  const rankPerGate: Array<string[]> = [];
  gates.forEach((_, index) => {
    const boatTimes = Array.from(markRoundingTimes.boatMarksTime.entries()).map(
      ([boatId, { marksTime }]) => ({
        boatId,
        time: index < marksTime.length ? marksTime[index] : null,
      })
    );
    boatTimes.sort((a, b) => {
      if (a.time && b.time) {
        return a.time - b.time;
      } else if (a.time && b.time === null) {
        return -1;
      } else if (a.time === null && b.time) {
        return 1;
      } else {
        return 0;
      }
    });
    rankPerGate.push(
      boatTimes.filter((bt) => bt.time !== null).map((bt) => bt.boatId)
    );
  });

  const isSpeedTest =
    gates.length === 2 &&
    gates[0].type === RaceGateType.START_LINE &&
    gates[1].type === RaceGateType.FINISH_LINE;

  const firstMarkTimes = Array.from(markRoundingTimes.boatMarksTime.values())
    .map((analysis) => analysis.marksTime[0])
    .filter(isNotNullish);

  const lastMarkTimes = Array.from(markRoundingTimes.boatMarksTime.values())
    .map((analysis) => analysis.marksTime[gates.length - 1])
    .filter(isNotNullish);

  const startPointOfSails = Array.from(
    markRoundingTimes.boatMarksTime.entries()
  )
    .map(([boatId, { marksTime }]) => {
      if (marksTime[0] === undefined) return undefined;
      const boat = boats.find((b) => b.id === boatId);
      const twa = trackpointWithWindDirection(
        boat?.data?.getValuesAtTime(marksTime[0]),
        trueWindDirection
      )?.twa;
      return twa !== undefined ? pointOfSail(twa) : undefined;
    })
    .filter(isNotNullish);
  const startPointOfSail = pickMostCommon(startPointOfSails);

  const startSequenceStartTime = isSpeedTest
    ? raceSegment.raceConfig.gunTime
    : Math.max(
        raceSegment.raceConfig.gunTime - DEFAULT_START_SEQUENCE_DURATION,
        raceSegment.startTime
      );

  const raceStats: RaceAnalysis = {
    type: isSpeedTest ? "speedtest" : "race",
    trueWindDirection,
    raceTitle: raceSegment.title ?? "",
    raceSegmentBounds: [raceSegment.startTime, raceSegment.endTime],
    start: {
      label: "START",
      ranks: rankPerGate.length > 0 ? rankPerGate[0] : [],
      startTime: startSequenceStartTime,
      finishTime: raceSegment.raceConfig.gunTime,
      duration: raceSegment.raceConfig.gunTime - startSequenceStartTime,
      firstBoatAtLine:
        firstMarkTimes.length > 0 ? Math.min(...firstMarkTimes) : null,
      lastBoatAtLine:
        firstMarkTimes.length > 0 ? Math.max(...firstMarkTimes) : null,
      startDirection: startPointOfSail ?? "upwind",
    },
    legs: [],
    finish: {
      ranks: rankPerGate.length > 1 ? rankPerGate[rankPerGate.length - 1] : [],
      firstBoatToFinish:
        lastMarkTimes.length > 0 ? Math.min(...lastMarkTimes) : null,
      lastBoatToFinish:
        lastMarkTimes.length > 0 ? Math.max(...lastMarkTimes) : null,
    },
    boats: new Map(),
    totalDistance: 0,
  };
  for (let index = 0; index < gates.length - 1; index++) {
    const startRanks = rankPerGate.length > index ? rankPerGate[index] : [];
    const finishRanks =
      rankPerGate.length > index + 1 ? rankPerGate[index + 1] : [];

    const legEntryTimes = Array.from(markRoundingTimes.boatMarksTime.values())
      .map((analysis) => analysis.marksTime[index])
      .filter(isNotNullish);
    const legExitTimes = Array.from(markRoundingTimes.boatMarksTime.values())
      .map((analysis) => analysis.marksTime[index + 1])
      .filter(isNotNullish);

    const firstBoatInTime =
      legEntryTimes.length > 0
        ? index === 0 && !isSpeedTest
          ? raceSegment.raceConfig.gunTime
          : Math.min(...legEntryTimes)
        : null;
    const lastBoatOutTime =
      legExitTimes.length > 0 ? Math.max(...legExitTimes) : null;

    // Calculate the general direction in which the boats are sailing
    const info = prepareLegInfo(raceSegment, index);
    const legTWA = shortAngleDist(info.bearing, trueWindDirection);

    const type = pointOfSail(legTWA);

    let label = `Leg ${index + 1}`;
    if (type === "upwind") {
      label = `Leg ${index + 1} Upwind`;
    } else if (type === "reaching") {
      label = `Leg ${index + 1} Reaching`;
    } else if (type === "downwind") {
      label = `Leg ${index + 1} Downwind`;
    }

    raceStats.legs.push({
      label,
      startRanks,
      finishRanks,
      firstBoatInTime,
      firstBoatOutTime:
        legExitTimes.length > 0 ? Math.min(...legExitTimes) : null,
      lastBoatInTime:
        legEntryTimes.length > 0
          ? index === 0 && !isSpeedTest
            ? raceSegment.raceConfig.gunTime
            : Math.max(...legEntryTimes)
          : null,
      lastBoatOutTime,
      distance: info.distance,
      bearing: info.bearing,
      trueWindAngle: legTWA,
      // This gets calculated and added after we do the leg stats for each boats - At the bottom of this long function.
      laylinePortTWA: 0,
      laylineStarboardTWA: 0,
      type,
      finishPoints: info.finishPoints,
    });
  }

  markRoundingTimes.boatMarksTime.forEach(({ marksTime }, boatId) => {
    const boatData = boats.find((b) => b.id === boatId)?.data;
    if (!boatData) {
      throw new Error(`Boat data not available ${boatId}`);
    }
    const boatStats: BoatRaceAnalysis = { start: null, finish: null, legs: [] };

    // Prepare stats for the start of this boat
    if (marksTime.length > 0) {
      const startData = boatData.getValuesAtTime(
        raceSegment.raceConfig.gunTime
      );

      // Calculate orthogonal distance to the line
      let dtl = 0;
      const startGate = raceSegment.raceConfig.gates[0];
      const lineA = raceSegment.raceConfig.marks.find(
        (m) => m.id === startGate?.markId
      );
      const lineB = raceSegment.raceConfig.marks.find(
        (m) => m.id === startGate?.secondMarkId
      );
      if (startData && lineA && lineB) {
        // getDistanceFromLine returned 0 here in my test. not good.
        const startPoint = point(coordinatesToLonLat(startData));
        const startLine = lineString([
          coordinatesToLonLat(lineA),
          coordinatesToLonLat(lineB),
        ]);
        dtl = pointToLineDistance(startPoint, startLine, { units: "meters" });
      }

      // Calculate sailed distance to the line
      const timeAtLine = marksTime[0];
      const startSegmentData = boatData.getBracketData(
        raceSegment.raceConfig.gunTime,
        timeAtLine
      );

      boatStats.start = {
        startTime: startSequenceStartTime,
        finishTime: raceSegment.raceConfig.gunTime,
        duration: raceSegment.raceConfig.gunTime - startSequenceStartTime,
        rank: raceStats.start.ranks.findIndex((b) => b === boatId),
        speedOverGroundAtGuntime:
          boatData.getValuesAtTime(raceSegment.raceConfig.gunTime)?.sog ?? 0,
        orthogonalDistanceToLineAtGuntime: dtl,
        sailedDistanceToLine: startSegmentData?.distance ?? 0,
        timeAtLine,
        timeToLine: timeAtLine - raceSegment.raceConfig.gunTime,
        speedOverGroundAtLine: boatData.getValuesAtTime(timeAtLine)?.sog ?? 0,
      };
    }

    // Prepare stats for each of the legs that this boat has sailed
    // marksTime.length should be equal to raceStats.legs.length but if the boat analysis bugged
    // this might not be the case so we make sure for safety.
    // for (let i = 1; i < marksTime.length && i < raceStats.legs.length; i++) {
    if (marksTime.length - 1 > raceStats.legs.length) {
      throw new Error(`More marksTime than legs.`);
    }
    for (let legIndex = 0; legIndex < marksTime.length - 1; legIndex++) {
      // Start time of the first leg is the guntime - not the time they passed the line.
      let startTime =
        legIndex === 0 ? raceSegment.raceConfig.gunTime : marksTime[legIndex];
      // Except in a speed test - where it's the time they passed the first mark
      if (isSpeedTest) {
        startTime = marksTime[0];
      }

      const finishTime = marksTime[legIndex + 1];

      const rankAtEndOfPreviousLeg = (
        legIndex === 0
          ? raceStats.start.ranks
          : raceStats.legs[legIndex - 1].finishRanks
      ).findIndex((bid) => bid === boatId);
      const rankAtEndOfLeg = raceStats.legs[legIndex].finishRanks.findIndex(
        (id) => id === boatId
      );
      const legData = boatData.getBracketData(startTime, finishTime, {
        trueWindDirection,
      });

      const segments = sailingInsights
        .getSailingSegments(boatId)
        .filter(
          (segment) =>
            segment.interval[0] > startTime && segment.interval[1] < finishTime
        );

      const laylines = calculateBoatLaylines(
        segments,
        boatData,
        trueWindDirection
      );
      boatStats.legs.push({
        startTime,
        finishTime,
        duration: finishTime - startTime,
        rank: rankAtEndOfLeg,
        averageSpeed: legData?.averageSpeedOverGround ?? 0,
        averageVMG: Math.abs(legData?.averageVelocityMadeGood ?? 0),
        averageTWA: Math.abs(legData?.averageTrueWindAngle ?? 0),
        distance: legData?.distance ?? 0,
        rankGained: rankAtEndOfPreviousLeg - rankAtEndOfLeg,
        laylineStarboardTWA: laylines.starboardLayline ?? null,
        laylinePortTWA: laylines.portLayline ?? null,
      });
    }

    // Prepare stats for finish
    if (marksTime.length > 1 && marksTime.length === gates.length) {
      // If there are only two gates then this is a speed test and the start time is the time the boat passed the start line
      // Otherwise it's a race and the start time is the time when the gun was fired.
      const startTime =
        raceSegment.raceConfig.gates.length > 2
          ? raceSegment.raceConfig.gunTime
          : marksTime[0];
      const raceData = boatData.getBracketData(
        startTime,
        marksTime[marksTime.length - 1]
      );
      boatStats.finish = {
        startTime,
        finishTime: marksTime[marksTime.length - 1],
        duration: marksTime[marksTime.length - 1] - startTime,
        rank: raceStats.finish.ranks.findIndex((b) => b === boatId),
        averageSpeed: raceData?.averageSpeedOverGround ?? 0,
        averageVMG: raceData?.averageVelocityMadeGood ?? 0,
        distance: raceData?.distance ?? 0,
      };
    }
    raceStats.boats.set(boatId, boatStats);
  });

  // Calculate leg by leg laylines from the boats laylines
  raceStats.legs = raceStats.legs.map((legStats, index) => {
    const allBoatsPortLayline = Array.from(raceStats.boats.values())
      .map((boatStats) => boatStats.legs[index]?.laylinePortTWA)
      .filter(isNotNullish);
    const allBoatsStarboardLayline = Array.from(raceStats.boats.values())
      .map((boatStats) => boatStats.legs[index]?.laylineStarboardTWA)
      .filter(isNotNullish);

    return {
      ...legStats,
      laylinePortTWA: median(allBoatsPortLayline) ?? null,
      laylineStarboardTWA: median(allBoatsStarboardLayline) ?? null,
    };
  });

  raceStats.totalDistance = raceStats.legs.reduce(
    (totalDistance, legStats) => totalDistance + legStats.distance,
    0
  );

  return raceStats;
};

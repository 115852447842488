import gql from "graphql-tag";

export const FRAGMENT_DAYPASS_OFFER = gql`
  fragment DayPassOffer on DayPassOffer {
    daypassType
    label
    description
    badge
    priceText
    priceUSD
    priceLegend
    chargeDescription
    priceBeforeDiscountUSD
    discountApplied
    discountDescription
  }
`;
export const FRAGMENT_SUBSCRIPTION_OFFER = gql`
  fragment SubscriptionOffer on SubscriptionOffer {
    plan
    label
    description
    badge
    disabled
    freeTrialDays
    priceText
    priceLegend
    priceUSD
    pricePeriod
    paymentMethodRequired
    initialChargeDescription
    recurrenceDescription
    priceBeforeDiscountUSD
    discountApplied
    discountDescription
  }
`;

import { Replay } from "../../replaycontext/Replay";
import { TrackConfig } from "./TrackConfig";

export const replayTracksWhileTrimming = (replay: Replay) => {
  return replay.boats.map<TrackConfig>((b) => ({
    mode: "solid",
    trackId: b.id,
    boatId: b.id,
    solidColor: b.color,
    visibleStartTime: replay.startTime,
    visibleEndTime: replay.endTime,
  }));
};

import { Position } from "@chartedsails/sailing-data";
import { csturf } from "~/util/turf-in-webapp";
import { GeoSegment } from "../GeoSegment";

export const normalSegmentByPointAndBearing = (
  point: Position,
  normalToDirection: number,
  length: number
): GeoSegment => {
  const a = csturf.destination(
    point,
    length / 2,
    normalToDirection - Math.PI / 2
  );
  const b = csturf.destination(
    point,
    length / 2,
    normalToDirection + Math.PI / 2
  );
  return {
    a,
    b,
  };
};

import { List, ListItem, makeStyles, Tooltip } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { CoordinatesDialog } from "~/components/dialogs/coordinates/CoordinatesDialog";
import { carbon } from "~/styles/chartedsailsColors";
import { fontLato } from "~/styles/chartedSailsTheme";
import {
  coordinatesToLonLat,
  lonLatToCoordinates,
} from "~/util/coordinates-to-lonlat";

interface IProps {
  children: ReactElement<any, any>;
  mark: SailingMark;
  onMarkEdited?: (m: SailingMark) => void;
}

const useStyles = makeStyles({
  tooltip: {
    background: "none",
    padding: 0,
  },
  arrow: {
    color: carbon[900],
  },
  root: {
    backgroundColor: carbon[900],
    borderRadius: 8,
    color: "white",
    ...fontLato,
    "& .MuiListItem-root": {
      justifyContent: "center",
      fontSize: 14,
      lineHeight: "16.8px",
      "&:hover": {
        backgroundColor: carbon[700],
      },
    },
  },
});

export const MarkMenuTooltip = ({ mark, onMarkEdited, ...props }: IProps) => {
  const classes = useStyles();
  const [editOpen, setEditOpen] = useState(false);

  return (
    <>
      <Tooltip
        interactive
        arrow
        title={
          <List className={classes.root}>
            <ListItem button onClick={() => setEditOpen(true)}>
              Edit coordinates
            </ListItem>
          </List>
        }
        classes={classes}
      >
        {props.children}
      </Tooltip>
      <CoordinatesDialog
        open={editOpen}
        initialPosition={coordinatesToLonLat(mark)}
        onSave={(p) => {
          onMarkEdited?.({ ...mark, ...lonLatToCoordinates(p) });
          setEditOpen(false);
        }}
        onClose={() => setEditOpen(false)}
      />
    </>
  );
};

import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { analytics, LinkAnalytics } from "~/backend/analytics/analytics";

export const LinkButton = ({
  to,
  eventName,
  eventProps,
  ...props
}: { to: string } & ButtonProps & LinkAnalytics) => {
  const history = useHistory();

  const handler = useCallback(
    (e) => {
      if (eventName) {
        analytics.event(eventName, eventProps);
      }
      const protocol = to.split("://").shift();
      if (protocol === "") {
        e.preventDefault();
        history.push(to);
      }
    },
    [to, history, eventName, eventProps]
  );

  return <Button {...props} onClick={handler} href={to} />;
};

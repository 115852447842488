import { simplifyDouglasPeucker } from "@chartedsails/analysis";
import { InteractiveTrip } from "@chartedsails/tracks";
import { v4 as uuid } from "uuid";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { log } from "~/util/devconsole";

export interface AlgoWorkerReturn {
  success: boolean;
  error?: string;
  data?: any;
  runId: string;
  runTime: number;
  t0: number;
  tF: number;
}

export type AlgoWorkerParam = AlgoWorkerParamDouglasPeucker;

export interface AlgoWorkerParamDouglasPeucker {
  algorithm: "douglas-peucker";
  runId: string;
  arguments: {
    positions: {
      latitudeFloat64: ArrayBuffer;
      longitudeFloat64: ArrayBuffer;
    };
    tolerance: number;
  };
}

interface InFlightRun {
  resolve: (v: any) => void;
  t0: number;
  algorithm: string;
  timer: PerfTimer;
}

// Browser side interface to the Algo Worker
export class AlgoWorker {
  private static instance: AlgoWorker;

  public static getInstance() {
    if (!AlgoWorker.instance) {
      AlgoWorker.instance = new AlgoWorker();
    }
    return AlgoWorker.instance;
  }

  private worker: Worker;
  private inflights = new Map<string, InFlightRun>();

  private constructor() {
    this.worker = new Worker(
      new URL("./algo-worker.worker.ts", import.meta.url)
    );
    this.worker.onmessage = this.onMessage.bind(this);
  }

  private onMessage(event: any) {
    const ret = event.data as AlgoWorkerReturn;

    const run = this.inflights.get(ret.runId);
    if (run) {
      run.timer.mark("response received");
      run.timer.log();
      const totalTime = Date.now() - run.t0;
      log(
        `algo ${run.algorithm} executed in ${totalTime}ms (runTime: ${ret.runTime
        }ms - StartOverhead: ${ret.t0 - run.t0} RetOverhead: ${Date.now() - ret.tF
        } TotalOverhead: ${totalTime - ret.runTime})`
      );
      run.resolve(ret.data);
    }
  }

  public douglasPeucker(trip: InteractiveTrip, tolerance: number) {
    const runId = uuid();

    const p = new Promise<ReturnType<typeof simplifyDouglasPeucker>>(
      (resolve) => {
        const pt = new PerfTimer("algo-worker (main-thread)");
        const positions = trip.getPositions();
        pt.mark("positions");

        // Copy the data so we can transfer it. This is very fast.
        // I have looked into SharedArrayBuffer too but the gain is negligible (~1ms)
        // and it adds crossOriginIsolation complexity.
        const latitudes = positions.latitude.slice();
        const longitudes = positions.longitude.slice();
        pt.mark("copy");

        const args: AlgoWorkerParam = {
          algorithm: "douglas-peucker",
          runId,
          arguments: {
            positions: {
              latitudeFloat64: latitudes.buffer,
              longitudeFloat64: longitudes.buffer,
            },
            tolerance,
          },
        };
        this.worker.postMessage(args, [latitudes.buffer, longitudes.buffer]);
        pt.mark("post-message");

        this.inflights.set(runId, {
          resolve,
          t0: Date.now(),
          algorithm: "douglas-peucker",
          timer: pt,
        });
      }
    );

    return p;
  }
}

import {
  Position,
  TrackPoint,
  TrackPointFieldsDescription,
} from "@chartedsails/sailing-data";
import { interpolate, interpolateAngles } from "@chartedsails/sailing-math";
import { isNotNullish } from "type-guards";
import { NavigationData } from "../types/InteractiveTrip";

/**
 * Interpolate between two navigation data point to create a new measured
 * point somewhere between point a and b (included).
 *
 * @param a first point
 * @param b second point
 * @param distance of the measured point when a to b has been normalized to 1
 */
export const interpolateNavigationData = (
  a: NavigationData,
  b: NavigationData,
  distance: number
): NavigationData => {
  if (distance < 0 || distance > 1) {
    throw new Error(
      `Distance must be normalized between 0 and 1 (${distance}).`
    );
  }
  if (distance === 0) {
    return a;
  }
  if (distance === 1) {
    return b;
  }

  const position: Position = [
    a.position[0] + (b.position[0] - a.position[0]) * distance,
    a.position[1] + (b.position[1] - a.position[1]) * distance,
  ];
  const result: NavigationData = {
    time: interpolate(a.time, b.time, distance),
    latitude: position[1],
    longitude: position[0],
    position,
    sog: interpolate(a.sog, b.sog, distance),
    cog: interpolateAngles(a.cog, b.cog, distance)!,
  };

  const otherFieldsToInterpolate = Object.keys(a)
    .filter(
      (f) =>
        !["time", "latitude", "longitude", "position", "sog", "cog"].includes(f)
    )
    .filter(
      (f) =>
        isNotNullish(a[f as keyof NavigationData]) &&
        isNotNullish(b[f as keyof NavigationData])
    );
  for (const key of otherFieldsToInterpolate as Array<keyof NavigationData>) {
    if (key in a && key in b) {
      if (
        TrackPointFieldsDescription[key as keyof TrackPoint]?.type === "angle"
      ) {
        (result[key] as number) = interpolateAngles(
          a[key] as number,
          b[key] as number,
          distance
        ) as number;
      } else if (
        TrackPointFieldsDescription[key as keyof TrackPoint]?.type ===
        "relative-angle"
      ) {
        const angle = interpolateAngles(
          a[key] as number,
          b[key] as number,
          distance
        ) as number;
        (result[key] as number) = angle > Math.PI ? angle - 2 * Math.PI : angle;
      } else {
        (result[key] as number) = interpolate(
          a[key] as number,
          b[key] as number,
          distance
        );
      }
    }
  }
  return result;
};

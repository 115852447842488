import gql from "graphql-tag";
import { useCallback } from "react";
import {
  ConsumeFreeFlexDay,
  ConsumeFreeFlexDayVariables,
} from "~/backend/graphql/ConsumeFreeFlexDay";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { isBackendError } from "~/backend/utils/BackendError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { useMutationWithErrorHandling } from "../../utils/useMutationWithErrorHandling";
import {
  FRAGMENT_IMPORT,
  FRAGMENT_IMPORT_LICENSE,
} from "../imports/fragmentImport";

const CONSUME_FREE_DAY = gql`
  mutation ConsumeFreeFlexDay($importId: ID!) {
    consumeFreeFlexDay(importId: $importId) {
      ...ImportInfo
      ...ImportLicense
    }
  }
  ${FRAGMENT_IMPORT}
  ${FRAGMENT_IMPORT_LICENSE}
`;

export const useConsumeFreeDay = () => {
  const consumeFreeDay = useMutationWithErrorHandling<
    ConsumeFreeFlexDay,
    ConsumeFreeFlexDayVariables
  >(CONSUME_FREE_DAY);

  const consumeFreeDayCompleteEvent = useAnalyticEvent(
    "consumefreeday-complete"
  );

  return useCallback(
    async (track: ImportInfoWithLicense) => {
      try {
        const result = await consumeFreeDay({
          variables: { importId: track.id },
        });
        consumeFreeDayCompleteEvent();
        return result.consumeFreeFlexDay;
      } catch (e) {
        if (isBackendError(e as Error, "freeday-notneeded")) {
          consumeFreeDayCompleteEvent({ error: "freeday-notneeded" });
          return track;
        }
        throw e;
      }
    },
    [consumeFreeDay, consumeFreeDayCompleteEvent]
  );
};

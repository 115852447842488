import { DayPassOffer } from "~/backend/graphql/DayPassOffer";
import { SubscriptionOffer } from "~/backend/graphql/SubscriptionOffer";
import { isNullish } from "~/components/util/isNotNullish";

export const isSubscriptionOffer = (
  offer: DayPassOffer | SubscriptionOffer | undefined | null
): offer is SubscriptionOffer => {
  if (isNullish(offer)) {
    return false;
  }
  if ("pricePeriod" in offer) {
    return true;
  }
  return false;
};

export const isDayPassOffer = (
  offer: DayPassOffer | SubscriptionOffer | undefined | null
): offer is SubscriptionOffer => {
  if (isNullish(offer)) {
    return false;
  }
  if (!("pricePeriod" in offer)) {
    return true;
  }
  return false;
};

import { InteractiveTrip, TrackPositions } from "../types/InteractiveTrip";
import { bracketData } from "./bracketData";
import { findRowWithTime } from "./findRowWithTime";
import { interpolateValues } from "./interpolateValues";
import { sliceSailingArray } from "./sliceSailingArray";
import { SailingDataArray } from "./types";

export const interactiveTripFromArrays = (
  arrays: SailingDataArray
): InteractiveTrip => {
  const positions: TrackPositions = {
    longitude: arrays.longitude,
    latitude: arrays.latitude,
  };

  let cache: { maxSpeed?: number } = {};

  let length = arrays.time.length;

  // Sanity check the data we are given
  for (const f of Object.keys(arrays)) {
    if (arrays[f as keyof SailingDataArray]!.length !== length) {
      throw new Error(
        `All SailingDataArray should have the same length (time: ${length}, ${f}: ${arrays[f as keyof SailingDataArray]!.length
        })`
      );
    }
  }
  for (const f of 'time sog cog longitude latitude'.split(' ')) {
    if (arrays[f as keyof SailingDataArray]?.length !== length) {
      throw new Error(
        `SailingDataArray should always have ${f} pre-calculated.`
      );
    }
  }
  for (const f of Object.keys(arrays)) {
    if (f !== 'time' && typeof arrays[f as 'sog']?.subarray !== 'function') {
      throw new Error(
        `SailingDataArray should be built with typed arrays (${f})`
      );
    }
  }

  return {
    length,
    time: (i: number) => arrays["time"][i],
    sog: (i: number) => arrays["sog"][i],
    cog: (i: number) => arrays["cog"][i],
    lonlat: (i: number) => [arrays["longitude"][i], arrays["latitude"][i]],
    startTime: arrays["time"][0],
    endTime: arrays["time"][arrays["time"].length - 1],

    indexFromTime: (t: number) => findRowWithTime(arrays["time"], t),
    getValuesAtTime: (t) =>
      interpolateValues(arrays, typeof t === "number" ? t : t.getTime()),
    getBracketData: (start, end, opts = {}) => {
      start = typeof start === "number" ? start : start.getTime();
      end = typeof end === "number" ? end : end.getTime();
      return bracketData(arrays, start, end, opts);
    },
    maxSpeed: (p) => {
      if (cache.maxSpeed === undefined) {
        cache.maxSpeed = quantile(arrays["sog"], p);
      }
      return cache.maxSpeed;
    },
    getPositions: () => {
      return positions;
    },
    timeSlice: (start, end) => {
      return interactiveTripFromArrays(sliceSailingArray(arrays, start, end));
    },
    isVariableAvailable: (variable) => {
      return variable in arrays;
    },
    value: (v, i) => {
      const array = arrays[v];
      return array ? array[i] : null;
    },
    range: (v, i1, i2) => {
      return arrays[v] ? range(arrays[v]!, i1, i2) : null;
    },
  };
};

const range = (
  array: Float32Array | Float64Array,
  i1: number,
  i2: number
): [number, number] | null => {
  const subarray = array.subarray(i1, i2);
  if (subarray === null || subarray === undefined) {
    return null;
  }
  let max = -Infinity;
  let min = Infinity;
  for (let i = 0; i < subarray.length; i++) {
    const value = subarray[i];
    if (value !== null && value > max) {
      max = value;
    }
    if (value !== null && value < min) {
      min = value;
    }
  }

  if (isFinite(max) && isFinite(min)) {
    return [min, max];
  } else {
    return null;
  }
};

const quantile = (array: Float32Array | Float64Array, p: number = 1) => {
  let sortedArray = array.slice().sort() as Float32Array;

  const index = Math.min(
    Math.max(0, Math.round(sortedArray.length * p)),
    sortedArray.length - 1
  );
  return sortedArray[index];
};

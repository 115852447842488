import { Position } from "@chartedsails/sailing-data";
import { toRadians } from "../units/angles";

// This is the turf distance function without support for different units.
// https://github.com/Turfjs/turf-distance/blob/master/index.js
export function distance(coordinates1: Position, coordinates2: Position) {
  var dLat = toRadians(coordinates2[1] - coordinates1[1]);
  var dLon = toRadians(coordinates2[0] - coordinates1[0]);
  var lat1 = toRadians(coordinates1[1]);
  var lat2 = toRadians(coordinates2[1]);

  var a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Return result in meters
  const R = 6373 * 1000;
  var distance = R * c;
  return distance;
}

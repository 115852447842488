import { Position } from "@chartedsails/sailing-data";
import { GeoSegment } from "~/algo/geometry/GeoSegment";

/*
 * For a discussion of how this algorithm works, refer to:
 * https://bryceboe.com/2006/10/23/line-segment-intersection-algorithm/
 * https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
 */

export const doSegmentIntersect = (s1: GeoSegment, s2: GeoSegment) => {
  const [p1, q1] = [s1.a, s1.b];
  const [p2, q2] = [s2.a, s2.b];

  const o1 = orientation(p1, q1, p2);
  const o2 = orientation(p1, q1, q2);
  const o3 = orientation(p2, q2, p1);
  const o4 = orientation(p2, q2, q1);

  // General case
  if (o1 !== o2 && o3 !== o4) {
    return true;
  }

  // Special Cases
  // p1, q1 and p2 are colinear and p2 lies on segment p1q1
  if (o1 === 0 && onSegment(p1, p2, q1)) return true;

  // p1, q1 and q2 are colinear and q2 lies on segment p1q1
  if (o2 === 0 && onSegment(p1, q2, q1)) return true;

  // p2, q2 and p1 are colinear and p1 lies on segment p2q2
  if (o3 === 0 && onSegment(p2, p1, q2)) return true;

  // p2, q2 and q1 are colinear and q1 lies on segment p2q2
  if (o4 === 0 && onSegment(p2, q1, q2)) return true;

  return false;
};

// Given three colinear points p, q, r, the function checks if
// point q lies on line segment 'pr'
const onSegment = (p: Position, q: Position, r: Position) => {
  if (
    q[0] <= Math.max(p[0], r[0]) &&
    q[0] >= Math.min(p[0], r[0]) &&
    q[1] <= Math.max(p[1], r[1]) &&
    q[1] >= Math.min(p[1], r[1])
  ) {
    return true;
  }
  return false;
};

// To find orientation of ordered triplet (p, q, r).
// The function returns following values
// 0 --> p, q and r are colinear
// 1 --> Clockwise
// 2 --> Counterclockwise
const orientation = (p: Position, q: Position, r: Position) => {
  // See https://www.geeksforgeeks.org/orientation-3-ordered-points/
  // for details of below formula.
  const val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);

  if (val === 0) return 0; // colinear

  return val > 0 ? 1 : 2; // clock or counterclock wise
};

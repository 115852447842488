import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Favorite from "@material-ui/icons/Favorite";
import clsx from "clsx";
import * as React from "react";
import LogoFacebookWhite from "~/assets/img/logos/facebook-white.svg";
import LogoFacebook from "~/assets/img/logos/facebook.svg";
import LogoInstagramWhite from "~/assets/img/logos/instagram-white.svg";
import LogoInstagram from "~/assets/img/logos/instagram.svg";
import LogoTwitterWhite from "~/assets/img/logos/twitter-white.svg";
import LogoTwitter from "~/assets/img/logos/twitter.svg";
import { analytics } from "~/backend/analytics/analytics";
import { carbon } from "~/styles/chartedsailsColors";
import { csRed } from "~/styles/chartedSailsTheme";
import { Link } from "../link/Link";
import { Logotype } from "../logotype/Logotype";

const useStyles = makeStyles((theme) => ({
  dark: {
    backgroundColor: carbon[900],
    color: "white",
    paddingTop: theme.spacing(5),
    "& a,span": {
      color: "white",
    },
    "& $socialLinks": {
      "& a": {
        backgroundColor: carbon[800],
      },
    },
  },
  footer: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  footerNav: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  socialLinks: {
    display: "flex",

    justifyContent: "flex-end",
    alignItems: "start",
    "& a": {
      marginLeft: theme.spacing(3),

      height: 40,
      width: 40,
      borderRadius: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      "& img": {
        height: 20,
      },
    },
  },
  loveMessage: {
    fontSize: 16,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    "& .MuiSvgIcon-root": {
      top: "3px",
      width: "18px",
      height: "18px",
      position: "relative",
      color: csRed,
    },
  },
}));

export const CSFooter = ({
  className,
  dark,
}: {
  className?: string;
  dark?: boolean;
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.footer, dark && classes.dark, className)}>
      <Container>
        <Grid container>
          <Hidden smDown>
            <Grid item md={2}>
              <Logotype small inverted={dark} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={8}>
            <div className={classes.footerNav}>
              <Link to="/blog/" variant="caption" color="textPrimary">
                Blog
              </Link>
              <Link to="/pricing" variant="caption" color="textPrimary">
                Pricing
              </Link>
              <Link to="/contact" variant="caption" color="textPrimary">
                Contact
              </Link>
              <Link to="/terms" variant="caption" color="textPrimary">
                Terms of Service
              </Link>
              <Link to="/privacy" variant="caption" color="textPrimary">
                Privacy
              </Link>
            </div>
            <Typography align="center" className={classes.loveMessage}>
              Made with <Favorite /> in San Mateo, California
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.socialLinks}>
            <a
              href="https://twitter.com/chartedsails"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
              onClick={() =>
                analytics.event("social-click", { network: "twitter" })
              }
            >
              <img
                src={dark ? LogoTwitterWhite : LogoTwitter}
                alt="Logo Twitter"
              />
            </a>
            <a
              href="https://facebook.com/chartedsails"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              onClick={() =>
                analytics.event("social-click", { network: "facebook" })
              }
            >
              <img
                src={dark ? LogoFacebookWhite : LogoFacebook}
                alt="Logo Facebook"
              />
            </a>
            <a
              href="https://instagram.com/chartedsails"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              onClick={() =>
                analytics.event("social-click", { network: "instagram" })
              }
            >
              <img
                src={dark ? LogoInstagramWhite : LogoInstagram}
                alt="Logo Instagram"
              />
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

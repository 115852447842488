/**
 * Returns the short angle between two angles.
 * https://gist.github.com/shaunlebron/8832585
 *
 * shortAngleDist(0, 50) => 50
 * shortAngleDist(0, 270) => -90
 * @param a0
 * @param a1
 */
export function shortAngleDist(a0: number, a1: number) {
  const max = Math.PI * 2;
  const da = (a1 - a0) % max;
  return ((2 * da) % max) - da;
}

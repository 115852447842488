// https://github.com/Turfjs/turf/blob/master/packages/turf-rhumb-bearing/index.ts

import { Position } from "@chartedsails/sailing-data";
import { toDegrees, toRadians } from "../units/angles";

/**
 * Takes two {@link Point|points} and finds the bearing angle between them along a Rhumb line
 * i.e. the angle measured in degrees start the north line (0 degrees)
 *
 * @name rhumbBearing
 * @param {Coord} start starting Point
 * @param {Coord} end ending Point
 * @returns {number} bearing from north in decimal degrees, between -180 and 180 degrees (positive clockwise)
 * @example
 * var point1 = turf.point([-75.343, 39.984], {"marker-color": "#F00"});
 * var point2 = turf.point([-75.534, 39.123], {"marker-color": "#00F"});
 *
 * var bearing = turf.rhumbBearing(point1, point2);
 *
 * //addToMap
 * var addToMap = [point1, point2];
 * point1.properties.bearing = bearing;
 * point2.properties.bearing = bearing;
 */
export function rhumbBearing(from: Position, to: Position) {
  // φ => phi
  // Δλ => deltaLambda
  // Δψ => deltaPsi
  // θ => theta
  const phi1 = toRadians(from[1]);
  const phi2 = toRadians(to[1]);
  let deltaLambda = toRadians(to[0] - from[0]);
  // if deltaLambdaon over 180° take shorter rhumb line across the anti-meridian:
  if (deltaLambda > Math.PI) {
    deltaLambda -= 2 * Math.PI;
  }
  if (deltaLambda < -Math.PI) {
    deltaLambda += 2 * Math.PI;
  }

  const deltaPsi = Math.log(
    Math.tan(phi2 / 2 + Math.PI / 4) / Math.tan(phi1 / 2 + Math.PI / 4)
  );

  const theta = Math.atan2(deltaLambda, deltaPsi);

  const bear360 = (toDegrees(theta) + 360) % 360;
  const bear180 = bear360 > 180 ? -(360 - bear360) : bear360;

  return toRadians(bear180);
}

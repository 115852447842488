import { Position } from "@chartedsails/sailing-data";
import { distance, rhumbBearing } from "@chartedsails/sailing-math";
import { SailingDataArray } from "./types";

/**
 * Takes a sailing array and re-calculates SOG/COG from the positions.
 *
 * If recalculateAllValues is true, then all values are recalculated, even if they are already present.
 * Otherwise, only missing values are calculated.
 *
 * @param sa
 * @param recalculateAllValues
 * @returns
 */
export const sogcogFromPositions = (
  sa: SailingDataArray,
  recalculateAllValues?: boolean
): SailingDataArray => {
  const len = sa.latitude.length;

  const sog = new Float32Array(len);
  const cog = new Float32Array(len);
  for (let i = 0; i < len - 1; i++) {
    if (
      !recalculateAllValues &&
      Number.isFinite(sa.sog?.[i]) &&
      Number.isFinite(sa.cog?.[i])
    ) {
      sog[i] = sa.sog[i];
      cog[i] = sa.cog[i];
    }
    else {
      const p1 = [sa.longitude[i], sa.latitude[i]] as Position;
      const p2 = [sa.longitude[i + 1], sa.latitude[i + 1]] as Position;

      const t1 = sa.time[i];
      const t2 = sa.time[i + 1];

      if (t2 > t1) {
        const d = distance(p1, p2);
        const pSog = sa.sog?.[i];
        if (recalculateAllValues || !Number.isFinite(pSog)) {
          sog[i] = d / ((t2 - t1) / 1000);
        }
        else {
          sog[i] = pSog;
        }

        const pCog = sa.cog?.[i];
        if (recalculateAllValues || !Number.isFinite(pCog)) {
          cog[i] = rhumbBearing(p1, p2);
        }
        else {
          cog[i] = pCog;
        }
      } else {
        sog[i] = i > 0 ? sog[i - 1] : 0;
        cog[i] = i > 0 ? cog[i - i] : 0;
      }
    }
  }
  if (!recalculateAllValues && (Number.isFinite(sa.sog?.[len - 1]))) {
    sog[len - 1] = sa.sog[len - 1];
  }
  else {
    sog[len - 1] = sog[sa.longitude.length - 2];
  }

  if (!recalculateAllValues && Number.isFinite(sa.cog?.[len - 1])) {
    cog[len - 1] = sa.cog[len - 1];
  }
  else {
    cog[sa.longitude.length - 1] = cog[sa.longitude.length - 2];
  }
  return { ...sa, sog, cog };
};

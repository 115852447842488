/*

This is heavily inspired by fit-bounds.js in the viewport-mercator-project but
takes a list instead of two points so that it can figure out how to fit all the
points best on the screen, considering the rotation of the map.

Having the list of points is required to find the left-most, top-most,
right-most, bottom-most points when you are considering rotation. The bounds are
not enough.

 */

import WebMercatorViewport from "viewport-mercator-project";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";

export interface PaddingObject {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface IArgs {
  width: number;
  height: number;
  bearing?: number;
  points: Array<[number, number]>;
  padding?: number | PaddingObject;
  offset?: number[];
}

export function fitBounds({
  width,
  height,
  points,
  bearing = 0,
  // options
  padding = 0,
  offset = [0, 0],
}: IArgs): {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
} {
  if (typeof padding === "number") {
    const p = padding;
    padding = {
      top: p,
      bottom: p,
      left: p,
      right: p,
    };
  }

  const viewport = new WebMercatorViewport({
    width,
    height,
    bearing,
  });

  if (points.length === 0) {
    throw new Error("No points to fit.");
  }

  const firstPoint = viewport.project(points[0]);

  let top = firstPoint[1];
  let bottom = firstPoint[1];
  let left = firstPoint[0];
  let right = firstPoint[0];

  for (const p of points) {
    // Map point to pixels
    const mp = viewport.project(p);
    // In mapbox coordinate system, the top-left corner is 0,0
    top = top === undefined ? mp[1] : Math.min(top, mp[1]);
    bottom = bottom === undefined ? mp[1] : Math.max(bottom, mp[1]);
    left = left === undefined ? mp[0] : Math.min(left, mp[0]);
    right = right === undefined ? mp[0] : Math.max(right, mp[0]);
  }
  const topLeft = [left, top];
  const bottomRight = [right, bottom];

  // width/height on the Web Mercator plane
  const size = [
    Math.abs(bottomRight[0] - topLeft[0]),
    Math.abs(bottomRight[1] - topLeft[1]),
  ];

  const targetSize = [
    width - padding.left - padding.right - Math.abs(offset[0]) * 2,
    height - padding.top - padding.bottom - Math.abs(offset[1]) * 2,
  ];

  if (!(size[0] > 0 && size[1] > 0 && targetSize[0] > 0 && targetSize[1] > 0)) {
    // Something is wrong - either we only have one point, or all the points are stacked together.
    // Or the window is too small.
    // In that case just return a viewport centered on the first point and with an arbitrary zoom level.
    return new WebMercatorViewport({
      ...viewport,
      latitude: points[0][1],
      longitude: points[0][0],
      zoom: 12,
    });
  }

  // scale = screen pixels per unit on the Web Mercator plane
  const scaleX = targetSize[0] / size[0];
  const scaleY = targetSize[1] / size[1];

  // Find how much we need to shift the center
  const offsetX = (padding.right - padding.left) / 2 / scaleX;
  const offsetY = (padding.bottom - padding.top) / 2 / scaleY;

  const center: [number, number] = [
    (bottomRight[0] + topLeft[0]) / 2 + offsetX,
    (bottomRight[1] + topLeft[1]) / 2 + offsetY,
  ];

  const centerLngLat = viewport.unproject(center);

  const zoom = viewport.zoom + Math.log2(Math.abs(Math.min(scaleX, scaleY)));

  return new WebMercatorViewport({
    width,
    height,
    longitude: centerLngLat[0],
    latitude: centerLngLat[1],
    zoom,
    bearing,
  }) as ChartedSailsViewport;
}

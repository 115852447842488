import { gql, useMutation } from "@apollo/client";
import {
  ResetPassword,
  ResetPasswordVariables,
} from "~/backend/graphql/ResetPassword";

const RESET_PASSWORD = gql`
  mutation ResetPassword($reset: PasswordResetInput!) {
    resetPassword(reset: $reset) {
      id
      name
      email
    }
  }
`;

export const useResetPassword = () => {
  const [mutation] = useMutation<ResetPassword, ResetPasswordVariables>(
    RESET_PASSWORD
  );
  return mutation;
};

import React from "react";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { useScaledDistance } from "~/components/replay/map/useScaledDistance";
import { range } from "~/components/util/linear-interpolations";
import { SVGLayerRedrawOptions } from "~/components/util/SVGRedrawOptions";
import { buoy } from "~/styles/chartedsailsColors";
import { fontLato } from "~/styles/chartedSailsTheme";
import { formatDistance } from "~/util/formatDistance";

// Three boat length - hull of the boat closer to the mark
export const MarkZone = ({
  redrawContext,
  mark,
  boatLength,
}: {
  mark: SailingMark;
  redrawContext: SVGLayerRedrawOptions;
  boatLength: number;
}) => {
  const radiusPixels = useScaledDistance(boatLength * 3) ?? 0;

  const p = redrawContext.project([mark.longitude, mark.latitude]);

  const lineText = `${formatDistance(boatLength * 3)}m`;

  // Smooth out the transition between "not visible" to "visible"
  const legendOpacity = range(45, 150, 0, 1, radiusPixels);
  const circleOpacity = legendOpacity;
  const strokeDashSize = range(10, 500, 6, 40, radiusPixels);
  return (
    <g>
      <circle
        cx={p[0]}
        cy={p[1]}
        r={radiusPixels}
        strokeWidth={2}
        strokeDasharray={`${strokeDashSize} ${strokeDashSize * 1.4}`}
        strokeOpacity={circleOpacity}
        stroke={buoy[900]}
        fill="none"
      />
      <g opacity={legendOpacity}>
        <line
          x1={p[0]}
          y1={p[1]}
          x2={p[0] + radiusPixels}
          y2={p[1]}
          strokeWidth={1}
          stroke={buoy[900]}
        />
        <foreignObject
          x={p[0] + radiusPixels / 2 - 42 / 2}
          y={p[1] - 20}
          height={40}
          width={42}
        >
          <div
            style={{
              height: 40,
              width: 42,
              backgroundColor: "white",
              border: `1px solid ${buoy[900]}`,
              borderRadius: 6,
              lineHeight: "14px",
              ...fontLato,
              fontWeight: "bold",
              fontSize: 12,
              letterSpacing: "0.2px",
              color: buoy[900],
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>3 BL</span>
            <span>{lineText}</span>
          </div>
        </foreignObject>
      </g>
    </g>
  );
};

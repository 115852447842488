import { Vector } from "apache-arrow";
import { SailingArrowSchemaType } from "./SailingArrowSchema";

/**
 * Find the first element in the (sorted!) vector which is greater than the given value.
 * @param arr
 * @param target
 * @returns
 */
export const binarySearchInTime = (
  arr: Vector<SailingArrowSchemaType["time"]>,
  target: number
) => {
  let start = 0,
    end = arr.length - 1;
  let ans = -1;

  while (start <= end) {
    let mid = Math.round((start + end) / 2);

    // Move to right side if target is
    // greater.
    const midValue = arr.get(mid);
    if (midValue === null || midValue <= target) {
      start = mid + 1;
    }

    // Move left side.
    else {
      ans = mid;
      end = mid - 1;
    }
  }
  return ans;
};

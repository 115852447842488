import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { ReactComponent as OptionCheckedIcon } from "~/assets/icons/option-checked.svg";
import { ReactComponent as OptionUncheckedIcon } from "~/assets/icons/option-unchecked.svg";
import { DayPassOffer } from "~/backend/graphql/DayPassOffer";
import { SubscriptionOffer } from "~/backend/graphql/SubscriptionOffer";
import { carbon, seaway } from "~/styles/chartedsailsColors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    border: `1px solid ${carbon[200]}`,
    borderRadius: 8,
    "&:hover": {
      boxShadow: theme.shadows[5],
    },
    cursor: "pointer",
    position: "relative",
  },
  badge: {
    position: "absolute",
    right: 16,
    top: 0,
    transform: "translateY(-50%)",
  },
  topHalf: {
    borderRadius: `8px 8px 0px 0px`,
    flex: "2 2",
    "& svg": {
      flexShrink: 0,
      width: 24,
      height: 24,
      marginLeft: 16,
      marginRight: 12,
    },
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${carbon[100]}`,
    paddingRight: 16,
  },
  titleBlock: {
    "& p": {
      margin: 0,
    },
    "& p:first-of-type": {
      color: carbon[900],
      fontWeight: "bold",
      marginTop: 16,
      marginBottom: 4,
    },
    "& p:last-of-type": {
      color: carbon[500],
      marginBottom: 16,
    },
  },
  bottomHalf: {
    padding: `8px 16px 16px 52px`,
    display: "flex",
    flex: "0 0 95px",
    flexDirection: "column",
    justifyContent: "space-around",
    "& p": {
      margin: 0,
    },
    "& p:first-of-type": {
      fontWeight: "bold",
      marginBottom: 4,
      color: carbon[900],
    },
    "& p:nth-of-type(2)": {
      color: carbon[500],
      marginBottom: "auto",
    },
  },
  selectedOption: {
    boxShadow: `0px 0px 0px 2px rgba(47, 128, 237, 0.2)`,
    borderColor: seaway[600],
    "& $topHalf": {
      backgroundColor: seaway[50],
    },
    "& $titleBlock p:first-of-type": {
      color: seaway[900],
    },
    "& $bottomHalf p:first-of-type": {
      color: seaway[900],
    },
  },
  disabled: {
    cursor: "default",
    "&:hover": {
      boxShadow: "none",
    },
    "& $topHalf": {
      backgroundColor: carbon[50],
    },
    "& $titleBlock p:first-of-type": {
      color: carbon[500],
    },
    "& $bottomHalf p:first-of-type": {
      color: carbon[500],
    },
  },
}));

interface IProps {
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  description: string;
  price: string;
  priceLegend?: string;
  badge?: string;
  onClick?: () => void;
}

export const LicenseOption = ({
  className,
  selected,
  disabled,
  title,
  badge,
  description,
  price,
  priceLegend,
  onClick,
}: IProps) => {
  const classes = useStyles({ selected });
  return (
    <div
      className={clsx(
        classes.root,
        selected && classes.selectedOption,
        disabled && classes.disabled,
        className
      )}
      onClick={disabled ? undefined : onClick}
    >
      {badge !== undefined && (
        <Chip
          className={classes.badge}
          color={selected ? "primary" : undefined}
          label={badge}
        />
      )}
      <div className={classes.topHalf}>
        {selected ? <OptionCheckedIcon /> : <OptionUncheckedIcon />}
        <div className={classes.titleBlock}>
          <p>{title}</p>
          <p>{description}</p>
        </div>
      </div>
      <div className={classes.bottomHalf}>
        <p>{price}</p>
        {priceLegend !== undefined ? <p>{priceLegend}</p> : null}
      </div>
    </div>
  );
};

export const DayPassOfferOption = ({
  className,
  offer,
  selected,
  onClick,
}: {
  className?: string;
  selected?: boolean;
  offer: DayPassOffer;
  onClick?: () => void;
}) => {
  return (
    <LicenseOption
      className={className}
      selected={selected}
      title={offer.label}
      description={offer.description}
      badge={offer.badge ?? undefined}
      price={offer.priceText}
      priceLegend={offer.priceLegend ?? undefined}
      onClick={onClick}
    />
  );
};

export const SubscriptionOfferOption = ({
  className,
  offer,
  selected,
  onClick,
}: {
  className?: string;
  selected?: boolean;
  offer: SubscriptionOffer;
  onClick?: () => void;
}) => {
  return (
    <LicenseOption
      className={className}
      selected={selected}
      disabled={offer.disabled ?? false}
      title={offer.label}
      description={offer.description}
      badge={offer.badge ?? undefined}
      price={offer.priceText}
      priceLegend={offer.priceLegend}
      onClick={onClick}
    />
  );
};

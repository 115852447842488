import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { GetBillingInfo } from "~/backend/graphql/GetBillingInfo";

export const FRAGMENT_USER_BILLING_INFO = gql`
  fragment UserBillingInfo on User {
    subscription {
      plan
      isFreeTrial
    }
    billingInfo {
      freeFlexTrackAvailable
      subscriptionBilling {
        plan
        status
        currentPeriodEnd
        willAutomaticallyRenew
        problemMessage
        actionSecret
        cardLastFour
        cardExpiration
      }
    }
  }
`;

export const GET_BILLING_INFO = gql`
  query GetBillingInfo {
    me {
      id

      ...UserBillingInfo
    }
  }
  ${FRAGMENT_USER_BILLING_INFO}
`;

export const useBillingInfo = ({
  fetchPolicy,
}: { fetchPolicy?: FetchPolicy } = {}) => {
  return useQuery<GetBillingInfo>(GET_BILLING_INFO);
};

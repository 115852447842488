import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  IsResetPasswordTokenValid,
  IsResetPasswordTokenValidVariables,
} from "~/backend/graphql/IsResetPasswordTokenValid";

const IS_RESET_PASSWORD_TOKEN_VALID = gql`
  query IsResetPasswordTokenValid($token: String!) {
    isResetPasswordTokenValid(token: $token) {
      name
      isValid
      error
    }
  }
`;

export const useIsResetPasswordTokenValid = (token: string | null) => {
  const [loadQuery, queryResult] = useLazyQuery<
    IsResetPasswordTokenValid,
    IsResetPasswordTokenValidVariables
  >(IS_RESET_PASSWORD_TOKEN_VALID);

  useEffect(() => {
    if (token !== null) {
      loadQuery({ variables: { token } });
    }
  }, [loadQuery, token]);
  return queryResult;
};

/**
 * Normalize a direction (azimuth) to [0,360[
 * @param d direction in radians
 */
export const normalizeDirection = (d: number) => {
  while (d < 0) {
    d += Math.PI * 2;
  }
  return d % (Math.PI * 2);
};

import React from "react";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { SVGLayerRedrawOptions } from "~/components/util/SVGRedrawOptions";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";

export const RaceLineSVG = ({
  redrawContext,
  markA,
  markB,
  lineText,
  color,
}: {
  markA: SailingMark;
  markB: SailingMark;
  redrawContext: SVGLayerRedrawOptions;
  lineText?: string;
  color: string;
}) => {
  const lp = {
    a: coordinatesToLonLat(markA),
    b: coordinatesToLonLat(markB),
  };
  const pa = redrawContext.project(lp.a);
  const pb = redrawContext.project(lp.b);

  const dx = pa[0] - pb[0];
  const dy = pa[1] - pb[1];
  const distancePixels = Math.sqrt(dx * dx + dy * dy);

  // Make sure the text is always upside up
  const textA = pa[0] < pb[0] ? { ...pa } : { ...pb };
  const textB = pa[0] < pb[0] ? { ...pb } : { ...pa };
  // Add offset
  const textAngle = Math.atan(dy / dx);
  const textOffset = 5;
  const textOffsetX = textOffset * Math.sin(textAngle);
  const textOffsetY = textOffset * Math.cos(textAngle);
  textA[0] += textOffsetX;
  textB[0] += textOffsetX;
  textA[1] -= textOffsetY;
  textB[1] -= textOffsetY;

  return (
    <g>
      <defs>
        <path
          id={`line-${markA.id}-${markB.id}`}
          d={`M${textA[0]},${textA[1]} L${textB[0]},${textB[1]}`}
        />
      </defs>
      <line
        x1={pa[0]}
        y1={pa[1]}
        x2={pb[0]}
        y2={pb[1]}
        strokeWidth={2}
        strokeDasharray={"8 4"}
        stroke={color}
      />
      {lineText !== undefined && distancePixels > 100 && (
        <text textAnchor="middle">
          <textPath href={`#line-${markA.id}-${markB.id}`} startOffset="50%">
            {lineText}
          </textPath>
        </text>
      )}
    </g>
  );
};

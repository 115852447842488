import { gql } from "@apollo/client";
import {
  RegisterBoatClass,
  RegisterBoatClassVariables,
} from "~/backend/graphql/RegisterBoatClass";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { FRAGMENT_BOAT_CLASS } from "./fragmentBoatClass";
import { GET_BOAT_CLASSES } from "./useBoatClasses";

const REGISTER_BOAT_CLASS = gql`
  mutation RegisterBoatClass(
    $name: String!
    $boatLength: Float!
    $url: String
  ) {
    registerBoatClass(name: $name, boatLength: $boatLength, url: $url) {
      ...BoatClass
    }
  }
  ${FRAGMENT_BOAT_CLASS}
`;

export const useRegisterBoatClass = () => {
  const mutation = useMutationWithErrorHandling<
    RegisterBoatClass,
    RegisterBoatClassVariables
  >(REGISTER_BOAT_CLASS, {
    refetchQueries: [{ query: GET_BOAT_CLASSES }],
    awaitRefetchQueries: true,
  });

  return mutation;
};

import { ApolloError } from "@apollo/client";
import { BackendErrorCode } from "@chartedsails/shared-config";
import { GraphQLError } from "graphql";

export const getBackendErrorCode = (
  errors: readonly GraphQLError[] | ApolloError | Error | undefined
) => {
  if (errors instanceof ApolloError) {
    errors = errors.graphQLErrors;
  }
  if (Array.isArray(errors)) {
    if (!errors || errors.length !== 1) {
      return null;
    }
    const e = errors[0];
    if (e.extensions && "code" in e.extensions) {
      const code = e.extensions.code as BackendErrorCode;

      return code;
    }
  }
};

export const isBackendError = (
  errors: readonly GraphQLError[] | ApolloError | Error | undefined,
  errorCode: BackendErrorCode
) => {
  return getBackendErrorCode(errors) === errorCode;
};

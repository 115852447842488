import { gql } from "@apollo/client";
import { FRAGMENT_BOAT_CLASS } from "./fragmentBoatClass";

export const FRAGMENT_BOAT = gql`
  fragment Boat on Boat {
    id
    name
    sailNumber
    boatClass {
      ...BoatClass
    }
  }
  ${FRAGMENT_BOAT_CLASS}
`;

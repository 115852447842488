import { InteractiveTrip } from "@chartedsails/tracks";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { DEFAULT_SPEED_SCALE_MAX } from "../speedgradient/calculate-speed-scale-max";

export const trackColoredBySpeed = (
  trip: InteractiveTrip,
  speedGradient: chroma.Scale<chroma.Color>,
  speedScaleMax: number
) => {
  const pt = new PerfTimer("trackColoredBySpeed");
  // Technik 1:
  // const indexedColors = new Array(trip.length)
  // pt.mark("create array")
  // for (let i = 0; i < trip.length - 1; i++) {
  //   // segmentX is between pointX and pointX+1
  //   // Calculate the average speed between both points and use this as the segment speed.
  //   const segmentSpeed = (trip.sog(i) + trip.sog(i + 1)) / 2
  //   indexedColors[i] = speedGradient(segmentSpeed).rgba()
  //   // needed for deck.gl color style
  //   indexedColors[i][3] = 255
  // }
  // pt.mark("fill array")
  // pt.log()

  // Technik 2: (no significant performance change)
  // colors[b.id] = new Array(b.data.length)
  //   .fill(0)
  //   .map((_, i) => speedGradient(b.data!.sog(i)).rgba())

  // Technik3: using an intermediate array to pre-calculate the colors
  const numColors = 200;
  const colorBand = new Array(numColors);

  for (let i = 0; i < numColors; i++) {
    try {
      // I am not sure how it happens but Sentry shows that sometimes speedScaleMax is NaN here.
      // https://chartedsails.sentry.io/issues/4321497902/?project=4505533933027328&query=is:unresolved&stream_index=3
      if (!Number.isFinite(speedScaleMax)) {
        speedScaleMax = DEFAULT_SPEED_SCALE_MAX;
      }
      colorBand[i] = speedGradient((i / numColors) * speedScaleMax).rgba();
    }
    catch (e) {
      // NOTE: Remove this try/catch block once we have a better understanding of the issue
      console.log(`Colorband ${i} failed: ${e}`, { i, numColors, speedScaleMax, color_index: (i / numColors) * speedScaleMax })
      throw e
    }
    // needed for deck.gl color style
    colorBand[i][3] = 255;
  }
  pt.mark("prepare color band");
  const indexedColors = new Array(trip.length);
  pt.mark("create array");
  for (let i = 0; i < trip.length - 1; i++) {
    // segmentX is between pointX and pointX+1
    // Calculate the average speed between both points and use this as the segment speed.
    const segmentSpeed = (trip.sog(i) + trip.sog(i + 1)) / 2;
    const segmentBand = Math.floor((segmentSpeed / speedScaleMax) * numColors);
    indexedColors[i] = colorBand[Math.min(segmentBand, numColors - 1)];
  }
  pt.mark("fill array");
  pt.log();

  return indexedColors;
};

import React from "react";
import { Link, Meta, Title } from "react-head";
import {
  facebookAppId,
  productName,
  twitterHandle,
} from "~/styles/chartedSailsTheme";

interface IProps {
  title?: string;
  socialTitle?: string;
  description?: string;
  image?: string;
  path?: string;
  contentType?: "article";
  tags?: string[];
}

export const SEO = ({
  title,
  socialTitle,
  description,
  image,
  path,
  contentType,
  tags,
}: IProps) => {
  let pagePath = window.location.pathname;
  // By default standardize to urls without a trailing slash
  if (pagePath.endsWith("/")) {
    pagePath = pagePath.slice(0, pagePath.length - 1);
  }
  path = path ?? pagePath;

  const url = window.location.origin + path;
  image = image ?? "/assets/session-social-card.png";
  const imageUrl = window.location.origin + image;

  return (
    <>
      <Title>{title ? `${title} - ${productName}` : productName}</Title>
      <Meta name="description" content={description} />
      <Link rel="canonical" href={url} />

      <Meta property="og:title" content={socialTitle || title} />
      <Meta property="og:type" content={contentType ?? "website"} />
      <Meta property="og:image" content={imageUrl} />
      <Meta property="og:url" content={url} />
      <Meta property="og:description" content={description} />
      <Meta property="og:site_name" content={productName} />
      <Meta property="og:image:secure_url" content={imageUrl} />
      {tags?.map((t, i) => (
        <Meta key={i} name="og:tag" content={t} />
      ))}
      <Meta property="fb:app_id" content={facebookAppId} />

      <Meta name="twitter:card" content="summary_large_image" />
      <Meta name="twitter:site" content={twitterHandle} />
      <Meta name="twitter:image" content={imageUrl} />
      <Meta name="twitter:title" content={socialTitle || title} />
      <Meta name="twitter:description" content={description} />
    </>
  );
};

import {
  Field,
  Float32,
  Float64,
  Schema,
  TimestampMillisecond,
} from "apache-arrow";

export type SailingArrowSchemaType = {
  time: TimestampMillisecond;
  longitude: Float64;
  latitude: Float64;
  sog: Float32;
  cog: Float32;
  heading: Float32;
  roll: Float32;
  pitch: Float32;
  bsp: Float32;
  depth: Float32;
  aws: Float32;
  awa: Float32;
  tws: Float32;
  twa: Float32;
  twd: Float32;
  rudderAngle: Float32;
  forestayTension: Float32;
  backstayTension: Float32;
  mainsheetTension: Float32;
  loadCell: Float32;
  atmosphericPressure: Float32;
  airTemperature: Float32;
  waterTemperature: Float32;
  heartRate: Float32;
};

export const SailingArrowSchema = new Schema<SailingArrowSchemaType>([
  new Field("time", new TimestampMillisecond()),
  new Field("longitude", new Float64(), true),
  new Field("latitude", new Float64(), true),
  new Field("sog", new Float32(), true),
  new Field("bsp", new Float32(), true),
  new Field("cog", new Float32(), true),
  new Field("heading", new Float32(), true),
  new Field("roll", new Float32(), true),
  new Field("pitch", new Float32(), true),
  new Field("bsp", new Float32(), true),
  new Field("depth", new Float32(), true),
  new Field("aws", new Float32(), true),
  new Field("awa", new Float32(), true),
  new Field("tws", new Float32(), true),
  new Field("twa", new Float32(), true),
  new Field("twd", new Float32(), true),
  new Field("rudderAngle", new Float32(), true),
  new Field("forestayTension", new Float32(), true),
  new Field("backstayTension", new Float32(), true),
  new Field("mainsheetTension", new Float32(), true),
  new Field("loadCell", new Float32(), true),
  new Field("atmosphericPressure", new Float32(), true),
  new Field("airTemperature", new Float32(), true),
  new Field("waterTemperature", new Float32(), true),
  new Field("heartRate", new Float32(), true),
]);

import * as Sentry from "@sentry/react";
import { FC } from "react";
import { analytics } from "~/backend/analytics/analytics";
import ErrorPage from "~/pages/ErrorPage";

export const ErrorBoundarySentry: FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => {
        analytics.event(`error`, {
          error: error.message,
          errorStack: JSON.stringify(componentStack),
        });

        return (
          <ErrorPage
            title={`Three sheets to the wind ...`}
            message={
              error.message
                ? `An error occurred. Please contact us with the error '${error.message}'. Thanks!`
                : "An unknown error occurred. Please let us know what led you here so we can fix this. Thank you!"
            }
          />
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

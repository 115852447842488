import { InteractiveTrip } from "@chartedsails/tracks";
import { LineLayer } from "deck.gl";

export type DeckGLColor = [number, number, number, number];
const transparentTrackColor: DeckGLColor = [0, 0, 0, 0];

export interface TripLayerColorSegment {
  from: number;
  color: DeckGLColor;
}

/*
 * This object should change as rarely as possible because any change
 * will cause deck.gl to completely discard the layer and recalculate all the line.
 */
export interface TripLayerData {
  trip: InteractiveTrip;
  speedColors: Array<DeckGLColor>;
  colorSegments: Array<TripLayerColorSegment>;
  length: number;
}

type TripLayerColor = DeckGLColor | "speed" | "colorSegments";

export interface LayerAccessorArg {
  index: number;
  data: TripLayerData;
}
type LayerAccessor<T> = (_: any, { index, data }: LayerAccessorArg) => T;

export const tripLayerSourceAccessor = (
  _: any,
  { index, data }: LayerAccessorArg
) => data.trip.lonlat(index);

export const tripLayerTargetAccessor = (
  _: any,
  { index, data }: LayerAccessorArg
) => data.trip.lonlat(index + 1);

interface TripLayerArgs {
  id: string;
  data: TripLayerData;
  layerColor: TripLayerColor;
  width: number;
  pickable: boolean;
  visible?: [number, number];
  fading: false | "forward" | "backward";
}

export class TripLayer {
  constructor({
    id,
    data,
    layerColor,
    width,
    pickable,
    fading,
    visible,
  }: TripLayerArgs) {
    let colorAccessor: DeckGLColor | DeckGLColor[] | LayerAccessor<DeckGLColor>;

    if (!visible) {
      visible = [data.trip.startTime, data.trip.endTime];
    }

    // We do not need an accessor when we are showing everything in solid. This will be much faster.
    if (
      !fading &&
      layerColor !== "colorSegments" &&
      layerColor !== "speed" &&
      visible[0] === data.trip.startTime &&
      visible[1] === data.trip.endTime
    ) {
      colorAccessor = layerColor;
    } else {
      if (fading === "forward") {
        colorAccessor = prepareFadingColorAccessor(
          layerColor,
          visible[0],
          visible[1],
          true
        );
      } else if (fading === "backward") {
        colorAccessor = prepareFadingColorAccessor(
          layerColor,
          visible[0],
          visible[1],
          false
        );
      } /* !fading */ else {
        colorAccessor = prepareSolidColorAccessor(
          layerColor,
          visible[0],
          visible[1]
        );
      }
    }

    return new LineLayer({
      id,
      data,
      getColor: colorAccessor,
      getWidth: width,
      getSourcePosition: tripLayerSourceAccessor,
      getTargetPosition: tripLayerTargetAccessor,
      pickable,
      updateTriggers: {
        getColor: [layerColor, fading, visible[0], visible[1]],
      },
    });
  }
}

const colorForTimeInSegments = (
  time: number,
  segments: Array<TripLayerColorSegment>
) => {
  // Return the last segment which is usable
  const usableColorSegments = segments.filter((s) => time >= s.from);
  if (usableColorSegments.length > 0) {
    return usableColorSegments[usableColorSegments.length - 1].color;
  }
  return transparentTrackColor;
};

const colorForIndex = (
  layerColor: TripLayerColor,
  { index, data }: LayerAccessorArg
) => {
  if (layerColor === "speed") {
    return data.speedColors[index];
  } else if (layerColor === "colorSegments") {
    return colorForTimeInSegments(data.trip.time(index), data.colorSegments);
  } else {
    return layerColor;
  }
};

/**
 * Returns a function that will provide the color for a given segment based on
 * whether it is in the selection or not.
 */
const prepareSolidColorAccessor = (
  layerColor: TripLayerColor,
  start: number,
  end: number
) => {
  return (_: any, { index, data }: LayerAccessorArg) => {
    if (data.trip.time(index) >= start && data.trip.time(index + 1) <= end) {
      return colorForIndex(layerColor, { index, data });
    } else {
      return transparentTrackColor;
    }
  };
};

/**
 * Returns a function that will provide the color for a given segment based on
 * a progressive transparent ramp from start to end.
 */
const prepareFadingColorAccessor = (
  layerColor: TripLayerColor,
  start: number,
  end: number,
  reverseFading: boolean
) => {
  return (_: any, { index, data }: LayerAccessorArg) => {
    if (data.trip.time(index) >= start && data.trip.time(index + 1) <= end) {
      let color = colorForIndex(layerColor, { index, data });

      let percentageOfColor = 1 - (end - data.trip.time(index)) / (end - start);
      if (reverseFading) {
        percentageOfColor = 1 - percentageOfColor;
      }
      color[3] = 255 * percentageOfColor;
      return color;
    } else {
      return transparentTrackColor;
    }
  };
};

import { SailingDataArray } from "@chartedsails/tracks";
import { Table } from "apache-arrow";
import { SailingArrowSchemaType } from "./SailingArrowSchema";
import { vectorToArray } from "./vectorToArray";

export const sailingArraysFromTable = (
  table: Table<SailingArrowSchemaType>
): SailingDataArray => {
  const sailingArrays: SailingDataArray = table.schema.fields
    .map((f) => f.name)
    .reduce((sailingArrays, k) => {
      // Time is a special case and is handled later.
      if (k === "time") {
        return sailingArrays;
      }
      const v = table.getChild(k as keyof SailingArrowSchemaType)!;
      sailingArrays[k as "sog"] = vectorToArray(v) as Float32Array;

      if (v.nullCount > 0) {
        for (let i = 0; i < v.length; i++) {
          if (!v.isValid(i)) {
            sailingArrays[k as "sog"][i] = NaN;
          }
        }
      }
      return sailingArrays;
    }, {} as SailingDataArray);

  const time = table.getChild("time");
  if (time === null || time === undefined) {
    throw new Error(`Table does not have a time column.`)
  }
  sailingArrays["time"] = [...time] as number[];
  return sailingArrays;
};

import { filterBogusGPSPoints, InteractiveTrip, interactiveTripFromArrays, interpolateArraysValues, sogcogFromPositions } from "@chartedsails/tracks";
import { Table } from "apache-arrow";
import { sailingArraysFromTable } from "./arraysFromTable";

export const interactiveTripFromArrow = (
  table: Table,
  filterPointsAboveSOG?: number
): InteractiveTrip => {
  if (table.numRows === 0) {
    throw new Error("SailingDataTable is empty");
  }
  let sa = sailingArraysFromTable(table);
  if (filterPointsAboveSOG !== undefined) {
    sa = filterBogusGPSPoints(sa, filterPointsAboveSOG);
    // Calculate SOG and COG - only for the points we just removed
    sa = sogcogFromPositions(sa, false);
  }

  // Fill all the NaN in the data so we have 100% coverage of data
  sa = interpolateArraysValues(sa);

  if (!("sog" in sa) || !("cog" in sa)) {
    sa = sogcogFromPositions(sa);
  }

  const trip = interactiveTripFromArrays(sa);
  return trip;
};

// For backwards compatibility with old session saved before new fields were added

import { TrackData } from "../types/TrackData";
import { SailingDataArray } from "./types";

export const arraysFromTrackData = (trackData: TrackData): SailingDataArray => {
  const length = trackData.latitude.length;
  const t0 = Date.parse(trackData.startTime);

  const sailingArrays: SailingDataArray = {
    time: Array.from({ length }, (_, i) => t0 + i * trackData.interval),
    latitude: new Float64Array(trackData.latitude),
    longitude: new Float64Array(trackData.longitude),
    sog: new Float32Array(trackData.sog),
    cog: new Float32Array(trackData.cog),
  }

  if (trackData.hdg) {
    sailingArrays.heading = new Float32Array(trackData.hdg)
  }
  if (trackData.roll) {
    sailingArrays.roll = new Float32Array(trackData.roll)
  }
  if (trackData.pitch) {
    sailingArrays.pitch = new Float32Array(trackData.pitch)
  }

  return sailingArrays
}

import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { GetImport, GetImportVariables } from "~/backend/graphql/GetImport";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import {
  PrepareUploadTrack,
  PrepareUploadTrackVariables,
} from "~/backend/graphql/PrepareUploadTrack";
import { processError } from "~/backend/utils/useErrorHandler";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { isNotNullish } from "~/components/util/isNotNullish";
import { useCloudUpload } from "./useCloudUpload";
import { GET_IMPORT_WITH_LICENSE } from "./useImport";
import { useSubscribeUploadJob } from "./useSubscribeToImportJob";

const PREPARE_UPLOAD_TRACK = gql`
  mutation PrepareUploadTrack(
    $filename: String!
    $mediaType: String!
    $size: Int!
  ) {
    prepareUploadTrack(
      filename: $filename
      mediaType: $mediaType
      size: $size
    ) {
      signedRequest
      importJobId
    }
  }
`;

export const useImportDirectUpload = () => {
  const prepareUploadTrack = useMutationWithErrorHandling<
    PrepareUploadTrack,
    PrepareUploadTrackVariables
  >(PREPARE_UPLOAD_TRACK);
  const apolloClient = useApolloClient();
  const subscribeToImportJob = useSubscribeUploadJob();
  const cloudUpload = useCloudUpload();

  const uploadProcessedEvent = useAnalyticEvent("upload-processed");
  const uploadErrorEvent = useAnalyticEvent("upload-error");

  return useCallback<
    (
      file: File,
      onProgress?: (step: "prepare" | "upload" | "process", p?: number) => void
    ) => Promise<ImportInfoWithLicense>
  >(
    async (file, onProgress) => {
      const startTime = performance.now();
      const fileInfo = formatFileInfoForAnalytics(file);

      try {
        onProgress?.("prepare");
        const directUpload = await prepareUploadTrack({
          variables: {
            filename: file.name,
            size: file.size,
            mediaType: file.type,
          },
        });
        const preparedTime = performance.now();

        await cloudUpload(
          directUpload.prepareUploadTrack.signedRequest,
          file,
          (p) => onProgress?.("upload", p / 2)
        );

        const uploadedTime = performance.now();

        const importId = await subscribeToImportJob(
          {
            jobId: directUpload.prepareUploadTrack.importJobId,
            processJob: true,
          },
          (p) =>
            onProgress?.("process", isNotNullish(p) ? 0.5 + p / 2 : undefined)
        );

        const processedTime = performance.now();

        // Almost done!
        onProgress?.("process", 0.99);
        const resultImport = await apolloClient.query<
          GetImport,
          GetImportVariables
        >({
          query: GET_IMPORT_WITH_LICENSE,
          variables: {
            id: importId,
          },
        });

        const finishedTime = performance.now();
        const uploadTrack = resultImport.data.import;

        // Include some stats on the track
        const duration = uploadTrack
          ? new Date(uploadTrack.latestPoint).getTime() -
          new Date(uploadTrack.earliestPoint).getTime()
          : undefined;
        const age = uploadTrack?.earliestPoint
          ? Date.now() - new Date(uploadTrack.earliestPoint).getTime()
          : undefined;
        uploadProcessedEvent({
          elapsedTime: finishedTime - startTime,
          uploadTime: uploadedTime - preparedTime,
          processingTime: processedTime - uploadedTime,
          downloadTime: finishedTime - processedTime,
          ...fileInfo,
          trackCountPoints: uploadTrack?.countPoints,
          trackDuration: duration,
          trackAge: age,
          trackHasBoatAssigned: uploadTrack ? !!uploadTrack?.boat : undefined,
        });

        return uploadTrack;
      } catch (error) {
        uploadErrorEvent({
          elapsedTime: performance.now() - startTime,
          ...fileInfo,
          ...processError(error as Error),
        });
        throw error;
      }
    },
    [
      apolloClient,
      cloudUpload,
      prepareUploadTrack,
      subscribeToImportJob,
      uploadErrorEvent,
      uploadProcessedEvent,
    ]
  );
};

export const formatFileInfoForAnalytics = (file: File) => {
  return {
    fileSize: file.size,
    fileExtension: file.name.split(".").pop(),
    fileAge: file.lastModified > 0 ? Date.now() - file.lastModified : undefined,
    fileLastModified: file.lastModified > 0 ? file.lastModified : undefined,
    fileName: file.name,
  };
};

import { gql, useQuery } from "@apollo/client";
import { GetImport, GetImportVariables } from "../../graphql/GetImport";
import { FRAGMENT_IMPORT, FRAGMENT_IMPORT_LICENSE } from "./fragmentImport";

export const GET_IMPORT_WITH_LICENSE = gql`
  query GetImport($id: ID!) {
    import(id: $id) {
      ...ImportInfo
      ...ImportLicense
      countPoints
    }
  }
  ${FRAGMENT_IMPORT}
  ${FRAGMENT_IMPORT_LICENSE}
`;

export const useImportWithLicense = (id: string) => {
  return useQuery<GetImport, GetImportVariables>(GET_IMPORT_WITH_LICENSE, {
    variables: {
      id,
    },
  });
};

import { toRadians } from "@chartedsails/sailing-math";
import { RaceSegment } from "~/model/RaceSegment";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { turf } from "~/util/turf-in-webapp";
import { findGateCenter } from "../utils/find-gate-center";
import { findMarkOrFail } from "../utils/find-mark-or-fail";

export const prepareLegInfo = (race: RaceSegment, legIndex: number) => {
  const startGate = race.raceConfig.gates[legIndex];
  const endGate = race.raceConfig.gates[legIndex + 1];
  const startCenter = findGateCenter(race, startGate);
  const endCenter = findGateCenter(race, endGate);

  const finishPoints = [
    coordinatesToLonLat(findMarkOrFail(race, endGate.markId)),
  ];
  if (endGate.secondMarkId) {
    finishPoints.push(
      coordinatesToLonLat(findMarkOrFail(race, endGate.secondMarkId))
    );
  }

  return {
    startCenter,
    endCenter,
    distance: turf.distance(turf.point(startCenter), turf.point(endCenter), {
      units: "meters",
    }),
    bearing: toRadians(
      turf.bearingToAzimuth(
        turf.bearing(turf.point(startCenter), turf.point(endCenter))
      )
    ),
    finishPoints,
  };
};

import { gql } from "@apollo/client";
import {
  SendContactMessage,
  SendContactMessageVariables,
} from "~/backend/graphql/SendContactMessage";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";

const SEND_CONTACT_MESSAGE = gql`
  mutation SendContactMessage(
    $email: String!
    $message: String!
    $context: String!
  ) {
    sendContactMessage(email: $email, message: $message, context: $context)
  }
`;

export const useContactMessage = () => {
  return useMutationWithErrorHandling<
    SendContactMessage,
    SendContactMessageVariables
  >(SEND_CONTACT_MESSAGE);
};

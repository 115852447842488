import React, { ReactNode, useContext, useMemo } from "react";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { AnalyticsContext } from "./AnalyticsContext";

/**
 * Refer to https://docs.google.com/spreadsheets/d/1e-x667K8SVXXGDNegzfKxgILdIFThpY0oGK_4-DOx8o/edit#gid=1309585840 for all event and props.
 */
interface IProps {
  children: ReactNode;
}

export const AnalyticsProps = ({
  children,
  ...props
}: IProps & AnalyticsProperties) => {
  const parentProps = useContext(AnalyticsContext);

  const value = useMemo(() => {
    return { ...parentProps, ...props };
  }, [parentProps, props]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

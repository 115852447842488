import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import LoadingButton from "~/components/buttons/LoadingButton";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";
import {
  DayPassOfferOption,
  SubscriptionOfferOption,
} from "../subscription/LicenseOption";
import { TrackSummary } from "../upload2/TrackSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3), // instead of 4
    paddingTop: theme.spacing(2),
  },
  trackSummary: {
    marginBottom: theme.spacing(4),
  },
  licensingOptions: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "stretch",
    "& > div": {
      flexBasis: "33%",
    },
    "& > div:not(:first-of-type)": {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "& > div:not(:first-of-type)": {
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
  },
  actions: {
    paddingRight: theme.spacing(3), // instead of 2
  },
}));

type LicenseOption = "daypass" | "monthly" | "annual";

interface IProps {
  className?: string;
  track: ImportInfoWithLicense;
  error?: string;
  onLicenseTypeSelected: (type: LicenseOption) => Promise<void>;
  onBack: () => void;
}

export const SelectLicensingOption = ({
  className,
  track,
  error,
  onLicenseTypeSelected,
  onBack,
}: IProps) => {
  const classes = useStyles();
  const [selected, updateSelected] = useState<LicenseOption | null>(null);

  const handleContinue = useCallback(() => {
    if (selected !== null) {
      return onLicenseTypeSelected(selected);
    }
    return Promise.resolve();
  }, [onLicenseTypeSelected, selected]);

  const offers = track.licensingOptions;

  return (
    <>
      <CSDialogTitle
        title="Select one of our plans"
        subtitle={offers?.prompt ?? undefined}
      />
      <CSDialogContent className={classes.root}>
        <TrackSummary className={classes.trackSummary} track={track} />
        <div className={classes.licensingOptions}>
          {offers ? (
            <>
              <DayPassOfferOption
                selected={selected === "daypass"}
                onClick={() => updateSelected("daypass")}
                offer={offers.dayByDayOption}
              />
              <SubscriptionOfferOption
                selected={selected === "monthly"}
                onClick={() => updateSelected("monthly")}
                offer={offers.monthlyOffer}
              />
              <SubscriptionOfferOption
                selected={selected === "annual"}
                onClick={() => updateSelected("annual")}
                offer={offers.annualOffer}
              />
            </>
          ) : (
            <p>Please contact us for help.</p>
          )}
        </div>
        {error && (
          <Typography align="center" color="error">
            {error}
          </Typography>
        )}
      </CSDialogContent>
      <CSDialogActions>
        <Button variant="contained" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={selected === null}
          onTriggerAction={handleContinue}
        >
          Continue
        </LoadingButton>
      </CSDialogActions>
    </>
  );
};

declare global {
  namespace jest {
    interface Expect {
      toBeCloseTo(expected: number): CustomMatcherResult;
    }
    interface Matchers<R> {
      toBeCloseTo(expected: number): CustomMatcherResult;
    }
  }
}

export function toBeCloseTo(actual: number, expected: number) {
  const pass = Math.abs(actual - expected) < 0.1;
  const message = pass
    ? () => `expected ${actual} NOT to be close to ${expected}`
    : () => `expected ${actual} to be close to ${expected}`;
  return { message, pass };
}

// Expect is only defined when the tests are running
if (typeof expect !== 'undefined') {
  expect.extend({
    toBeCloseTo,
  });
}

import { gql, useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { analytics } from "~/backend/analytics/analytics";
import { forceDisconnectSubscriptionsClient } from "~/backend/graphql";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { Logout } from "../../graphql/Logout";

const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const useLogout = () => {
  const mutation = useMutationWithErrorHandling<Logout>(LOGOUT);
  const client = useApolloClient();

  // Make sure that every time we log off we also clear the cache completely
  return useCallback<typeof mutation>(
    (args) => {
      return mutation(args).then(async (x) => {
        // We want to clear AND re-fetch active queries so we still have some stuff to show on homepage.
        await client.resetStore();
        analytics.resetUser();
        // Force disconnect websocket so we are not still logged in with a different sessions
        forceDisconnectSubscriptionsClient();
        return x;
      });
    },
    [mutation, client]
  );
};

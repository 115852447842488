import { ExtraState, ViewState } from "react-map-gl";
import { ReplayMapPick } from "../map/useMapPickCallback";
import { Replay } from "./Replay";
import { replayMakeAllTracksFitView } from "./helpers/makeAllTracksFitView";
import { centerReplayOnBoat, updatePlaybackAnimation } from "./reducer-helpers";

export type ReplayMapAction =
  | { event: "map-hover-track"; pick?: ReplayMapPick }
  | { event: "map-click-track"; pick?: ReplayMapPick }
  | {
      event: "map-viewstate-change";
      viewState: ViewState;
      interactionState: ExtraState;
    }
  | { event: "map-click-boat"; boatId: string };

export const mapEventReducer = (
  replay: Replay,
  action: ReplayMapAction
): Replay => {
  switch (action.event) {
    case "map-click-boat":
      replay = {
        ...centerReplayOnBoat({ ...replay }, action.boatId),
        lockMapOnBoatId: action.boatId,
      };
      return replay;
    case "map-hover-track":
      if (action.pick && replay.activePane === "replay-chart") {
        return {
          ...replay,
          hover: {
            type: "map",
            ...action.pick,
          },
        };
      } else {
        return {
          ...replay,
          hover: undefined,
        };
      }
    case "map-click-track":
      if (action.pick) {
        const segment = replay.sailingInsights?.getSailingSegmentAt(
          action.pick.boatId,
          action.pick.time
        );
        const maneuver = segment?.type === "maneuver" ? segment : undefined;
        const boat = replay.boats.find((b) => b.id === action.pick?.boatId);

        let selectedManeuver = replay.selectedManeuver;
        if (boat && maneuver) {
          selectedManeuver = {
            maneuver,
            boatId: boat.id,
            boatName: boat.name,
            boatColor: boat.color,
          };
        }
        return centerReplayOnBoat(
          {
            ...updatePlaybackAnimation(
              { ...replay, hover: undefined },
              action.pick.time
            ),
            lockMapOnBoatId: action.pick.boatId,
            selectedManeuver: selectedManeuver,
          },
          action.pick.boatId
        );
      } else {
        return { ...replay, hover: undefined };
      }
    case "map-viewstate-change":
      // If user is panning, unlock the boat
      if (action.interactionState.isDragging) {
        return {
          ...replay,
          viewState: action.viewState,
          lockMapOnBoatId: undefined,
        };
      } else {
        if (
          replay.viewState.width === undefined ||
          replay.viewState.height === undefined
        ) {
          // If this is the first time we know what are the width/height of the screen then re-fit map to screen. This will animate to boats.
          return replayMakeAllTracksFitView(
            {
              ...replay,
              viewState: action.viewState,
            },
            replay.initialZoomToBoatsRequested ? 1500 : 0
          );
        } else {
          return {
            ...replay,
            viewState: action.viewState,
          };
        }
      }
  }
  return replay;
};

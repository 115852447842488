import { makeStyles } from "@material-ui/core";
import React from "react";
import { UserInfo } from "~/backend/graphql/UserInfo";
import { Avatar } from "./Avatar";
import { INavAction } from "./INavAction";
import { ProfileAction } from "./ProfileAction";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .MuiAvatar-root": {
      height: 74,
      width: 74,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    maxWidth: "80vw",
  },
  introText: {
    fontSize: 24,
    lineHeight: "33px",
    margin: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  document: {
    color: theme.palette.primary.main,
    width: "100%",
    fontSize: 24,
    backgroundColor: "#F8F8F8",
    borderRight: `3px solid ${theme.palette.primary.main}`,
    paddingRight: theme.spacing(3) - 3,
    textAlign: "right",
    marginBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  documentLink: {
    fontSize: 20,
    lineHeight: "20px",
    marginTop: theme.spacing(3),
    paddingRight: 0,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  link: {
    marginLeft: theme.spacing(3),
    fontSize: 20,
    lineHeight: "40px",
    marginBottom: theme.spacing(5),
  },
}));

interface IProps {
  user?: UserInfo;
  actions?: INavAction[];
  documentTitle?: string;
  documentActions?: INavAction[];
}

export const ProfileSidebar = ({
  user,
  actions,
  documentTitle,
  documentActions,
}: IProps) => {
  const classes = useStyles();
  const introText = user ? user.name : "Welcome!";
  actions = actions || [];
  documentActions = documentActions || [];

  let documentBlock;
  if (documentTitle) {
    documentBlock = (
      <div className={classes.document}>
        {documentTitle}
        {documentActions.map((a, idx) => (
          <ProfileAction className={classes.documentLink} {...a} key={idx} />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Avatar user={user} />
      <p className={classes.introText}>{introText}</p>
      {documentBlock}
      {actions.map((a, idx) => (
        <ProfileAction className={classes.link} {...a} key={idx} />
      ))}
    </div>
  );
};

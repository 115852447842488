import WebMercatorViewport from "viewport-mercator-project";
import { Replay } from "~/components/replay/replaycontext/Replay";
import { ChartedSailsViewport } from "./ChartedSailsViewport";

export const viewportFromViewState = (viewState: Replay["viewState"]) => {
  return new WebMercatorViewport({
    // Pass default width/height because viewState could not have them yet.
    width: 1000,
    height: 1000,
    ...viewState,
  }) as ChartedSailsViewport;
};

import { gql, useQuery } from "@apollo/client";
import { GetKnownBoats } from "../graphql/GetKnownBoats";
import { FRAGMENT_BOAT } from "./boat/fragmentBoat";

export const GET_KNOWN_BOATS = gql`
  query GetKnownBoats {
    me {
      id
      boats {
        ...Boat
      }
    }
  }
  ${FRAGMENT_BOAT}
`;

export const useKnownBoats = () => {
  return useQuery<GetKnownBoats>(GET_KNOWN_BOATS);
};

import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import {
  applyEditToSession,
  SailingSessionEdit,
} from "~/model/SailingSessionEdit";
import { EditSession, EditSessionVariables } from "../../graphql/EditSession";
import { SessionInfo } from "../../graphql/SessionInfo";
import { FRAGMENT_SESSION } from "./fragmentSession";

const EDIT_SESSION = gql`
  mutation EditSession($session: EditSessionInput!) {
    editSession(session: $session) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

const useMutateSession = (session: SessionInfo) => {
  const [mutateSession] = useMutation<EditSession, EditSessionVariables>(
    EDIT_SESSION
  );
  // We return a function that can do some processing before calling the mutation.

  const smartMutation: (
    sailingSessionEdit: SailingSessionEdit
  ) => Promise<FetchResult<EditSession>> = useCallback(
    (editSession) => {
      const options: MutationFunctionOptions<
        EditSession,
        EditSessionVariables
      > = {};

      options.optimisticResponse = {
        editSession: applyEditToSession(session, editSession),
      };

      options.variables = { session: { id: session.id, ...editSession } };

      return mutateSession(options);
    },
    [session, mutateSession]
  );
  return smartMutation;
};

export default useMutateSession;

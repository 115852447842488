import { setContext } from "@apollo/client/link/context";

let sessionCreated = false;

/**
 * This link is intended to be concat'd with the WebSocketLink.
 *
 * It will make sure that the user has a session by calling a specific POST
 * endpoint before letting the websocket connection establish.
 *
 * We use the `setContext` link as a base here because it is an easy way to
 * make an async link.
 */
export const withServerSessionLink = (serverBaseUri: string) =>
  setContext((request) => {
    if (sessionCreated) {
      return {};
    } else {
      return new Promise((resolve, reject) => {
        fetch(`${serverBaseUri}/s`, { method: "POST", credentials: "include" })
          .then((r) => {
            sessionCreated = true;
            resolve({});
          })
          .catch((e) => reject(e));
      });
    }
  });

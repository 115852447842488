import { interpolateNavigationData } from "../interactive/interpolate-navigation-data";
import { NavigationData } from "../types/InteractiveTrip";
import { findRowWithTime } from "./findRowWithTime";
import { rowToNavData } from "./rowToNavData";
import { SailingDataArray } from "./types";

/**
 * Return a set of interpolated NavigationData given a track and a time.
 *
 * @param discrete
 * @param time
 * @param param2
 */
export const interpolateValues = (
  sailingArrays: SailingDataArray,
  time: number
): NavigationData | null => {
  const index = findRowWithTime(sailingArrays.time, time);

  if (index === null) {
    return null;
  }

  const navA = rowToNavData(sailingArrays, index);
  let navData = navA;
  const tA = sailingArrays["time"][index];

  if (tA !== time) {
    const tB = sailingArrays["time"][index + 1];
    const navB = rowToNavData(sailingArrays, index + 1);
    navData = interpolateNavigationData(navData, navB, (time - tA) / (tB - tA));
  }

  return navData;
};

import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import "./FullPageSpinner.css";

export default function FullPageSpinner({ p }: { p?: number }) {
  return (
    <div className="fullPageSpinner">
      <CircularProgress
        variant={p === undefined ? "indeterminate" : "determinate"}
        value={p !== undefined ? p * 100 : undefined}
      />
    </div>
  );
}

import { useCallback } from "react";
import { useImportDirectUpload } from "~/backend/data-hooks/imports/useImportDirectUpload";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { processApolloError } from "~/backend/utils/processApolloError";

export type FileAcceptedEvent =
  | { event: "upload-start"; file: File }
  | { event: "upload-uploading"; file: File; progress: number }
  | { event: "upload-processing"; file: File; progress?: number }
  | { event: "upload-complete"; info: ImportInfoWithLicense }
  | { event: "error"; file: File; message: string };

interface IProps {
  handleEvent: (e: FileAcceptedEvent) => void;
}

export const useFileAcceptedCallback = ({ handleEvent }: IProps) => {
  let doUpload = useImportDirectUpload();

  return useCallback(
    (files: File[]) => {
      const file = files[0];

      const progressCallback = (
        step: "prepare" | "upload" | "process",
        progress?: number
      ) => {
        switch (step) {
          case "prepare":
            handleEvent({ event: "upload-uploading", file, progress: 0 });
            break;
          case "upload":
            handleEvent({
              event: "upload-uploading",
              file,
              progress: progress ?? 0,
            });
            break;

          case "process":
            handleEvent({ event: "upload-processing", file, progress });
        }
      };
      handleEvent({ event: "upload-start", file: files[0] });
      doUpload(files[0], progressCallback)
        .then((uploadTrack) => {
          handleEvent({
            event: "upload-complete",
            info: uploadTrack,
          });
        })
        .catch((e) =>
          handleEvent({
            event: "error",
            file,
            message: processApolloError(e).simplifiedError,
          })
        );
    },
    [handleEvent, doUpload]
  );
};

import { gql } from "@apollo/client";
import {
  UpdatePaymentMethod,
  UpdatePaymentMethodVariables,
} from "~/backend/graphql/UpdatePaymentMethod";
import { useMutationWithErrorHandling } from "../../utils/useMutationWithErrorHandling";
import { GET_BILLING_INFO } from "./useBillingInfo";

const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updatePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;

export const useUpdatePaymentMethod = () => {
  return useMutationWithErrorHandling<
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD, {
    refetchQueries: [{ query: GET_BILLING_INFO }],
    awaitRefetchQueries: true,
  });
};

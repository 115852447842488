export type Quaternion = [number, number, number, number]; // [w,x,y,z]
export type EulerAngles = [number, number, number]; // [roll, pitch, yaw]

export const convertQuaternionToEulerAngles = (q: Quaternion) => {
  const a: EulerAngles = [0, 0, 0];

  // https://en.wikipedia.org/wiki/Conversion_between_quaternions_and_Euler_angles#Quaternion_to_Euler_angles_conversion

  // roll (x-axis rotation)
  // double sinr_cosp = 2 * (q.w * q.x + q.y * q.z);
  // double cosr_cosp = 1 - 2 * (q.x * q.x + q.y * q.y);
  // angles.roll = std::atan2(sinr_cosp, cosr_cosp);
  const sinr_cosp = 2 * (q[0] * q[1] + q[2] * q[3]);
  const cosr_cosp = 1 - 2 * (q[1] * q[1] + q[2] * q[2]);
  a[0] = Math.atan2(sinr_cosp, cosr_cosp);

  // pitch (y-axis rotation)
  // double sinp = 2 * (q.w * q.y - q.z * q.x);
  // if (std::abs(sinp) >= 1)
  //     angles.pitch = std::copysign(M_PI / 2, sinp); // use 90 degrees if out of range
  // else
  //     angles.pitch = std::asin(sinp);
  const sinp = 2 * (q[0] * q[2] - q[3] * q[1]);
  if (Math.abs(sinp) >= 1) {
    a[1] = (Math.sign(sinp) * Math.PI) / 2;
  } else {
    a[1] = Math.asin(sinp);
  }

  // yaw (z-axis rotation)
  // double siny_cosp = 2 * (q.w * q.z + q.x * q.y);
  // double cosy_cosp = 1 - 2 * (q.y * q.y + q.z * q.z);
  // angles.yaw = std::atan2(siny_cosp, cosy_cosp);
  const siny_cosp = 2 * (q[0] * q[3] + q[1] * q[2]);
  const cosy_cosp = 1 - 2 * (q[2] * q[2] + q[3] * q[3]);

  a[2] = Math.atan2(siny_cosp, cosy_cosp);

  return a;
};

export interface TurnTestCase {
  label: string;
  session: string;
  boat: string;
  bounds: [number, number];
  turns: Array<{
    t: number;
    start: number;
    end: number;
    startTolerance: number;
    endTolerance: number;
  }>;
}

export const turnTestCases: TurnTestCase[] = [
  {
    label: "upwind tack",
    session: "extreme32-20200205",
    boat: "Fuzz.Extreme2",
    bounds: [1580937143223, 1580937383739],
    turns: [
      {
        t: 1580937274534,
        start: 1580937263341,
        end: 1580937299157,
        startTolerance: 3000,
        endTolerance: 3000,
      },
    ],
  },
  {
    label: "two 90deg turn",
    session: "nood2019-j111-race5",
    boat: "Slush Fund",
    bounds: [1556982338326, 1556982585416],
    turns: [
      {
        // This one is really hard. Turn is too tight then gets back on track.
        t: 1556982359082,
        start: 1556982351500,
        // after realigning:
        // end: 1556982381875,
        // before realigning:
        end: 1556982372625,
        startTolerance: 2000,
        endTolerance: 3000,
      },
      {
        t: 1556982513006,
        start: 1556982504000,
        end: 1556982531500,
        startTolerance: 2000,
        endTolerance: 2000,
      },
    ],
  },
  {
    label: "turn too far",
    session: "nood2019-j111-race5",
    boat: "Bravo",
    bounds: [1556982338011, 1556982399719],
    turns: [
      {
        t: 1556982364368,
        start: 1556982360310,
        end: 1556982375295,
        startTolerance: 3000,
        endTolerance: 2000,
      },
    ],
  },
  {
    label: "zigzag",
    session: "nood2019-j111-race5",
    boat: "Spaceman Spiff",
    bounds: [1556982319048, 1556982410000],
    turns: [
      {
        t: 1556982351028,
        start: 1556982346500,
        startTolerance: 1000,
        end: 1556982364875,
        endTolerance: 1000,
      },
      {
        t: 1556982374000,
        start: 1556982370938,
        startTolerance: 1000,
        end: 1556982386375,
        endTolerance: 5000,
      },
    ],
  },
  {
    label: "gybe",
    session: "extreme32-20200205",
    boat: "Fuzz.Extreme2",
    bounds: [1580928413459, 1580928782526],
    turns: [
      {
        t: 1580928536160,
        start: 1580928532000,
        startTolerance: 2000,
        end: 1580928555750,
        endTolerance: 2000,
      },
      {
        t: 1580928642499,
        start: 1580928638436,
        end: 1580928661375,
        startTolerance: 2000,
        endTolerance: 2000,
      },
    ],
  },
  {
    label: "tack",
    session: "phrf",
    boat: "Snickers",
    bounds: [1211756870993, 1211757159272],
    turns: [
      {
        t: 1211757054000,
        start: 1211757053626,
        end: 1211757088936,
        startTolerance: 2000,
        endTolerance: 4000,
      },
    ],
  },
  {
    // Fuzz thought these tacks were well identified. Let's not break it.
    label: "M32 tacks 1",
    session: "extreme32-20200205",
    boat: "Fuzz.Extreme2",
    bounds: [1580934631656, 1580935205981],
    turns: [
      {
        t: 1580934781000,
        start: 1580934761000,
        // fuzz liked: end: 1580934795500, but we reach entry speed earlier than that
        end: 1580934790625,
        startTolerance: 1000,
        endTolerance: 1000,
      },
      {
        t: 1580934859500,
        start: 1580934836500,
        end: 1580934877000,
        startTolerance: 1500,
        endTolerance: 5001, // extended when I implemented range on interactiveFromDiscrete. This changed the result here.
      },
      {
        t: 1580935024187.5,
        start: 1580934996500,
        end: 1580935046875,
        startTolerance: 1500,
        endTolerance: 5000, // extended for experimental tracks and gql - both choking on it :/
      },
    ],
  },
  {
    // Fuzz thought these gybes were well identified. Let's not break it.
    label: "M32 gybes 1",
    session: "extreme32-20200205",
    boat: "Fuzz.Extreme2",
    bounds: [1580935385963, 1580936106537],
    turns: [
      // On this one we could have stopped earlier too
      {
        t: 1580935452250,
        start: 1580935435000,
        end: 1580935469500,
        startTolerance: 1500,
        endTolerance: 1500,
      },
      {
        t: 1580935623187.5,
        start: 1580935606500,
        end: 1580935639875,
        startTolerance: 1500,
        endTolerance: 8000, // extended for gql version - choked on it
      },
      {
        t: 1580935807812.5,
        start: 1580935780000,
        end: 1580935830375,
        // 2021.11 - This one is clearly not working well, especially not that we are using turf for math calculation.
        // Problem seems to be that there is a small piece of turn before the real turn. It gets merged into the previous line instead of
        // being merged into the turn. This causes the entry speed to be lower than it should be and the exit to be earlier than it should be
        startTolerance: 20000, // FIXME - very high
        endTolerance: 20000, // FIXME - very high
      },
      {
        t: 1580936025187.5,
        start: 1580936002500,
        end: 1580936039375,
        // fuzz liked end: 1580936047875, but we reach entry speed earlier than this
        startTolerance: 1500,
        endTolerance: 1500,
      },
    ],
  },
  {
    // There was a bug here because the TP52 data we used had magnetic VMG so it looked like the VMG was == to SOG.
    label: "Tack with no VMG gain",
    session: "tp52-capetown",
    boat: "09",
    bounds: [1583405171515, 1583405436067],
    turns: [
      {
        t: 1583405343187.5,
        start: 1583405331000,
        end: 1583405351000,
        startTolerance: 1500,
        endTolerance: 1500,
      },
    ],
  },
  {
    label: "TP52 tacks too long",
    session: "tp52-capetown",
    boat: "09",
    bounds: [1583405913756, 1583406863258],

    turns: [
      {
        t: 1583406037562.5,
        start: 1583406027500,
        // Should stop when SOG out == SOG in
        end: 1583406044197,
        startTolerance: 1500,
        endTolerance: 1500,
      },
      {
        t: 1583406363187.5,
        start: 1583406351500,
        end: 1583406370798,
        startTolerance: 1501,
        endTolerance: 1500,
      },
      {
        t: 1583406509187.5,
        start: 1583406497500,
        end: 1583406518482,
        startTolerance: 1500,
        endTolerance: 1500,
      },
      {
        t: 1583406587062.5,
        start: 1583406575500,
        end: 1583406597232,
        startTolerance: 1500,
        endTolerance: 1500,
      },
      {
        t: 1583406822562.5,
        start: 1583406812500,
        end: 1583406831125,
        startTolerance: 2501,
        endTolerance: 1500,
      },
    ],
  },
  {
    label: "TP52 gybe too long",
    session: "tp52-capetown",
    boat: "09",
    bounds: [1583405427473, 1583405911082],
    turns: [
      {
        t: 1583405719250,
        start: 1583405695000,
        end: 1583405742375,
        startTolerance: 1500,
        endTolerance: 5500,
      },
    ],
  },
  {
    label: "F15 Tacks too long",
    session: "20200607-holthus-menard-f15",
    boat: "Holthus-Menard",
    bounds: [1591555688441, 1591556021018],

    turns: [
      {
        t: 1591555718437,
        start: 1591555710000,
        end: 1591555726875,
        startTolerance: 1500,
        endTolerance: 1500,
      },
      {
        t: 1591555816062,
        start: 1591555810000,
        end: 1591555822125,
        startTolerance: 1500,
        endTolerance: 4500,
      },
      {
        t: 1591555886562,
        start: 1591555880500,
        end: 1591555892625,
        startTolerance: 1500,
        endTolerance: 2500,
      },
      {
        t: 1591555945125,
        start: 1591555933500,
        end: 1591555956750,
        startTolerance: 1500,
        endTolerance: 5000,
      },
    ],
  },
];

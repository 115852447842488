import { gql, useQuery } from "@apollo/client";
import { GetBoatClasses } from "~/backend/graphql/GetBoatClasses";
import { FRAGMENT_BOAT_CLASS } from "./fragmentBoatClass";

export const GET_BOAT_CLASSES = gql`
  query GetBoatClasses {
    boatClasses {
      ...BoatClass
    }
  }
  ${FRAGMENT_BOAT_CLASS}
`;

export const useBoatClasses = () => {
  return useQuery<GetBoatClasses>(GET_BOAT_CLASSES);
};

import { Position } from "@chartedsails/sailing-data";
import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import CoordinateField from "~/components/coordinatefield/CoordinateField";
import { formatAngle } from "~/util/formatAngle";
import { formatDistance } from "~/util/formatDistance";
import { csturf } from "~/util/turf-in-webapp";
import { CSDialog2, CSDialogProps } from "../csdialog/CSDialog2";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";

interface IProps extends CSDialogProps {
  onSave: (position: Position) => void;
  initialPosition: Position;
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const CoordinatesDialog = ({
  open,
  onSave,
  initialPosition,
  ...props
}: IProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<Position>([0, 0]);

  useEffect(() => {
    if (open) {
      setValue(initialPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const changeData = useMemo(() => {
    return {
      distance: csturf.distance(initialPosition, value),
      bearing: csturf.azimuth(initialPosition, value),
    };
  }, [initialPosition, value]);

  return (
    <CSDialog2 {...props} open={open}>
      <CSDialogTitle title="Edit mark position" />
      <CSDialogContent className={classes.content}>
        <CoordinateField
          variant="outlined"
          type="latitude"
          value={value[1]}
          onChange={(v) => setValue((p) => [p[0], v ?? p[1]])}
        />
        <CoordinateField
          variant="outlined"
          type="longitude"
          value={value[0]}
          onChange={(v) => setValue((p) => [v ?? p[0], p[1]])}
        />
        {changeData.distance > 0 && (
          <Typography variant="body2">
            The mark will move {formatDistance(changeData.distance)}m to{" "}
            {formatAngle(changeData.bearing)}º.
          </Typography>
        )}
      </CSDialogContent>
      <CSDialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => onSave(value)}
        >
          SAVE
        </Button>
      </CSDialogActions>
    </CSDialog2>
  );
};

import { makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import { useBoatClasses } from "~/backend/data-hooks/boat/useBoatClasses";
import { BoatClass } from "~/backend/graphql/BoatClass";
import { processApolloError } from "~/backend/utils/processApolloError";
import { AddBoatClassDialog } from "../dialogs/boatclass/AddBoatClassDialog";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 20px)",
    },
  },
}));

interface IProps {
  className?: string;
  margin?: TextFieldProps["margin"];
  value: BoatClass | null;
  onChange: (boatClass: BoatClass | null) => void;
}

const filter = createFilterOptions<BoatClass>();

const AddNewBoatClassId = "add-boat-class";

export const BoatClassSelector = ({
  className,
  margin,
  value,
  onChange,
}: IProps) => {
  const classes = useStyles();
  const boatClassesQuery = useBoatClasses();

  const [addOpen, updateAddOpen] = useState(false);
  const [boatClassInputValue, updateBoatClassInputValue] = useState("");

  if (boatClassesQuery.loading) {
    return (
      <TextField
        className={clsx(classes.root, className)}
        variant="outlined"
        margin={margin}
        label="Loading..."
        disabled
      />
    );
  }
  if (boatClassesQuery.error) {
    const errorText = processApolloError(
      boatClassesQuery.error
    ).simplifiedError;
    return (
      <TextField
        className={clsx(classes.root, className)}
        variant="outlined"
        margin={margin}
        label="Error"
        helperText={errorText}
        error
        disabled
      />
    );
  }

  const boatClasses = boatClassesQuery.data?.boatClasses || [];

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(_e, value, reason) => {
          if (value?.id === AddNewBoatClassId) {
            updateAddOpen(true);
          } else {
            onChange(value);
          }
        }}
        options={boatClasses}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== "") {
            updateBoatClassInputValue(params.inputValue);
            filtered.push({
              id: AddNewBoatClassId,
              name: `Add "${params.inputValue}"`,
              boatLength: 0,
            });
          } else {
            filtered.push({
              id: AddNewBoatClassId,
              name: `Add new boat class`,
              boatLength: 0,
            });
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={clsx(classes.root, className)}
            label="Boat Class"
            margin={margin}
            variant="outlined"
          />
        )}
      />
      <AddBoatClassDialog
        open={addOpen}
        onClose={(bc: BoatClass | undefined) => {
          updateAddOpen(false);
          onChange(bc ?? null);
        }}
        initialValue={boatClassInputValue}
      />
    </>
  );
};

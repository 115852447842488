import { Position } from "@chartedsails/sailing-data";
import { toDegrees } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { WebMercatorViewport } from "react-map-gl";
import { ChartedSailsViewport } from "./ChartedSailsViewport";

// We need one point to get an idea of scale for lat/lon. We could do
// something much more complicated (find the center of all the tracks) but for
// small tracks like we have this is enough.
const findApproximateCenterOfTrips = (trips: InteractiveTrip[]): Position => {
  let p: Position | undefined;
  let i = 0;
  while (
    (p === undefined || p[0] === null || p[1] === null) &&
    i < trips[0].length
  ) {
    p = trips[0].lonlat(i);
  }
  if (!p) {
    throw new Error(`Sailing data does not have any usable position.`);
  }
  return p;
};

export const viewportFromTripsAndWind = (
  trips: InteractiveTrip[],
  trueWindDirection: number
) => {
  if (trips.length === 0) {
    throw new Error("No sailing data to find bounds.");
  }

  const projectionCenter = findApproximateCenterOfTrips(trips);
  if (
    projectionCenter[0] < -180 ||
    projectionCenter[0] > 180 ||
    projectionCenter[1] < -90 ||
    projectionCenter[1] > 90
  ) {
    throw new Error(
      `Sailing data has invalid position: ${JSON.stringify(projectionCenter)}`
    );
  }

  return new WebMercatorViewport({
    width: 1000,
    height: 1000,
    latitude: projectionCenter[1],
    longitude: projectionCenter[0],
    bearing: toDegrees(trueWindDirection),
  }) as ChartedSailsViewport;
};

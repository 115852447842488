import {
  TrackPoint,
  TrackPointFieldsDescription,
} from "@chartedsails/sailing-data";
import {
  Float32,
  Float64,
  makeBuilder,
  Table,
  TimestampMillisecond,
  Vector,
} from "apache-arrow";
import {
  SailingArrowSchema,
  SailingArrowSchemaType,
} from "./SailingArrowSchema";

export const tableFromTrackpoints = (points: TrackPoint[]) => {
  let timesBuilder = makeBuilder({
    type: new TimestampMillisecond(),
  });
  const longitudeBuilder = makeBuilder({
    type: new Float64(),
    nullValues: [NaN, undefined, null],
  });
  const latitudeBuilder = makeBuilder({
    type: new Float64(),
    nullValues: [NaN, undefined, null],
  });
  points.forEach((p) => {
    timesBuilder = timesBuilder.append(p.time);
    longitudeBuilder.append(p.position?.[0]);
    latitudeBuilder.append(p.position?.[1]);
  });

  const columns: Array<keyof SailingArrowSchemaType> = [
    "time",
    "longitude",
    "latitude",
  ];
  const data: { [k: string]: Vector } = {
    time: timesBuilder.finish().toVector(),
    longitude: longitudeBuilder.finish().toVector(),
    latitude: latitudeBuilder.finish().toVector(),
  };

  for (const key of Object.keys(TrackPointFieldsDescription) as Array<
    keyof TrackPoint
  >) {
    // If we have not already processed this column and at least one point is defined then let's add it
    if (
      !["time", "longitude", "latitude"].includes(key) &&
      points.some((p) => p[key] !== undefined && p[key] !== null)
    ) {
      let builder = makeBuilder({
        type: new Float32(),
        nullValues: [NaN, undefined, null],
      });
      points.forEach((p) => builder.append(p[key] as number));
      builder = builder.finish();
      columns.push(key as keyof SailingArrowSchemaType);
      data[key] = builder.toVector();
    }
  }

  let table = new Table(SailingArrowSchema.select(columns));
  for (const key of columns) {
    table = table.setChild(key, data[key]);
  }
  return table;
};

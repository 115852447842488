import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useMe } from "~/backend/data-hooks/user/useMe";
import FullPageSpinner from "~/components/util/FullPageSpinner";
import { isPrerendering } from "./NoPrerenderRoute";

export const LoggedInRoute: FC<RouteProps> = (props) => {
  const { data, loading } = useMe();

  if (isPrerendering()) {
    return <div>Open this page in your browser.</div>;
  }

  if (loading) {
    return <FullPageSpinner />;
  }

  if (data && data.me) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

import { Position } from "@chartedsails/sailing-data";
import { InteractiveTrip } from "@chartedsails/tracks";
import { pdistance } from "~/util/turf-in-webapp";

export const maxDistance = (
  trip: InteractiveTrip,
  origin: Position,
  startTime: number,
  minimumMaxDuration: number
) => {
  let maxIndex = trip.indexFromTime(startTime);
  if (maxIndex === null) {
    throw new Error(`startTime not in trip`);
  }
  let max = pdistance(origin, trip.lonlat(maxIndex));

  let index = maxIndex;

  while (index < trip.length) {
    const d = pdistance(origin, trip.lonlat(index));
    // Distance is increasing - Take note of the new max
    if (d > max) {
      max = d;
      maxIndex = index;
    } else {
      // Distance is not increasing - if it has stopped increasing for more than minMax then stop on our max
      if (trip.time(index) - trip.time(maxIndex) > minimumMaxDuration) {
        break;
      }
    }
    index++;
  }

  return trip.time(maxIndex);
};

import { InteractiveTrip } from "@chartedsails/tracks";
import { SailingMarkType } from "../../../backend/graphql/globalTypes";
import { SailingMark } from "../../../backend/graphql/SailingMark";
import { ReplaySailingMark } from "./Replay";

export const createReplaySailingMarks = (
  marks: SailingMark[],
  trips?: Map<string, InteractiveTrip>
): ReplaySailingMark[] => {
  return marks.map<ReplaySailingMark>((mark) => ({
    mark,
    trip:
      mark.id && mark.type === SailingMarkType.TRACKED
        ? trips?.get(mark.id)
        : undefined,
  }));
};

import { Theme, Tooltip, withStyles } from "@material-ui/core";
import { fontLato } from "../../../styles/chartedSailsTheme";

export const MapTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    ...fontLato,
    fontSize: 12,
    lineHeight: "14px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

/*
0. test title
1. session
2. boat
3. time span to look at
4. number of expected tacks (straight segments)
*/
export const breakTripTestCases: Array<
  [string, string, string, [number, number], number, number]
> = [
  [
    "phrf straight line",
    "phrf",
    "Morning Missile",
    [1211757775282, 1211758375528],
    1,
    0,
  ],
  [
    "straight - curve - straight",
    "phrf",
    "Morning Missile",
    [1211757277528, 1211758379241],
    2,
    1,
  ],

  ["curve", "phrf", "Morning Missile", [1211757702465, 1211757752381], 2, 1],
  [
    "curve followed by straight line",
    "phrf",
    "Morning Missile",
    [1211758406661, 1211758958791],
    2,
    1,
  ],
  [
    "extreme 32 upwind tacks",
    "extreme32-20200205",
    "Fuzz.Extreme2",
    [1580934505712, 1580935344062],
    9,
    8,
  ],
  [
    "extreme 32 upwind tacks 2",
    "extreme32-20200205",
    "Fuzz.Extreme2",
    [1580937138586, 1580937713953],
    6,
    5,
  ],
  [
    "charles upwind",
    "syc-saturday",
    "unplugged",
    [1565460609256, 1565463862149],
    6,
    5,
  ],
  [
    "charles upwind - 90deg bend",
    "syc-saturday",
    "unplugged",
    [1565462539000, 1565463862000],
    2,
    1,
  ],
  [
    "180 turn",
    "syc-saturday",
    "unplugged",
    [1565460449500, 1565460744364],
    2,
    1,
  ],
  [
    "NOOD2019 Wide turn",
    "nood2019-j111-race5",
    "Slush Fund",
    [1556982338326, 1556982585416],
    3,
    2,
  ],
  [
    "NOOD2019 Wide turn2",
    "nood2019-j111-race5",
    "Bravo",
    [1556982338011, 1556982399719],
    2,
    1,
  ],
  [
    "Zigzag 1",
    "nood2019-j111-race5",
    "Spaceman Spiff",
    [1556982319048, 1556982780000],
    3,
    3,
  ],
  [
    // One second more in this test. But we now get the same result (was not always the case).
    "Zigzag 1b",
    "nood2019-j111-race5",
    "Spaceman Spiff",
    [1556982319048, 1556982779000],
    3,
    3,
  ],
  // Fuzz thought this tack was well identified. Let's not break it.
  [
    "M32 tacks 1",
    "extreme32-20200205",
    "Fuzz.Extreme2",
    [1580934631656, 1580935205981],
    7,
    6,
  ],
];

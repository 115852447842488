/**
 * Make sure every element in the list is unique.
 * Returns element in the order in which they were first seen.
 *
 * We could make this faster by requesting that the list be sorted (or sorting
 * it ourselves) - or by using a Set but then we would lose ordering.
 * @param list
 * @returns
 */
export function uniq<T>(list: Array<T>, equals = (a: T, b: T) => a === b) {
  return list.reduce((uniques, item) => {
    if (uniques.findIndex((v) => equals!(v, item)) !== -1) {
      return uniques;
    } else {
      uniques.push(item);
      return uniques;
    }
  }, new Array<T>());
}

import destination from "@turf/destination";
import { point } from "@turf/helpers";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";

export const convertMetricDistanceToPixelsWithViewport = (
  distance: number,
  viewport: ChartedSailsViewport
): number | null => {
  // The viewport in mapContext sometimes does not have latitude/longitude (when animating for example).
  if (viewport.latitude === undefined || viewport.longitude === undefined) {
    return null;
  }

  const p0 = point([viewport.longitude, viewport.latitude]);

  // Now we project our distance towards the right so we have a stable measurement
  // eg: boat will have same size pointing up or right
  const p1 = destination(p0, distance, viewport.bearing + 90, {
    units: "meters",
  });

  const pix0 = viewport.project(p0.geometry.coordinates);
  const pix1 = viewport.project(p1.geometry.coordinates);

  const pixDistance = Math.abs(pix1[0] - pix0[0]);

  return pixDistance;
};

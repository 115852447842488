import { getDistance } from "geolib";
import React, { useCallback } from "react";
import { SVGOverlay } from "react-map-gl";
import { findMarkOrFail } from "~/algo/race/utils/find-mark-or-fail";
import { isGateLine } from "~/algo/race/utils/gate-types";
import { RaceGate } from "~/backend/graphql/RaceGate";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { GuntimeMapMarker } from "~/components/replay/marks/GuntimeMapMarker";
import { defaultBoatLength } from "~/components/replay/movingboat/MovingBoat";
import { ReplayBoat } from "~/components/replay/replaycontext/Replay";
import { isNotNullish } from "~/components/util/isNotNullish";
import { SVGLayerRedrawOptions } from "~/components/util/SVGRedrawOptions";
import { RaceSegment } from "~/model/RaceSegment";
import { buoy, carbon } from "~/styles/chartedsailsColors";
import { formatDistance } from "~/util/formatDistance";
import { uniq } from "~/util/uniq";
import { MarkZone } from "./MarkZone";
import { RaceLineSVG } from "./RaceLineSVG";
import { RaceMark } from "./RaceMark";

interface IProps {
  editableGate?: RaceGate;
  race: RaceSegment;
  onMarkChange?: (mark: SailingMark) => void;
  boats?: ReplayBoat[];
}

/*  IMPLEMENTATION NOTES

It would be very hard to render everything in SVG and still have elements be draggable independently:
 - the SVG layer has one captureDrag property but we would have to always set it to true if we want to capture drags.
 - svg elements (<g>, etc) do not expose onDragStart event and we would have to implement dragging with onMouseDown/onMouseUp

So instead, we use Marker (which is provided and supports dragging) for all the movable elements, and we also use an SVG layer to add things 
like lines between marks, etc.
*/
export const RaceOverlay = ({
  race,
  onMarkChange,
  editableGate,
  boats,
}: IProps) => {
  // Render everything that cannot be rendered as part of the marker so lines between marks for example

  let raceBoatLength = defaultBoatLength;
  const boatLengths = boats
    ?.map((b) => b.boatClass?.boatLength)
    .filter(isNotNullish);
  if (boatLengths && boatLengths.length > 0) {
    raceBoatLength = Math.max(...boatLengths);
  }

  const handleRedraw = useCallback(
    (redrawContext: SVGLayerRedrawOptions) => {
      return (
        <>
          {race.raceConfig.gates.map((gate, index) => {
            // We can have multiple gates with the same mark/secondMark and we
            // want to draw them all the same way, even if they are not really
            // the 'active' one
            const editable =
              editableGate !== undefined &&
              gate.markId === editableGate.markId &&
              gate.secondMarkId === editableGate.secondMarkId;
            // We show them active when they are editable or when nothing is editable.
            const active = editable || editableGate === undefined;
            if (isGateLine(gate)) {
              const markA = findMarkOrFail(race.raceConfig, gate.markId);
              const markB = findMarkOrFail(race.raceConfig, gate.secondMarkId);
              const distanceMeters = getDistance(markA, markB);
              return (
                <RaceLineSVG
                  key={index}
                  redrawContext={redrawContext}
                  markA={markA}
                  markB={markB}
                  lineText={
                    editable ? `${formatDistance(distanceMeters)}m` : undefined
                  }
                  color={active ? buoy[900] : carbon[400]}
                />
              );
            }
            return null;
          })}

          {
            // Get the list of all the marks that are used, make sure we have each only once and then draw the 3 BL circle around them.
            uniq(
              race.raceConfig.gates.reduce((markList, gate) => {
                markList.push(gate.markId);
                if (gate.secondMarkId) {
                  markList.push(gate.secondMarkId);
                }
                return markList;
              }, new Array<string>())
            ).map((markId) => {
              const mark = findMarkOrFail(race, markId);

              return (
                <MarkZone
                  key={markId}
                  mark={mark}
                  boatLength={raceBoatLength}
                  redrawContext={redrawContext}
                />
              );
            })
          }
        </>
      );
    },
    [editableGate, race, raceBoatLength]
  );

  return (
    <>
      <SVGOverlay redraw={handleRedraw} />
      {race.raceConfig.marks.map((mark) => {
        const editable =
          editableGate !== undefined &&
          (mark.id === editableGate.markId ||
            mark.id === editableGate.secondMarkId);

        return (
          <RaceMark
            mark={mark}
            onMarkChange={onMarkChange}
            editable={editableGate !== undefined ? editable : undefined}
            key={mark.id}
          />
        );
      })}
      {boats?.map((b) => (
        <GuntimeMapMarker
          time={race.raceConfig.gunTime}
          boatId={b.id}
          key={b.id}
        />
      )) ?? null}
    </>
  );
};

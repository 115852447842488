import { CssBaseline, useMediaQuery } from "@material-ui/core";
import { createTheme, MuiThemeProvider, Theme } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import chroma from "chroma-js";
import React from "react";
import "./chartedsails.css";
import { carbon, seaway } from "./chartedsailsColors";

export const csBlue = "#176CDF"; // used to be "#137CE6"
export const csBlueLight = "#2F80ED";
export const csOrange = chroma([251, 122, 0]).hex();
export const csRed = chroma([230, 42, 16]).hex();
export const textDefaultColor = "#252525";
export const textColorGrey = "#757575";
export const csBackgroundGrey1 = "#EEF5F5";
export const csButtonGrey = "#E0E0E0";

// These fonts are imported in chartedsails.css
export const fontPoppins = {
  fontFamily: "'Poppins', sans-serif",
};

export const fontLato = {
  fontFamily: "'Lato', sans-serif",
  fontStyle: "normal",
};

export const chartedSailsTheme = createTheme({
  palette: {
    primary: { main: csBlue, light: "#2F80ED" },
    secondary: { main: csOrange, contrastText: "#fff" },
    text: {
      primary: textDefaultColor,
      secondary: textColorGrey,
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440, // default was 1280
      xl: 1920,
    },
  },
  // See the typography storybook for more explaination.
  typography: {
    ...fontPoppins,
    fontSize: 20,
    h1: {
      fontSize: 72,
      lineHeight: "72px",
    },
    h2: {
      fontWeight: "bold",
      fontSize: 38,
    },
    h3: {
      fontWeight: "bold",
      fontSize: 30,
      textTransform: "uppercase",
    },
    h4: {
      fontWeight: "bold",
      fontSize: 24,
    },
    // Title same size as body1 text
    h5: {
      fontWeight: "bold",
      fontSize: 20,
    },
    // Title same size as body2 text
    h6: {
      fontWeight: "bold",
      fontSize: 16,
    },
    button: {
      fontSize: 18,
      fontWeight: "bold",
    },
    body1: {
      // 20px
      fontSize: 20,
    },
    body2: {
      // 16px
      fontSize: 16,
    },
    // Used on the links in the footer
    caption: {
      fontWeight: "bold",
      fontSize: 16,
      textTransform: "uppercase",
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#fff",
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: "red",
        color: "white",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        color: textDefaultColor,
      },
      outlinedPrimary: {
        border: `3px solid ${csBlue}`,
        padding: `3px 15px`,
        "&:hover": {
          border: `3px solid ${csBlue}`,
        },
      },
      sizeLarge: {
        fontSize: 18,
      },
    },
    MuiChip: {
      root: {
        height: 26,
        backgroundColor: carbon[800],
        border: `1px solid ${carbon[900]}`,
        borderRadius: 4,
        textTransform: "uppercase",
        ...fontLato,
        fontWeight: 900,
        fontSize: 12,
        lineHeight: "26px",
        color: "white",
      },
      label: {
        paddingLeft: 8,
        paddingRight: 8,
      },
      colorPrimary: {
        borderColor: seaway[900],
        backgroundColor: seaway[600],
      },
    },
    MuiTableRow: {
      root: {
        "&$hover": {
          backgroundColor: chroma(csBlue).alpha(0.2).css(),
          "&:hover": {
            backgroundColor: chroma(csBlue).alpha(0.2).css(),
          },
        },
        "&$selected": {
          backgroundColor: `${csBlue} !important`,
          "& .MuiTableCell-body": {
            color: "white",
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
        lineHeight: "18px",
      },
      head: {
        lineHeight: "18px",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.8em",
        textTransform: "unset",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 18,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 18,
      },
    },
    MuiInputLabel: {
      outlined: {
        // default is translate 14,20 but that does not center the text with our default font.
        transform: "translate(14px, 21px) scale(1)",
      },
      filled: {
        // default is translate 12,20 but that does not center the text with our default font.
        transform: "translate(12px, 24px) scale(1)",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 18,
      },
    },
  },
});

chartedSailsTheme.typography.h1 = {
  ...chartedSailsTheme.typography.h1,
  [chartedSailsTheme.breakpoints.down("sm")]: {
    fontSize: 42,
    lineHeight: "42px",
  },
};

export const useAppTheme = () => {
  return useTheme<Theme>();
};

export const useResponsiveSmall = () =>
  useMediaQuery(chartedSailsTheme.breakpoints.down("sm"));

export const trackHighlightColor = chroma(csBlue).rgba();
trackHighlightColor[3] = 255;

export const trackColorsScheme = chroma
  .scale([
    "#EA06AA", // Pink (remains the same)
    "#46B96E", // Fresh Green (remains the same)
    "#4D22A8", // Purple (remains the same)
    "#EB5757", // Red (remains the same)
    "#0D5066", // Dark Blue (remains the same)
    "#74039C", // Dark Purple (remains the same)
    "#F4C430", // Golden Yellow (remains the same)
    "#CE0B3A", // Dark Red (remains the same)
    "#5B0E1D", // Wine Red (remains the same)
    "#C421FE", // Magenta (remains the same)
    "#AF8B1C", // Brown (remains the same)
    "#7200A8", // Deep Violet (remains the same)
    "#324fd4", // Blue (remains the same)
    "#6DCB40", // Bright Green (remains the same)
    "#e0d100", // Gold (remains the same)
    "#D32F2F", // Fire Red (remains the same)
    "#8B0000", // Dark Crimson (replacing Deep Crimson)
    "#ff7f00", // Orange (remains the same)
    "#1f78b4", // Sky Blue (remains the same)
    "#2D7F2C", // Forest Green (remains the same)
    "#F4A261", // Warm Peach (remains the same)
    "#cab2d6", // Lavender (remains the same)
    "#6a3d9a", // Indigo (remains the same)
    "#b15928", // Dark Brown (remains the same)
  ])
  .mode("lab")
  .colors(25)
  .map((color) => chroma(color).saturate(1.5).hex());

export const speedGradientColorScheme = [
  "#a100c7",
  "#324fd4",
  "#48c91f",
  "#e0d100",
  "#ff1700",
  "#790000",
];

export const shadowOverReplayMap = "1px 3px 2px rgba(0,0,0,0.25)";

export const CSThemeDecorator: (story: any) => any = (story) => (
  <React.Fragment>
    <MuiThemeProvider theme={chartedSailsTheme}>
      <CssBaseline />
      {story()}
    </MuiThemeProvider>
  </React.Fragment>
);

export const productName = "ChartedSails";
export const companyName = "ChartedSails";
export const productDomain = "chartedsails.com";
export const supportEmail = "ahoy@chartedsails.com";
export const twitterHandle = "@ChartedSails";
export const facebookAppId = "194726488087085";

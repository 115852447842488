import { detectEnvironment } from "~/util/detect-environment";
import { log } from "~/util/devconsole";

type PerfMark = { name: string; t: ReturnType<typeof performance.now> };

export class PerfTimer {
  constructor(
    private name = "undefined",
    private marks = new Array<PerfMark>({ name: "t0", t: performance.now() })
  ) {}

  public mark(name: string) {
    this.marks.push({ name, t: performance.now() });
    log(`⏰  PERF[${this.name}] ${name}`);
  }

  public log() {
    const marks = this.marks;
    const lastMark = marks[marks.length - 1];
    const firstMark = marks[0];
    const total = lastMark.t - firstMark.t;
    const marksWithDeltas = marks.map((m, i) => {
      const prev = marks[i - 1];
      const delta = prev ? m.t - prev.t : m.t - firstMark.t;
      return { mark: m.name, delta };
    });
    log(`PERF: ${this.name} took ${total}ms`);
    if (detectEnvironment() !== "prod") {
      console.table(marksWithDeltas.slice(1));
    }
  }
}

import {
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useIsResetPasswordTokenValid } from "~/backend/data-hooks/user/useIsResetPasswordTokenValid";
import { processApolloError } from "~/backend/utils/processApolloError";
import { PasswordForgottenForm } from "~/components/dialogs/login/PasswordForgottenForm";
import CSPage from "~/components/util/CSPage";
import { SEO } from "~/components/util/SEO";
import { useQuery } from "~/components/util/useQuery";
import { PasswordForm } from "./PasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  loader: {
    marginTop: theme.spacing(8),
  },
  passwordForm: {
    marginTop: theme.spacing(4),
  },
}));

export const ResetPasswordPage = () => {
  const classes = useStyles();

  const token = useQuery().get("token") as string | null;

  const queryTokenValidity = useIsResetPasswordTokenValid(token);
  const invalidTokenError = queryTokenValidity.error
    ? processApolloError(queryTokenValidity.error).simplifiedError
    : queryTokenValidity.data?.isResetPasswordTokenValid?.error;
  const tokenValid =
    queryTokenValidity.data?.isResetPasswordTokenValid?.isValid || false;

  const history = useHistory();
  const handleChangeComplete = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <CSPage>
      <SEO title="Reset Password" />
      <Container maxWidth="sm">
        <div className={classes.root}>
          {queryTokenValidity.loading ? (
            <CircularProgress className={classes.loader} />
          ) : (
            <>
              {token !== null && tokenValid ? (
                <>
                  <Typography variant="h3">Reset Your Password</Typography>
                  <Typography align="center">
                    Choose a new password for your ChartedSails account.
                  </Typography>
                  <PasswordForm
                    className={classes.passwordForm}
                    token={token}
                    onPasswordChangeComplete={handleChangeComplete}
                  />
                </>
              ) : (
                <>
                  <Typography variant="h3">Reset Your Password</Typography>
                  {invalidTokenError ?? (
                    <Typography color="error">{invalidTokenError}</Typography>
                  )}
                  <PasswordForgottenForm onComplete={handleChangeComplete} />
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </CSPage>
  );
};

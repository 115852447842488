// I am sure this is a well known CS-problem with much smarter solutions but this is what I came up with!
export function combinations<T>(
  pickFromElements: Array<T>,
  pickCount: number
): Array<Array<T>> {
  if (pickCount === 0) {
    return [];
  } else if (pickCount === 1) {
    return pickFromElements.map((x) => [x]);
  } else if (pickCount === pickFromElements.length) {
    return [pickFromElements];
  } else if (pickCount > pickFromElements.length || pickCount < 0) {
    throw new Error(
      `Can't pick ${pickCount} elements from a list with ${pickFromElements.length} elements.`
    );
  } else {
    // (pickCount > 1) && pickCount < pickFromElements.length
    const result = new Array<Array<T>>();
    for (let i = 0; i < pickFromElements.length; i++) {
      const e = pickFromElements[i];

      const otherElements = pickFromElements.slice(i + 1);
      const smallerCombinations = combinations(otherElements, pickCount - 1);

      smallerCombinations.forEach((c) => {
        result.push([e, ...c]);
      });
    }
    return result;
  }
}

type IInterval = [number, number] | { startTime: number; endTime: number };

const normalizeInterval = (i: IInterval): [number, number] => {
  if (Array.isArray(i)) {
    return i;
  } else {
    return [i.startTime, i.endTime];
  }
};

export const isIntervalOverlapping = (
  interval: IInterval,
  otherInterval: IInterval
) => {
  interval = normalizeInterval(interval);
  otherInterval = normalizeInterval(otherInterval);

  if (
    (interval[0] > otherInterval[0] && interval[0] < otherInterval[1]) ||
    (interval[1] > otherInterval[0] && interval[1] < otherInterval[1]) ||
    (interval[0] < otherInterval[0] && interval[1] > otherInterval[1])
  ) {
    return true;
  } else {
    return false;
  }
};

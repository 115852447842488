import { Button, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { usePasswordForgotten } from "~/backend/data-hooks/user/usePasswordForgotten";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";

const initialFormValues = {
  email: "",
};

const passwordForgottenSchema = yup.object({
  email: yup
    .string()
    .required("The email address you used to create your account.")
    .email("This is not a valid email address."),
});

interface IProps {
  prefillEmail?: string;
  onComplete?: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiLink-root": {
      textTransform: "none",
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  errorMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export const PasswordForgottenForm = ({ onComplete, prefillEmail }: IProps) => {
  const passwordForgottenEvent = useAnalyticEvent(
    "passwordforgotten-requested"
  );

  const classes = useStyles();

  const passwordForgotten = usePasswordForgotten();
  const [sent, updateSent] = useState(false);

  const handlePasswordForgotten = useCallback<
    FormikConfig<typeof initialFormValues>["onSubmit"]
  >(
    (values, actions) => {
      actions.setStatus();

      passwordForgotten({ variables: { email: values.email } })
        .then(() => {
          passwordForgottenEvent();
          updateSent(true);
        })
        .catch((e) => {
          passwordForgottenEvent({
            error: processApolloError(e).simplifiedError,
          });
          actions.setStatus(processApolloError(e).simplifiedError);
          actions.setSubmitting(false);
        });
    },
    [passwordForgotten, passwordForgottenEvent]
  );

  return (
    <Formik
      initialValues={{ email: prefillEmail || "" }}
      onSubmit={handlePasswordForgotten}
      validationSchema={passwordForgottenSchema}
    >
      {({ isSubmitting, handleSubmit, status, setStatus }) => (
        <form className={classes.form} onChange={() => setStatus()}>
          <Typography
            color="error"
            align="center"
            variant="body2"
            paragraph
            className={classes.errorMessage}
          >
            {status || " "}
          </Typography>
          <TextFormField
            label="Email address"
            name="email"
            variant="outlined"
            disabled={sent}
          />
          {sent ? (
            <>
              <Typography>We have sent you instructions by email.</Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onComplete}
              >
                OK
              </Button>
            </>
          ) : (
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isSubmitting}
              type="submit"
              size="large"
              onClick={handleSubmit as any}
            >
              Recover Password
            </LoadingButton>
          )}
        </form>
      )}
    </Formik>
  );
};

import { useCallback, useContext } from "react";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { analytics } from "../../backend/analytics/analytics";
import { AnalyticsContext } from "./AnalyticsContext";

export const useAnalyticEvent = (
  eventName: string,
  eventProps?: AnalyticsProperties
) => {
  const contextProps = useContext(AnalyticsContext);

  return useCallback(
    ({
      eventName: customEventName,
      ...extraProps
    }: AnalyticsProperties = {}) => {
      const thisEventName = customEventName ?? eventName;
      const props = { ...contextProps, ...eventProps, ...extraProps };
      analytics.event(thisEventName, props);
    },
    [contextProps, eventName, eventProps]
  );
};

import { Media } from "~/backend/graphql/Media"
import { VideoMedia, isVideoMedia } from "~/model/VideoMedia"

interface IProps {
  time: number
  media: Media[]
}

export const useActiveVideoMedia = ({ time, media }: IProps): VideoMedia[] => {
  if (!media) {
    return []
  }

  const videoMedia = media.filter(isVideoMedia)

  const availableTracks = videoMedia.filter(
    v =>
      v.videoMeta.playbackURL !== undefined &&
      time >= v.time &&
      time < v.time + v.videoMeta.duration
  )

  return availableTracks
}

import { gql } from "@apollo/client";
import { FRAGMENT_SAILINGMARK } from "../mark/fragmentSailingMark";

export const FRAGMENT_SEGMENT = gql`
  fragment RaceGate on RaceGate {
    type
    markId
    secondMarkId
  }
  fragment RaceConfig on RaceConfig {
    gunTime
    gates {
      ...RaceGate
    }
    marks {
      ...SailingMark
    }
  }
  fragment Segment on Segment {
    id
    title
    startTime
    endTime
    trueWindDirection
    raceConfig {
      ...RaceConfig
    }
  }
  ${FRAGMENT_SAILINGMARK}
`;

import { InteractiveTrip } from "@chartedsails/tracks";
import { useEffect, useMemo, useState } from "react";
import { SailingInsights } from "~/model/SailingInsights";

export const useSailingInsights = (
  trips: Map<string, InteractiveTrip>,
  {
    trueWindDirection,
    liveStreaming,
  }: {
    trueWindDirection?: number;
    liveStreaming?: boolean;
  } = {}
) => {
  const [insightsWithoutTWD, updateInsightsWithoutTWD] =
    useState<SailingInsights>(SailingInsights.analyzeSync(new Map()));

  // Ask the webworker to recalculate the insights every time the trips change.
  // Do not take TWD into consideration here because it can change often and the
  // most expensive part of the calculation is breaking the trip in segments.
  useEffect(() => {
    if (!liveStreaming) {
      SailingInsights.analyze(trips).then((i) => {
        updateInsightsWithoutTWD(i);
      });
    }
  }, [liveStreaming, trips]);

  // And recalculate the maneuver stats when the true wind direction changes
  const insightsWithTWD = useMemo(() => {
    if (trueWindDirection !== undefined) {
      return insightsWithoutTWD.applyTrueWindDirection(trueWindDirection);
    } else {
      return insightsWithoutTWD;
    }
  }, [insightsWithoutTWD, trueWindDirection]);
  return insightsWithTWD;
};

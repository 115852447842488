import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode, useRef, useState } from "react";
import { Marker } from "react-map-gl";
import { ReactComponent as MapPingMark } from "~/assets/icons/map-ping-mark.svg";
import { ReactComponent as MapRaceMark } from "~/assets/icons/map-race-mark.svg";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { useScalableMarkerStyle } from "~/components/replay/map/useScalableMarkerSize";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { MarkMenuTooltip } from "./MarkMenuTooltip";
import { RaceTooltip } from "./RaceTooltip";

interface IProps {
  className?: string;
  mark: SailingMark;
  /** By default (undefined), marks are orange and have no shadow. When editable is true we add the shadow and when it's false we remove the shadow and make them grey. */
  editable?: boolean;
  onMarkChange?: (m: SailingMark) => void;
  tooltip?: ReactNode;
}

const markSymbols: {
  [k: string]: {
    offsetX: number;
    offsetY: number;
    svg: React.FC<React.SVGProps<SVGSVGElement>>;
    cssClass?: string;
  };
} = {
  mark: {
    offsetX: -17,
    offsetY: -17,
    svg: MapRaceMark,
  },
  TRACKED: {
    offsetX: -17,
    offsetY: -17,
    svg: MapRaceMark,
  },
  PINGED: {
    offsetX: -17,
    offsetY: -17,
    svg: MapPingMark,
    cssClass: "pinged",
  },
};

const useStyles = makeStyles(() => ({
  root: {
    "&.pinged": {},
  },
  editableMarker: {
    zIndex: 5,
  },
  nonEditableMarker: {
    pointerEvents: "none",
    "& svg": {
      "& .mark-active": {
        opacity: 0,
      },
      opacity: 0.3,
    },
  },
}));

export const RaceMark = ({ mark, editable, onMarkChange, tooltip }: IProps) => {
  const classes = useStyles();
  const position = coordinatesToLonLat(mark);

  const markSymbol = markSymbols[mark.type ?? "mark"];
  const MarkSVG = markSymbol.svg;

  const svgStyle = useScalableMarkerStyle(2, 34, 16);
  const svgRef = useRef<SVGSVGElement>(null);
  let svgElement = (
    <MarkSVG
      className={clsx(classes.root, "marksvg", markSymbol.cssClass)}
      style={svgStyle}
      ref={svgRef}
    />
  );
  const [isDragging, setIsDragging] = useState(false);

  if (tooltip) {
    svgElement = <RaceTooltip title={tooltip}>{svgElement}</RaceTooltip>;
  }
  if (editable && !isDragging) {
    svgElement = (
      <MarkMenuTooltip mark={mark} onMarkEdited={onMarkChange}>
        {svgElement}
      </MarkMenuTooltip>
    );
  }

  return (
    <Marker
      latitude={position[1]}
      longitude={position[0]}
      offsetLeft={markSymbol.offsetX}
      offsetTop={markSymbol.offsetY}
      draggable={editable}
      className={
        editable === true
          ? classes.editableMarker
          : editable === false
          ? classes.nonEditableMarker
          : ""
      }
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      onDrag={(e) => {
        onMarkChange?.({
          ...mark,
          longitude: e.lngLat[0],
          latitude: e.lngLat[1],
        });
      }}
    >
      {svgElement}
    </Marker>
  );
};

import { Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { analytics } from "~/backend/analytics/analytics";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { CSDialog } from "../csdialog/CSDialog";
import { LoginForm } from "./LoginForm";
import { PasswordForgottenForm } from "./PasswordForgottenForm";
import { SignupForm } from "./SignupForm";

interface IProps {
  open: boolean;
  variant?: "login" | "signup" | "passwordforgotten";
  onDismiss?: (success: boolean) => void;
  eventProps?: AnalyticsProperties;
  knownEmailAddress?: string;
}

export const LoginFlowDialog = ({
  open,
  onDismiss,
  variant,
  eventProps: parentEventProps,
  knownEmailAddress,
}: IProps) => {
  variant = variant || "login";
  const [step, updateStep] = useState(variant);
  const [prefillEmail, updatePrefillEmail] = useState("");
  const isFirstStepInFlow = step === variant;
  const signupRef = useRef<{ step: string; error?: string }>({ step: "" });
  const loginRef = useRef<{ error?: string }>({});

  useEffect(() => {
    if (open) {
      analytics.event(`${variant}-open`, parentEventProps);
      updateStep(variant!);
      updatePrefillEmail(knownEmailAddress ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, variant, updateStep]);

  const handleBack = useCallback(() => {
    if (step === "login") {
      analytics.event(`login-incomplete`, {
        ...parentEventProps,
        error: loginRef.current.error,
      });
    } else if (step === "signup") {
      analytics.event(`signup-incomplete`, {
        ...parentEventProps,
        step: signupRef.current.step,
        error: signupRef.current.error,
      });
    } else if (step === "passwordforgotten") {
      analytics.event(`passwordforgotten-incomplete`, { ...parentEventProps });
    }
    updateStep(variant!);
  }, [updateStep, step, variant, parentEventProps]);

  const handleClose = useCallback(() => {
    const props: AnalyticsProperties = { ...parentEventProps };
    if (step === "login") {
      props.error = loginRef.current.error;
    } else if (step === "signup") {
      props.step = signupRef.current.step;
      props.error = signupRef.current.error;
    }
    analytics.event(`${step}-incomplete`, props);

    if (onDismiss) {
      onDismiss(false);
    }
  }, [onDismiss, step, loginRef, signupRef, parentEventProps]);

  const handleComplete = useCallback(() => {
    if (onDismiss) {
      onDismiss(true);
    }
  }, [onDismiss]);

  const handleGotoSignup = useCallback(
    (email: string) => {
      updateStep("signup");
      updatePrefillEmail(email);
      analytics.event(`signup-open`, { ...parentEventProps });
    },
    [updateStep, parentEventProps]
  );

  const handleGotoLogin = useCallback(
    (email: string) => {
      updateStep("login");
      updatePrefillEmail(email);
      analytics.event(`login-open`, { ...parentEventProps });
    },
    [updateStep, parentEventProps]
  );

  const handleGotoPasswordForgotten = useCallback(
    (email: string) => {
      updateStep("passwordforgotten");
      updatePrefillEmail(email);
      analytics.event(`passwordforgotten-open`, {
        ...parentEventProps,
      });
    },
    [updateStep, parentEventProps]
  );

  let dialogContent;
  switch (step) {
    case "login":
      dialogContent = (
        <LoginForm
          onComplete={handleComplete}
          onSignup={handleGotoSignup}
          onPasswordForgotten={handleGotoPasswordForgotten}
          statusRef={loginRef}
          prefillEmail={prefillEmail}
        />
      );
      break;
    case "signup":
      dialogContent = (
        <SignupForm
          onComplete={handleComplete}
          statusRef={signupRef}
          onLogin={handleGotoLogin}
          prefillEmail={prefillEmail}
        />
      );
      break;
    case "passwordforgotten":
      dialogContent = (
        <>
          <Typography variant="h4" align="center" paragraph>
            Forgot your password?
          </Typography>
          <PasswordForgottenForm
            onComplete={handleComplete}
            prefillEmail={prefillEmail}
          />
        </>
      );
      break;
    default:
      dialogContent = <div>step=&gt;{step}&lt;</div>;
  }

  return (
    <CSDialog
      open={open}
      onBack={handleBack}
      onClose={onDismiss ? handleClose : undefined}
      showBackArrow={!isFirstStepInFlow}
      maxWidth="sm"
      fullWidth
    >
      {dialogContent}
    </CSDialog>
  );
};

import { gql } from "@apollo/client";

export const FRAGMENT_USER_INFO = gql`
  fragment UserInfo on User {
    id
    name
    email
    subscription {
      plan
      isFreeTrial
    }
    isAdmin
    memberSince
    liveTrackingEnabled
    flags {
      mediacard
      garmin
      pro
      comments
    }
    showTutorialVideoHowtoSetupDebrief
    trackerOrgSlug
  }
`;

import gql from "graphql-tag"

export const FRAGMENT_MEDIA = gql`
  fragment Media on Media {
    id
    user {
      name
    }
    creationDate
    time
    timeOrigin
    boat {
      id
    }
    sourceMeta {
      filename
      mediaType
      size
      detectedTime
      detectedTimeSource
      adjustableTime
      sourceName
      s3url
      uploaded
    }
    videoMeta {
      duration
      playbackURL
    }
    comment {
      text
    }
    permissions {
      edit
      delete
    }
  }
`

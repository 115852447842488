import chroma from "chroma-js";
import { useMemo, useRef } from "react";
import { speedGradientColorScheme } from "~/styles/chartedSailsTheme";
import { ReplayBoat } from "../replaycontext/Replay";
import { trackColoredBySpeed } from "./track-colored-by-speed";
import { TripLayerColorSegment, TripLayerData } from "./TripLayer";

export const useMemoTripLayerData = (
  boats: ReplayBoat[],
  speedScaleMax: number,
  generateColorSegments?: (boat: ReplayBoat) => Array<TripLayerColorSegment>
) => {
  const speedGradient = useMemo(() => {
    return chroma.scale(speedGradientColorScheme).domain([0, speedScaleMax]);
  }, [speedScaleMax]);

  // Keep a cache of the data object we pass to DeckGL so DeckGL does not
  // re-render everything all the time.
  // We make our own cache here so that we only recalculate the minimum.
  const boatLayerDatas = useRef(
    new Map<
      string,
      TripLayerData & {
        speedGradientUsed: typeof speedGradient;
        colorGeneratorUsed: typeof generateColorSegments;
      }
    >()
  );
  boats.forEach((boat) => {
    // Recalculate the boat layer data, only when it changes or when the color gradient changed.
    // Providing a new TripLayerData object to deck gl will force it to recompute the layer completely
    // so try to do this as rarely as possible.
    if (
      boat.data &&
      (!boatLayerDatas.current.has(boat.id) ||
        boatLayerDatas.current.get(boat.id)?.trip !== boat.data ||
        boatLayerDatas.current.get(boat.id)?.speedGradientUsed !==
          speedGradient ||
        boatLayerDatas.current.get(boat.id)?.colorGeneratorUsed !==
          generateColorSegments)
    ) {
      boatLayerDatas.current.set(boat.id, {
        trip: boat.data,
        length: boat.data?.length - 1,
        speedColors: trackColoredBySpeed(
          boat.data,
          speedGradient,
          speedScaleMax
        ),
        speedGradientUsed: speedGradient,
        colorSegments: generateColorSegments ? generateColorSegments(boat) : [],
        colorGeneratorUsed: generateColorSegments,
      });
    }
  });
  // Remove boats that are not needed anymore
  Array.from(boatLayerDatas.current.keys())
    .filter((boatId) => boats.find((b) => b.id === boatId) === undefined)
    .forEach((boatId) => boatLayerDatas.current.delete(boatId));

  return boatLayerDatas.current;
};

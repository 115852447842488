import { gql } from "@apollo/client";

export const FRAGMENT_SESSION_EVENT = gql`
  fragment SessionEvent on SessionEvent {
    boatId
    trackEvent {
      ... on TrackEventRaceStart {
        event
        time
      }
      ... on TrackEventRaceEnd {
        event
        time
      }
      ... on TrackEventPingBoat {
        event
        time
        position
      }
      ... on TrackEventPingPin {
        event
        time
        position
      }
    }
  }
`;

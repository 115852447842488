import { useContext } from "react";
import { MapContext } from "react-map-gl";
import { convertMetricDistanceToPixelsWithViewport } from "~/algo/projections/convertMetricDistanceToPixels";
import { ChartedSailsViewport } from "./ChartedSailsViewport";

export const useScaledDistance = (meters: number) => {
  const context = useContext(MapContext);
  return convertMetricDistanceToPixelsWithViewport(
    meters,
    context.viewport as ChartedSailsViewport
  );
};

import { useEffect, useRef } from "react";

export const useIsMounted = () => {
  const isMounted = useRef<boolean>(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

import { NavigationData } from "../types/InteractiveTrip";
import { SailingDataArray } from "./types";

export const rowToNavData = (
  sailingArray: SailingDataArray,
  index: number
): NavigationData => {
  const navData: NavigationData = {
    time: sailingArray.time[index],
    latitude: sailingArray.latitude[index],
    longitude: sailingArray.longitude[index],
    position: [sailingArray.longitude[index], sailingArray.latitude[index]],
    sog: sailingArray.sog[index],
    cog: sailingArray.cog[index],
  };

  const otherFields = Object.keys(sailingArray).filter(
    (f) => Object.keys(navData).includes(f) === false
  );

  for (const k of otherFields as Array<
    Exclude<keyof NavigationData, "time" | "position">
  >) {
    navData[k] = sailingArray[k]![index];
  }
  return navData;
};

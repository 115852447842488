import { centerReplayOnBoat, updatePlaybackAnimation } from "./reducer-helpers";
import { Replay } from "./Replay";

export type ReplayLiveAction = { event: "live-updatetime"; time: number };

export const liveEventReducer = (replay: Replay, action: ReplayLiveAction) => {
  switch (action.event) {
    case "live-updatetime":
      if (replay.lockMapOnBoatId) {
        return updatePlaybackAnimation(
          centerReplayOnBoat(replay, replay.lockMapOnBoatId),
          action.time
        );
      } else {
        return updatePlaybackAnimation(replay, action.time);
      }
  }
  return replay;
};

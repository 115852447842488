import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { useCreateBoat } from "~/backend/data-hooks/boat/useCreateBoat";
import { useAssignBoatToImport } from "~/backend/data-hooks/imports/useAssignBoatToImport";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { useErrorHandler } from "~/backend/utils/useErrorHandler";
import { BoatClassSelector } from "~/components/boatclass/BoatClassSelector";
import LoadingButton from "~/components/buttons/LoadingButton";
import { BoatDialogProperties } from "../boat/BoatDialog";
import { CSDialogActions } from "../csdialog/CSDialogActions";
import { CSDialogContent } from "../csdialog/CSDialogContent";
import { CSDialogTitle } from "../csdialog/CSDialogTitle";
import { TrackTextSummary } from "./TrackTextSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      backgroundColor: "white",
      width: "100%",
      "&:not(:first-of-type)": {
        marginTop: theme.spacing(3),
      },
    },
    "& .MuiAutocomplete-root": {
      marginTop: theme.spacing(3),
    },
  },
  uploadInfo: {},
}));

interface IProps {
  className?: string;
  initialBoatName: string;
  track: ImportInfo;
  onBoatAssigned: (track: ImportInfoWithLicense) => void | Promise<void>;
  onBack: () => void;
}

export const AddBoatContent = ({
  className,
  initialBoatName,
  track,
  onBoatAssigned,
  onBack,
}: IProps) => {
  const classes = useStyles();
  const doCreateBoat = useCreateBoat();
  const doAssignBoat = useAssignBoatToImport();
  const [error, updateError] = useState<string>();

  const errorHandler = useErrorHandler(updateError, "upload-error");

  const [addBoat, updateAddBoat] = useState<BoatDialogProperties>({
    name: initialBoatName,
    sailNumber: null,
    boatClass: null,
  });

  const handleContinue = useCallback(async () => {
    updateError(undefined);
    try {
      const { createBoat: boat } = await doCreateBoat({
        variables: {
          name: addBoat.name,
          boatClassId: addBoat.boatClass?.id,
          sailNumber: addBoat.sailNumber,
        },
      });
      const { assignBoatToImport: trackWithBoat } = await doAssignBoat({
        variables: { importId: track.id, boatId: boat.id },
      });
      await onBoatAssigned(trackWithBoat);
    } catch (e) {
      errorHandler(e as Error);
    }
  }, [
    addBoat.boatClass?.id,
    addBoat.name,
    addBoat.sailNumber,
    doAssignBoat,
    doCreateBoat,
    errorHandler,
    onBoatAssigned,
    track.id,
  ]);

  return (
    <>
      <CSDialogTitle title="Add a new boat" />
      <CSDialogContent className={clsx(classes.root, className)}>
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          label="Boat Name"
          value={addBoat.name}
          onChange={(e) => updateAddBoat({ ...addBoat, name: e.target.value })}
        />
        <TrackTextSummary track={track} />
        <TextField
          variant="outlined"
          fullWidth
          label="Sail Number"
          placeholder="USA 123 or ITA456"
          value={addBoat.sailNumber ?? ""}
          onChange={(e) =>
            updateAddBoat({
              ...addBoat,
              sailNumber: e.target.value === "" ? null : e.target.value,
            })
          }
        />
        <BoatClassSelector
          value={addBoat.boatClass}
          onChange={(boatClass) => updateAddBoat({ ...addBoat, boatClass })}
        />
      </CSDialogContent>
      <CSDialogActions>
        <Button variant="contained" onClick={onBack}>
          Back
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={addBoat.name === ""}
          onTriggerAction={handleContinue}
        >
          Continue
        </LoadingButton>
      </CSDialogActions>
    </>
  );
};

import { Container, IconButton, makeStyles, Theme } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { ReactNode, useCallback, useState } from "react";
import { seaway } from "~/styles/chartedsailsColors";
import { fontLato, fontPoppins } from "~/styles/chartedSailsTheme";

const useStyles = makeStyles<
  Theme,
  { backgroundColor?: string; borderColor?: string }
>((theme) => ({
  root: {
    backgroundColor: "white",
    padding: 8,
    transition: theme.transitions.create(["height", "opacity", "padding"], {
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hidden: {
    height: 0,
    opacity: "0%",
    padding: 0,
  },
  container: ({ backgroundColor, borderColor }) => ({
    padding: "8px 16px",
    backgroundColor: backgroundColor ?? seaway[100],
    border: `1px solid ${borderColor ?? seaway[500]}`,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    ...fontLato,
    fontSize: 14,

    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  }),
  content: {
    "& .MuiButton-root": {
      ...fontPoppins,
      fontSize: 16,
    },
  },
}));

interface IProps {
  className?: string;
  leftAccessory?: ReactNode;
  children?: ReactNode;
  hidden?: boolean;

  backgroundColor?: string;
  borderColor?: string;
}

export const Banner = ({
  className,
  leftAccessory,
  children,
  backgroundColor,
  borderColor,
  hidden,
}: IProps) => {
  const classes = useStyles({ backgroundColor, borderColor });

  const [dismissed, setDismissed] = useState(true);

  const handleHide = useCallback(() => setDismissed(false), []);

  return (
    <div
      className={clsx(
        classes.root,
        className,
        (!dismissed || hidden) && classes.hidden
      )}
    >
      <Container className={classes.container}>
        {leftAccessory ?? <div />}
        <div className={classes.content}>{children}</div>
        <IconButton color="primary" size="small" onClick={handleHide}>
          <Close />
        </IconButton>
      </Container>
    </div>
  );
};

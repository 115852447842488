import React, { useCallback, useState } from "react";
import { useSubscriptionFlow } from "~/backend/data-hooks/subscription/useSubscriptionFlow";
import { SubscriptionOffer } from "~/backend/graphql/SubscriptionOffer";
import { AnalyticsProps } from "~/components/analytics/AnalyticsProps";
import { CSDialog2 } from "../csdialog/CSDialog2";
import { PaymentContent } from "./PaymentContent";

interface IProps {
  open: boolean;
  offer: SubscriptionOffer;
  onClose: () => void;
  onSuccess: () => void;
}

export const EnrollSubscriptionDialog = ({
  open,
  offer,
  onClose,
  onSuccess,
}: IProps) => {
  const [error, updateError] = useState<string>();

  const doEnrollSubscription = useSubscriptionFlow({
    onSuccess,
    onError: updateError,
  });
  const handleConfirm = useCallback(() => {
    return doEnrollSubscription(
      offer.plan,
      offer.priceUSD,
      !!offer.freeTrialDays
    );
  }, [doEnrollSubscription, offer.freeTrialDays, offer.plan, offer.priceUSD]);

  return (
    <AnalyticsProps plan={offer.plan} withFreeTrial={!!offer.freeTrialDays}>
      <CSDialog2 dialogName="enrollsubscription" open={open} onClose={onClose}>
        <PaymentContent
          offer={offer}
          error={error}
          onPaymentConfirmed={handleConfirm}
        />
      </CSDialog2>
    </AnalyticsProps>
  );
};

import { makeStyles } from "@material-ui/core";
import { SailingMarkType } from "~/backend/graphql/globalTypes";
import { RaceMark } from "~/components/race/racemark/RaceMark";
import { isNotNullish } from "~/components/util/isNotNullish";
import { formatTimeHHMMSS } from "~/util/timeFormat";
import { GuntimeMapMarker } from "../marks/GuntimeMapMarker";
import { SessionEventWithTypedTrackEvent } from "../replaycontext/Replay";

interface IProps {
  sessionEvents: SessionEventWithTypedTrackEvent[];
  visibleBoats: string[];
  visibleTimeBounds: [number, number];
}

const useStyles = makeStyles({
  pingTooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const SessionEventOverlay = ({
  sessionEvents,
  visibleBoats,
  visibleTimeBounds,
}: IProps) => {
  const classes = useStyles();

  const marks = sessionEvents
    .filter((e) => visibleBoats.indexOf(e.boatId) !== -1)
    .filter((e) => {
      return (
        e.trackEvent.time > visibleTimeBounds[0] &&
        e.trackEvent.time < visibleTimeBounds[1]
      );
    })
    .map((e) => {
      if (
        e.trackEvent.event === "ping-pin" ||
        e.trackEvent.event === "ping-boat"
      ) {
        return (
          <RaceMark
            key={`${e.boatId}-${e.trackEvent.time}`}
            mark={{
              id: null,
              latitude: e.trackEvent.position[1],
              longitude: e.trackEvent.position[0],
              type: SailingMarkType.PINGED,
            }}
            tooltip={
              <div className={classes.pingTooltip}>
                <span>
                  {e.trackEvent.event === "ping-boat" ? "BOAT" : "PIN"}
                </span>
                <span>{formatTimeHHMMSS(e.trackEvent.time)}</span>
              </div>
            }
          />
        );
      } else if (e.trackEvent.event === "race-start") {
        return (
          <GuntimeMapMarker
            key={`${e.boatId}-${e.trackEvent.time}`}
            boatId={e.boatId}
            time={e.trackEvent.time}
            tooltip={
              <div className={classes.pingTooltip}>
                <span>START</span>
                <span>{formatTimeHHMMSS(e.trackEvent.time)}</span>
              </div>
            }
          />
        );
      }
      return null;
    })
    .filter(isNotNullish);

  return <>{marks}</>;
};

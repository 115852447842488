import { RaceConfig } from "~/backend/graphql/RaceConfig";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { RaceSegment } from "~/model/RaceSegment";

export const findMarkOrFail = (
  o: RaceSegment | RaceConfig | SailingMark[],
  markId: string
) => {
  const marks = Array.isArray(o)
    ? o
    : "marks" in o
    ? o.marks
    : o.raceConfig.marks;

  const m = marks.find((m) => m.id === markId);
  if (!m) throw new Error(`Mark not found ${markId}`);
  return m;
};

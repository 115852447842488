import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

// Takes an ApolloError and tries to parse it in a way that is easier to use in the frontend.
// Each validation error will be mapped to a key with the name of the field and

export interface BackendError {
  generalError?: string;
  fieldErrors: { [fieldName: string]: string };
  simplifiedError: string;
}

export const processGraphQLErrors = (errors: readonly GraphQLError[]) => {
  const processedError: BackendError = { simplifiedError: "", fieldErrors: {} };

  errors.forEach((graphqlError) => {
    if (
      graphqlError.message === "Argument Validation Error" &&
      graphqlError.extensions &&
      graphqlError.extensions.exception &&
      (graphqlError.extensions.exception as any).validationErrors
    ) {
      const validationErrors = (graphqlError.extensions.exception as any)
        .validationErrors as Array<{
        property: string;
        constraints: { [k: string]: string };
      }>;

      validationErrors.forEach((validationError) => {
        const errorMessage = Object.keys(validationError.constraints)
          .map((constraint) => validationError.constraints[constraint])
          .join(", ");
        if (!processedError.fieldErrors) {
          processedError.fieldErrors = {};
        }
        processedError.fieldErrors[validationError.property] = errorMessage;
      });
    } else {
      processedError.generalError =
        (processedError.generalError ? processedError.generalError + " " : "") +
        graphqlError.message;
    }
  });

  if (
    processedError.fieldErrors === undefined &&
    processedError.generalError === undefined
  ) {
    processedError.generalError = "Unexpected server error.";
    processedError.simplifiedError = processedError.generalError;
  } else {
    if (processedError.generalError) {
      processedError.simplifiedError = processedError.generalError;
    } else {
      processedError.simplifiedError = Object.keys(processedError.fieldErrors)
        .map((field) => `${field}: ${processedError.fieldErrors[field]}`)
        .join(" ");
    }
  }
  return processedError;
};

export const processApolloError = (error: ApolloError): BackendError => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return processGraphQLErrors(error.graphQLErrors);
  }
  if (error.message) {
    return {
      simplifiedError: error.message,
      generalError: error.message,
      fieldErrors: {},
    };
  }

  return {
    generalError: "Unexpected server error.",
    simplifiedError: "Unexpected server error.",
    fieldErrors: {},
  };
};

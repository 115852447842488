import { gql } from "@apollo/client";
import {
  SessionForImport,
  SessionForImportVariables,
} from "~/backend/graphql/SessionForImport";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { GET_MY_SESSIONS } from "../user/useMySessions";
import { GET_MY_TRACKS } from "../user/useMyTracks";
import { FRAGMENT_SESSION } from "./fragmentSession";

const SESSION_FOR_IMPORT = gql`
  mutation SessionForImport($importId: ID!) {
    sessionForImport(importId: $importId) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

export const useSessionForImport = () => {
  return useMutationWithErrorHandling<
    SessionForImport,
    SessionForImportVariables
  >(SESSION_FOR_IMPORT, {
    refetchQueries: [{ query: GET_MY_SESSIONS }, { query: GET_MY_TRACKS }],
  });
};

/* From https://github.com/Turfjs/turf/blob/master/packages/turf-point-to-line-distance/index.ts 

Simplified to work directly with our types and make less function calls */

import { Position } from "@chartedsails/sailing-data";
import { distance } from "./distance";

/**
 * Returns the distance between a point P on a segment AB.
 *
 * @private
 * @param {Array<number>} p external point
 * @param {Array<number>} a first segment point
 * @param {Array<number>} b second segment point
 * @returns {number} distance
 */
export function distanceToSegment(p: Position, a: Position, b: Position) {
  const v = [b[0] - a[0], b[1] - a[1]];
  const w = [p[0] - a[0], p[1] - a[1]];

  const c1 = dot(w, v);
  if (c1 <= 0) {
    return distance(p, a);
  }
  const c2 = dot(v, v);
  if (c2 <= c1) {
    return distance(p, b);
  }
  const b2 = c1 / c2;
  const Pb = [a[0] + b2 * v[0], a[1] + b2 * v[1]] as Position;
  return distance(p, Pb);
}

function dot(u: number[], v: number[]) {
  return u[0] * v[0] + u[1] * v[1];
}

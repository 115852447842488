import { Position } from "@chartedsails/sailing-data";
import { RaceGate } from "~/backend/graphql/RaceGate";
import { RaceSegment } from "~/model/RaceSegment";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { turf } from "~/util/turf-in-webapp";
import { isGateLine } from "../utils/gate-types";
import { findMarkOrFail } from "./find-mark-or-fail";

/**
 * For a mark, returns the coordinates of the mark.
 * For a gate, returns the coordiantes of the middle point between the two marks.
 * @param raceSegment
 * @param gate
 * @returns
 */
export const findGateCenter = (
  raceSegment: RaceSegment,
  gate: RaceGate
): Position => {
  if (isGateLine(gate)) {
    const gateLine = turf.points([
      coordinatesToLonLat(findMarkOrFail(raceSegment.raceConfig, gate.markId)),
      coordinatesToLonLat(
        findMarkOrFail(raceSegment.raceConfig, gate.secondMarkId)
      ),
    ]);
    return turf.center(gateLine).geometry.coordinates as Position;
  } else {
    return coordinatesToLonLat(
      findMarkOrFail(raceSegment.raceConfig, gate.markId)
    );
  }
};

import { useEffect, useMemo } from "react";
import { useSession } from "~/backend/data-hooks/session/useSession";
import { processApolloError } from "~/backend/utils/processApolloError";
import { AnalyticsProps } from "~/components/analytics/AnalyticsProps";
import { MobileSessionReplay } from "~/components/mobilesessionreplay/MobileSessionReplay";
import { SEO } from "~/components/util/SEO";
import { formatDateAndTime } from "~/util/timeFormat";
import GQLSessionReplay from "../../components/sessionreplay/GQLSessionReplay";

const MobileDataLoadingComponent = () => {
  useEffect(() => {
    return () => {
      const webView = (window as any).ReactNativeWebView as
        | undefined
        | { postMessage: (data: string) => void };
      if (webView) {
        webView.postMessage("replay-loaded");
      }
    };
  }, []);
  return null;
};

const MobileSessionReplayPage = ({ sessionId }: { sessionId: string }) => {
  const retval = useSession(sessionId);
  const { data, loading, error } = retval;

  // session.boats can change in cache even when the content actually does not
  // change. To avoid this we cache the array and reuse it as long as the boats
  // do not change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const boatIds = data?.session?.boats.map((b) => b.id).join("-");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sessionBoats = useMemo(() => data?.session.boats ?? [], [boatIds]);

  const sessionWithStableBoats = useMemo(() => {
    return data?.session ? { ...data.session, boats: sessionBoats } : undefined;
  }, [data, sessionBoats]);

  if (error) {
    throw new Error(processApolloError(error).simplifiedError);
  }

  if (loading) {
    return null;
  } else if (sessionWithStableBoats) {
    return (
      <AnalyticsProps pageName="mobile-session">
        <SEO
          title={sessionWithStableBoats.title || "Sailing Session"}
          description={`Sailing replay recorded on ${formatDateAndTime(
            sessionWithStableBoats.startTime
          )}`}
          contentType="article"
          path={`/session/${sessionWithStableBoats.id}`}
        />
        <GQLSessionReplay
          session={sessionWithStableBoats}
          ReplayComponent={MobileSessionReplay}
          waitUntilDataLoaded
          DataLoadingComponent={MobileDataLoadingComponent}
        />
      </AnalyticsProps>
    );
  } else {
    // Note: This happens when the optimisticMutationResult is invalid (for
    // example missing some fields)
    throw new Error("Not loading, no error but no data???");
  }
};

export default MobileSessionReplayPage;

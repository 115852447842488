import {
  bissectAngle,
  shortAngleDist,
  toRadians,
} from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import {
  ManeuverSegment,
  ManeuverType,
  NauticalSide,
  SailingSegment,
} from "../types";
import { sailingSegmentCourse } from "../sailing-segments/sailing-segment";
import { bucketLoss } from "../bucket-loss/bucket-loss";

/** Takes a list of segments and converts all the turns in maneuver using the provided trueWindDirection.
 *
 */
export const convertAllTurnsInManeuver = (
  trip: InteractiveTrip,
  segments: SailingSegment[],
  trueWindDirection?: number
): SailingSegment[] => {
  return segments.map((s) => {
    if (s.type === "turn") {
      const maneuver = convertTurnInManeuver(
        trip,
        segments,
        s,
        trueWindDirection
      );
      return maneuver ?? s;
    }
    return s;
  });
};

/**
 * Takes a turn segments and converts it into a maneuver segment which has a
 * lot more information associated to it.
 *
 * @param trip
 * @param segments
 * @param maneuver
 * @param trueWindDirection
 */
export const convertTurnInManeuver = (
  trip: InteractiveTrip,
  segments: SailingSegment[],
  maneuver: SailingSegment,
  trueWindDirection?: number
): ManeuverSegment => {
  const index = segments.findIndex((s) => s === maneuver);
  const entryData = trip.getValuesAtTime(maneuver.interval[0]);
  const exitData = trip.getValuesAtTime(maneuver.interval[1]);
  if (!entryData || !exitData) {
    throw new Error("Invalid segment");
  }

  const entryCourse =
    index > 0 ? sailingSegmentCourse(trip, segments[index - 1]) : entryData.cog;
  const exitCourse =
    index < segments.length - 1
      ? sailingSegmentCourse(trip, segments[index + 1])
      : exitData.cog;
  const bissectingCourse = bissectAngle(entryCourse, exitCourse);

  let maneuverType: ManeuverType | undefined;
  let side: NauticalSide | undefined;

  let entryAngle: number | undefined = undefined;
  let exitAngle: number | undefined = undefined;
  if (trueWindDirection !== undefined) {
    entryAngle = shortAngleDist(trueWindDirection, entryCourse);
    exitAngle = shortAngleDist(trueWindDirection, exitCourse);
    [maneuverType, side] = identifyManeuverFromAngles(entryAngle, exitAngle);
  }

  return {
    ...maneuver,
    type: "maneuver",
    maneuverType,
    side,
    duration: maneuver.interval[1] - maneuver.interval[0],
    entryCourse,
    exitCourse,
    bissectingCourse,
    entrySpeed: entryData.sog,
    exitSpeed: exitData.sog,
    angle: shortAngleDist(entryCourse, exitCourse),
    entryAngle,
    exitAngle,
    distanceLost: bucketLoss({
      twd: bissectingCourse,
      trip,
      interval: maneuver.interval,
    }),
  };
};

export const identifyManeuverFromAngles = (
  entryAngle: number,
  exitAngle: number
): [ManeuverType | undefined, NauticalSide | undefined] => {
  let type: ManeuverType | undefined;
  let side: NauticalSide | undefined;

  if (
    entryAngle > 0 &&
    entryAngle < toRadians(90) &&
    exitAngle < 0 &&
    exitAngle > toRadians(-90)
  ) {
    side = "port";
    type = "tack";
  } else if (
    entryAngle < 0 &&
    entryAngle > toRadians(-90) &&
    exitAngle > 0 &&
    exitAngle < toRadians(90)
  ) {
    side = "starboard";
    type = "tack";
  } else if (
    entryAngle > toRadians(90) &&
    entryAngle < toRadians(180) &&
    exitAngle < toRadians(-90) &&
    exitAngle > toRadians(-180)
  ) {
    side = "starboard";
    type = "gybe";
  } else if (
    entryAngle < toRadians(-90) &&
    entryAngle > toRadians(-180) &&
    exitAngle > toRadians(90) &&
    exitAngle < toRadians(180)
  ) {
    side = "port";
    type = "gybe";
  }

  return [type, side];
};

import { useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback } from "react";
import {
  SubscribeUploadJob,
  SubscribeUploadJobVariables,
} from "~/backend/graphql/SubscribeUploadJob";

const SUBSCRIBE_UPLOAD_JOB = gql`
  subscription SubscribeUploadJob($jobId: ID!, $processJob: Boolean) {
    subscribeUploadJob(jobId: $jobId, processJob: $processJob) {
      id
      importId
      status
      error
      progress
    }
  }
`;

export const useSubscribeUploadJob = () => {
  const apolloClient = useApolloClient();
  return useCallback(
    (
      variables: SubscribeUploadJobVariables,
      onProgress: (p?: number) => void
    ) => {
      // Get the subscription ready while the upload starts...
      return new Promise<string>((resolve, reject) => {
        const subscription = apolloClient.subscribe<
          SubscribeUploadJob,
          SubscribeUploadJobVariables
        >({
          query: SUBSCRIBE_UPLOAD_JOB,
          variables,
        });
        const observable = subscription.subscribe({
          error: (error) => {
            observable.unsubscribe();
            reject(error);
          },
          next: (value) => {
            const job = value.data?.subscribeUploadJob;
            if (job?.status === "error") {
              observable.unsubscribe();
              reject(new Error(job.error ?? "An error happened."));
            } else if (job?.status === "success" && job.importId) {
              observable.unsubscribe();
              resolve(job.importId);
            } else if (job?.status === "processing") {
              onProgress(job?.progress ?? undefined);
            } else {
              console.log(`Invalid progress update`, { value });
            }
          },
        });
      });
    },
    [apolloClient]
  );
};

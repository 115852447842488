import { RaceAnalysis } from "~/algo/race/model/results/RaceAnalysis";
import { isNotNullish } from "~/components/util/isNotNullish";
import { Replay, ReplayBoat } from "../../replaycontext/Replay";
import { TailMode } from "../ReplayMap";
import { TrackConfig } from "./TrackConfig";

export const tailFadingTime = 10 * 60 * 1000;

const trackModeFromTailMode = (tailMode: TailMode): TrackConfig["mode"] => {
  switch (tailMode) {
    case "solid":
      return "solid";
    case "fading-tail":
      return "fading-solid";
    case "speed-gradient":
      return "speed";
    case "color-segments":
      return "segments";
  }
};

const tracksForLeg = (
  visibleBoats: ReplayBoat[],
  trackMode: TrackConfig["mode"],
  raceStats: RaceAnalysis,
  legIndex: number,
  fadingPoint?: number
) => {
  return visibleBoats
    .map<TrackConfig | null>((b) => {
      const boatStats = raceStats.boats.get(b.id);

      const legStats =
        legIndex === -1 ? boatStats?.start : boatStats?.legs[legIndex];

      if (legStats) {
        if (fadingPoint && trackMode === "fading-solid") {
          // In fading mode, don't show anything unless boat is in the leg.
          if (
            fadingPoint >= legStats.startTime &&
            fadingPoint <= legStats.finishTime
          ) {
            return {
              trackId: b.id,
              boatId: b.id,
              solidColor: b.color,
              mode: trackMode,
              visibleStartTime: fadingPoint - tailFadingTime,
              visibleEndTime: fadingPoint,
            };
          }
          return null;
        } else {
          return {
            trackId: b.id,
            boatId: b.id,
            solidColor: b.color,
            mode: trackMode,
            visibleStartTime: legStats.startTime,
            // For the start we show all the way to the line to help the discussion.
            visibleEndTime:
              legIndex === -1
                ? boatStats?.start?.timeAtLine ?? legStats.finishTime
                : legStats.finishTime,
          };
        }
      } else {
        return null;
      }
    })
    .filter(isNotNullish);
};

const tracksBetweenT1AndT2 = (
  visibleBoats: ReplayBoat[],
  trackMode: TrackConfig["mode"],
  visibleStartTime: number,
  visibleEndTime: number,
  fadingPoint?: number
) => {
  // When fading, adjust start/end to be beginning and end of fading point
  if (fadingPoint && trackMode === "fading-solid") {
    visibleStartTime = fadingPoint - tailFadingTime;
    visibleEndTime = fadingPoint;
  }
  // If we are not fading, make sure the mode does not say fading
  else {
    if (trackMode === "fading-solid" || trackMode === "fading-forward") {
      trackMode = "solid";
    }
  }

  return visibleBoats.map<TrackConfig>((b) => ({
    trackId: b.id,
    boatId: b.id,
    solidColor: b.color,
    mode: trackMode,
    visibleStartTime,
    visibleEndTime,
  }));
};

export const replayTracksInReplay = (replay: Replay) => {
  let mode: TrackConfig["mode"] = trackModeFromTailMode(
    replay.selectedTailMode
  );

  const visibleBoats = replay.boats.filter(
    (b) => b.data && replay.visibleBoats.indexOf(b.id) !== -1
  );

  // Fading point is only used when the track is "fading"
  //   When user is hovering on the map or on a maneuver, it's the playback time
  //   When user is hovering on the timeline, it's the hover time
  const fadingTime =
    replay.hover?.type === "time" ? replay.hover.time : replay.playbackTime;

  // When hovering on a segment, show that segment in solid
  if (replay.hover?.type === "segment") {
    return tracksBetweenT1AndT2(
      visibleBoats,
      mode,
      replay.hover.segment.startTime,
      replay.hover.segment.endTime,
      undefined // no fading tail when hovering on segment
    );
  }

  // When a selection is active, show only the track for the current selection.
  // Force solid mode if the user is editing the selection.
  if (replay.timeSelection) {
    return tracksBetweenT1AndT2(
      visibleBoats,
      mode,
      replay.timeSelection[0],
      replay.timeSelection[1],
      // Disable fading when time selection is changing
      replay.isTimeSelectionChanging ? undefined : fadingTime
    );
  }

  // If a race is active and a leg is selected, show only boats in that leg
  if (
    replay.activeSegment &&
    replay.selectedLegIndex !== undefined &&
    replay.raceAnalysis
  ) {
    return tracksForLeg(
      visibleBoats,
      mode,
      replay.raceAnalysis,
      replay.selectedLegIndex,
      fadingTime
    );
  }

  // If a segment is active, show tracks for the segment
  if (replay.activeSegment) {
    return tracksBetweenT1AndT2(
      visibleBoats,
      mode,
      replay.activeSegment.startTime,
      replay.activeSegment.endTime,
      fadingTime
    );
  }

  // Default to showing everything
  return tracksBetweenT1AndT2(
    visibleBoats,
    mode,
    replay.startTime,
    replay.endTime,
    fadingTime
  );
};

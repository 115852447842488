import { gql } from "@apollo/client";
import {
  AssignBoatToImport,
  AssignBoatToImportVariables,
} from "~/backend/graphql/AssignBoatToImport";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { FRAGMENT_IMPORT, FRAGMENT_IMPORT_LICENSE } from "./fragmentImport";

// We refetch the license here so that when assigning a boat during day pass
// payment process it will get updated.
const ASSIGN_BOAT_TO_IMPORT = gql`
  mutation AssignBoatToImport($importId: ID!, $boatId: ID!) {
    assignBoatToImport(importId: $importId, boatId: $boatId) {
      ...ImportInfo
      ...ImportLicense
    }
  }
  ${FRAGMENT_IMPORT}
  ${FRAGMENT_IMPORT_LICENSE}
`;

export const useAssignBoatToImport = () => {
  return useMutationWithErrorHandling<
    AssignBoatToImport,
    AssignBoatToImportVariables
  >(ASSIGN_BOAT_TO_IMPORT, {});
};

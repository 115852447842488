import {
  rhumbBearing,
  shortAngleDist,
  toDegrees,
  toRadians,
} from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import { SailingSegment } from "../types";

const calculateCourseBetween = (
  trip: InteractiveTrip,
  a: number,
  b: number
) => {
  const dataA = trip.getValuesAtTime(a);
  const dataB = trip.getValuesAtTime(b);

  if (!dataA || !dataB) {
    return null;
  }

  const pA = [dataA.longitude, dataA.latitude] as [number, number];
  const pB = [dataB.longitude, dataB.latitude] as [number, number];

  return rhumbBearing(pA, pB);
};

export const calculateAveragingTurnRate = (
  trip: InteractiveTrip,
  t: number,
  averagingWindow: number = 5000
) => {
  const courseBefore = calculateCourseBetween(trip, t - averagingWindow / 2, t);
  const courseAfter = calculateCourseBetween(trip, t, t + averagingWindow / 2);
  if (courseBefore === null || courseAfter === null) {
    // We went too far - We dont have data.
    return null;
  }
  return shortAngleDist(courseBefore, courseAfter);
};

/**
 * Measure the acceleration at a given point.
 *
 * Returns the variation in m/s which will be positive if boat is accelerating and negative if decelerating.
 * @param trip
 * @param t
 * @param window
 */
export const measureAcceleration = (
  trip: InteractiveTrip,
  t: number,
  window: number = 5000
) => {
  const speed1 = trip.getBracketData(t - window / 2, t)?.averageSpeedOverGround;
  const speed2 = trip.getBracketData(t, t + window / 2)?.averageSpeedOverGround;

  if (speed1 && speed2) {
    return (speed2 - speed1) / (window / 1000);
  }
  return undefined;
};

/**
 * Measure the range of speeds around a given point. It is the difference between min and max value.
 *
 * This is useful to identify if the speed is still changing or not.
 */

// Same as the other one but faster because discrete
export const measureSpeedRangeWithIndex = (
  trip: InteractiveTrip,
  i: number,
  window: number
) => {
  let i1 = trip.indexFromTime(trip.time(i) - window / 2);
  let i2 = trip.indexFromTime(trip.time(i) + window / 2);

  // temp fix to see if we are error-ing because the tests have small data sets
  if (i1 === null) {
    i1 = 0;
  }
  if (i2 === null) {
    i2 = trip.length - 1;
  }

  if (i1 === null || i2 === null) {
    console.log(`warning i1 or i2 is null`);
    return undefined;
  }

  if (trip.range) {
    const range = trip.range("sog", i1, i2);
    if (range === null) {
      console.log(`warning range is null`);
      return undefined;
    } else {
      return range[1] - range[0];
    }
  } else {
    // Calculate manually if the trip does not give a quick way to get it.
    const speeds = Array.from({ length: i2 - i1 }, (_, index) =>
      trip.sog(i1! + index)
    );

    const range = Math.max(...speeds) - Math.min(...speeds);
    if (range === undefined) {
      console.log(`warning range is undefined`);
    }
    return range;
  }
};

export const measureSpeedRange = (
  trip: InteractiveTrip,
  t: number,
  window: number
) => {
  const bracketData = trip.getBracketData(t - window / 2, t + window / 2);
  if (bracketData) {
    return Math.abs(bracketData.maxSpeed - bracketData.minSpeed);
  }
  return undefined;
};

const debug = false;
const turnRateLimitDegrees = 3;

// So if you are sailing at 10kts, the variation must be less than 1/2 knot - if 20kts, less than 1kt
const speedRangeLimitPercent = 0.035;
const speedRangeWindow = 5000;

/**
 * Given two segments of a trip, find when the turn started and when it ended.
 *
 * This algorithm can be called with:
 *   - segment[n], segment[n+1] when we have two lines and we are looking to
 *   "reduce" the end of segment[n] and the beginning of segment[n+1] to make
 *   room for the turn (a new segment we will insert in between)
 *   - segment[n-1, segment[n+1] when we already know that segment[n] is a turn
 *   but we want to find its bounds more precisely.
 *
 * @param trip
 * @param turnCenter
 */
export const findTurnBoundsOLD = (
  trip: InteractiveTrip,
  segmentA: SailingSegment,
  segmentB: SailingSegment
): { bounds: [number, number]; debug?: any } => {
  let [a, b] = [segmentA.interval[1], segmentB.interval[0]];
  const turnCenter = a + (b - a) / 2;
  let debugData: any = undefined;
  if (a === b) {
    a -= 1000;
    b += 1000;
  }

  // Define limits to how much we can walk back or forward from what we were givin
  const segmentAlimit =
    segmentA.interval[0] + (segmentA.interval[1] - segmentA.interval[0]) / 2;
  const segmentBlimit =
    segmentB.interval[0] + (segmentB.interval[1] - segmentB.interval[0]) * 0.8;

  const t0 = performance.now();

  if (debug) {
    console.log(
      `${performance.now() - t0} step1 - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  // Then keep walking back until the boat has stopped turning
  const WALK_TURN_STEP_MS = 500;
  for (; a > segmentAlimit; a -= WALK_TURN_STEP_MS) {
    const turnRate = calculateAveragingTurnRate(trip, a);
    if (
      turnRate === null ||
      Math.abs(turnRate) < toRadians(turnRateLimitDegrees)
    ) {
      break;
    }
  }
  // Start searching at 75% to avoid unbalanced turns
  b = turnCenter + Math.max(1000, Math.round(0.75 * (turnCenter - a)));

  for (; b < segmentBlimit; b += WALK_TURN_STEP_MS) {
    const turnRate = calculateAveragingTurnRate(trip, b);
    if (
      turnRate === null ||
      Math.abs(turnRate) < toRadians(turnRateLimitDegrees)
    ) {
      break;
    }
  }

  if (debug) {
    console.log(
      `${performance.now() - t0} step1b - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  // Boat is not moving now let's try to find the spot where it's at least 80%
  // of entry speed and its stable -- or it exceeds entry speed
  //const entrySpeed = trip.getBracketData(a - 3000, a)?.averageSpeedOverGround
  const entrySpeed = trip.getValuesAtTime(a)?.sog;
  const WALK_EXIT_STEP_MS = 125;
  while (b < segmentBlimit) {
    // We want to stop before we get there
    const nextB = b + WALK_EXIT_STEP_MS;
    const speed = trip.getValuesAtTime(nextB)?.sog;
    // const speed = trip.getBracketData(b - 1000, b + 1000)
    //   ?.averageSpeedOverGround
    const speedRange = measureSpeedRange(trip, nextB, speedRangeWindow);
    if (speed === undefined) {
      debugData = "speed undefined";
      break;
    } else if (speedRange === undefined) {
      debugData = "speed range undefined";
      break;
    } else if (entrySpeed !== undefined && speed >= entrySpeed) {
      debugData = "speed > entrySpeed";
      break;
    } else if (
      entrySpeed !== undefined &&
      speed !== undefined &&
      speed > entrySpeed * 0.8 &&
      speedRange < entrySpeed * speedRangeLimitPercent
    ) {
      debugData = "speedRange < limit";
      break;
    }
    b = nextB;
  }
  if (b >= segmentBlimit) {
    debugData = "segmentBlimit";
  }

  if (debug) {
    console.log(
      `${performance.now() - t0} step2 - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  if (a < segmentAlimit) {
    a = segmentAlimit;
  }
  if (b > segmentBlimit) {
    b = segmentBlimit;
    debugData = "reduced-to-limit";
  }
  if (debug) {
    console.log(`${performance.now() - t0} step4 - a=${a} b=${b}`);
  }

  return { bounds: [a, b], debug: debugData };
};

/*
 * A version that does not pretend that time is continuous. Hopefully much faster.
 * Given two segments of a trip, find when the turn started and when it ended.
 *
 * This algorithm can be called with:
 *   - segment[n], segment[n+1] when we have two lines and we are looking to
 *   "reduce" the end of segment[n] and the beginning of segment[n+1] to make
 *   room for the turn (a new segment we will insert in between)
 *   - segment[n-1, segment[n+1] when we already know that segment[n] is a turn
 *   but we want to find its bounds more precisely.
 */
export const findTurnBoundsNEW = (
  trip: InteractiveTrip,
  segmentA: SailingSegment,
  segmentB: SailingSegment
): { bounds: [number, number]; debug?: any } => {
  let timeBounds = [segmentA.interval[1], segmentB.interval[0]];

  let [a, b] = timeBounds.map((t) => trip.indexFromTime(t));
  if (a === null || b === null) {
    throw new Error(`turn does not exist?`);
  }

  const turnCenterTime = timeBounds[0] + (timeBounds[1] - timeBounds[0]) / 2;
  const turnCenterIndex = trip.indexFromTime(turnCenterTime);
  if (turnCenterIndex === null) {
    throw new Error(`turncenter null`);
  }

  let debugData: any = undefined;
  if (a === b) {
    a--;
    b++;
  }

  // Define limits to how much we can walk back or forward from what we were givin
  const segmentAlimit = trip.indexFromTime(
    segmentA.interval[0] + (segmentA.interval[1] - segmentA.interval[0]) / 2
  );
  const segmentBlimit = trip.indexFromTime(
    segmentB.interval[0] + (segmentB.interval[1] - segmentB.interval[0]) * 0.8
  );
  if (segmentAlimit === null || segmentBlimit === null) {
    throw new Error(`invalid limits?`);
  }

  const t0 = performance.now();

  if (debug) {
    console.log(
      `${performance.now() - t0} step1 - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  // Then keep walking back until the boat has stopped turning
  for (; a > segmentAlimit; a--) {
    const turnRate = calculateAveragingTurnRate(trip, trip.time(a));
    if (
      turnRate === null ||
      Math.abs(turnRate) < toRadians(turnRateLimitDegrees)
    ) {
      break;
    }
  }
  // Start searching at 75% to avoid unbalanced turns
  const timeB = Math.min(
    trip.endTime,
    turnCenterTime +
      Math.max(1000, Math.round(0.75 * (turnCenterTime - trip.time(a))))
  );
  b = trip.indexFromTime(timeB);
  if (b === null) {
    throw new Error(`invalid center for b`);
  }

  for (; b < segmentBlimit; b++) {
    const turnRate = calculateAveragingTurnRate(trip, trip.time(b));
    if (
      turnRate === null ||
      Math.abs(turnRate) < toRadians(turnRateLimitDegrees)
    ) {
      break;
    }
  }

  if (debug) {
    console.log(
      `${performance.now() - t0} step1b - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  // Boat is not moving now let's try to find the spot where it's at least 80%
  // of entry speed and its stable -- or it exceeds entry speed
  //const entrySpeed = trip.getBracketData(a - 3000, a)?.averageSpeedOverGround
  const entrySpeed = trip.sog(a);
  while (b !== null && b < segmentBlimit) {
    // We want to stop before we get there
    const nextB: number = b + 1;
    const speed = trip.sog(nextB);
    // const speed = trip.getBracketData(b - 1000, b + 1000)
    //   ?.averageSpeedOverGround
    const speedRange = measureSpeedRangeWithIndex(
      trip,
      nextB,
      speedRangeWindow
    );
    if (speed === undefined) {
      debugData = "speed undefined";
      break;
    } else if (speedRange === undefined) {
      debugData = "speed range undefined";
      break;
    } else if (entrySpeed !== undefined && speed >= entrySpeed) {
      debugData = "speed > entrySpeed";
      break;
    } else if (
      entrySpeed !== undefined &&
      speed !== undefined &&
      speed > entrySpeed * 0.8 &&
      speedRange < entrySpeed * speedRangeLimitPercent
    ) {
      debugData = "speedRange < limit";
      break;
    }
    b = nextB;
  }
  if (b >= segmentBlimit) {
    debugData = "segmentBlimit";
  }

  if (debug) {
    console.log(
      `${performance.now() - t0} step2 - a=${a} b=${b} turnRateA=${toDegrees(
        calculateAveragingTurnRate(trip, a)!
      )} turnRateB=${toDegrees(calculateAveragingTurnRate(trip, b)!)}`
    );
  }

  if (a < segmentAlimit) {
    a = segmentAlimit;
  }
  if (b > segmentBlimit) {
    b = segmentBlimit;
    debugData = "reduced-to-limit";
  }
  if (debug) {
    console.log(`${performance.now() - t0} step4 - a=${a} b=${b}`);
  }

  return { bounds: [trip.time(a), trip.time(b)], debug: debugData };
};

export const findTurnBounds = findTurnBoundsNEW;

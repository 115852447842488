/**
 * Adapted from https://github.com/mourner/simplify-js
 *
 * The biggest change is that we want to keep the original index of the points (time dimension).
 *
 */

import { Position } from "@chartedsails/sailing-data";
import { distanceToSegment } from "@chartedsails/sailing-math";
import { TrackPositions } from "@chartedsails/tracks";

export const sailingManeuverTolerance = 15;

// square distance from a point to a segment
// adapted to be accurate with geo-coordinates
function getSqSegDist(
  points: TrackPositions,
  p: number,
  p1: number,
  p2: number
) {
  const x = [points.longitude[p], points.latitude[p]] as Position;
  const a = [points.longitude[p1], points.latitude[p1]] as Position;
  const b = [points.longitude[p2], points.latitude[p2]] as Position;

  const d = distanceToSegment(x, a, b);
  return d * d;
}

function simplifyDPStep(
  points: TrackPositions,
  first: number,
  last: number,
  sqTolerance: number,
  simplified: number[]
) {
  var maxSqDist = sqTolerance,
    index = 0;

  for (let i = first + 1; i < last; i++) {
    const sqDist = getSqSegDist(points, i, first, last);

    if (sqDist > maxSqDist) {
      index = i;
      maxSqDist = sqDist;
    }
  }

  if (maxSqDist > sqTolerance) {
    // Index always has a valid value here because maxSqDist > sqTolerance. (which means we entered the if at least once above)
    if (index - first > 1)
      simplifyDPStep(points, first, index, sqTolerance, simplified);
    simplified.push(index);
    if (last - index > 1)
      simplifyDPStep(points, index, last, sqTolerance, simplified);
  }
}

// simplification using Ramer-Douglas-Peucker algorithm
// Takes a Trip, a tolerance and returns a list of the indices of points that form the simplified path.
export function simplifyDouglasPeucker(
  points: TrackPositions,
  tolerance: number
) {
  const sqTolerance = tolerance * tolerance;

  var last = points.latitude.length - 1;

  var simplified = [0];
  simplifyDPStep(points, 0, last, sqTolerance, simplified);
  simplified.push(last);

  return simplified;
}

import { DataType, Type, Vector } from "apache-arrow";

/**
 * Converts a vector into an array. We should be able to use .toArray()
 * but there is a bug.
 *
 * See https://issues.apache.org/jira/projects/ARROW/issues/ARROW-18247
 *
 * @param vector
 * @returns
 */
export const vectorToArray = <T extends DataType>(
  vector: Vector<T>
): T["TArray"] => {
  const { type, data, length, stride, ArrayType } = vector;
  // Fast case, return subarray if possible
  switch (type.typeId) {
    case Type.Int:
    case Type.Float:
    case Type.Decimal:
    case Type.Time:
    case Type.Timestamp:
      switch (data.length) {
        case 0:
          return new ArrayType();
        case 1:
          return data[0].values.subarray(0, length * stride);
        default:
          return data.reduce(
            (memo, { values, length }) => {
              memo.array.set(values.subarray(0, length), memo.offset);
              memo.offset += length;
              return memo;
            },
            { array: new ArrayType(length * stride), offset: 0 }
          ).array;
      }
  }
  // Otherwise if not primitive, slow copy
  return [...vector] as T["TArray"];
};

import { useMemo } from "react";
import { isNotNullish } from "../../util/isNotNullish";
import { viewportFromViewState } from "../map/viewportFromViewState";
import { useReplayContext } from "../replaycontext/ReplayContext";
import { BoatTooltip } from "./BoatTooltip";

export const ReplayBoatTooltips = () => {
  const replay = useReplayContext();
  const viewport = useMemo(
    () => viewportFromViewState(replay.viewState),
    [replay.viewState]
  );

  const time = replay.hover?.time ?? replay.playbackTime;
  const includeBoats = useMemo(
    () =>
      // Only show tooltips in replay mode
      replay.activePane === "trimming" || replay.activePane === "racesetup"
        ? []
        : replay.hover?.type === "map"
        ? [replay.hover.boatId]
        : replay.lockMapOnBoatId
        ? [replay.lockMapOnBoatId]
        : [],
    [replay.hover, replay.lockMapOnBoatId, replay.activePane]
  );

  const tooltips = useMemo(
    () =>
      replay.boats
        .filter((b) => includeBoats.includes(b.id))
        .map((boat) => {
          const data = boat.data?.getValuesAtTime(time);
          if (!data) {
            return null;
          } else {
            const [x, y] = viewport.project([data.longitude, data.latitude]);
            if (x < 0 || x > viewport.width || y < 0 || y > viewport.height) {
              return null;
            }
            return (
              <BoatTooltip
                key={boat.id}
                x={x}
                y={y}
                boatName={boat.name}
                variable="sog"
                value={data.sog}
                boatColor={boat.color}
              />
            );
          }
        })
        .filter(isNotNullish),
    [replay.boats, includeBoats, time, viewport]
  );

  return <>{tooltips}</>;
};

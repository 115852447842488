import {
  normalizeDirection,
  oppositeAzimuth,
} from "@chartedsails/sailing-math";
import React, { memo, useCallback } from "react";
import { SVGOverlay } from "react-map-gl";
import { findIntersectionFromPointAndBearing } from "~/algo/geometry/find-intersection/find-intersection-point-and-bearing";
import { RaceAnalysis } from "~/algo/race/model/results/RaceAnalysis";
import { findGateSide } from "~/algo/race/utils/find-gate-side";
import { RaceConfig } from "~/backend/graphql/RaceConfig";
import { isNotNullish } from "~/components/util/isNotNullish";
import { SVGOverlayPropsRedraw } from "~/components/util/SVGRedrawOptions";

interface IProps {
  raceConfig: RaceConfig;
  raceAnalysis: RaceAnalysis;
  activeLeg: number;
}

export const LaylineOverlay = memo(
  ({ activeLeg, raceConfig, raceAnalysis }: IProps) => {
    const legStats = raceAnalysis.legs[activeLeg];

    const handleRedraw = useCallback<SVGOverlayPropsRedraw>(
      (redrawContext) => {
        if (
          !legStats ||
          !legStats.laylinePortTWA ||
          !legStats.laylineStarboardTWA
        ) {
          return null;
        }

        const starboardTWD = normalizeDirection(
          raceAnalysis.trueWindDirection - legStats.laylineStarboardTWA
        );
        const portTWD = normalizeDirection(
          raceAnalysis.trueWindDirection - legStats.laylinePortTWA
        );

        const previousMarkLeft = findGateSide(
          raceConfig,
          raceConfig.gates[activeLeg],
          raceAnalysis.trueWindDirection,
          "left"
        );
        const nextMarkLeft = findGateSide(
          raceConfig,
          raceConfig.gates[activeLeg + 1],
          raceAnalysis.trueWindDirection,
          "left"
        );
        const previousMarkRight = findGateSide(
          raceConfig,
          raceConfig.gates[activeLeg],
          raceAnalysis.trueWindDirection,
          "right"
        );
        const nextMarkRight = findGateSide(
          raceConfig,
          raceConfig.gates[activeLeg + 1],
          raceAnalysis.trueWindDirection,
          "right"
        );

        const maxDistance = 10000;
        const leftIntercept = findIntersectionFromPointAndBearing(
          previousMarkLeft,
          starboardTWD,
          nextMarkLeft,
          oppositeAzimuth(portTWD),
          maxDistance
        );
        let rightIntercept = findIntersectionFromPointAndBearing(
          previousMarkRight,
          portTWD,
          nextMarkRight,
          oppositeAzimuth(starboardTWD),
          maxDistance
        );

        const laylinesBoxPoints = [
          previousMarkLeft,
          leftIntercept,
          nextMarkLeft,
          nextMarkRight,
          rightIntercept,
          previousMarkRight,
        ];
        let svgPath = `M0 0 h${redrawContext.width} v${redrawContext.height} h-${redrawContext.width}z`;

        laylinesBoxPoints.filter(isNotNullish).forEach((point, index) => {
          const p = redrawContext.project(point);
          svgPath += `${index === 0 ? "M" : "L"}${p[0]} ${p[1]} `;
        });
        svgPath += "z";

        return (
          <g>
            <path d={svgPath} opacity={0.2} fillRule="evenodd" />
          </g>
        );
      },
      [activeLeg, legStats, raceAnalysis.trueWindDirection, raceConfig]
    );

    return <SVGOverlay redraw={handleRedraw} />;
  }
);

import { computeDestinationPoint } from "geolib";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { TripBounds, TripCorners } from "./TripBounds";

export const innerBoundsOfBounds = (
  bounds: TripBounds[],
  { project }: ChartedSailsViewport
): TripBounds => {
  // Sort them from lower to higher
  const minOfMaxTop = bounds
    .map((b) => b.topMost)
    .sort((a, b) => project(b)[1] - project(a)[1]);
  const maxDownMost = bounds
    .map((b) => b.downMost)
    .sort((a, b) => project(a)[1] - project(b)[1]);
  const minOfMaxLeft = bounds
    .map((b) => b.leftMost)
    .sort((a, b) => project(b)[0] - project(a)[0]);
  const maxOfMaxRight = bounds
    .map((b) => b.rightMost)
    .sort((a, b) => project(a)[0] - project(b)[0]);

  return {
    topMost: minOfMaxTop[0],
    leftMost: minOfMaxLeft[0],
    rightMost: maxOfMaxRight[0],
    downMost: maxDownMost[0],
  };
};

export const outerBoundsOfBounds = (
  bounds: TripBounds[],
  { project }: ChartedSailsViewport
): TripBounds => {
  // Sort them from lower to higher
  const maxTopMost = bounds
    .map((b) => b.topMost)
    .sort((a, b) => project(a)[1] - project(b)[1]);
  const minOfDownMost = bounds
    .map((b) => b.downMost)
    .sort((a, b) => project(b)[1] - project(a)[1]);
  const minLeftMost = bounds
    .map((b) => b.leftMost)
    .sort((a, b) => project(a)[0] - project(b)[0]);
  const maxRightMost = bounds
    .map((b) => b.rightMost)
    .sort((a, b) => project(b)[0] - project(a)[0]);

  return {
    topMost: maxTopMost[0],
    leftMost: minLeftMost[0],
    rightMost: maxRightMost[0],
    downMost: minOfDownMost[0],
  };
};

// Inset bounds by X meters
export const insetBounds = (
  bounds: TripBounds,
  { bearing: bearingDegrees }: Pick<ChartedSailsViewport, "bearing">,
  insetMargin: number
): TripBounds => {
  return {
    topMost: coordinatesToLonLat(
      computeDestinationPoint(
        bounds.topMost,
        insetMargin,
        (bearingDegrees + 180) % 360
      )
    ),
    rightMost: coordinatesToLonLat(
      computeDestinationPoint(
        bounds.rightMost,
        insetMargin,
        (bearingDegrees + 270) % 360
      )
    ),
    downMost: coordinatesToLonLat(
      computeDestinationPoint(
        bounds.downMost,
        insetMargin,
        bearingDegrees % 360
      )
    ),
    leftMost: coordinatesToLonLat(
      computeDestinationPoint(
        bounds.leftMost,
        insetMargin,
        (bearingDegrees + 90) % 360
      )
    ),
  };
};

// Return corners of the rectangle defined by the bounds
export const boundsCorners = (
  tripBounds: TripBounds,
  { project, unproject }: ChartedSailsViewport
): TripCorners => {
  const topLeftProjected = [
    project(tripBounds.leftMost)[0],
    project(tripBounds.topMost)[1],
  ] as [number, number];
  const topRightProjected = [
    project(tripBounds.rightMost)[0],
    project(tripBounds.topMost)[1],
  ] as [number, number];
  const downRightProjected = [
    project(tripBounds.rightMost)[0],
    project(tripBounds.downMost)[1],
  ] as [number, number];
  const downLeftProjected = [
    project(tripBounds.leftMost)[0],
    project(tripBounds.downMost)[1],
  ] as [number, number];

  return {
    topLeft: unproject(topLeftProjected),
    topRight: unproject(topRightProjected),
    downRight: unproject(downRightProjected),
    downLeft: unproject(downLeftProjected),
  };
};

import { Position } from "@chartedsails/sailing-data";
import { toDegrees } from "@chartedsails/sailing-math";
import { WebMercatorViewport } from "react-map-gl";
import { RaceConfig } from "~/backend/graphql/RaceConfig";
import { RaceGate } from "~/backend/graphql/RaceGate";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { isGateLine } from "../utils/gate-types";
import { findMarkOrFail } from "./find-mark-or-fail";

/**
 * For a mark, returns the coordinates of the mark.
 * For a gate, returns the coordinates of the left or right side (when wind coming from the top).
 * @param raceSegment
 * @param gate
 * @returns
 */
export const findGateSide = (
  raceConfig: RaceConfig,
  gate: RaceGate,
  trueWindDirection: number,
  side: "left" | "right"
): Position => {
  if (!isGateLine(gate)) {
    return coordinatesToLonLat(findMarkOrFail(raceConfig, gate.markId));
  } else {
    const mark1 = findMarkOrFail(raceConfig, gate.markId);
    const mark2 = findMarkOrFail(raceConfig, gate.secondMarkId);
    const mercator = new WebMercatorViewport({
      latitude: mark1.latitude,
      longitude: mark1.longitude,
      bearing: toDegrees(trueWindDirection),
      width: 1000,
      height: 1000,
    }) as ChartedSailsViewport;

    const projectedMark1X = mercator.project(coordinatesToLonLat(mark1))[0];
    const projectedMark2X = mercator.project(coordinatesToLonLat(mark2))[0];

    if (projectedMark1X < projectedMark2X) {
      return side === "left"
        ? coordinatesToLonLat(mark1)
        : coordinatesToLonLat(mark2);
    } else {
      return side === "left"
        ? coordinatesToLonLat(mark2)
        : coordinatesToLonLat(mark1);
    }
  }
};

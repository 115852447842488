import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { UserInfo } from "~/backend/graphql/UserInfo";
import { Avatar } from "./Avatar";
import { INavAction } from "./INavAction";
import { ProfileAction } from "./ProfileAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(20),
    borderRadius: 7,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& .MuiAvatar-root": {
      height: 74,
      width: 74,
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  link: {
    "&:last-child": {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
  },
}));

interface IProps {
  user?: UserInfo;
  actions: INavAction[];
}

export const ProfilePopup = ({ user, actions }: IProps) => {
  const classes = useStyles();
  return (
    <Paper elevation={6} className={classes.root}>
      <Avatar user={user} />
      {actions.map((a, idx) => (
        <ProfileAction className={classes.link} {...a} key={idx} />
      ))}
    </Paper>
  );
};

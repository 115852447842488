import { makeStyles } from "@material-ui/core";
import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import clsx from "clsx";
import React from "react";
import { useAppTheme } from "~/styles/chartedSailsTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: "solid",
    borderColor: "#bababa",
    borderWidth: 1,
    borderRadius: 4,
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
}));

/**
 * Just the stripe CardElement with our style.
 *
 * @returns
 */
export const CSCardElement = ({
  className,
  options,
  ...otherProps
}: CardElementProps) => {
  const theme = useAppTheme();
  const classes = useStyles();

  return (
    <CardElement
      className={clsx(classes.root, className)}
      options={{
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: `${theme.typography.fontSize}px`,
            fontFamily: theme.typography.fontFamily,
            "::placeholder": {
              color: theme.palette.text.hint,
            },
          },
          invalid: {
            color: theme.palette.error.main,
          },
        },
        ...options,
      }}
      {...otherProps}
    />
  );
};

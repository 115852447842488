import { ktsToMs, msToKts } from "../units/speed";

declare global {
  namespace jest {
    interface Expect {
      toBeCloseToKnots(expectedInKnots: number): CustomMatcherResult;
    }
    interface Matchers<R> {
      toBeCloseToKnots(expectedInKnots: number): CustomMatcherResult;
    }
  }
}

// Expect is only defined when the tests are running
if (typeof expect !== 'undefined') {
  expect.extend({
    toBeCloseToKnots(actual: number, expected: number) {
      const pass = Math.abs(msToKts(actual) - expected) < 0.01;
      const message = pass
        ? () => `expected ${ktsToMs(actual)} NOT to be close to ${expected}`
        : () => `expected ${ktsToMs(actual)} to be close to ${expected}`;
      return { message, pass };
    },
  });
}

import { distance } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";

export interface SpeedRun {
  distance: number,
  duration: number,
  startTime: number,
  endTime: number,
}

export const speedRun = (trip: InteractiveTrip, runLength: number): SpeedRun | null => {
  let i = 0;
  let j = 0;
  let sum_distance = 0;

  let fastest_interval: null | [number, number] = null;
  let fastest_speed: null | number = null;
  let fastest_distance = 0;
  while (j < trip.length - 1) {
    let dist = distance(trip.lonlat(j), trip.lonlat(j + 1));
    sum_distance += dist;

    while (i <= j && sum_distance >= runLength) {
      let duration = trip.time(j + 1) - trip.time(i);
      // Update the minimum if needed
      if (duration > 0 && (fastest_speed === null || fastest_speed < sum_distance / duration)) {
        fastest_interval = [i, j + 1];
        fastest_speed = sum_distance / duration;
        fastest_distance = sum_distance;
      }

      // Move the head pointer forward one step
      let subtract_dist = distance(trip.lonlat(i), trip.lonlat(i + 1));
      sum_distance -= subtract_dist;

      i += 1;
    }
    j++;
  }

  if (!fastest_speed || !fastest_interval) {
    return null
  }

  let startTime = trip.time(fastest_interval[0]);
  let endTime = trip.time(fastest_interval[1]);

  if (fastest_distance > runLength) {
    const extraDistance = fastest_distance - runLength;

    const speed0 = distance(trip.lonlat(fastest_interval[0]), trip.lonlat(fastest_interval[0] + 1)) / ((trip.time(fastest_interval[0] + 1) - trip.time(fastest_interval[0])) / 1000);
    const speed1 = distance(trip.lonlat(fastest_interval[1] - 1), trip.lonlat(fastest_interval[1])) / ((trip.time(fastest_interval[1]) - trip.time(fastest_interval[1] - 1)) / 1000);

    if (speed1 > speed0) {
      // If we are faster in the end, we cut the beginning
      const extraDuration = extraDistance / speed0;
      startTime += extraDuration * 1000;
      fastest_distance -= extraDistance;
    }
    else {
      // If we are faster in the beginning, we cut the end
      const extraDuration = extraDistance / speed1;
      endTime -= extraDuration * 1000;
      fastest_distance -= extraDistance;
    }
  }

  return {
    distance: fastest_distance,
    duration: endTime - startTime,
    startTime,
    endTime,
  };
}
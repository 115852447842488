import {
  TrackPoint,
  TrackPointFieldsDescription,
} from "@chartedsails/sailing-data";

/**
 * This is not a custom matcher - just a convenient function that will call expect for you.
 * @param expected
 * @param actual
 */
export const expectTrackpoint = (actual: TrackPoint, expected: TrackPoint) => {
  const expectedMatchers = {} as { [key: string]: any };

  const indexableExpected = expected as unknown as {
    [key: string]: number | null;
  };
  Object.keys(TrackPointFieldsDescription).forEach((key: keyof TrackPoint) => {
    const field = TrackPointFieldsDescription[key];
    const expectedValue = indexableExpected[key];

    if (expectedValue !== null && expectedValue !== undefined) {
      switch (field?.type) {
        case "angle":
          expectedMatchers[key] = expect.toBeCloseTo(expectedValue);
          break;
        case "depth":
        case "distance":
        case "force":
        case "heartrate":
        case "pressure":
        case "temperature":
        case "speed":
          expectedMatchers[key] = expect.toBeCloseTo(expectedValue);
          break;
        case "position":
          expectedMatchers[key] = expect.arrayContaining(
            expectedValue as unknown as [number]
          );
          break;
        default:
          expectedMatchers[key] = expectedValue;
      }
    } else if (key in expected) {
      expectedMatchers[key] = expectedValue;
    }
  });

  expect(actual).toMatchObject(expectedMatchers);
};

import { useMemo } from "react";
import { BoatRaceStartAnalysis } from "~/algo/race/model/results/RaceAnalysis";
import { isRaceSegment } from "~/model/RaceSegment";
import { ManeuverSegment } from "~/model/SailingSegment";
import { useReplayContext } from "../replaycontext/ReplayContext";

type MapHoverTarget =
  | {
      type: "start";
      interval: [number, number];
      boatId: string;
      startAnalysis: BoatRaceStartAnalysis;
    }
  | {
      type: "line";
      interval: [number, number];
      boatId: string;
    }
  | {
      type: "maneuver";
      interval: [number, number];
      boatId: string;
      maneuver: ManeuverSegment;
    };

/**
 * Returns what the user is hovering on.
 */
export const useMapHoverTarget = () => {
  const replay = useReplayContext();

  return useMemo<MapHoverTarget | null>(() => {
    if (replay.hover?.type === "map") {
      // Is this the start of a race?
      if (isRaceSegment(replay.activeSegment)) {
        const raceStartTime = replay.activeSegment.raceConfig.gunTime;
        const boatStartStats = replay.raceAnalysis?.boats.get(
          replay.hover.boatId
        )?.start;

        if (
          replay.hover.time >= raceStartTime &&
          boatStartStats?.timeAtLine &&
          replay.hover.time <= boatStartStats?.timeAtLine
        ) {
          return {
            type: "start",
            interval: [raceStartTime, boatStartStats.timeAtLine],
            boatId: replay.hover.boatId,
            startAnalysis: boatStartStats,
          };
        }
      }

      const segment = replay.sailingInsights?.getSailingSegmentAt(
        replay.hover.boatId,
        replay.hover.time
      );
      if (segment?.type === "line") {
        return {
          type: segment.type,
          interval: segment.interval,
          boatId: replay.hover.boatId,
        };
      } else if (segment?.type === "maneuver") {
        return {
          type: "maneuver",
          interval: segment.interval,
          boatId: replay.hover.boatId,
          maneuver: segment,
        };
      }
    }
    return null;
  }, [
    replay.hover,
    replay.sailingInsights,
    replay.activeSegment,
    replay.raceAnalysis,
  ]);
};

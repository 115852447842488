import { binarySearchInTime } from "./binarySearchInTime";

export const findRowWithTime = (times: number[], t: number) => {
  // const index = data.findIndex(row => row.time > t)
  const index = binarySearchInTime(times, t);

  // t is smaller than the defined range
  if (index === 0) {
    return null;
  }
  if (index === -1) {
    if (t === times[times.length - 1]) {
      // t is the last element
      return times.length - 1;
    } else {
      // t is bigger than the defined range
      return null;
    }
  }
  // t is within range
  return index - 1;
};

import { gql } from "@apollo/client";
import { useCallback } from "react";
import { forceDisconnectSubscriptionsClient } from "~/backend/graphql";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { Login, LoginVariables } from "../../graphql/Login";
import { FRAGMENT_USER_INFO } from "./fragmentUserInfo";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...UserInfo
    }
  }
  ${FRAGMENT_USER_INFO}
`;

export const useLogin = () => {
  const mutation = useMutationWithErrorHandling<Login, LoginVariables>(LOGIN, {
    refetchQueries: ["GetMe"],
    awaitRefetchQueries: true,
  });
  return useCallback<typeof mutation>(
    (args) =>
      mutation(args).then((data) => {
        forceDisconnectSubscriptionsClient();
        return data;
      }),
    [mutation]
  );
};

import { gql } from "@apollo/client";

export const FRAGMENT_SAILINGMARK = gql`
  fragment SailingMark on SailingMark {
    id
    latitude
    longitude
    type
  }
`;

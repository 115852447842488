import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import gql from "graphql-tag";
import { useCallback } from "react";
import {
  ConfirmDayPassPurchase,
  ConfirmDayPassPurchaseVariables,
} from "~/backend/graphql/ConfirmDayPassPurchase";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import {
  PrepareDayPassPurchase,
  PrepareDayPassPurchaseVariables,
} from "~/backend/graphql/PrepareDayPassPurchase";
import { isBackendError } from "~/backend/utils/BackendError";
import { useMutationWithErrorHandling } from "~/backend/utils/useMutationWithErrorHandling";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import { isNullish } from "~/components/util/isNotNullish";
import { useErrorHandler } from "../../utils/useErrorHandler";
import { FRAGMENT_BOAT } from "../boat/fragmentBoat";
import {
  FRAGMENT_IMPORT,
  FRAGMENT_IMPORT_LICENSE,
} from "../imports/fragmentImport";

const PREPARE_DAY_PASS_PURCHASE = gql`
  mutation PrepareDayPassPurchase($importId: ID!) {
    prepareDayPassPurchase(importId: $importId) {
      ...DayPassPurchase
    }
  }
  fragment DayPassPurchase on DayPassPurchase {
    date
    boat {
      ...Boat
    }
    price
    stripe_client_secret
  }
  ${FRAGMENT_BOAT}
`;
const CONFIRM_DAY_PASS_PURCHASE = gql`
  mutation ConfirmDayPassPurchase($importId: ID!, $paymentIntent: String!) {
    confirmDayPassPurchase(
      importId: $importId
      paymentIntentId: $paymentIntent
    ) {
      ...ImportInfo
      ...ImportLicense
    }
  }
  ${FRAGMENT_IMPORT}
  ${FRAGMENT_IMPORT_LICENSE}
`;

interface IProps {
  onError: (e: string | undefined) => void;
  onSuccess: (track: ImportInfoWithLicense) => void | Promise<any>;
}

/**
 * Returns a function that takes an Import and will purchase a daypass.
 *
 * There needs to be a <CardElements /> on the screen.
 *
 * onError will be called to update the error message
 * onSuccess will be called when the payment is successfully completed.
 *
 * @param param0
 * @returns
 */
export const useDayPassFlow = ({ onError, onSuccess }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentCompleteEvent = useAnalyticEvent("buydaypass-paymentcomplete");
  const errorHandler = useErrorHandler(onError, "buydaypass-paymenterror");

  const prepareDayPassPurchase = useMutationWithErrorHandling<
    PrepareDayPassPurchase,
    PrepareDayPassPurchaseVariables
  >(PREPARE_DAY_PASS_PURCHASE);
  const confirmDayPassPurchase = useMutationWithErrorHandling<
    ConfirmDayPassPurchase,
    ConfirmDayPassPurchaseVariables
  >(CONFIRM_DAY_PASS_PURCHASE);

  return useCallback(
    async (track: ImportInfoWithLicense) => {
      onError(undefined);
      try {
        if (!stripe || !elements) {
          throw new Error(
            `Payment system not ready. Please try again in a few seconds.`
          );
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          throw new Error(`Card Element not found. Please contact us.`);
        }

        if (isNullish(track.boat)) {
          throw new Error(`You need to have a boat assigned to this track.`);
        }

        // Get a payment intent from the backend
        const purchaseInfo = await prepareDayPassPurchase({
          variables: { importId: track.id },
        });

        // Confirm the payment with Stripe
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          purchaseInfo.prepareDayPassPurchase.stripe_client_secret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (error || !paymentIntent) {
          throw error ?? new Error(`no payment intent`);
        }

        // Confirm payment with the backend which will have the side effect of update the import in the Apollo Cache and it will now have a valid license
        const confirmedTrack = await confirmDayPassPurchase({
          variables: { importId: track.id, paymentIntent: paymentIntent.id },
        });

        paymentCompleteEvent({ amountUSD: paymentIntent.amount / 100 });
        await onSuccess(confirmedTrack.confirmDayPassPurchase);
      } catch (error) {
        if (isBackendError(error as Error, "daypass-notneeded")) {
          paymentCompleteEvent({ error: "daypass-notneeded" });
          await onSuccess(track);
        } else {
          errorHandler(error as Error);
        }
      }
    },
    [
      confirmDayPassPurchase,
      elements,
      errorHandler,
      onError,
      onSuccess,
      paymentCompleteEvent,
      prepareDayPassPurchase,
      stripe,
    ]
  );
};

import { Tooltip, withStyles } from "@material-ui/core";
import { buoy } from "~/styles/chartedsailsColors";
import { fontLato } from "~/styles/chartedSailsTheme";

export const RaceTooltip = withStyles({
  tooltip: {
    ...fontLato,
    fontSize: 12,
    fontWeight: 700,
    textTransform: "uppercase",
    color: buoy[900],
    backgroundColor: "white",
    borderRadius: 8,
    border: `1px solid ${buoy[500]}`,
  },
})(Tooltip);

import { gql, useMutation } from "@apollo/client";
import {
  PasswordForgotten,
  PasswordForgottenVariables,
} from "~/backend/graphql/PasswordForgotten";

const PASSWORD_FORGOTTEN = gql`
  mutation PasswordForgotten($email: String!) {
    passwordForgotten(email: $email)
  }
`;

export const usePasswordForgotten = () => {
  const [mutation] = useMutation<PasswordForgotten, PasswordForgottenVariables>(
    PASSWORD_FORGOTTEN
  );
  return mutation;
};

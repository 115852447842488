import { Fab } from "@material-ui/core";
import PauseIcon from "@material-ui/icons/Pause";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useCallback } from "react";

const useStyles = makeStyles({
  root: {},
});

interface IProps {
  playing: boolean;
  onChange?: (v: boolean) => void;
  className?: string;
}

export const PlayButton = ({ playing, onChange, className }: IProps) => {
  const classes = useStyles();
  const handleClick = useCallback(() => {
    onChange?.(!playing);
  }, [onChange, playing]);
  return (
    <Fab
      color="primary"
      aria-label="play"
      className={clsx(classes.root, className)}
      size="large"
      onClick={handleClick}
    >
      {playing ? <PauseIcon /> : <PlayIcon />}
    </Fab>
  );
};

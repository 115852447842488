import { Position } from "@chartedsails/sailing-data";
import { toDegrees } from "@chartedsails/sailing-math";
import { InteractiveTrip, safeTimeSlice } from "@chartedsails/tracks";
import { RaceGateType } from "~/backend/graphql/globalTypes";
import { isNotNullish } from "~/components/util/isNotNullish";
import { RaceSegment } from "~/model/RaceSegment";
import { coordinatesToLonLat } from "~/util/coordinates-to-lonlat";
import { turf } from "~/util/turf-in-webapp";
import { GeoSegment } from "../../geometry/GeoSegment";
import { BoatMarkRoundingTimes } from "../model/results/MarkRoundingTimes";
import { findGateCenter } from "../utils/find-gate-center";
import { findMarkOrFail } from "../utils/find-mark-or-fail";
import { isGateLine, isWindOrientedBuoy } from "../utils/gate-types";
import { findTripIntersectionWithSegment } from "./findTripIntersectionWithSegment";

const segmentToCrossComingFromAnotherGate = (
  previousGateCenter: Position,
  thisGateCenter: Position
) => {
  const lineAngle = turf.bearingToAzimuth(
    turf.bearing(turf.point(previousGateCenter), turf.point(thisGateCenter))
  );

  const destinationPoint = turf.destination(
    turf.point(thisGateCenter),
    5000,
    lineAngle,
    { units: "meters" }
  ).geometry.coordinates as Position;
  return { a: thisGateCenter, b: destinationPoint };
};

const segmentsBoatsNeedToCross = (
  raceSegment: RaceSegment,
  gateIndex: number,
  twd: number
): GeoSegment[] => {
  const gate = raceSegment.raceConfig.gates[gateIndex];
  if (gate.type === RaceGateType.MARK) {
    if (gateIndex === 0) {
      throw new Error(`The first gate of a race must be a line.`);
    }

    const previousGateCenter = findGateCenter(
      raceSegment,
      raceSegment.raceConfig.gates[gateIndex - 1]
    );
    const thisGateCenter = findGateCenter(raceSegment, gate);

    return [
      segmentToCrossComingFromAnotherGate(previousGateCenter, thisGateCenter),
    ];
  } else if (gate.type === RaceGateType.GATE) {
    const previousGateCenter = findGateCenter(
      raceSegment,
      raceSegment.raceConfig.gates[gateIndex - 1]
    );
    const firstMark = coordinatesToLonLat(
      findMarkOrFail(raceSegment, gate.markId)
    );
    const secondMark = coordinatesToLonLat(
      findMarkOrFail(raceSegment, gate.secondMarkId!)
    );
    return [
      segmentToCrossComingFromAnotherGate(previousGateCenter, firstMark),
      segmentToCrossComingFromAnotherGate(previousGateCenter, secondMark),
    ];
  } else if (isGateLine(gate)) {
    return [
      {
        a: coordinatesToLonLat(
          findMarkOrFail(raceSegment.raceConfig, gate.markId)
        ),
        b: coordinatesToLonLat(
          findMarkOrFail(raceSegment.raceConfig, gate.secondMarkId ?? "missing")
        ),
      },
    ];
  }
  // If this race was defined with buoys that work differently depending on the wind.
  else if (isWindOrientedBuoy(gate)) {
    const angleForDirection = {
      [RaceGateType.UPWIND_BUOY]: 0,
      [RaceGateType.WING_BUOY]: -90,
      [RaceGateType.DOWNWIND_BUOY]: 180,
    };
    const lineAngle =
      (toDegrees(twd) +
        angleForDirection[gate.type as RaceGateType.UPWIND_BUOY]) %
      360;
    const markPosition = coordinatesToLonLat(
      findMarkOrFail(raceSegment.raceConfig, gate.markId)
    );
    const destPoint = turf.destination(
      turf.point(markPosition),
      5000,
      lineAngle,
      { units: "meters" }
    ).geometry.coordinates as Position;
    return [{ a: markPosition, b: destPoint }];
  } else {
    return [];
  }
};

export const findMarkRoundingTimesForBoat = (
  raceSegment: RaceSegment,
  fullTrip: InteractiveTrip,
  twd: number
): BoatMarkRoundingTimes => {
  const marksTime = new Array<number>();

  const trip = safeTimeSlice(
    fullTrip,
    raceSegment.startTime,
    raceSegment.endTime
  );
  if (!trip) {
    return { marksTime };
  }

  let time: number | null = raceSegment.raceConfig.gunTime;

  for (let i = 0; i < raceSegment.raceConfig.gates.length; i++) {
    let markSegments = segmentsBoatsNeedToCross(raceSegment, i, twd);

    // For gates, there are multiple segments the boats can cross. We pick the first one.
    const lastMarkCrossingTime: number = time;
    const crossingTimes = markSegments
      .map((s) =>
        findTripIntersectionWithSegment(trip, s, lastMarkCrossingTime)
      )
      .filter(isNotNullish);
    const roundingTimesSorted = crossingTimes.sort((a, b) => a - b);
    time = roundingTimesSorted.length > 0 ? roundingTimesSorted[0] : null;

    if (time !== null) {
      marksTime.push(time);
    } else {
      // Boat DNF
      return { marksTime };
    }
  }
  return Object.freeze({ marksTime: Object.freeze(marksTime) });
};

import { useMemo } from "react";
import { AnalyticsProperties } from "~/backend/analytics/AnalyticsProperties";
import { DaypassType } from "~/backend/graphql/globalTypes";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";

export const extractAnalyticsPropertiesFromImport = (
  track: ImportInfo | ImportInfoWithLicense | undefined
) => {
  if (!track) {
    return {};
  }
  const props: AnalyticsProperties = {
    fileName: track.displayName,
    fileExtension: track.displayName.split(".").pop(),
    fileSize: track.fileSize,
    origin:
      track.userAgent && track.userAgent.match(/Mac/)
        ? "vcc-mac"
        : "vcc-windows",
    trackAge: track.earliestPoint
      ? Date.now() - new Date(track.earliestPoint).getTime()
      : undefined,
    trackDuration:
      track.latestPoint && track.earliestPoint
        ? new Date(track.latestPoint).getTime() -
          new Date(track.earliestPoint).getTime()
        : undefined,
    trackHasBoatAssigned: !!track.boat?.name,
  };
  if ("licensed" in track) {
    props.trackLicensed = track.licensed;
    props.trackFreeFlexDayAvailable =
      track.licensingOptions?.dayByDayOption.daypassType ===
      DaypassType.FREEFLEXDAY;
    props.trackFreeTrialAvailable =
      !!track.licensingOptions?.monthlyOffer.freeTrialDays;
  }

  return props;
};

export const useAnalyticsImportProperties = (
  track: ImportInfoWithLicense | ImportInfo | undefined
) => {
  return useMemo(() => extractAnalyticsPropertiesFromImport(track), [track]);
};

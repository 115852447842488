import { toRadians } from "..";

export type PointOfSail = "upwind" | "reaching" | "downwind";

export const pointOfSail = (twa: number): PointOfSail => {
  if (Math.abs(twa) < toRadians(60)) {
    return "upwind";
  } else if (Math.abs(twa) > toRadians(120)) {
    return "downwind";
  } else {
    return "reaching";
  }
};

import { gql } from "@apollo/client";

export const FRAGMENT_SESSION_DESCRIPTION = gql`
  fragment SessionDescription on Session {
    id
    title
    startTime
    endTime
    boats {
      id
      name
    }
    owner {
      id
      name
      email
    }
  }
`;

import { Button, Link, makeStyles, Typography } from "@material-ui/core";
import { Formik, FormikConfig } from "formik";
import React, { RefObject, useCallback, useEffect } from "react";
import * as yup from "yup";
import { useLogin } from "~/backend/data-hooks/user/useLogin";
import { processApolloError } from "~/backend/utils/processApolloError";
import { useAnalyticEvent } from "~/components/analytics/useAnalyticEvent";
import LoadingButton from "~/components/buttons/LoadingButton";
import { TextFormField } from "~/components/formcomponents/TextFormField";
import { HorizontalSplitter } from "~/components/util/HorizontalSplitter";
import { productName } from "~/styles/chartedSailsTheme";

const initialFormValues = {
  email: "",
  password: "",
};

const loginFormSchema = yup.object({
  email: yup
    .string()
    .required("The email address you used to create your account.")
    .email("This is not a valid email address."),
  password: yup
    .string()
    .required("The password you chose.")
    .min(6, "Your password has at least 6 characters."),
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiLink-root": {
      textTransform: "none",
    },
    "& .MuiButton-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  errorMessage: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

interface IProps {
  onPasswordForgotten?: (email: string) => void;
  onComplete?: () => void;
  onSignup?: (email: string) => void;
  statusRef?: RefObject<{ error?: string }>;
  prefillEmail?: string;
}

export const LoginForm = ({
  onComplete,
  onSignup,
  onPasswordForgotten,
  statusRef,
  prefillEmail,
}: IProps) => {
  const classes = useStyles();
  const doLogin = useLogin();

  const loginSuccessEvent = useAnalyticEvent("login-complete");
  const loginErrorEvent = useAnalyticEvent("login-error");

  const handleLogin: FormikConfig<typeof initialFormValues>["onSubmit"] =
    useCallback(
      (values, actions) => {
        actions.setStatus();
        doLogin({ variables: values })
          .then(({ login: user }) => {
            if (user) {
              loginSuccessEvent({
                id: user.id,
                email: user.email,
                name: user.name ?? undefined,
                isSubscriber: !!user.subscription,
              });
              onComplete?.();
            } else {
              loginErrorEvent({ error: "invalid-login" });
              actions.setStatus("Email or password invalid.");
              if (statusRef && statusRef.current) {
                statusRef.current.error = "invalid-login";
              }
            }
            actions.setSubmitting(false);
          })
          .catch((e) => {
            const pe = processApolloError(e);
            loginErrorEvent({ error: pe.simplifiedError });
            if (Object.keys(pe.fieldErrors).length > 0) {
              actions.setErrors(pe.fieldErrors);
            } else {
              actions.setStatus(pe.simplifiedError);
            }
            if (statusRef && statusRef.current) {
              statusRef.current.error = pe.simplifiedError;
            }
            actions.setSubmitting(false);
          });
      },
      [doLogin, loginErrorEvent, statusRef, loginSuccessEvent, onComplete]
    );

  // Clear current error on load
  useEffect(() => {
    if (statusRef && statusRef.current) {
      statusRef.current.error = undefined;
    }
  });

  return (
    <React.Fragment>
      <Formik
        initialValues={{ ...initialFormValues, email: prefillEmail || "" }}
        onSubmit={handleLogin}
        validationSchema={loginFormSchema}
      >
        {({ isSubmitting, handleSubmit, status, setStatus, values }) => (
          <form className={classes.form} onChange={() => setStatus()}>
            <Typography variant="h4" align="center">
              Login to {productName}.
            </Typography>
            <Typography
              color="error"
              align="center"
              variant="body2"
              paragraph
              className={classes.errorMessage}
            >
              {status || " "}
            </Typography>
            <TextFormField
              label="Email address"
              name="email"
              variant="outlined"
            />
            <TextFormField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
            />
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isSubmitting}
              type="submit"
              size="large"
              onClick={handleSubmit as any}
            >
              Log in
            </LoadingButton>
            <HorizontalSplitter text="OR" />
            <Button
              variant="outlined"
              onClick={() => onSignup?.(values.email)}
              color="primary"
              size="large"
            >
              Sign up
            </Button>
            <Link
              onClick={() => onPasswordForgotten?.(values.email)}
              color="textPrimary"
              variant="caption"
              align="center"
              href="#"
            >
              Forgot password?
            </Link>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

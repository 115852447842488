/**
 * Calculate the average angle with optional time weighting.
 *
 */
export const averageAngles = (
  slice: number[] | Float32Array | Float64Array,
  time: number[] = new Array(slice.length).fill(1)
): number => {
  if (slice.length !== time.length) {
    throw new Error(
      `slice.length !== time.length (${slice.length} !== ${time.length})`
    );
  }
  let sum_x = 0;
  let sum_y = 0;
  for (let i = 0; i < slice.length; i++) {
    sum_x += Math.sin(slice[i]) * time[i];
    sum_y += Math.cos(slice[i]) * time[i];
  }
  let average_angle = (Math.atan2(sum_x, sum_y) + 2 * Math.PI) % (2 * Math.PI);
  return average_angle;
};

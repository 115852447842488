import React, { CSSProperties, useMemo } from "react";
import { MapContext } from "react-map-gl";
import { convertMetricDistanceToPixelsWithViewport } from "~/algo/projections/convertMetricDistanceToPixels";
import { ChartedSailsViewport } from "~/components/replay/map/ChartedSailsViewport";

export const useScalableMarkerStyle = (
  realWorldSizeInMeters: number,
  svgSizeInPixels: number,
  minSizeInPixels: number
): CSSProperties => {
  const context = React.useContext(MapContext);
  return useMemo(() => {
    let scale = 1;
    if (context.viewport) {
      const displaySizeInPixels = Math.max(
        minSizeInPixels,
        convertMetricDistanceToPixelsWithViewport(
          realWorldSizeInMeters,
          context.viewport as ChartedSailsViewport
        ) ?? minSizeInPixels
      );
      scale = displaySizeInPixels / svgSizeInPixels;
    }
    return { transform: `scale(${scale})` };
  }, [
    context.viewport,
    minSizeInPixels,
    realWorldSizeInMeters,
    svgSizeInPixels,
  ]);
};

import { useEffect } from "react";
import { useLocation } from "react-router";
import { analytics } from "../../backend/analytics/analytics";
import { useMe } from "../../backend/data-hooks/user/useMe";

export const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.page(location.pathname);
  }, [location.pathname]);

  const { data } = useMe();
  useEffect(() => {
    // Note: We clear user info in the useLogout mutation hook.
    if (data && data.me) {
      analytics.identifyUser(data.me);
    }
  }, [data]);
};

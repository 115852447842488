import { makeStyles } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import React from "react";
import { seaway } from "~/styles/chartedsailsColors";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    borderRadius: "100px",
    // Hide the small white border visible
    boxShadow: `inset 0px 0px 0px 2px ${seaway[100]}`,
  },
}));

interface IProps {
  className?: string;
}

export const BlueInfoIcon = ({ className }: IProps) => {
  const classes = useStyles();
  return (
    <InfoOutlined className={clsx(classes.root, className)} color="primary" />
  );
};

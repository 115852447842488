import { InteractiveTrip } from "../types/InteractiveTrip";

/**
 * Returns trip.timeSlice(start,end) but adjusts start and end to stay within bounds.
 *
 * If that is not possible, return null.
 *
 * @param trip
 * @param start
 * @param end
 */
export const safeTimeSlice = (
  trip: InteractiveTrip,
  start: number,
  end: number
) => {
  const s = Math.max(start, trip.time(0));
  const e = Math.min(end, trip.time(trip.length - 1));

  if (s === e) {
    return null
  }

  try {
    return trip.timeSlice(s, e);
  } catch { }
  return null;
};

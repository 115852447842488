import { toDegrees } from "@chartedsails/sailing-math";
import { safeTimeSlice } from "@chartedsails/tracks";
import { easeCubic } from "d3-ease";
import { FlyToInterpolator, ViewportProps, ViewState } from "react-map-gl";
import { boundsFromPoints } from "~/algo/projections/bounds-from-points";
import { boundsFromTrip } from "~/algo/projections/bounds-from-trip";
import {
  boundsCorners,
  outerBoundsOfBounds,
} from "~/algo/projections/bounds-operations";
import { SailingMark } from "~/backend/graphql/SailingMark";
import { PerfTimer } from "~/backend/utils/PerfTimer";
import { isNotNullish } from "~/components/util/isNotNullish";
import { isRaceSegment } from "~/model/RaceSegment";
import { chartedSailsTheme } from "~/styles/chartedSailsTheme";
import { fitBounds } from "~/util/MercatorFitBounds";
import { viewportFromTripsAndWind } from "../../map/viewportFromTripsAndWind";
import { calculateSpeedScaleMax } from "../../speedgradient/calculate-speed-scale-max";
import { standardMapAnimationDuration } from "../reducer-helpers";
import { Replay, replayCurrentTwd } from "../Replay";

const mapPaddingNotSmallScreen = {
  top: 32,
  bottom: 32,
  // Map Controls are on the right
  right: 16 + 80 + 32,
  // Replay Analysis panel is on the left
  left: 16 + 360 + 32,
};

const mapPaddingSmallScreen = {
  top: 16,
  bottom: 16,
  left: 16,
  right: 16,
};

export const replayMakeAllTracksFitView = (
  replay: Replay,
  transitionDuration: number = standardMapAnimationDuration
): Replay => {
  const pt = new PerfTimer("replayMakeAllTracksFitView");
  const twd = replayCurrentTwd(replay);
  const legIndex =
    replay.activePane === "racesetup"
      ? replay.currentGateIndex - 1
      : replay.selectedLegIndex;

  const boatDatasForTarget = Array.from(replay.boats.values())
    .map((b) => {
      if (!b.data) {
        return null;
      }
      if (isRaceSegment(replay.activeSegment)) {
        const boatStats = replay.raceAnalysis?.boats.get(b.id);
        if (legIndex !== undefined) {
          const legStats =
            legIndex === -1 ? boatStats?.start : boatStats?.legs[legIndex];
          return legStats
            ? safeTimeSlice(b.data, legStats.startTime, legStats.finishTime)
            : // If the boat does not finish this leg, we will include the entire race for that boat
              safeTimeSlice(
                b.data,
                replay.activeSegment.startTime,
                replay.activeSegment.endTime
              );
        } else {
          return safeTimeSlice(
            b.data,
            replay.activeSegment.startTime,
            replay.activeSegment.endTime
          );
        }
      } else if (replay.activeSegment) {
        return safeTimeSlice(
          b.data,
          replay.activeSegment.startTime,
          replay.activeSegment.endTime
        );
      } else {
        return b.data;
      }
    })
    .filter(isNotNullish);
  pt.mark("boatDatasForTarget");

  if (
    boatDatasForTarget.length > 0 &&
    replay.viewState.width &&
    replay.viewState.height
  ) {
    const viewport = viewportFromTripsAndWind(boatDatasForTarget, twd);
    pt.mark("viewport");
    const boundsToFitInView = boatDatasForTarget.map((bd) =>
      boundsFromTrip(bd, viewport)
    );
    pt.mark("boundsToFitInView");
    // Let's make sure all the marks are visible
    if (isRaceSegment(replay.activeSegment)) {
      const raceConfig = replay.activeSegment.raceConfig;
      let marks: SailingMark[] = raceConfig.marks;
      if (legIndex !== undefined) {
        if (legIndex === -1) {
          const startGate = raceConfig.gates[0];
          marks = raceConfig.marks.filter(
            (m) => m.id === startGate.markId || m.id === startGate.secondMarkId
          );
        } else {
          const activeGates = [
            raceConfig.gates[legIndex],
            raceConfig.gates[legIndex + 1],
          ];
          const activeMarksIds = activeGates.reduce((marks, gate) => {
            marks.add(gate.markId);
            if (gate.secondMarkId) {
              marks.add(gate.secondMarkId);
            }
            return marks;
          }, new Set<string>());
          marks = raceConfig.marks.filter((m) =>
            activeMarksIds.has(m.id ?? "")
          );
        }
      }
      if (marks.length > 0) {
        const marksBounds = boundsFromPoints(marks, viewport);
        boundsToFitInView.push(marksBounds);
      }
    }
    const enclosingBounds = outerBoundsOfBounds(boundsToFitInView, viewport);
    const boundingBox = boundsCorners(enclosingBounds, viewport);

    const isSmall =
      replay.viewState.width < chartedSailsTheme.breakpoints.values["md"];
    pt.mark("other...");

    const newViewport = fitBounds({
      width: replay.viewState.width,
      height: replay.viewState.height,
      bearing: toDegrees(replayCurrentTwd(replay)),
      points: [boundingBox.topLeft, boundingBox.downRight],
      padding: isSmall ? mapPaddingSmallScreen : mapPaddingNotSmallScreen,
    });
    pt.mark("fitBounds");

    const viewState = {
      ...newViewport,
      bearing: toDegrees(replayCurrentTwd(replay)),
    } as ViewState & Partial<ViewportProps>;

    if (transitionDuration) {
      viewState.transitionDuration = 1500;
      viewState.transitionInterpolator = new FlyToInterpolator();
      viewState.transitionEasing = easeCubic;
    }

    pt.mark("viewState prepared");
    const speedScaleMax = calculateSpeedScaleMax(boatDatasForTarget);
    pt.mark("speedScaleMax");
    pt.log();

    return { ...replay, viewState, speedScaleMax };
  }
  return replay;
};

import { default as MuiLink, LinkProps } from "@material-ui/core/Link";
import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

const NavLinkAdapter = React.forwardRef<
  RouterNavLink,
  { to: string; children: string }
>((props, ref) => <RouterNavLink innerRef={ref as null} exact {...props} />);

type ILinkProps =
  | ({ to: string; children: string } & Omit<LinkProps, "ref">)
  | { to: string; children: JSX.Element | null; className?: string };

export const NavLink = (props: ILinkProps) => {
  if (typeof props.children === "string") {
    return (
      <MuiLink
        component={NavLinkAdapter}
        {...props}
        children={props.children as string}
      />
    );
  } else {
    return <RouterNavLink {...props} />;
  }
};

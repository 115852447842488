import { RaceConfig } from "~/backend/graphql/RaceConfig";

/**
 * Remove unused marks from a race config and returns a a copy.
 * @param raceConfig
 */
export const cleanRaceConfig = (raceConfig: RaceConfig): RaceConfig => {
  const usedMarks = raceConfig.gates.reduce<Set<string>>((usedMarks, gate) => {
    usedMarks.add(gate.markId);
    if (gate.secondMarkId) {
      usedMarks.add(gate.secondMarkId);
    }
    return usedMarks;
  }, new Set());

  return {
    ...raceConfig,
    marks: raceConfig.marks.filter((m) => m.id && usedMarks.has(m.id)),
  };
};

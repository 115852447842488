export type UnmarkedSailingSegment = { interval: [number, number] };

export type SailingSegment =
  | LineSailingSegment
  | TurnSailingSegment
  | ManeuverSegment;

export interface LineSailingSegment {
  interval: [number, number];
  type: "line";
  debug?: any;
}

export interface TurnSailingSegment {
  interval: [number, number];
  type: "turn";
  debug?: any;
}

export function isManeuver(s: SailingSegment): s is ManeuverSegment {
  return s.type === "maneuver";
}

export type ManeuverType = "tack" | "gybe";
export type NauticalSide = "port" | "starboard";

export interface ManeuverSegment {
  interval: [number, number];
  type?: "maneuver";
  debug?: any;

  duration: number;
  entryCourse: number;
  exitCourse: number;
  bissectingCourse: number;
  entrySpeed: number;
  exitSpeed: number;
  angle: number;
  distanceLost?: number;

  // Only when TWD provided
  maneuverType?: ManeuverType;
  side?: NauticalSide;
  entryAngle?: number;
  exitAngle?: number;
}

// One export easy to use but without importing everything.

import { Position } from "@chartedsails/sailing-data";
import { toDegrees, toRadians } from "@chartedsails/sailing-math";
import bearing from "@turf/bearing";
import center from "@turf/center";
import destination from "@turf/destination";
import distance from "@turf/distance";
import { bearingToAzimuth, lineString, point, points } from "@turf/helpers";
import pointToLineDistance from "@turf/point-to-line-distance";

export const pdistance = (p0: Position, p1: Position) => {
  return distance(point(p0), point(p1), { units: "meters" });
};

export const turf = {
  bearing,
  bearingToAzimuth,
  center,
  destination,
  distance,
  pdistance,
  point,
  points,
};

const turfBearingDegrees = (azimuthRadians: number) =>
  toDegrees((azimuthRadians + 2 * Math.PI) % (2 * Math.PI));

export const csturf = {
  distance: (a: Position, b: Position) =>
    turf.distance(turf.point(a), turf.point(b), { units: "meters" }),
  distanceToLine: (x: Position, a: Position, b: Position) =>
    pointToLineDistance(turf.point(x), lineString([a, b]), { units: "meters" }),
  /** Azimuth is between 0 and 2*PI */
  azimuth: (a: Position, b: Position) =>
    toRadians(
      turf.bearingToAzimuth(turf.bearing(turf.point(a), turf.point(b)))
    ),
  /** Bearing is between -PI and +PI */
  bearing: (a: Position, b: Position) =>
    toRadians(turf.bearing(turf.point(a), turf.point(b))),
  destination: (origin: Position, distance: number, azimuth: number) =>
    turf.destination(
      turf.point(origin),
      distance,
      turfBearingDegrees(azimuth),
      { units: "meters" }
    ).geometry.coordinates as Position,
};

import { Table } from "apache-arrow";
import { SailingArrowSchemaType } from "./SailingArrowSchema";

export const sailingTableTimeBounds = (
  table: Table<SailingArrowSchemaType>
) => {
  const time = table.getChild("time");

  if (!time) {
    throw new Error("Sailing tables must have a time column");
  }

  return [time.get(0), time.get(time.length - 1)] as [number, number];
};

export const detectEnvironment = () => {
  if (typeof window === "object") {
    const location = window.location;
    if (location.hostname === "www.chartedsails.com") {
      return "prod";
    } else if (location.hostname === "beta.chartedsails.com") {
      return "beta";
    } else if (
      location.hostname.startsWith("deploy-preview") &&
      location.hostname.endsWith(".netlify.com")
    ) {
      return "preview";
    } else {
      return "development";
    }
  } else {
    // Web worker.
    // This technique probably does not work on preview sites but that seems like an acceptable compromise.
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      return "development";
    } else {
      return "prod";
    }
  }
};

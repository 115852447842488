import { ApolloProvider } from "@apollo/client";
import { CssBaseline } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import * as ReactDOM from "react-dom";
import { HeadProvider } from "react-head";
import App from "./App";
import { graphqlClient } from "./backend/graphql";
import { stripePromise } from "./backend/stripe";
import { FileManager } from "./components/filemanager/FileManagerContext";
import * as serviceWorker from "./serviceWorker";
import { chartedSailsTheme } from "./styles/chartedSailsTheme";
import { initSentry } from "./util/sentry";

initSentry();

ReactDOM.render(
  <MuiThemeProvider theme={chartedSailsTheme}>
    <CssBaseline />
    <HeadProvider>
      <ApolloProvider client={graphqlClient}>
        <Elements stripe={stripePromise}>
          <FileManager>
            <App />
          </FileManager>
        </Elements>
      </ApolloProvider>
    </HeadProvider>
  </MuiThemeProvider>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import { toDegrees } from "../units/angles";

declare global {
  namespace jest {
    interface Expect {
      toBeCloseToDegrees(expected: number): CustomMatcherResult;
    }
    interface Matchers<R> {
      toBeCloseToDegrees(expected: number): CustomMatcherResult;
    }
  }
}

// Expect is only defined when the tests are running
if (typeof expect !== 'undefined') {
  expect.extend({
    toBeCloseToDegrees(actual: number, expected: number) {
      const pass = Math.abs(toDegrees(actual) - expected) < 0.1;
      const message = pass
        ? () => `expected ${toDegrees(actual)} NOT to be close to ${expected}`
        : () => `expected ${toDegrees(actual)} to be close to ${expected}`;
      return { message, pass };
    },
  });
}

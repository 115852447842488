import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    width: 32,
    height: 32,
    borderRadius: "50%",
  },
  large: {
    width: 48,
    height: 48,
    "&:after": {},
  },
});

interface IProps {
  color: string;
  className?: string;
  size?: "normal" | "large";
  tooltip?: string;
}

export const BoatSymbol = ({ color, className, tooltip, size }: IProps) => {
  const classes = useStyles();

  const symbol = (
    <div
      className={clsx(
        classes.root,
        size === "large" && classes.large,
        className
      )}
      style={{ backgroundColor: color }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 36 36"
        style={{ filter: "drop-shadow(2px 2.5px 0px rgba(7, 52, 115, 0.3))" }}
      >
        <g
          id="boat"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          opacity={size === "large" ? 1 : 0.753417969}
        >
          <path
            d="M18.9304946,8.5 C20.7762857,10.374039 22.0444885,12.5444298 22.7970325,15.0329658 C23.7129768,18.0622369 23.7230878,21.1112523 22.8901978,24.158405 C22.6993535,24.8572813 22.4218438,25.5328741 22.1515563,26.2051141 C22.100821,26.3314036 21.8949908,26.467379 21.7590346,26.4694279 C19.9558539,26.4962505 18.1524926,26.5063089 16.3493119,26.4960642 C16.2052308,26.4953191 15.9894702,26.3479814 15.9291656,26.2110747 C14.1848451,22.2679745 14.0557498,18.248132 15.4405897,14.1683113 C16.1366207,12.1180631 17.2555064,10.3328738 18.6809706,8.74456944 C18.7512057,8.66633702 18.8308294,8.59723172 18.9304946,8.5"
            id="Fill-1-Copy-4"
            fill="#FFFFFF"
            transform="translate(19.000000, 17.500000) rotate(-270.000000) translate(-19.000000, -17.500000) "
          ></path>
        </g>
      </svg>
    </div>
  );

  return tooltip ? <Tooltip title={tooltip}>{symbol}</Tooltip> : symbol;
};

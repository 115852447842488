/* eslint-disable no-redeclare */
import { TrackPoint } from "@chartedsails/sailing-data";
import { shortAngleDist } from "./short-angle";

function trackpointWithWindDirection(tp: null | undefined, twd?: any): null;
function trackpointWithWindDirection<T extends TrackPoint>(tp: T, twd?: any): T;
function trackpointWithWindDirection<T extends TrackPoint>(
  tp: T | null | undefined,
  twd?: any
): T | null;

/**
 * Force a specific wind direction on the given trackpoint and calculates
 * twa and vmg.
 *
 * @param tp
 * @param twd
 * @returns
 */
function trackpointWithWindDirection<T extends TrackPoint>(
  tp: T | null | undefined,
  twd?: number
): T | null {
  if (
    tp === null ||
    tp === undefined ||
    tp.cog === undefined ||
    tp.sog === undefined ||
    twd === undefined
  ) {
    return tp ?? null;
  }
  const twa = shortAngleDist(tp.cog, twd);
  const vmg = Math.abs(Math.cos(twa) * tp.sog);

  return {
    ...tp,
    twd,
    twa,
    vmg,
  };
}

export { trackpointWithWindDirection };

export function isNullish<T>(value: T): value is T {
  // (A)
  return value === undefined || value === null;
}

// https://2ality.com/2020/06/type-guards-assertion-functions-typescript.html
export function isNotNullish<T>(value: T): value is NonNullable<T> {
  // (A)
  return value !== undefined && value !== null;
}

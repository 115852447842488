import { makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { LoginFlowDialog } from "~/components/dialogs/login/LoginFlowDialog";
import CSPage from "~/components/util/CSPage";
import { SEO } from "~/components/util/SEO";
import { useQuery } from "~/components/util/useQuery";

const useStyles = makeStyles((theme) => ({
  blank: {
    height: "80vh",
  },
}));

export const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const handleDismiss = useCallback(() => {
    history.push("/");
  }, [history]);

  const { data } = useMe();
  if (data?.me) {
    history.push("/");
  }

  const email = useQuery().get("email") as string | undefined;

  return (
    <CSPage>
      <SEO title={"Login"} description={"Login to use ChartedSails"} />
      <div className={classes.blank}></div>
      <LoginFlowDialog
        variant="login"
        open={true}
        onDismiss={handleDismiss}
        knownEmailAddress={email}
      />
    </CSPage>
  );
};

export default LoginPage;

import { useCallback } from "react";

export const useCloudUpload = () => {
  return useCallback(
    (signedUploadUrl: string, file: File, onProgress?: (p: number) => void) => {
      return new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedUploadUrl);
        xhr.onreadystatechange = (...args) => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve();
            } else {
              if (xhr.status === 0) {
                reject(new Error(`Network error during upload.`));
              } else {
                reject(
                  new Error(
                    `Upload error (${xhr.status}): ${xhr.responseText}.`
                  )
                );
              }
            }
          }
        };
        xhr.upload.addEventListener("progress", (e) => {
          // upload progress as percentage
          let percent_completed = e.loaded / e.total;
          onProgress?.(percent_completed);
        });

        xhr.send(file);
      });
    },
    []
  );
};

import { ReplayBoat } from "~/components/replay/replaycontext/Replay";
import { RaceSegment } from "~/model/RaceSegment";
import {
  BoatMarkRoundingTimes,
  MarkRoundingTimes,
} from "../model/results/MarkRoundingTimes";
import { findMarkRoundingTimesForBoat } from "./findMarkRoundingTimesForBoat";

export const findMarkRoundingTimes = (
  raceSegment: RaceSegment,
  boats: ReplayBoat[],
  twd: number
): MarkRoundingTimes => {
  const boatMarksTime = new Map<string, BoatMarkRoundingTimes>();

  for (const b of boats) {
    if (b.data) {
      const boatAnalysis = findMarkRoundingTimesForBoat(
        raceSegment,
        b.data,
        twd
      );
      boatMarksTime.set(b.id, boatAnalysis);
    }
  }
  return Object.freeze({
    boatMarksTime,
  });
};

import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import AppLogoWhite from "~/assets/img/chartedsails-logo-white.svg";
import AppLogo from "~/assets/img/chartedsails-logo-whitecircles.svg";
import { productName } from "~/styles/chartedSailsTheme";
import { Link } from "../link/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "none",
    marginRight: theme.spacing(1),
  },
  appLogo: {
    marginRight: theme.spacing(1),
    width: 38,
    verticalAlign: "bottom",
  },
  appLogoSmall: {
    marginLeft: 0,
    width: 38,
  },
  appLogotype: {
    fontSize: 24,
    letterSpacing: -0.5,
    color: theme.palette.text.primary,
  },
  appLogotypeSmall: {
    fontSize: 16,
  },
}));

export const Logotype = ({
  small,
  hideType,
  inverted,
}: {
  small?: boolean;
  hideType?: boolean;
  inverted?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Link to="/" className={classes.root}>
      <React.Fragment>
        <img
          className={clsx(classes.appLogo, small && classes.appLogoSmall)}
          src={inverted ? AppLogoWhite : AppLogo}
          alt={`${productName} logo`}
        />
        {!hideType && (
          <span
            className={clsx(
              classes.appLogotype,
              small && classes.appLogotypeSmall
            )}
          >
            {productName.toLowerCase()}
          </span>
        )}
      </React.Fragment>
    </Link>
  );
};

import { centerReplayOnBoat } from "./reducer-helpers";
import { Replay } from "./Replay";

export type ReplayBoatCardAction =
  | {
      event: "boatcard-click-boat";
      boatId: string;
    }
  | { event: "boatstats-update-visibleboats"; visibleBoats: string[] };

export const boatCardEventReducer = (
  replay: Replay,
  action: ReplayBoatCardAction
) => {
  switch (action.event) {
    case "boatcard-click-boat": {
      return {
        ...centerReplayOnBoat(replay, action.boatId),
        lockMapOnBoatId: action.boatId,
      };
    }
    case "boatstats-update-visibleboats": {
      const isLockedBoatInVisibleBoats =
        replay.lockMapOnBoatId &&
        action.visibleBoats.indexOf(replay.lockMapOnBoatId) !== -1;
      return {
        ...replay,
        lockMapOnBoatId: isLockedBoatInVisibleBoats
          ? replay.lockMapOnBoatId
          : undefined,
        visibleBoats: action.visibleBoats,
      };
    }
  }
  return replay;
};

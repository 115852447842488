import { shortAngleDist } from "./short-angle";

/**
 * Interpolate the value of an angle at a given distance between a and b.
 *
 * @param a
 * @param b
 * @param distance
 */
export function interpolateAngles(
  a: number | null | undefined,
  b: number | null | undefined,
  distance: number
) {
  if (a === null || a === undefined) {
    return b;
  }
  if (b === null || b === undefined) {
    return a;
  }
  return (2 * Math.PI + a + shortAngleDist(a, b) * distance) % (2 * Math.PI);
}

import { Table } from "apache-arrow";
import { binarySearchInTime } from "./binarySearchInTime";
import { SailingArrowSchemaType } from "./SailingArrowSchema";
import { sailingTableTimeBounds } from "./sailingTableTimeBounds";

export const splitTableAtTime = (
  table: Table<SailingArrowSchemaType>,
  time: number
) => {
  const times = table.getChild("time");
  if (times === null) {
    throw new Error(`Sailing tables must have a time column (time:${times}).`);
  }
  const [start, end] = sailingTableTimeBounds(table);

  if (time <= start) {
    return [null, table];
  } else if (time >= end) {
    return [table, null];
  } else {
    let cutofIndex = binarySearchInTime(times, time);
    if (times.get(cutofIndex) === time) {
      cutofIndex--;
    }
    if (cutofIndex < 0) {
      throw new Error(`assert(cutof ${cutofIndex} >0)`);
    }
    const t1a = table.slice(0, cutofIndex);
    const t1b = table.slice(cutofIndex);
    return [t1a, t1b];
  }
};

/**
 * Returns two subset of table that do not include the interval [time1, time2]. If the subset would be empty, null is returned.
 * @param table
 * @param time1
 * @param time2
 * @returns
 */
export const splitTableAroundTimes = (
  table: Table<SailingArrowSchemaType>,
  time1: number,
  time2: number
) => {
  let [before, after] = splitTableAtTime(table, time1);

  if (after) {
    after = splitTableAtTime(after, time2)[1];
  }
  return [before, after];
};

import { Field, FieldProps, getIn } from "formik";
import React from "react";

export function FormikizeInputField<P>(Component: React.ComponentType<P>) {
  return (props: P & { name: string }) => {
    return (
      <Field name={props.name}>
        {({ field, form }: FieldProps) => {
          const errorText =
            getIn(form.touched, field.name) && getIn(form.errors, field.name);

          return (
            <Component
              {...field}
              {...props}
              // Always include a helper text to avoid layout moving
              helperText={errorText || " "}
              error={!!errorText}
            />
          );
        }}
      </Field>
    );
  };
}

import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useKnownBoats } from "~/backend/data-hooks/useKnownBoats";
import { ImportInfo } from "~/backend/graphql/ImportInfo";
import { ImportInfoWithLicense } from "~/backend/graphql/ImportInfoWithLicense";
import { processApolloError } from "~/backend/utils/processApolloError";
import { AddBoatContent } from "./AddBoatContent";
import { AssignBoatBox } from "./AssignBoatBox";
import { UploadTrackLoadingContent } from "./UploadTrackLoadingContent";

interface IProps {
  track: ImportInfo;
  onBoatAssigned: (trackWithBoat: ImportInfoWithLicense) => void | Promise<any>;
  onBack: () => void;
}

export const AddOrAssignBoat = ({ track, onBoatAssigned, onBack }: IProps) => {
  const [newBoatName, updateNewBoatName] = useState<string>();

  const { data, loading, error } = useKnownBoats();

  useEffect(() => {
    if (data?.me?.boats !== undefined) {
      if (data.me.boats.length === 0) {
        updateNewBoatName("");
      }
    }
  }, [data]);

  if (error) {
    return (
      <Typography color="error" paragraph>
        {processApolloError(error).simplifiedError}
      </Typography>
    );
  }
  if (loading) {
    return <UploadTrackLoadingContent />;
  }

  if (newBoatName === undefined) {
    return (
      <AssignBoatBox
        track={track}
        onBoatAssigned={onBoatAssigned}
        onBack={onBack}
        onCreateNewBoat={(name) => updateNewBoatName(name)}
      />
    );
  } else {
    return (
      <AddBoatContent
        track={track}
        initialBoatName={newBoatName}
        onBoatAssigned={onBoatAssigned}
        onBack={() => updateNewBoatName(undefined)}
      />
    );
  }
};

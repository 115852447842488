import { ktsToMs, msToKts } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";

export const DEFAULT_SPEED_SCALE_MAX = ktsToMs(10);
export const MAXIMUM_SPEED_SCALE = ktsToMs(35);

export const calculateSpeedScaleMax = (trips: InteractiveTrip[]) => {
  const maxSpeeds = trips.map((t) => {
    return t.maxSpeed(t.length > 100 ? 0.999 : 1);
  });
  const maxSpeed = Math.min(Math.max(...maxSpeeds), MAXIMUM_SPEED_SCALE);
  return ktsToMs(Math.ceil(msToKts(maxSpeed))) || DEFAULT_SPEED_SCALE_MAX;
};

import {
  FlaggedFeatureName,
  flaggedFeatures
} from "@chartedsails/shared-config";
import posthog from "posthog-js";
import { useMe } from "~/backend/data-hooks/user/useMe";
import { detectEnvironment } from "~/util/detect-environment";
import { isNotNullish } from "./isNotNullish";
import { useQuery } from "./useQuery";

export const useFeatureFlag = (featureName: FlaggedFeatureName): boolean => {
  const { data } = useMe();

  const feature = flaggedFeatures[featureName];
  const query = useQuery();
  const queryParam = feature.urlParameter
    ? query.get(feature.urlParameter)
    : undefined;

  // First look at what the URL says
  if (queryParam !== undefined) {
    if (queryParam === "true" || queryParam === "t" || queryParam === "1") {
      return true;
    }
    if (queryParam === "false" || queryParam === "f" || queryParam === "0") {
      return false;
    }
  }

  // Do we have a user specific value?
  if (data?.me?.flags && featureName in data?.me?.flags) {
    const flags = data.me.flags as unknown as {
      [key: string]: boolean | null | undefined;
    };
    const flag = flags[featureName];
    if (isNotNullish(flag)) {
      return flag;
    }
  }

  // Does posthog have an opinion? -- decideEndpointWasHit is not really documented but seems to work to make sure posthog has not been blocked by browser
  if (posthog.__loaded && posthog.decideEndpointWasHit) {
    const allFlags = posthog.featureFlags.getFlagVariants();
    if (featureName in allFlags) {
      return !!allFlags[featureName];
    }
  }

  // Fallback to whatever the default is for the current environment
  const env = detectEnvironment();
  if (env in feature && feature[env]) {
    return true;
  }

  // Default to false
  return false;
};

import { useEffect } from "react";
import { useFPSEvent } from "~/components/util/useFPSEvent";
import { Replay } from "../replaycontext/Replay";
import { ReplayDispatch } from "../replaycontext/useReplayReducer";

interface IArgs {
  replay: Replay;
  dispatch: ReplayDispatch;
  autoStart?: boolean;
  autoLoop?: boolean;
}

export const useAnimatedPlayback = ({ replay, dispatch, autoLoop }: IArgs) => {
  // Schedule next animation
  // Note that each update of playback will trigger a re-render and therefore
  // the animate function does not need to re-schedule itself.
  // And in case we get rendered multiple time we need to cancel the callback request.

  const fpsEvent = useFPSEvent({ eventName: "session-playback-fps" });

  useEffect(() => {
    // If we are playing and the video is not in charge, then let's schedule another animation frame.
    if (replay.playing && !replay.activeVideo) {
      const id = requestAnimationFrame(() => {
        dispatch({ event: "animation-newframe", autoLoop });
        fpsEvent(true);
      });
      return () => {
        cancelAnimationFrame(id);
      };
    } else {
      fpsEvent(false);
    }
  });
};

/*

Here is a version of useAnimatedPlayback that avoids scheduling/canceling animation frames on every render like ours currently does.

But it is more complicated to read, needs a workaround for a weird bug and does not perform better in Chrome so I am going to keep the existing version and just leave this one here settle for a bit.


export const useAnimatedPlayback = ({ replay, dispatch, autoLoop }: IArgs) => {
  // Schedule next animation
  // Note that each update of playback will trigger a re-render and therefore
  // the animate function does not need to re-schedule itself.
  // And in case we get rendered multiple time we need to cancel the callback request.

  const fpsEvent = useFPSEvent({ eventName: "session-playback-fps" })

  const animationFrame = useRef<number>()

  // Workaround for a bug:
  // When dispatch(animation-new-frame) causes the animation to end (when playbar hits end of timeline), the app
  // is re-rendered synchronously (in the call to dispatch()) which cancels the next frame but not the current execution of
  // animateFrame which continues and reschedules itself.
  // This ref helps use make sure we do not do that.
  const keepPlaying = useRef<boolean>(false)

  const animateFrame = useCallback(() => {
    dispatch({ event: "animation-newframe", autoLoop })
    fpsEvent(true)
    if (keepPlaying.current) {
      animationFrame.current = requestAnimationFrame(animateFrame)
    }
  }, [autoLoop, dispatch, fpsEvent])

  // Important to use a layoutEffect otherwise the cancelAnimationFrame could be called after the callback ran one more time
  // and then it keeps running on its own. See this article: https://blog.jakuba.net/request-animation-frame-and-use-effect-vs-use-layout-effect/
  useLayoutEffect(() => {
    if (replay.playing && !replay.activeVideo) {
      animationFrame.current = requestAnimationFrame(animateFrame)
      keepPlaying.current = true
    } else {
      fpsEvent(false)
    }
    return () => {
      if (animationFrame.current !== undefined) {
        cancelAnimationFrame(animationFrame.current)
        keepPlaying.current = false
      }
    }
  }, [
    replay.playing,
    replay.activeVideo,
    dispatch,
    autoLoop,
    fpsEvent,
    animateFrame,
  ])
}

*/

import { Button, ButtonProps, Link, LinkProps } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { AnalyticsProps } from "~/components/analytics/AnalyticsProps";
import { ContactDialog } from "~/components/dialogs/contact/ContactDialog";

interface IProps {
  actionName: string;
  prompt?: string;
}
interface ContactButtonProps extends Omit<ButtonProps, "onClick" | "variant"> {
  variant: "contained" | "outlined" | "text";
}
interface ContactLinkProps extends Omit<LinkProps, "onClick" | "variant"> {
  variant: "link";
}

export const ContactButton = ({
  actionName,
  ...props
}: IProps & (ContactButtonProps | ContactLinkProps)) => {
  const [contactDialogVisible, updateContactDialogVisible] = useState(false);

  const handleClick = useCallback(
    (e) => {
      if (props.variant === "link") {
        e.preventDefault();
      }
      updateContactDialogVisible(true);
    },
    [props.variant]
  );
  const handleClose = useCallback(() => updateContactDialogVisible(false), []);

  if (props.variant === "link") {
    return (
      <>
        <Link
          {...{ ...props, variant: undefined }}
          onClick={handleClick}
          href="#"
        />
        <AnalyticsProps actionName={actionName}>
          <ContactDialog
            open={contactDialogVisible}
            onClose={handleClose}
            prompt={props.prompt}
            actionName={actionName}
          />
        </AnalyticsProps>
      </>
    );
  } else {
    return (
      <>
        <Button {...props} onClick={handleClick} />
        <AnalyticsProps actionName={actionName}>
          <ContactDialog
            open={contactDialogVisible}
            onClose={handleClose}
            prompt={props.prompt}
            actionName={actionName}
          />
        </AnalyticsProps>
      </>
    );
  }
};

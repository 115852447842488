import { Position, TrackPoint } from "@chartedsails/sailing-data";
import {
  arrayMinMax,
  averageAngles,
  distance,
  rhumbBearing,
  shortAngleDist,
} from "@chartedsails/sailing-math";
import { BracketData } from "../types/InteractiveTrip";
import { sliceSailingArray } from "./sliceSailingArray";
import { SailingDataArray } from "./types";

type TimeAggregation = { start: number; end: number; duration: number };
type SpeedAggregation = { min: number; max: number; average: number };
type DirectionAggregation = { average: number };
type PositionAggregation = {
  distance: number;
  courseSailed: number;
  straightLineDistance: number;
};
type PitchRollAggregation = { min: number; max: number; average: number };

type SailingDataAggregation = {
  // TODO: Find a better way to write this that will extend better
  [k in keyof (TrackPoint &
    Pick<Required<TrackPoint>, "position" | "sog">)]: k extends "time"
  ? TimeAggregation
  : k extends "position"
  ? PositionAggregation
  : k extends "sog"
  ? SpeedAggregation
  : k extends "twd"
  ? DirectionAggregation
  : k extends "pitch" | "roll"
  ? PitchRollAggregation
  : never;
};

// Calculates an average taking in consideration the time between each value
const timeCorrectAverage = (
  slice: number[] | Float32Array | Float64Array,
  time: number[]
) => {
  let timeWeightedSum = 0;
  let duration = time[time.length - 1] - time[0];

  for (let i = 0; i < slice.length - 1; i++) {
    const thisAverage = (slice[i] + slice[i + 1]) / 2;
    timeWeightedSum += thisAverage * (time[i + 1] - time[i]);
  }
  return timeWeightedSum / duration;
};

// Calculates an average taking in consideration the time between each value
const timeCorrectAverageAngle = (
  slice: number[] | Float32Array | Float64Array,
  time: number[]
) => {
  let angles = [];
  let times = [];
  for (let i = 0; i < slice.length - 1; i++) {
    angles.push(averageAngles([slice[i], slice[i + 1]]));
    times.push(time[i + 1] - time[i]);
  }
  return averageAngles(angles, times);
};

const minMaxAverage = (
  slice: number[] | Float32Array | Float64Array,
  time: number[]
) => {
  let [min, max] = arrayMinMax(slice);
  return { min, max, average: timeCorrectAverage(slice, time) };
};

const computePositionStats = (
  latitude: Float64Array,
  longitude: Float64Array
): PositionAggregation => {
  if (latitude.length !== longitude.length) {
    throw new Error(`latitude.length !== longitude.length`);
  }

  // Calculate total distance sailed
  let d = 0;
  for (let i = 0; i < latitude.length - 1; i++) {
    if (i + 1 < latitude.length) {
      d += distance(
        [longitude[i], latitude[i]],
        [longitude[i + 1], latitude[i + 1]]
      );
    }
  }

  // Calculate angle from start/end position
  const p0: Position = [longitude[0], latitude[0]];
  const pN: Position = [
    longitude[longitude.length - 1],
    latitude[latitude.length - 1],
  ];

  return {
    distance: d,
    courseSailed: rhumbBearing(p0, pN),
    straightLineDistance: distance(p0, pN),
  };
};

export const aggregateSailingData = (
  sailingArrays: SailingDataArray,
  start: number,
  end: number
): SailingDataAggregation | null => {
  if (
    start < sailingArrays.time[0]
    || end <= start
    || end > sailingArrays.time[sailingArrays.time.length - 1]
  ) {
    return null;
  }
  const slice = sliceSailingArray(sailingArrays, start, end);

  const stats: SailingDataAggregation = {
    time: {
      start: slice.time[0],
      end: slice.time[slice.time.length - 1],
      duration: slice.time[slice.time.length - 1] - slice.time[0],
    },
    sog: minMaxAverage(slice.sog, slice.time),
    position: computePositionStats(slice.latitude, slice.longitude),
  };

  if (slice.pitch) {
    stats.pitch = minMaxAverage(slice.pitch, slice.time);
  }
  if (slice.roll) {
    stats.roll = minMaxAverage(slice.roll, slice.time);
  }
  if (slice.twd) {
    stats.twd = { average: timeCorrectAverageAngle(slice.twd, slice.time) };
  }

  return stats;
};

export const bracketData = (
  sailingArrays: SailingDataArray,
  start: number,
  end: number,
  { trueWindDirection }: { trueWindDirection?: number } = {}
): BracketData | null => {
  let stats = aggregateSailingData(sailingArrays, start, end);

  if (!stats) {
    return null;
  }

  let averageTrueWindAngle = undefined;
  let averageVelocityMadeGood = undefined;

  const duration = stats.time.duration;
  if (trueWindDirection !== undefined && stats.position) {
    averageTrueWindAngle = shortAngleDist(
      stats.position.courseSailed,
      trueWindDirection
    );
    const distanceMadeGood =
      stats.position.straightLineDistance * Math.cos(averageTrueWindAngle);
    averageVelocityMadeGood = distanceMadeGood / (duration / 1000);
  }

  return {
    duration,
    distance: stats.position.distance,
    averageSpeedOverGround: stats.sog.average,
    courseSailed: stats.position.courseSailed,
    maxSpeed: stats.sog.max,
    minSpeed: stats.sog.min,
    averageTrueWindAngle,
    averageVelocityMadeGood,
    averagePitch: stats["pitch"]?.average ?? null,
    averageRoll: stats["roll"]?.average ?? null,
  };
};

import { InputAdornment, TextField, TextFieldProps } from "@material-ui/core";
import * as geolib from "geolib";
import React, { useCallback, useEffect } from "react";

interface IProps {
  className?: string;
  type: "latitude" | "longitude";
  value?: number;
  onChange: (v: number | undefined) => void;
  variant?: TextFieldProps["variant"];
}

const CoordinateField = ({
  value,
  onChange,
  type,
  className,
  variant,
}: IProps) => {
  const [textValue, updateTextValue] = React.useState<string>("");
  const [error, updateError] = React.useState(false);
  const [focused, updateFocused] = React.useState(false);

  const handleBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.replace("º", "°");
      if (geolib.isSexagesimal(e.target.value)) {
        updateError(false);
        onChange(geolib.sexagesimalToDecimal(e.target.value));
        // Force the update in case the text value changed but not the decimal value
        updateTextValue(
          geolib.decimalToSexagesimal(
            geolib.sexagesimalToDecimal(e.target.value)
          )
        );
      } else if (geolib.isDecimal(e.target.value)) {
        updateError(false);
        onChange(Number.parseFloat(e.target.value));
      } else {
        updateError(true);
      }
      updateFocused(false);
    },
    [onChange]
  );
  const sign =
    value !== undefined
      ? type === "latitude"
        ? value > 0
          ? "N"
          : "S"
        : value > 0
        ? "E"
        : "W"
      : "";
  const handleFocus = useCallback(() => {
    if (value !== undefined && !error) {
      updateTextValue(textValue + " " + sign);
    }
    updateFocused(true);
  }, [value, textValue, sign, updateTextValue, error]);

  useEffect(() => {
    updateTextValue(
      value !== undefined ? geolib.decimalToSexagesimal(value) : ""
    );
  }, [value]);

  return (
    <TextField
      id={type}
      error={error}
      variant={variant}
      className={className}
      label={type === "latitude" ? "Latitude" : "Longitude"}
      value={textValue}
      onChange={(e) => updateTextValue(e.target.value)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      InputProps={{
        endAdornment: !focused && (
          <InputAdornment position="end">{sign}</InputAdornment>
        ),
      }}
    />
  );
};

export default CoordinateField;

import { useCallback, useRef } from "react";
import { AnalyticsProperties } from "../../backend/analytics/AnalyticsProperties";
import { useAnalyticEvent } from "../analytics/useAnalyticEvent";

interface IProps {
  eventName: string;
}

/**
 * Returns a trigger function that should be called from an animation frame (from requestAnimationFrame).
 *
 * Pass true when we are actively playing and want to measure fps.
 * Pass false to the function when animation is stopped.
 *
 */
export const useFPSEvent = ({ eventName }: IProps) => {
  const frameRenderingTime = useRef<number[]>([]);
  const lastFrame = useRef<number>();
  const lastFPSEvent = useRef<number>();

  const fpsEvent = useAnalyticEvent(eventName);
  const fpsEventPeriod = 3000;

  const triggerFrameRender = useCallback(
    (animating: boolean, extraProperties?: AnalyticsProperties) => {
      if (animating) {
        if (lastFrame.current) {
          frameRenderingTime.current.push(Date.now() - lastFrame.current);
          lastFrame.current = Date.now();

          if (
            lastFPSEvent.current &&
            Date.now() - lastFPSEvent.current > fpsEventPeriod
          ) {
            const avg =
              frameRenderingTime.current.reduce((sum, x) => sum + x, 0) /
              frameRenderingTime.current.length;
            const max = frameRenderingTime.current.reduce(
              (max, x) => Math.max(max, x),
              0
            );
            const min = frameRenderingTime.current.reduce(
              (max, x) => Math.min(max, x),
              0
            );
            fpsEvent({
              fps: 1000 / avg,
              frameMinTime: min,
              frameMaxTime: max,
              frameAvgTime: avg,
              ...extraProperties
            });
            frameRenderingTime.current = [];
            lastFPSEvent.current = Date.now();
          }
        } else {
          lastFrame.current = Date.now();
          lastFPSEvent.current = Date.now();
        }
      } else {
        lastFrame.current = undefined;
        lastFPSEvent.current = undefined;
      }
    },
    [fpsEvent]
  );
  return triggerFrameRender;
};

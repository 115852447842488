import { TripNumericVariable } from "@chartedsails/tracks";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { carbon } from "../../../styles/chartedsailsColors";
import { fontLato } from "../../../styles/chartedSailsTheme";
import { useDataFormatter } from "../../data-format/useDataFormatter";
import { MapTooltip } from "./MapTooltip";

interface IProps {
  className?: string;
  x: number;
  y: number;
  boatName: string;
  variable: TripNumericVariable;
  value: number;
  boatColor: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    pointerEvents: "none",
    position: "absolute",
    width: 0,
    height: 0,
  },
  tooltip: {},
  boatName: {
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.text.primary,
    margin: 4,
  },
  data: {
    ...fontLato,
    fontSize: 12,
    margin: 4,
  },
  label: {
    color: carbon[800],
    fontSize: 14,
  },
  value: {
    ...fontLato,
    color: carbon[900],
    fontWeight: 900,
    marginLeft: 4,
    marginRight: 4,
  },
  unit: {
    fontSize: 14,
    color: carbon[600],
  },
}));

export const BoatTooltip = ({
  className,
  x,
  y,
  variable,
  value,
  boatName,
  boatColor,
}: IProps) => {
  const classes = useStyles();
  const { formattedValue, unit } = useDataFormatter()(variable, value);

  let data = (
    <div className={classes.tooltip} style={{}}>
      <div className={classes.boatName}>{boatName}</div>
      <div className={classes.data}>
        <span>{formattedValue}</span>
        <span className={classes.unit}>{unit}</span>
      </div>
    </div>
  );

  return (
    <MapTooltip
      style={{ pointerEvents: "none" }}
      title={data}
      open
      arrow
      placement="right"
    >
      <div
        className={clsx(classes.root, className)}
        style={{
          top: y,
          left: x,
        }}
      />
    </MapTooltip>
  );
};

import { gql, useLazyQuery } from "@apollo/client";
import {
  GetSubscriptionOffer,
  GetSubscriptionOfferVariables,
} from "~/backend/graphql/GetSubscriptionOffer";
import { SubscriptionPlan } from "~/backend/graphql/globalTypes";
import { useWebSession } from "~/components/websession/WebSessionContext";
import { FRAGMENT_SUBSCRIPTION_OFFER } from "./fragmentOffer";

const GET_SUBSCRIPTION_OFFER = gql`
  query GetSubscriptionOffer($plan: SubscriptionPlan!, $affiliateCode: String) {
    subscriptionOffer(plan: $plan, affiliateCode: $affiliateCode) {
      ...SubscriptionOffer
    }
  }
  ${FRAGMENT_SUBSCRIPTION_OFFER}
`;

export const useLazySubscriptionOffer = (plan: SubscriptionPlan) => {
  const affiliateCode = useWebSession().affiliateCode;
  return useLazyQuery<GetSubscriptionOffer, GetSubscriptionOfferVariables>(
    GET_SUBSCRIPTION_OFFER,
    { variables: { plan, affiliateCode } }
  );
};

import { gql, useQuery } from "@apollo/client";
import { GetSession, GetSessionVariables } from "../../graphql/GetSession";
import { FRAGMENT_SESSION } from "./fragmentSession";

export const GET_SESSION = gql`
  query GetSession($sessionId: ID!) {
    session(id: $sessionId) {
      ...SessionInfo
    }
  }
  ${FRAGMENT_SESSION}
`;

export const useSession = (sessionId: string) => {
  return useQuery<GetSession, GetSessionVariables>(GET_SESSION, {
    variables: {
      sessionId,
    },
  });
};

import { shortAngleDist, toRadians } from "@chartedsails/sailing-math";
import { InteractiveTrip } from "@chartedsails/tracks";
import {
  sailingManeuverTolerance,
  simplifyDouglasPeucker
} from "../douglas-peucker/douglas-peucker";
import { findTurnBounds } from "../turns-bounds/turns-bounds";
import { SailingSegment, UnmarkedSailingSegment } from "../types";
import {
  makeSegmentsFromIndices,
  sailingSegmentCourse,
  sailingSegmentMidpointAngle
} from "./sailing-segment";

export const breakTripInSegment = (trip: InteractiveTrip, keyPoints?: number[]): SailingSegment[] => {
  // Calculating the keypoints is the most expensive part of this algorithm. The caller can choose to do this
  // prior (maybe in a worker thread for example) and pass the result in as a parameter.
  if (!keyPoints) {
    keyPoints = simplifyDouglasPeucker(
      trip.getPositions(),
      sailingManeuverTolerance
    );
  }
  let basicSegments = makeSegmentsFromIndices(trip, keyPoints);
  let segments = markSegmentType(trip, basicSegments, toRadians(30));
  segments = mergeStraightLineSegments(trip, segments, toRadians(30));
  segments = mergeConsecutiveTurns(segments);
  segments = expandTurnSegments(trip, segments);
  // Make sure there is a turn between every line segment
  segments = insertTurnSegments(trip, segments);
  // Creating the turns may have created empty line segments, so we filter them out.
  segments = filterOutEmptyLineSegments(segments);
  // And now we may have consecutive turns, so we merge them.
  segments = mergeConsecutiveTurns(segments);

  return segments;
};

/**
 * Measure angle between [start point, midpoint] and [midpoint, endpoint]
 * to distinguish turns from straight lines.
 * (in place change)
 * @param trip
 * @param sourceSegments
 * @param minimumDeltaAngle
 */
export const markSegmentType = (
  trip: InteractiveTrip,
  segments: UnmarkedSailingSegment[],
  minimumDeltaAngle: number
) => {
  return segments.map<SailingSegment>((s) => {
    if (Math.abs(sailingSegmentMidpointAngle(trip, s)) > minimumDeltaAngle) {
      return { ...s, type: "turn", debug: "marked" };
    } else {
      return { ...s, type: "line" };
    }
  });
};

export const mergeStraightLineSegments = (
  trip: InteractiveTrip,
  sourceSegments: SailingSegment[],
  minimumDeltaAngle: number
) => {
  const segments = [...sourceSegments];
  for (let i = 0; i < segments.length - 1; i++) {
    if (segments[i].type === "line" && segments[i + 1].type === "line") {
      const angle1 = sailingSegmentCourse(trip, segments[i]);
      const angle2 = sailingSegmentCourse(trip, segments[i + 1]);
      const deltaAngle = shortAngleDist(angle1, angle2);

      if (Math.abs(deltaAngle) < minimumDeltaAngle) {
        segments[i].interval[1] = segments[i + 1].interval[1];
        segments.splice(i + 1, 1);
        i--;
      }
    }
  }
  return segments;
};

export const mergeConsecutiveTurns = (sourceSegments: SailingSegment[]) => {
  const segments = [...sourceSegments];
  for (let i = 0; i < segments.length - 1; i++) {
    if (segments[i].type === "turn" && segments[i + 1].type === "turn") {
      segments[i].interval[1] = segments[i + 1].interval[1];
      segments.splice(i + 1, 1);
      i--;
    }
  }
  return segments;
};

export const expandTurnSegments = (
  trip: InteractiveTrip,
  sourceSegments: readonly SailingSegment[]
) => {
  const segments = [...sourceSegments];
  for (let i = 0; i < segments.length; i++) {
    if (segments[i].type === "turn" && i > 0 && i + 1 < segments.length) {
      const turn = findTurnBounds(trip, segments[i - 1], segments[i + 1]);
      const turnBounds = turn.bounds;

      segments[i - 1].interval[1] = turnBounds[0];
      segments[i].interval[0] = turnBounds[0];
      segments[i].interval[1] = turnBounds[1];
      segments[i].debug = turn.debug;
      segments[i + 1].interval[0] = turnBounds[1];
    }
  }
  return segments;
};

/**
 * Insert 'turn' sailing segment between consecutive and joint 'line' segment.
 *
 * @param trip
 * @param sourceSegments
 */
export const insertTurnSegments = (
  trip: InteractiveTrip,
  sourceSegments: readonly SailingSegment[]
) => {
  const segments = [...sourceSegments];
  for (let i = 0; i < segments.length - 1; i++) {
    if (segments[i].type === "line" && segments[i + 1].type === "line") {
      const turn = findTurnBounds(trip, segments[i], segments[i + 1]);
      const turnBounds = turn.bounds;

      segments[i].interval[1] = turnBounds[0];
      segments[i + 1].interval[0] = turnBounds[1];
      segments.splice(i + 1, 0, {
        type: "turn",
        interval: turnBounds,
        debug: turn.debug,
      });
    }
  }
  return segments;
};

export const filterOutEmptyLineSegments = (sourceSegments: readonly SailingSegment[]) => {
  return sourceSegments.filter((s) => s.type !== 'line' || s.interval[0] !== s.interval[1]);
};

import { makeStyles } from "@material-ui/core";
import React from "react";
import { SeasonalPromoBanner } from "../banner/SeasonalPromoBanner";
import { IntercomButton } from "../buttons/intercom/IntercomButton";
import { ElevatingNavBar } from "../navbar/ElevatingMenuBar";
import { NavBar } from "../navbar/NavBar";
import { CSFooter } from "./CSFooter";

interface IProps {
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  intercomButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}));

const CSPage = ({ children }: IProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ElevatingNavBar banner={<SeasonalPromoBanner />}>
        <NavBar position="static" elevation={0} />
      </ElevatingNavBar>
      {children}
      <CSFooter dark={true} />
      <IntercomButton className={classes.intercomButton} />
    </React.Fragment>
  );
};

export default CSPage;
